import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';


import PK from "../../Icons/airlines/PK.png"
import Blue from "../../Icons/airlines/PA.png"
import Fly from "../../Icons/airlines/jinnah.png"
import Serene from "../../Icons/airlines/serenee.png"
import Askari from "../../Icons/airlines/ask.png"
import Sial from "../../Icons/airlines/PF.png"
import Emirates from "../../Icons/airlines/EK.png"
import Qatar from "../../Icons/airlines/QR.png"
import Saudi from "../../Icons/airlines/SV.png"
import Turkey from "../../Icons/airlines/TK.png"
import Etihad from "../../Icons/airlines/EY.png"
import American from "../../Icons/airlines/AA.png"
import Arabia from "../../Icons/airlines/AAA.png"
import China from "../../Icons/airlines/CA.png"
import Malindo from "../../Icons/airlines/OD.png"
import British from "../../Icons/airlines/British.jpg"
import Cathay from "../../Icons/airlines/CX.png"
import Sri from "../../Icons/airlines/UL.png"
import Thai from "../../Icons/airlines/TG.png"
import Dubai from "../../Icons/airlines/FZ.png"
import Gulf from "../../Icons/airlines/GF.png"
import Kenya from "../../Icons/airlines/KU.png"
import Kuwait from "../../Icons/airlines/LH.png"
import Oman from "../../Icons/airlines/WY.png"
import KLM from "../../Icons/airlines/CL.png"
import Singapore from "../../Icons/airlines/SQ.png"
import Asia from "../../Icons/airlines/AK.png"
import Austria from "../../Icons/airlines/Aus.png"
import Easy from "../../Icons/airlines/3K.png"
import Canada from "../../Icons/airlines/can.png"


import TabIconone from "../../Icons/TabIcons/1.png";
import TabIcontwo from "../../Icons/TabIcons/2.png";
import TabIconthree from "../../Icons/TabIcons/3.png";
import TabIconfour from "../../Icons/TabIcons/4.png";
import TabIconfive from "../../Icons/TabIcons/5.png";

function PopularAirLine() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    function SendCustomRequest(CityName) {
        const url = apiUrl + "/api/VisaSearch/CountryGet?term=" + CityName;
        // console.log(url);
        axios
            .get(url)
            .then((response) => {
                const data = response.data;
                // navigate('/visas/' + CityName + '-visa', { state: { props: data } });
            })
            .catch((error) => console.error("Error: ${error}"));
    }
    return (

        <section id="services-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Popular Airlines </h2>
                            <hr className="heading-line" />


                        </div>
                        <div className="innerpage-heading">
                            <h2 style={{fontWeight:'bold'}}>Pakistani Airlines </h2>
                        </div>
                        <div className="row" style={{ marginBottom: '20px',marginLeft:'50px' }}>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Turkey")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={PK} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Pakistan International Airlines</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("China")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Blue} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air Blue</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Fly} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Fly Jinnah</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Serene} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Serene Air</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Sial} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air Sial </span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Askari} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Askari Aviation</span>
                                </a>
                            </div>
                        </div>

                        <div className="innerpage-heading ">
                            <h2 style={{fontWeight:'bold'}}>International Airlines</h2>
                        </div>
                        <div className="row" style={{marginLeft:'50px'}}>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Turkey")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Emirates} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Emirates</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("China")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Qatar} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Qatar Airways</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Saudi} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Saudi Airlines</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Turkey} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Turkish Airlines</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Turkey")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Etihad} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Etihad Airways</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("China")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={American} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>American Airlines</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Arabia} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air Arabia</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={China} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air China</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Turkey")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Malindo} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air Malindo</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("China")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={British} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>British Airways</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Cathay} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Cathay Pacific</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Sri} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Sri Lankan Airlines</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Thai} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Thai Airways</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Dubai} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Fly Dubai</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Turkey")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Gulf} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Gulf Air</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("China")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Kenya} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Kenya Airways</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Kuwait} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Kuwait Airways</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Oman} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Oman Air</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("China")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={KLM} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>KLM Lufthansa Ryan Air</span>
                                </a>
                            </div>
                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Singapore} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Singapore Airlines</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Asia} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air Asia</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Dubai")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Canada} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Air Canada</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("UK")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Austria} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Austrian Airlines</span>
                                </a>
                            </div>

                            <div className="col-4 text-left">
                                <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Turkey")}>
                                    <img height="35px" width="35px" className=" shadow-sm p-1" src={Easy} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                                    <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Easy Jet</span>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );

}



export default PopularAirLine;