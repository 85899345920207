import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';

function TourPlan() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

   
    return (

        <section>
             

            
            
            <div className="plan-80 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
                       
    <div className="justify-content-center">


<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
    <div className="col-md-5 " >
            <div>
            <img
                                            src="../images/turkey.png"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
        </div>
          
          
        <div className="col-md-7 " >
            <div>
          
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Tour Packages for Turkey</h2>
                <blockquote>
                Unlock the wonders of the vibrant country with our unbeatable tour packages for Turkey, tailored to fit any budget. From the bustling streets of Istanbul to the surreal landscapes of Cappadocia, embark on a journey filled with culture and adventure. Exclusive deals for Turkey Tour from Pakistan await, so pack your bags and let Malik Express Travel & Tours guide you through Turkey's rich history and delicious cuisine.
                </blockquote>
            </div>
            <div style={{margin:'20px'}}>
                            <a href="/tour-packages-from-pakistan" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>
        </div>


        <div className="row justify-content-center" style={{paddingBottom:'50px'}}>
    <div className="col-md-7 order-2 order-md-1" >
        <div>
            <h2 className="text-left" style={{ fontWeight: 'bold' }}>Tour Packages Dubai</h2>
            <blockquote>
            Experience the allure of Dubai with our top-rated and affordable tour packages from Pakistan, meticulously designed to suit every traveler's needs. From the iconic Burj Khalifa to the historic Dubai Museum, our Dubai Tour packages cover all the must-see attractions. With affordable options for solo travelers, families, and groups, let Malik Express Travel & Tours take care of the details while you enjoy the ride.
            </blockquote>
        </div>
        <div style={{margin:'5px'}}>
                            <a href="/tour-packages-from-pakistan" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
    </div>
    <div className="col-md-5 order-1 order-md-2" >
        <div>
            <img
                src="../images/dubai tourist.png"
                alt="travel insurance"
                style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
            />
        </div>
    </div>
</div>

        
<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
         <div className="col-md-5 " >
            <div>
            <img
                                            src="../images/malaysia.png"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
            </div>
            <div className="col-md-7 " >
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Tour Packages Malaysia</h2>
                <blockquote>
                Venture into the enchanting landscapes of Malaysia with our diverse tour packages from Pakistan. From the bustling streets of Kuala Lumpur to the serene beaches of Langkawi, Malaysia offers a blend of cultural experiences and natural beauty. With Malaysia tour packages that suit every preference and budget, book your dream vacation with Malik Express Travel & Tours and immerse yourself in the wonders of Malaysia.
                </blockquote>
            </div>
            <div style={{margin:'20px'}}>
                            <a href="/tour-packages-from-pakistan" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>

</div>

<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
        <div className="col-md-7 order-2 order-md-1" >
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Thailand Tour Packages</h2>
                <blockquote>
                Embark on a journey to Thailand with our exclusive tour packages, crafted especially for travelers from Pakistan. Whether you seek beach bliss, cultural immersion, or adventure, our Thailand tour packages have something for everyone. Explore the vibrant streets of Bangkok, uncover ancient wonders in Ayutthaya, or relax on the pristine beaches of Phuket. With seamless itineraries & comfortable accommodations, let us make your Thailand getaway unforgettable. Book now and prepare for an extraordinary adventure!         
            
               </blockquote>
            </div>
            <div style={{margin:'5px'}}>
                            <a href="/tour-packages-from-pakistan" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>

        <div className="col-md-5  order-1 order-md-2" >
            <div>
            <img
                                            src="../images/thailand.png"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
            </div>
         </div> 

        
        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>
    );

}



export default TourPlan;