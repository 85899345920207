import { Filter } from '@mui/icons-material';
import React, { useState, useEffect, useRef } from "react";
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../../CustomCss.css';

function FlightFilters({stopsData, carriersData, selectedStops,selectedCarriers, updateSelectedStops,updateSelectedCarriers}){
  const [FiltersToggle, setFiltersToggle] = useState(false)
  const [mobileSelectedStops, setMobileSelectedStops] = useState(selectedStops);
  const [mobileSelectedCarriers, setMobileSelectedCarriers] = useState(selectedCarriers);

  const handleMobileCheckboxChange = (event) => {
    const checkboxName = event.target.name;
    const isChecked = event.target.checked;

    if (stopsData.includes(checkboxName)) {
      setMobileSelectedStops({ ...mobileSelectedStops, [checkboxName]: isChecked });
    } else if (carriersData.includes(checkboxName)) {
      setMobileSelectedCarriers({ ...mobileSelectedCarriers, [checkboxName]: isChecked });
    }
  };

  const handleMobileDoneButtonClick = () => {
    setFiltersToggle(false);
    updateSelectedStops({ ...selectedStops, ...mobileSelectedStops });
    updateSelectedCarriers({ ...selectedCarriers, ...mobileSelectedCarriers });
  };

  const handleStopsChange = (event) => {
        const checkboxName = event.target.name;
        const isChecked = event.target.checked;
        // Update selectedCheckboxes state based on checkbox changes
        updateSelectedStops({ ...selectedStops, [checkboxName]: isChecked });
      };
      const handleCarrierChange = (event) => {
        const checkboxName = event.target.name;
        const isChecked = event.target.checked;
        // Update selectedCheckboxes state based on checkbox changes
        updateSelectedCarriers({ ...selectedCarriers, [checkboxName]: isChecked });
      };
return(
  <>
 <div className="card d-lg-block " style={{ margin: "0px 100px 0px 100px", display: "none" }}>
     <div className="filterLeft row">
       <div className="filters filterOne col-lg-2" >
         <div className="row head colorBlue">
           <div className="col-xs-8 col-sm-8 col-md-8 headsize">Stops</div>
           <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }} >Reset</div>
         </div>
         <div className="content" id="dvstopsFilter">

           {
             stopsData.length >0 && stopsData.map((stop) => (
               <>
                 <div className="">
                   <div className="col-xs-8 col-sm-8 col-md-8 m-auto ">
                     <label className="checkbox-inline m-auto row">
                       <input id="chkNonStop" name={stop} type="checkbox" value={stop}  onChange={handleStopsChange}/><span className="checkmark ml-1" style={{ fontSize: "13px" }}> {stop} Stop</span>
                     </label>
                   </div>
                 </div>
               </>
             ))
           }
         </div>
       </div>
       <div className="filters filterTwo col-lg-3" >
         <div className="row head colorBlue">
           <div className="col-xs-8 col-sm-8 col-md-8 headsize">Departure Time</div>
           <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }}>Reset</div>
         </div>
         <div className="content" >
           <div className="row m-auto">
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkdepTimefilter" value="00-06" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>12 Night-6AM</span>
               </label>
             </div>
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkdepTimefilter" value="00-06" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>12 Night-6AM</span>
               </label>
             </div>
           </div>
           <div className="row m-auto">
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkdepTimefilter" value="00-06" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>12 Night-6AM</span>
               </label>
             </div>
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkdepTimefilter" value="00-06" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>12 Night-6AM</span>
               </label>
             </div>
           </div>
         </div>
       </div>
       <div className="filters filterThree col-lg-3">
         <div className="row head colorBlue">
           <div className="col-xs-8 col-sm-8 col-md-8 headsize">Arrival Time</div>
           <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }}>Reset</div>
         </div>
         <div className="content" >
           <div className="row">
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkarrTimefilter" value="00-06" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>12 Night-6AM</span>
               </label>
             </div>
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkarrTimefilter" value="06-12" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>6AM-12 Noon</span>
               </label>
             </div>
           </div>
           <div className="row">
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkarrTimefilter" value="12-18" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>12 Noon-6PM</span>
               </label>
             </div>
             <div className="col-sm-6 col-md-6 col-lg-6">
               <label className="checkbox-inline m-auto row">
                 <input type="checkbox" name="chkarrTimefilter" value="18-24" /><span className="checkmark ml-1" style={{ fontSize: "13px" }}>6PM-12 Night</span>
               </label>
             </div>
           </div>
         </div>
       </div>
       <div className="filters filterFour col-lg-4" >
         <div className="row head colorBlue">
           <div className="col-xs-8 col-sm-8 col-md-8 headsize">Airlines</div>
           <div className="col-xs-4 col-sm-4 col-md-4  " style={{ visibility: "hidden", cursor: "pointer" }} >Reset</div>
         </div>
         <div className="row">
           {
             carriersData .length >0 && carriersData.map((airlines) => (
               <>
                 <div className="content col-sm-4 col-md-4 col-lg-4" id="dvairlinesFilter" >
                   <label className="checkbox-inline row m-auto">
                     <input type="checkbox" value={airlines} name={airlines} onChange={handleCarrierChange} />
                     <span data-aircode="PK" className="alFilterName"></span>
                     <span className="checkmark ml-1" style={{ top: "3px" }}>
                     </span><span style={{ fontSize: "12px" }}>{airlines} </span>
                   </label>
                 </div>
               </>
             ))
           }
         </div>



       </div>
       <div className="filters filterFive " style={{ height: "73px", display: "none" }} >
         <div className="row head colorBlue hidden-xs" style={{ visibility: "hidden" }} >
           <div className="col-xs-8 col-sm-8 col-md-8 headsize">Stops</div>
           <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }} >Reset</div>
         </div>

         <div className="fullfilter container">
           <div className="fulfilterOptions">
             <div className="container">
               <div className="filters filterOne inlineDiv">
                 <div className="row head colorBlue">
                   <div className="col-xs-8 col-sm-8 col-md-8 headsize">Stops</div>
                   <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }}>Reset</div>
                 </div>
                 <div className="content" id="dvstopsFilter2" >
                   <div className="row">
                     <div className="col-xs-8 col-sm-8 col-md-8">
                       <label className="checkbox-inline">
                         <input id="chkNonStop2" name="chkstopfilter2" type="checkbox" value="0" />Non Stop<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-xs-4 col-sm-4 col-md-4 text-center" id="dvNonStop2">6</div>
                   </div>
                   <div className="row">
                     <div className="col-xs-8 col-sm-8 col-md-8">
                       <label className="checkbox-inline">
                         <input id="chkOneStop2" name="chkstopfilter2" type="checkbox" value="1" />1 Stop<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-xs-4 col-sm-4 col-md-4 text-center" id="dvOneStop2">0</div>
                   </div>
                   <div className="row">
                     <div className="col-xs-8 col-sm-8 col-md-8">
                       <label className="checkbox-inline">
                         <input id="chkTwoStop2" name="chkstopfilter2" type="checkbox" value="2" />2 Stops<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-xs-4 col-sm-4 col-md-4 text-center" id="dvTwoStop2">0</div>
                   </div>
                   <div className="row">
                     <div className="col-xs-8 col-sm-8 col-md-8">
                       <label className="checkbox-inline">
                         <input id="chkThreeStop2" name="chkstopfilter2" type="checkbox" value="3" />3 Stops<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-xs-4 col-sm-4 col-md-4 text-center" id="dvThreeStop2">0</div>
                   </div>
                 </div>
               </div>
               <div className="filters filterTwo inlineDiv">
                 <div className="row head colorBlue">
                   <div className="col-xs-8 col-sm-8 col-md-8 headsize">Airlines</div>
                   <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }}>Reset</div>
                 </div>
                 <div className="content" id="dvairlinesFilter2" ><div className="row"><div className="col-xs-8 col-sm-8 col-md-8"><label className="checkbox-inline"><input type="checkbox" name="chkALfilter" value="PK" /><span data-aircode="PK" className="alFilterName"></span><span className="checkmark"></span></label></div><div className="col-xs-4 col-sm-4 col-md-4 dvfilterALCount" id="dvfilterALCount_PK">6</div></div></div>
               </div>

               <div className="filters filterFour inlineDiv">
                 <div className="row head colorBlue">
                   <div className="col-xs-8 col-sm-8 col-md-8 headsize">Departure Time</div>
                   <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }}>Reset</div>
                 </div>
                 <div className="content" >
                   <div className="row">
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkdepTimefilter2" value="00-06" />12 Night-6AM<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkdepTimefilter2" value="06-12" />6AM-12 Noon<span className="checkmark"></span>
                       </label>
                     </div>
                   </div>
                   <div className="row">
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkdepTimefilter2" value="12-18" />12 Noon-6PM<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkdepTimefilter2" value="18-24" />6PM-12 Night<span className="checkmark"></span>
                       </label>
                     </div>
                   </div>
                 </div>
                 <div className="row head colorBlue">
                   <div className="col-xs-8 col-sm-8 col-md-8 headsize">Arrival Time</div>
                   <div className="col-xs-4 col-sm-4 col-md-4  " style={{ cursor: "pointer" }} >Reset</div>
                 </div>
                 <div className="content" >
                   <div className="row">
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkarrTimefilter2" value="00-06" />12 Night-6AM<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkarrTimefilter2" value="06-12" />6AM-12 Noon<span className="checkmark"></span>
                       </label>
                     </div>
                   </div>
                   <div className="row">
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkarrTimefilter2" value="12-18" />12 Noon-6PM<span className="checkmark"></span>
                       </label>
                     </div>
                     <div className="col-sm-6 col-md-6 col-lg-6">
                       <label className="checkbox-inline">
                         <input type="checkbox" name="chkarrTimefilter2" value="18-24" />6PM-12 Night<span className="checkmark"></span>
                       </label>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>

    <div className="justify-content-between mt-5 d-flex d-lg-none d-sm-block">
      <div className="card" style={{ padding: "0px !important" }}>
        <div className="card-header">
          <span style={{ fontFamily: "monospace" }}>Select Your Flight Filters</span>
        </div>
      </div>

      <div className="card" style={{ padding: "0px !important" }}>
        <button style={{ height: "100%" }} onClick={() => setFiltersToggle(!FiltersToggle)}> <FontAwesomeIcon icon={faFilter} />   Filter
        </button>

      </div>

    </div>

    {FiltersToggle &&
                (

                  <div tabIndex={'1'} className="mlk-drawer mlk-drawer-open mlk-drawer-bottom _212lkgahcq8aN2d79b6yAX" id="MobilePersonPicker">
                    <div className="mlk-drawer-mask"></div>
                    <div className="mlk-drawer-content-wrapper" style={{ height: "90%" }} >
                      <div className="mlk-drawer-content">
                        <div className="mlk-drawer-wrapper-body">
                          <div className="mlk-drawer-header" style={{ padding: "16px", borderBottom: "0px" }} >
                            <div className="mlk-drawer-title"><span className="mlk-typography text-xl font-normal">Travelers</span></div>
                            <button type="submit" onClick={() => setFiltersToggle(!FiltersToggle)} aria-label="Close" className="mlk-drawer-close"  >
                              <span role="img" aria-label="close" className="anticon anticon-close">
                                <svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                  <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                                </svg>
                              </span>
                            </button>
                          </div>
                          <div className="mlk-drawer-body" style={{ padding: "16px" }} >
                            <div className="mlk-row" style={{ rowGap: "0px" }} >
                              <div className="mlk-col mlk-col-24">


                                <div className="col-12 col-md-12 col-lg-3 side-bar left-side-bar ">
                                  <div className="side-bar-block ">
                                    <h3>Sort By Filter</h3>
                                    <p>Find your dream flights today</p>

                                    <div className="panels-group">
                                      <div className="card">
                                        <div className="card-header">
                                          Stops
                                        </div>
                                        <div className="card-body ">
                                          <ul className='row'>
                                            {
                                              stopsData && stopsData.map((stop) => (

                                                <li className='col-4'><input type='checkbox' value={stop} className='mb-1' name={stop} onChange={handleMobileCheckboxChange} checked={mobileSelectedStops[stop] || false}/> {stop} </li>
                                              
                                                ))
                                            }
                                          </ul>

                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="card">
                                        <div className="card-header">
                                          Departure Time
                                        </div>
                                        <div className="card-body">
                                          {
                                            <ul>
                                              <li><input type='checkbox'className='mb-1' /> 00:00 - 05:59 </li>
                                              <li><input type='checkbox' className='mb-1'/> 06:00 - 11:59 </li>
                                              <li><input type='checkbox'className='mb-1' /> 12:00 - 17:59 </li>
                                              <li><input type='checkbox' className='mb-1'/> 18:00 - 23:59 </li>
                                            </ul>

                                          }
                                        </div>
                                      </div>
                                      <br></br>
                                      <div className="card">
                                        <div className="card-header">
                                          Airline
                                        </div>
                                        <div className="card-body">
                                          <ul className="row">
                                            {
                                              carriersData && carriersData.map((airlines) => (

                                                <li className='col'><input type='checkbox' value={airlines} className='mb-1' name={airlines} onChange={handleMobileCheckboxChange} checked={mobileSelectedCarriers[airlines] || false}/> {airlines} </li>
                                              ))
                                            }
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mlk-drawer-footer" style={{ padding: "16px", borderTop: "0px" }} >
                            <div className="mlk-row" style={{ rowGap: "0px" }} >
                              <div className="mlk-col mlk-col-24 text-center">
                                <button type="submit" onClick={() => handleMobileDoneButtonClick()} className="mlk-btn mlk-btn-primary mlk-btn-lg" ><span>Done</span></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                )}
  </>
   
   );
}
export default FlightFilters;