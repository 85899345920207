import React, { useState, useEffect } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
function ConfirmedPayment() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const apiUrl = process.env.REACT_APP_API_URL;

    const BasketId = searchParams.get("basket_id");
    const err_msg = searchParams.get("err_msg");
    const err_code = searchParams.get("err_code");
    const transaction_amount = searchParams.get("transaction_amount");
    const Status_code = searchParams.get("status_code");
    const Status_msg = searchParams.get("status_msg");
    const Transaction_id = searchParams.get("transaction_id");

    const [orderId, setOrderId] = useState('');
    const [vendor, setVendor] = useState('');
    const [booking, setBooking] = useState([]);
    const [bookingfamily, setBookingfamily] = useState([]);
    const [policy, setPolicy] = useState('');
    //console.log('pilicy is ',policy)
    useEffect(() => {
        if (BasketId) {
            const [id, ven] = BasketId.split('-');
            setOrderId(id);
            setVendor(ven);
        }
    }, [BasketId]);
    const [formData, setFormData] = useState({
        package: '',
        countrytotravel: '',
        departuredate: '',
        returndate: '',
        passengertype: '',
        CoverageUpto: '',
        plan: '',
        PersonName: '',
        cnic: '',
        dob: '',
        contactno: '',
        purposeofvisit: '',
        email: '',
        passportno: '',
        residentialaddress: '',
        residentialcity: '',
        premium: '',
        beneficiaryname: '',
        beneficiaryrelation: '',
        beneficiarycnic: '',
        beneficiarycontact: '',
        beneficiaryaddress: '',
        AdultName: '',
        adultdob: '',
        Adultpassportno: '',
        child1name: '',
        child1dob: '',
        child1passportno: '',
        child2name: '',
        child2dob: '',
        child2passportno: '',
        child3name: '',
        child3dob: '',
        child3passportno: '',
        child4name: '',
        child4dob: '',
        child4passportno: ''
    });
    // useEffect(() => {
    //     if (vendor === 'UIC') {
    //         Booking();
    //         Bookingfamily();
    //     }
    // }, [vendor]);

    //     useEffect(() => {
    //         if (booking && booking.length > 0) {
    //             const bookingData = booking[0];
    //             const ventor = booking[0].Vendor
    //            // console.log('ventor', ventor)
    //             const dob = new Date(bookingData.TravelerDOB).toISOString().slice(0, 19); // Convert DOB to ISO format
    //             const departure = new Date(bookingData.DepartureDate).toISOString().slice(0, 19); // Convert DOB to ISO format
    //             const returndate = new Date(bookingData.ArrivalDate).toISOString().slice(0, 19); // Convert DOB to ISO format

    //             let childCounter = 0;

    //                 const formData = {
    //                     UserName: 'API_MALIK.EXPRESS',
    //                     Password: 'API!UIC**10596!',
    //                     AreaShortCode: bookingData.AreaShortCode || '',
    //                     TravelDate: bookingData.DepartureDate || '',
    //                     TravelerName: bookingData.TravelerName  || '',
    //                     NoOfDays: bookingData.PDays ? `${bookingData.PDays}` : '',
    //                     DOB: bookingData.TravelerDOB || '',
    //                     PassportNo: bookingData.Passport || '',
    //                     NICNo: bookingData.TravelerNICNo || '',
    //                     Address: bookingData.ResidentialAddress || '',
    //                     ContactNo: bookingData.ContactNumber || '',
    //                     BeneficiaryName: (bookingData.BeneficiaryFirstName + ' ' + bookingData.BeneficiaryLastName) || '',
    //                     Relationship: bookingData.BeneficiaryRelation || '',
    //                     Country: bookingData.FirstCountry || '',
    //                     Remarks: 'Test',
    //                     plantype: bookingData.PlanType === 'Single' ? 'S' : 'F' || '',
    //                     plan: bookingData.Plan || '',
    //                     premium: bookingData.Premium || '',
    //                     ntnno: '',
    //                     traveleremail: bookingData.EmailAddress || '',
    //                     SpouseName: '',
    //                     SpouseDOB: '',
    //                     SpousePassport: '',
    //                     Child1Name: '',
    //                     Child1DOB: '',
    //                     Child1Passport: '',
    //                     Child2Name: '',
    //                     Child2DOB: '',
    //                     Child2Passport: '',
    //                     Child3Name: '',
    //                     Child3DOB: '',
    //                     Child3Passport: '',
    //                     isRequestPolicy: 'false',
    //                     SrvSrce: 'UIC',
    //                     ReferenceNo: '',
    //                     ESystemName: '',
    //                     EUserName: '',
    //                     Covid: bookingData.Covid || '',

    //                 };

    //                 bookingfamily.forEach((member, index) => {
    //                     if (member.PaxType === 'Child' && childCounter < 4) {
    //                         if (member.FullName) formData[`Child${childCounter + 1}Name`] = member.FullName;
    //                         if (member.DOB) formData[`Child${childCounter + 1}DOB`] = member.DOB;
    //                         if (member.PassportNo) formData[`Child${childCounter + 1}Passport`] = member.PassportNo;
    //                         childCounter++;
    //                     } else if (member.PaxType === 'Adult') {
    //                         if (member.FullName) formData.SpouseName = member.FullName;
    //                         if (member.DOB) formData.SpouseDOB = member.DOB;
    //                         if (member.PassportNo) formData.SpousePassport = member.PassportNo;
    //                     }
    //                 });
    //                 setFormData(formData);
    //                 //console.log('venter is UIC')




    //         }
    //     }, [booking, bookingfamily]);
    //    // console.log('formdata state is', formData)

    const DownloadPdf = async () => {
        try {
            const response = await axios.get(`https://api.malikexpress.com/api/Insurance/getpdfdetails/?BookingId=${orderId}`, {
                responseType: 'blob',
            });

            const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
            saveAs(pdfBlob, 'downloadedFile.pdf');
        } catch (error) {
            console.error('Error downloading the PDF', error);
        }
    };

    const Bookingfamily = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Insurance/getuicpaxlist/?id=${orderId}`);
            if (response.status === 200) {
                setBookingfamily(response.data);
            } else {
                console.error("Error: Booking is not confirmed");
            }
        } catch (error) {
            console.error('Error: Booking is not confirmed');
        }
    };

    const sendFormData = async (formData) => {
        const url = new URL('https://travelapi.theunitedsoftware.com/API/Travel/GenerateUWDocumentWithCovid');

        // Append form data as URL parameters
        Object.keys(formData).forEach(key => url.searchParams.append(key, formData[key]));

        const headers = {
            'API_KEY': '7b6f1ttz41169d544e4eda4b2b263e6bffe50d',
            'Authorization': 'Basic ' + btoa('TravelAPI:85aeezx305ae285f5a7959f89a8af9caa5f57'),
            'Content-Type': 'application/json'
        };

        // console.log('URL:', url.toString());
        //  console.log('Headers:', headers);

        try {
            const response = await fetch(url.toString(), {
                method: 'POST',
                headers: headers,
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            const policyNumber = data[0]?.PolicyNo || 'null';
            setPolicy(policyNumber);
            return policyNumber;
        } catch (error) {
            console.error('There was a problem with your fetch operation:', error);
            return 'null';
        }
    };

    const payment = async () => {

        try {
            let endpoint = `${apiUrl}/api/Insurance/paymentconfirmation/?BookingId=${orderId}&PaymentMessage=${err_msg}&PaymentStatus=${err_code === '000' ? true : false}&PolicyNumber=null`;
            const response = await axios.get(endpoint);

            if (response.status === 200) {

                if (err_code === '000') {
                    alert("Insurance generated successfully, you will receive the email shortly");
                }
                else {
                    // console.error("Error: Payment not successful");
                }
            } else {
                console.error("Error: Payment not successful");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        if (orderId && err_msg && err_code) {
            payment()
        }
    }, [orderId, err_msg, err_code]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>
                <meta
                    name="description"
                    content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
                    data-react-helmet="true"
                />
                <meta
                    name="keywords"
                    content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
                    data-react-helmet="true"
                />
                <link rel="canonical" href="https://malikexpress.com/thankyou" />
            </Helmet>
            <MainMenu />
            <section className="innerpage-wrapper">
                <div id="payment-success" >
                    <div className="container text-center" >
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12 col-lg-8 col-lg-offset-2">



                                <div className="payment-success-text">
                                    {err_code === '000' ? (

                                        <span><i className="fa fa-check-circle"></i></span>
                                    ) : (
                                        <span><i class="fa fa-exclamation-circle"></i></span>
                                    )}
                                    {err_code === '000' ? (
                                        <h2>Thank You!</h2>
                                    ) : (
                                        <h2>Error!</h2>
                                    )}
                                    {err_code === '000' ? (
                                        <p>Your payment of <b>PKR {transaction_amount}</b> has been processed successfully</p>
                                    ) : (
                                        <p>Your payment of <b>PKR {transaction_amount}</b> has been processed</p>
                                    )}
                                    {err_code === '000' && (
                                        <div className="center2 pt-5 pb-5">

                                            <button
                                                onClick={DownloadPdf}
                                                type="button"
                                                className="btn-lg btn-orange fn semibold"

                                            >
                                                PDF Download
                                            </button>
                                        </div>
                                    )}                           
                                  {err_code === '000' ? (
                                    <p>Booking Details Has Been Sent To Your Email ID. Further Detail For Any Query Please Call At This Number <a href="tel:+923111778811">+92 311 1778811</a>.</p>
                                   ) : (
                                    <p>Error During Transaction Please Try Again <br />
                                       Please Call At This Number <a href="tel:+923111778811">+92 311 1778811</a>.
                                       
                                    </p>
                                   )}     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12 col-md-12  side-bar blog-sidebar right-side-bar" style={{ marginTop: '30px' }}>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="side-bar-block follow-us">

                            <a href="https://www.facebook.com/malikexpress/" className="facebook fa fa-facebook fass"></a>
                            <a href="https://twitter.com/malik_express" className="twitter fa fa-twitter fass"></a>
                            <a href="https://www.linkedin.com/in/malik-express-805888138/" className="linkedin fa fa-linkedin fass"></a>
                            <a href="https://www.youtube.com/channel/UCv5a82ncf9ZV2HxRAcvykUg" className="youtube fa fa-youtube fass"></a>
                            <a href="https://www.instagram.com/malikexpress.isb/?igshid=YmMyMTA2M2Y%3D" className="instagram fa fa-instagram fass"></a>




                            {/* <ul className="list-unstyled list-inline">
                                                <li className="list-inline-item"><a href="https://www.facebook.com/malikexpress/"><span><i className="fa fa-facebook"></i></span></a></li>
                                                <li className="list-inline-item"><a href="https://twitter.com/malik_express"><span><i className="fa fa-twitter"></i></span></a></li>
                                                <li className="list-inline-item"><a href="https://www.linkedin.com/in/malik-express-805888138/"><span><i className="fa fa-linkedin"></i></span></a></li>
                                                <li className="list-inline-item"><a href="https://www.youtube.com/channel/UCv5a82ncf9ZV2HxRAcvykUg"><span><i className="fa fa-youtube-play"></i></span></a></li>
                                                <li className="list-inline-item"><a href="https://www.pinterest.com/malikexpress/"><span><i className="fa fa-pinterest-p"></i></span></a></li>
                                                <li className="list-inline-item"><a href="https://www.instagram.com/malikexpress.isb/?igshid=YmMyMTA2M2Y%3D"><span><i className="fa fa-instagram"></i></span></a></li>

                                            </ul> */}
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default ConfirmedPayment;
