import React from 'react'
import './LahoreContent.css'
const LahoreContent = () => {
  return (
    <div className="container">
        <div className='row'>
    <div className="image-container-u">
      <img 
        src="images/PckgIcon/MakkahList.png" 
        alt="Pilgrims in Kaaba" 
        className="image-unique"
      />
       <div className="icon-list">
      <ul className="icon-list-items">
        <li className="icon-list-item">
          <span className="icon-list-icon">
            <svg aria-hidden="true" className="icon-uniq" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </span>
          <span className="icon-list-text">Umrah Visa Processing</span>
        </li>
        <li className="icon-list-item">
          <span className="icon-list-icon">
            <svg aria-hidden="true" className="icon-uniq" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </span>
          <span className="icon-list-text">Round-trip air ticket

</span>
        </li>
        <li className="icon-list-item">
          <span className="icon-list-icon">
            <svg aria-hidden="true" className="icon-uniq" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </span>
          <span className="icon-list-text">Hotel accommodation in Makkah and Madinah</span>
        </li>
        <li className="icon-list-item">
          <span className="icon-list-icon">
            <svg aria-hidden="true" className="icon-uniq" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </span>
          <span className="icon-list-text">Ground transportation</span>
        </li>
        <li className="icon-list-item">
          <span className="icon-list-icon">
            <svg aria-hidden="true" className="icon-uniq" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"></path>
            </svg>
          </span>
          <span className="icon-list-text">Optional Ziyarat (visits to sacred sites)</span>
        </li>
      </ul>
    </div>
    </div>
   
    </div>
  </div>
  )
}

export default LahoreContent