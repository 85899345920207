import { useState, useEffect } from 'react';
import './umrahenquiry.css';

import { format } from 'date-fns';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DatePicker } from 'react-rainbow-components';
import { Radio, Space, Select } from 'antd';
import { Padding } from '@mui/icons-material';
const UmrahEnquiryForm = () => {
  const [selectedCity, setSelectedCity] = useState('');
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [Startdate, setStartDate] = useState(format(new Date(), 'yyyy/MM/dd'));
  const [EndDate, setEndDate] = useState(format(new Date(Startdate), 'yyyy/MM/dd'));
  const [UmrahStartdate, UmrahsetStartDate] = useState(format(new Date(), 'yyyy/MM/dd'));
  const [UmrahEnddate, UmrahsetEndDate] = useState(format(new Date(Startdate), 'yyyy/MM/dd'));
  const [selectedUmrahCity, setselectedUmrahCity] = useState('Makkah');




  const [formData, setFormData] = useState({
    departureAirport: '',
    departureDate: '',
    adults: '',
    child: '',
    infant: '',
    categories: '',
    durationOfWeeks: '',
    leadPassengerName: '',
    email: '',
    contactNo: '',
  });

  const handleChanged = (event) => {
    const city = event.target.value;
    if (city === '') {
      setIsDropdownVisible(true);
    } else {
      setSelectedCity(city);
      setIsDropdownVisible(false);
    }
  };

  const handleDropdownClick = () => {
    setIsDropdownVisible(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };


  const navigate = useNavigate();

  const UmrahSetStartDate = (date) => {
    UmrahsetStartDate(format(date, 'yyyy/MM/dd'));
  };
  const UmrahSetEndDate = (date) => {
    UmrahsetEndDate(format(date, 'yyyy/MM/dd'));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function SendUmrahRequest() {

    const apiUrl = process.env.REACT_APP_API_URL;
    const url = apiUrl + "/api/umrah/umrahhotels?term=" + selectedUmrahCity;
    // console.log(url);
    await axios
      .get(url)
      .then((response) => {
        const data = response.data;
        navigate('/UmrahHotels', { state: { props: data } });
        console.log("umrah responce",response)

      })
      .catch((error) => console.error("Error: ${error}"));

  }

  const handleSelectChange = (e) => {
    setselectedUmrahCity(e.target.value);
  };
  return (
    <div className="umrah-enquiry-main">
      <div className="umrah-enquiry-container">
        <h3>Search for Umrah Hotel</h3>
   
        <form onSubmit={handleSubmit} className="umrah-form-row">
        <div className="umrahh-coll">
              <label>Choose City


                <select

                  id="UmrahCity"
                  name="UmrahCity"
                 className='UmrahCity'
                  value={selectedUmrahCity}
                  onChange={handleSelectChange}
                >
                  <option value="Makkah">Makkah</option>
                  <option value="Madinah">Madinah</option>

                </select>

              </label>
            </div>
          <div className="umrah-form-row">
           
            <div className="umrah-col">
              <label>Check in</label>
              <DatePicker
                className='Datepickerwidth'
                placeholder='Start Date'
                minDate={new Date()}
                name="StartDatePicker"
                value={UmrahStartdate}
                onChange={UmrahSetStartDate}
              />
            </div>
            <div className="umrah-col">
              <label>Check out</label>
              <DatePicker
                style={{ Padding: '1.8px' }}
                placeholder='Start Date'
                minDate={new Date()}
                name="StartDatePicker"
                value={UmrahEnddate}
                onChange={UmrahSetEndDate}
              />
            </div>

          </div>
          <div className="umrah-form-row">
            <div className="umrah-col" style={{ flex: 'auto' }}>
              <button type="submit" onClick={SendUmrahRequest} className="btn submit-btn" style={{ padding: '8px' }}><i className="fa-solid fa-magnifying-glass"></i><span>  GO</span></button>
            </div>
          </div>
        </form>

      </div>
    </div>
  );
};

export default UmrahEnquiryForm;




