import React from 'react';
import './topContent.css';   

const TopContent = () => {
  return (
    <div className='top-content'>
      <div className="custom-container">
        <div className="text-column">
          <h2 className="heading">Personalized Umrah Packages Tailored Just for You</h2>
          <div className="divider"></div>
          <p className="text">
          Embark on a spiritual journey like no other with Malik Express Travel & Tours' Customized Umrah Packages. We understand that every pilgrim has unique needs and preferences, which is why we offer tailored packages to suit your specific requirements. From selecting the best travel dates to arranging luxurious accommodations and seamless transport, our dedicated team ensures every detail is meticulously planned. Experience a hassle-free and spiritually enriching Umrah journey designed just for you
          </p>
          <p className="text">
            Our customized Umrah packages allow you to tailor your pilgrimage according to your preferences, ensuring that your spiritual quest is a reflection of your devotion. From the tranquility of Ramadan Umrah packages to the togetherness of family Umrah packages, every pilgrimage is an opportunity to draw closer to your faith. Join us on this spiritual voyage, where your sacred journey unfolds with ease, comfort, and unwavering guidance.
          </p>
        </div>
        <div className="image-row">
          <div className="image-small-container">
            <img src='images/umrah/5.png' alt="Bedroom" className="image-small" />
            <img src='images/umrah/8.png' alt="Kitchen" className="image-small" />
          </div>
          <img src='images/umrah/33.png' alt="Bathroom" className="image-large" />
        </div>
      </div>
    </div>
  );
};

export default TopContent;

