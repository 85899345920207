import React, { useEffect, useState } from "react";
import { Helmet } from 'react-helmet';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import LatestBlog from '../Home/LatestBlogs';
import Testimonials from '../Home/Testimonials';
import Tours from '../Layout/Tours';
import TourPlan from "./TourPlan";
import Features from "./Features";
import Offers from "./Offers";

function TourPackages() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const slides = [
    {
      title: "DUBAI Tour Package",
      description: "Embark on an unforgettable journey with our Dubai tour package from Pakistan. Experience the glitz & glamour of this dynamic city, from towering skyscrapers to golden deserts, all curated to ensure an exhilarating adventure awaits.",
      price: "STARTING FROM 220,000/-PKR",
      image: "../images/tour package.jpg"
    },
    {
      title: "TURKEY Tour Package ",
      description: "Embark on a mesmerizing journey with our Turkey tour package from Pakistan. Immerse yourself in the rich history, vibrant culture, and stunning landscapes of Turkey, where every moment promises unforgettable experiences.",
      price: "STARTING FROM 230,000/-PKR ",
    },
    {
      title: "THAILAND Tour Package ",
      description: "Embark on an unforgettable adventure with our Thailand tour package from Pakistan. Dive into the vibrant culture, stunning beaches, and bustling city life of Thailand, all expertly curated to create memories that last a lifetime. ",
      price: "STARTING FROM 250,000/-PKR ",
    },
    {
      title: "MALAYSIA Tour Package ",
      description: "Embark on a mesmerizing journey with our Malaysia tour package from Pakistan. Experience the vibrant culture, stunning landscapes, and captivating attractions of Malaysia, all meticulously crafted to offer you an unforgettable adventure.",
      price: "STARTING FROM 250,000/-PKR ",
    },
    {
      title: "SINGAPORE Tour Package",
      description: "Set off on an extraordinary journey with our Singapore tour package from Pakistan. Explore the captivating sights, sounds, and flavors of this cosmopolitan gem, meticulously designed to offer an unparalleled travel experience.",
      price: "STARTING FROM 270,000/-PKR ",
    },
    {
      title: "SWITZERLAND Tour Package ",
      description: "Set forth on an enchanting adventure with our Switzerland tour package from Pakistan. Immerse yourself in the breathtaking landscapes of the Swiss Alps and explore charming cities like Zurich and Geneva, all tailored to create unforgettable memories of a lifetime.",
      price: "STARTING FROM 280,000/-PKR ",
    },
    {
      title: "MALDIVES Tour Package ",
      description: "Escape to paradise with our exclusive Maldives Tour Package from Pakistan! Dive into crystal-clear waters, lounge on pristine beaches, and experience luxury like never before. Book now for an unforgettable island getaway with Malik Express Travel & Tours.",
      price: "STARTING FROM 280,000/-PKR ",
    },
    {
      title: "BAKU Tour Package ",
      description: "Experience the charm of Baku like never before! Explore the vibrant culture and stunning landscapes of Azerbaijan with our unbeatable Baku tour packages from Pakistan. Book now for an unforgettable journey filled with rich history, delectable cuisine, and breathtaking sights.",
      price: "STARTING FROM 80,000/-PKR ",
    },
    
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <>
      <Helmet>
        <title>Cheap worldwide Tour Packages from Pakistan | Malik Express</title>
        <meta name="description" content="Malik Express Travel & Tours offer the best tour packages from Pakistan. Get the best rates for Turkey, Dubai, Maldives, Thailand and Europe tour packages." />
        <meta name="keywords" content="tour packages Pakistan, tour packages, tour packages in Pakistan, tour packages for Pakistan, tour packages Pakistan, tour packages for turkey from Pakistan, tour packages for turkey, tour packages Maldives, tour packages to Dubai, tour packages Dubai, world tour packages from Pakistan, cheap tour packages from Pakistan, tour packages for Thailand, tour packages Malaysia, tour packages Europe" />
        <link rel="canonical" href="https://malikexpress.com/tour-packages-from-pakistan" />
      </Helmet>
      <MainMenu />
    
      <section className="flexslider-container" id="flexslider-container-4">
        <div className="flexslider slider tour-slider" id="slider-4">
          <ul className="slides">
            {slides.map((slide, index) => (
              <li
                key={index}
                className={`item-1 back-size ${index === activeIndex ? 'flex-active-slide' : ''}`}
                style={{
                  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../images/tours.jpg)`,
                  backgroundPosition: '50% 15%',
                  backgroundSize: 'cover',
                  height: '100%',
                  // width: '1303px',
                  marginRight: '0px',
                  float: 'left',
                  ...(index === activeIndex ? { display: 'block' } : { display: 'none' })
                }}
              >
                <div className="meta">
                  <div className="container">
                    <span className="highlight-price highlight-2">{slide.price}</span>
                    <h2>{slide.title}</h2>
                    <p>{slide.description}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <ul className="flex-direction-nav">
            <li className="flex-nav-prev"><a className="flex-prev" href="#">Previous</a></li>
            <li className="flex-nav-next"><a className="flex-next" href="#">Next</a></li>
          </ul>
        </div>
      </section>
   
          <section className="coverage" style={{paddingTop:'60px'}}>
            <div className="container">
              <div className="page-heading">
                <h1 style={{ fontWeight: 'bold' }}>Worldwide Tour Packages from Pakistan</h1>
                <blockquote>
                  From dreamy destinations in Europe and Asia to breathtaking landscapes in Africa and South America, our worldwide tour packages from Pakistan promise to take you on the journey of a lifetime. Book your trip today, and let us help you discover the world in the most unforgettable way possible!
                  <br />
                  Experience the world like never before with our extensive range of worldwide tour packages from Pakistan. At Malik Express, we understand the importance of exploring new cultures, seeing new sights, and creating memories that will stay with you forever. Our tour packages cater to every need and budget, with affordable options that provide exceptional value for money. Whether you're looking for a luxurious getaway to a tropical paradise, an adventure-filled trip to the mountains, or a cultural experience that immerses you in the local way of life, we've got you covered.
                </blockquote>
                <br />
                <hr className="heading-line" />
              </div>
            </div>
          </section>
       
        
      
      <Tours />
      <TourPlan />
      <Features />
      <Offers />
      <Testimonials />
      <LatestBlog />
      <Footer />
    </>
  )
}

export default TourPackages;
