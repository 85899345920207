import { Tour } from "antd";
import { React } from "react";
//import { Carousel, Icon  } from 'antd';
//import Carousel from 'nuka-carousel';

import Tours from '../Layout/Tours';


function Features() {
  const contentStyle = {
    maxheight: "160px",
    color: "red",
    lineHeight: "160px",
    textAlign: "center",
    background: "white",
  };

  return (
    <>
    
                 

      <section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
              <p style={{fontWeight:'bold',color:'#fff'}}>From Dreaming to Jet-Setting – Make Your Travel Goals a Reality </p>
                <h1 style={{fontWeight:'bold',color:'#fff'}}>
                Welcome to Malik Express Travel & Tours  
                </h1>
              <h1 style={{fontWeight:'bold',color:'#fff'}}>Your Premier Travel Agency in Islamabad, Pakistan</h1>
              <hr  style={{borderColor:'#fff'}} className="heading-line" />
              <br />
              <blockquote style={{color:'white'}}>
              Are you tired of scrolling through countless travel websites and feeling overwhelmed by the options? Look no further! Malik Express Travel & Tours - top travel agency in Islamabad, Pakistan is here to turn your travel dreams into a reality. With our expertise and insider knowledge, we can curate the perfect itinerary tailored to your preferences.
              <br />
              Imagine sipping cocktails on pristine beaches, exploring ancient ruins, or embarking on thrilling adventures - all with the peace of mind that comes from having a professional guiding you every step of the way. Whether you're a solo traveller, a couple seeking a romantic getaway, or a family in search of unforgettable memories, this travel agency has got you covered.
              </blockquote>
            </div>
            </div>
          </div>
        </div>
      </section>
      <Tours></Tours>  
    </>
  );
}

export default Features;
