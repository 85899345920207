import React from 'react';
import './DaysFoot.css';

const DaysFoot = () => {
  return (
    <div className="footer-top-unique">
      <div className="container">
        <div className="contact-info-unique">
          <div className="contact-title-unique">Contact Our Travel expert:</div>
          <div className="contact-item-unique">
            <a href='mailto:info@malikexpress.com'>
            <i className='fa fa-envelope' style={{color:'#fff',fontSize:'25px',marginRight:'10px'}}></i>
              info@malikexpress.com
            </a>
          </div>
          <div className="contact-item-unique">
            <a href='tel: +92 311 1778811'>
             <i className='fa fa-phone' style={{color:'#fff',fontSize:'25px',marginRight:'10px'}}></i>
              +92 311 1778811
            </a>
          </div>
          <div className="contact-item-unique">
            <a href='https://api.whatsapp.com/send/?phone=%2B923111778811&text=Hi%21+Malik+Express&type=phone_number&app_absent=0'>
            <i className='fa fa-whatsapp' style={{color:'#fff',fontSize:'25px',marginRight:'10px'}}></i>

              +92 311 1778811
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DaysFoot;
