import React from 'react';
import SevenPackages from './SevenPackages';
import { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
const TenData = () => {
    const packages = [
       
        //Six
        {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'RUSHD AL MAJD ',
                    description: '500-600 MTR / IBRAHIM KHALIL ROAD- shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'MAJOOM AL MADINAH ',
                    description: '500 MTR / ALI IBN ABI TALIB ROAD - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '216,500',
        },
        //Seven
        {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'DIWAB AL BAIT',
                    description: '500-600 MTR / IBRAHIM KHALIL ROAD - shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'ANWAAR TAQWA',
                    description: '500 MTR / BAB AL SALAM ROAD  - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '216,500',
        },


       
        //Nine
        {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'SAIF AL MAJD ',
                    description: '700-750 MTR/ AL HIJRAH ROAD SIDE - shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'WARDA SAFA',
                    description: '200-250 MTR /SOUTH MARKAZIA - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '230,500',
        },
        //Ten
        {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'MATHER AL JEWAR',
                    description: '450-500 MTR/ AL HIJRAH ROAD - shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'BIR AL EIMAN',
                    description: '200-250 MTR /SOUTH MARKAZIA - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '231,500',
        },
       
    ];

    const getVisiblePackages = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1200) {
            return 4;
        } else if (screenWidth >= 768) {
            return 2;
        } else {
            return 1;
        }
    };

    const [visiblePackages, setVisiblePackages] = useState(getVisiblePackages());

    useEffect(() => {
        const handleResize = () => {
            setVisiblePackages(getVisiblePackages());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const packageChunks = [];
    for (let i = 0; i < packages.length; i += visiblePackages) {
        packageChunks.push(packages.slice(i, i + visiblePackages));
    }
    const handleDownloadCSI = () => {
        const pdfUrl = '../../images/umrahpackages.pdf';
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'UmrahPackages-2024.pdf';
        anchor.click();
    };

    return (
        <section className='unique-packages-section'>
        
                <div className='container'>
                  
                <div className='row'>
                    <Carousel indicators={true} controls={false} interval={null}>
                        {packageChunks.map((chunk, index) => (
                            <Carousel.Item key={index}>
                                <div className="d-flex">
                                    {chunk.map((pkg, pkgIndex) => (
                                        <SevenPackages key={pkgIndex} packageData={pkg} />
                                    ))}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
              

                </div>
                  
                <div className="unique-center">
                    <p>
                        <a target="_blank" rel="noopener noreferrer">
                            <button onClick={handleDownloadCSI}>
                                <b>10 Days Economy Umrah Packages PDF Download</b>
                            </button>
                        </a>
                    </p>
                </div>
            
        </section>
    );
};

export default TenData;