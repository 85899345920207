import React from 'react';
import './MiddleContent.css'

const MiddleContent = () => {
  return (
    <div className='umrah-packages'>
      <div className="umrah-container">
        <div className="umrah-image-row">
          <div className="umrah-image-small-container">
           
            <img src='images/umrah/4.png' alt="blog-img" className="umrah-image-small" />
            <img src='images/umrah/7.png' alt="blog-img" className="umrah-image-small" />
          </div>
          <img src='images/PckgIcon/MakkahMidd.png' alt="blog-img" className="umrah-image-large" />
        </div>
        <div className="umrah-text-column">
          <h2 className="umrah-heading">Umrah Economy Packages</h2>
          <div className="umrah-divider"></div>
          <p className="umrah-text">
            Our Umrah economy packages strike the perfect balance between affordability and spiritual fulfillment. We believe that your journey should be both enriching and budget-friendly, and we've meticulously designed our economy packages to meet these objectives.
          </p>
          <ul className="umrah-text">
            <li><h3><b>5 Days Umrah Package</b></h3> <p className='item-p'> A short but spiritually fulfilling journey, perfect for those with limited time.</p></li>
            <li><h3><b>7 Days Umrah Package</b></h3>  <p className='item-p'>A balanced blend of worship and leisure for a memorable pilgrimage.</p></li>
            <li><h3><b>10 Days Umrah Package</b></h3> <p className='item-p'>Ideal for a more immersive Umrah experience, allowing you to absorb the holy atmosphere.</p> </li>
            <li><h3><b>15 Days Umrah Package</b></h3>   <p className='item-p'>A comprehensive spiritual journey, providing ample time for reflection and prayers.</p></li>
            <li><h3><b>20 Days Umrah Package</b></h3>  <p className='item-p'>A more extended stay for those who wish to deepen their connection with the holy sites.</p></li>
            <li><h3><b>30 Days Umrah Package</b></h3>  <p className='item-p'>The ultimate pilgrimage experience, allowing you to savor every moment of devotion.</p></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MiddleContent;
