import React from 'react'

const Inclusion = () => {
  return (
    <div className="economy-packages" style={{marginTop: "0px"}}>
    <div className='outer-margin'>
    <div className = "vip-border">
      <div className='vip-margin'>
  <div className="economy-heading">
    <h2>Inclusions</h2>
    <p >
    With years of experience serving countless pilgrims, our dedicated team at Malik Express Travel & Tours understands the significance of your journey. We are committed to tailoring the perfect Umrah package that aligns with your specific needs and timelines, ensuring an exceptional experience for you and your family. Let us handle the logistics while you embark on a transformative spiritual journey.
    </p>
  </div>
  <div className="economy-row">
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-plane"></i></span>
        <div className="economy-feature-text">
          <h4>Umrah Visa Processing</h4>
      
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-hotel"></i></span>
        <div className="economy-feature-text">
          <h4>Accommodation</h4>
      
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-bus"></i></span>
        <div className="economy-feature-text">
          <h4>Transportation</h4>
       
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-map"></i></span>
        <div className="economy-feature-text">
          <h4>Guidance from experienced tour leaders</h4>
  
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-universal-access"></i></span>
        <div className="economy-feature-text">
          <h4>Spiritual lectures to enrich your journey</h4>

        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i class="fa fa-heartbeat"></i></span>
        <div className="economy-feature-text">
          <h4 >Ziyarat of significant religious sites</h4>
       
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>
 
 
</div>
  )
}

export default Inclusion