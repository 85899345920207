import React,{useRef} from 'react'
import SevenPackage from './SevenPackage'
import SevenData from './SevenData'
import TenDaysPackage from './TenDaysPackage'
import TenData from './TenData'
import FifteenDaysPackage from './FifteenPackage'
import FifteenData from './FifteenData'
import TwentyOnePackage from './TwentyOnePackage'
import TwentyOneData from './TwentyOneData'
import TwentyEightData from './TwentyEightData'
import TwentyEightPackage from './TwentyEightPackage'
import CustomPackageC from './CustomPackageC'
import UmrahPackagesDays from './UmrahPackageDays'

const ContentHomeDes = () => {
    const SevenPackageRef = useRef(null);
    const TenDaysPackageRef = useRef(null);
    const FifteenDaysPackageRef = useRef(null);
    const TwentyOnePackageRef = useRef(null);
    const TwentyEightPackageRef = useRef(null);
     const CustomPackageCRef = useRef(null);

  
  return (
    <div>
        <UmrahPackagesDays SevenPackageRef={SevenPackageRef} TenDaysPackageRef={TenDaysPackageRef} FifteenDaysPackageRef={FifteenDaysPackageRef} TwentyOnePackageRef={TwentyOnePackageRef} TwentyEightPackageRef= {TwentyEightPackageRef} CustomPackageCRef= {CustomPackageCRef}/>
    <SevenPackage ref={SevenPackageRef}/>
    <SevenData/>
    <TenDaysPackage ref={TenDaysPackageRef}/>
    <TenData/>
    <FifteenDaysPackage ref={FifteenDaysPackageRef}/>
    <FifteenData/>
    <TwentyOnePackage ref={TwentyOnePackageRef}/>
    <TwentyOneData/>
    <TwentyEightPackage  ref={TwentyEightPackageRef}/>
    <TwentyEightData/>
    <CustomPackageC ref={ CustomPackageCRef }/>
    </div>
  )
}

export default ContentHomeDes