
import React from 'react';
import {Link} from 'react-router-dom';
import Carousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

function Tours(){

    const responsive = {
        0: { items: 2 },
        568: { items: 3 },
        1024: { items: 4 },
    };
    
    return(
        <>
        <section id="best-features4" className="banner-padding" style={{ overflow: 'hidden' }}>
          <div className="container">
            <div className="row">
              <div className="page-heading m-auto">
                <h2>Popular Tour Packages From Pakistan</h2>
                <hr className="heading-line" />
                <blockquote>
                Embark on unforgettable journeys to popular destinations such as Dubai, Malaysia, Thailand, and more with our exclusive tour packages from Pakistan. Experience the allure of iconic landmarks, indulge in culinary delights, and immerse yourself in the vibrant cultures of these captivating destinations.
                </blockquote>
                <br />
               
              </div>
            </div>
            <div className="row mt-5">
              <Carousel

                disableDotsControls
                disableButtonsControls
                mouseTracking
                responsive={responsive}
                controlsStrategy="alternate"
              >

                <div className="item pl-3 " >
                  <Link to="/DubaiPackages">
                    <div className="main-block hotel-block" >
                      <div className="main-img" >
                        <a href="hotel-detail-right-sidebar.html" >
                          <img src="../images/tours/dubaipackages.jpg" className="img-fluid" alt="hotel-img" />
                        </a >
                        <div className="main-mask" >
                          <ul className="list-unstyled list-inline offer-price-1" >
                            <li className="list-inline-item price" >PKR 250k<span className="divider" > </span > <span className="pkg"style={{color:"white"}} ></span > </li >

                          </ul >
                        </div >
                      </div >

                      <div className="main-info hotel-info" >
                        <div className="arrow" >
                          <a href="hotel-detail-right-sidebar.html" > <span > <i className="fa fa-angle-right" > </i > </span > </a >
                        </div >

                        <div className="main-title hotel-title">
                          <a href="hotel-detail-right-sidebar.html">Dubai</a>
                          <p style={{ textTransform: "lowercase", fontSize: "14px" }}>6 nights Package </p>
                        </div>

                      </div >
                    </div >
                  </Link>
                </div >

                <div className="item pl-3" >
                  <Link to="/ThailandPackages">
                    <div className="main-block hotel-block" >
                      <div className="main-img" >
                        <a href="hotel-detail-right-sidebar.html" >
                          <img src="../images/tours/Thailandpackage.jpeg" className="img-fluid" alt="hotel-img" />
                        </a >
                        <div className="main-mask" >
                          <ul className="list-unstyled list-inline offer-price-1" >
                            <li className="list-inline-item price" > PKR 350k<span className="divider" > </span > <span className="pkg"style={{color:"white"}} ></span > </li >

                          </ul >
                        </div >
                      </div >

                      <div className="main-info hotel-info" >
                        <div className="arrow" >
                          <a href="hotel-detail-right-sidebar.html" > <span > <i className="fa fa-angle-right" > </i > </span > </a >
                        </div >

                        <div className="main-title hotel-title">
                          <a href="hotel-detail-right-sidebar.html">Thailand</a>
                          <p style={{ textTransform: "lowercase", fontSize: "14px" }}>6 nights Package </p>
                        </div>

                      </div >
                    </div >
                  </Link>
                </div >

                <div className="item pl-3" >
                  <Link to="/MalaysiaPackages">
                    <div className="main-block hotel-block" >
                      <div className="main-img" >
                        <a href="hotel-detail-right-sidebar.html" >
                          <img src="../images/tours/malaysiapacakge.jpg" className="img-fluid" alt="hotel-img" />
                        </a >
                        <div className="main-mask" >
                          <ul className="list-unstyled list-inline offer-price-1" >
                            <li className="list-inline-item price" > PKR 350k<span className="divider" > </span > <span className="pkg"style={{color:"white"}} ></span > </li >

                          </ul >
                        </div >
                      </div >

                      <div className="main-info hotel-info" >
                        <div className="arrow" >
                          <a href="hotel-detail-right-sidebar.html" > <span > <i className="fa fa-angle-right" > </i > </span > </a >
                        </div >

                        <div className="main-title hotel-title">
                          <a href="hotel-detail-right-sidebar.html">Malaysia</a>
                          <p style={{ textTransform: "lowercase", fontSize: "14px" }}>6 nights Package </p>
                        </div>

                      </div >
                    </div >
                  </Link>
                </div >

                <div className="item pl-3" >
                  <Link to="/TurkeyPackages">
                    <div className="main-block hotel-block" >
                      <div className="main-img" >
                        <a href="hotel-detail-right-sidebar.html" >
                          <img src="../images/tours/turkeypackage.jpg" className="img-fluid" alt="hotel-img" />
                        </a >
                        <div className="main-mask" >
                          <ul className="list-unstyled list-inline offer-price-1" >
                            <li className="list-inline-item price" >PKR 450K<span className="divider" > </span > <span className="pkg"style={{color:"white"}} ></span > </li >

                          </ul >
                        </div >
                      </div >

                      <div className="main-info hotel-info" >
                        <div className="arrow" >
                          <a href="hotel-detail-right-sidebar.html" > <span > <i className="fa fa-angle-right" > </i > </span > </a >
                        </div >

                        <div className="main-title hotel-title">
                          <a href="hotel-detail-right-sidebar.html">Turkey</a>
                          <p style={{ textTransform: "lowercase", fontSize: "14px" }}>6 nights Package </p>
                        </div>

                      </div >
                    </div >
                  </Link>
                </div >

                <div className="item pl-3" >
                  <Link to="/ThailandPackages">
                    <div className="main-block hotel-block" >
                      <div className="main-img" >
                        <a href="hotel-detail-right-sidebar.html" >
                          <img src="../images/tours/Malaysia.jpg" className="img-fluid" alt="hotel-img" />
                        </a >
                        <div className="main-mask" >
                          <ul className="list-unstyled list-inline offer-price-1" >
                            <li className="list-inline-item price" >PKR 37,215/-<span className="divider" > </span > <span className="pkg"style={{color:"white"}} >| /Night</span > </li >

                          </ul >
                        </div >
                      </div >

                      <div className="main-info hotel-info" >
                        <div className="arrow" >
                          <a href="hotel-detail-right-sidebar.html" > <span > <i className="fa fa-angle-right" > </i > </span > </a >
                        </div >

                        <div className="main-title hotel-title">
                          <a href="hotel-detail-right-sidebar.html">Thailand</a>
                          <p style={{ textTransform: "lowercase", fontSize: "14px" }}>6 nights Package </p>
                        </div>

                      </div >
                    </div >
                  </Link>
                </div >

                <div className="item pl-3" >
                  <Link >
                    <div className="main-block hotel-block" >
                      <div className="main-img" >
                        <a href="hotel-detail-right-sidebar.html" >
                          <img src="../images/tours/tour1.jpg" className="img-fluid" alt="hotel-img" />
                        </a >
                        <div className="main-mask" >
                          <ul className="list-unstyled list-inline offer-price-1" >
                            <li className="list-inline-item price" >PKR 37,215/-<span className="divider" > </span > <span className="pkg"style={{color:"white"}} >| /Night</span > </li >

                          </ul >
                        </div >
                      </div >

                      <div className="main-info hotel-info" >
                        <div className="arrow" >
                          <a href="hotel-detail-right-sidebar.html" > <span > <i className="fa fa-angle-right" > </i > </span > </a >
                        </div >

                        <div className="main-title hotel-title">
                          <a href="hotel-detail-right-sidebar.html">Bali</a>
                          <p style={{ textTransform: "lowercase", fontSize: "14px" }}>6 nights Package </p>
                        </div>

                      </div >
                    </div >
                  </Link>
                </div >

              </Carousel>

            </div>
          </div>
        </section>
        </>
    )
}

export default Tours;