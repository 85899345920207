import React, { useState, useEffect } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { Helmet } from "react-helmet";

import { ConfirmationNumber } from "@mui/icons-material";
import Swal from "sweetalert2";
function ConfirmedPayment() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const apiUrl = process.env.REACT_APP_API_URL;

    const BasketId = searchParams.get("basket_id");
    const err_msg = searchParams.get("err_msg");
    const err_code = searchParams.get("err_code");
    const transaction_amount = searchParams.get("transaction_amount");
    const Status_code = searchParams.get("status_code");
    const Status_msg = searchParams.get("status_msg");
    const Transaction_id = searchParams.get("transaction_id");

    const [pnr, setPnr] = useState('');
    const [vector, setVector] = useState('');
    const [price, setprice] = useState('')
    useEffect(() => {
        
          if (BasketId) {
              const parts = BasketId.split('-');
              if (parts.length === 3) {
                  const [pnrPart, vectorPart, pricePart] = parts;
                  setPnr(pnrPart);
                  setVector(vectorPart);
                  setprice(pricePart)
              } else {
                  console.error("Invalid OrderId format");
              }
          }
      }, []);
      console.log('pnr vector price', pnr , vector, price)
    const TicketPreview = async () => {
        try {
           
            if (vector === 'NDC') {
                const response = await axios.get(`https://api.malikexpress.com/api/NDC/ticketpreview?orderID=${pnr}&price=${price}`);
                if (response.status === 200) {
                    const result = await swalWithBootstrapButtons.fire({
                        title: 'Ticket',
                        text: 'Are you sure you want to generate ticket?',
                        //icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        reverseButtons: true
                    });
                    if (result.isConfirmed) {
                        try {
                            const cancellationResponse = await axios.get(`https://api.malikexpress.com/api/NDC/ticketpreview?orderID=${pnr}&price=${price}`);
                            if (cancellationResponse.status === 200) {
                                console.log('Responce is', cancellationResponse);
                                alert("Ticket Generated Successfully");
                            } else {
                                console.log('Error', cancellationResponse);
                                alert("Error occurred during ticket generation");
                            }
                        } catch (error) {
                            console.log('Error ndc', error);
                            alert("Error occurred during ticket generation");
                        }
                    }
                }
            } else {
                const response = await axios.get(`https://api.malikexpress.com/api/GalTicket/issuegalticket?PNR=${pnr}`);
                if (response.status === 200) {
                    const result = await swalWithBootstrapButtons.fire({
                        title: 'Ticket',
                        text: 'Are you sure you want to generate ticket?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        reverseButtons: true
                    });
                    if (result.isConfirmed) {
                        try {
                            const Response = await axios.get(`https://api.malikexpress.com/api/GalTicket/issuegalticket?PNR=${pnr}`);
                            if (Response.status === 200) {
                                console.log('Response is ', Response);
                                alert("Ticket Generated Successfully");
                            } else {
                                console.log('Error gal', Response);
                                alert("Error occurred during ticket generation");

                            }
                        } catch (error) {
                            console.log('Error Gal', error);
                            alert("Error occurred during ticket generation");

                        }
                    }
                }
            }
        } catch (error) {
            console.log('Error', error);
        }
    };

 

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    

    const handleClick = (e) => {
       
            TicketPreview();
        
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>
                <meta
                    name="description"
                    content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
                    data-react-helmet="true"
                />
                <meta
                    name="keywords"
                    content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
                    data-react-helmet="true"
                />
                <link rel="canonical" href="https://malikexpress.com/thankyou" />
            </Helmet>
            <MainMenu />
            <section className="innerpage-wrapper">
                <div id="payment-success" >
                    <div className="container text-center" >
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-12 col-lg-8 col-lg-offset-2">
                                <div className="payment-success-text">
                                    {err_code === '000' ? (
                                        <span><i className="fa fa-check-circle"></i></span>
                                    ) : (
                                        <span><i class="fa fa-exclamation-circle"></i></span>
                                    )}
                                    {err_code === '000' ? (
                                        <h2>Thank You!</h2>
                                    ) : (
                                        <h2>Error!</h2>
                                    )}
                                    {err_code === '000' ? (
                                        <p>Your payment of <b>PKR {transaction_amount}</b> has been processed successfully</p>
                                    ) : (
                                        
                                        <p>Your payment of <b>PKR {transaction_amount}</b> has been processed</p>

                                    )}
                                    {err_code === '000' && (
                                        <div className="center2 pt-5 pb-5">                                   
                                            <button
                                         onClick={handleClick}
                                         type="button"
                                         className="btn-lg btn-orange fn semibold"
                                         
                                     >
                                                 Ticket Issue
                                     </button>
                                        </div>
                                         
                                    )}                           
                                  {err_code === '000' ? (
                                    <p>Further Detail For Any Query Please Call At This Number <a href="tel:+923111778811">+92 311 1778811</a>.</p>
                                ) : (
                                    <p>Error During Transaction Please Try Again <br />
                                       Please Call At This Number <a href="tel:+923111778811">+92 311 1778811</a>.
                                    
                                    </p>
                                   )}     
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="col-12 col-md-12  side-bar blog-sidebar right-side-bar" style={{ marginTop: '30px' }}>
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className="side-bar-block follow-us">

                            <a href="https://www.facebook.com/malikexpress/" className="facebook fa fa-facebook fass"></a>
                            <a href="https://twitter.com/malik_express" className="twitter fa fa-twitter fass"></a>
                            <a href="https://www.linkedin.com/in/malik-express-805888138/" className="linkedin fa fa-linkedin fass"></a>
                            <a href="https://www.youtube.com/channel/UCv5a82ncf9ZV2HxRAcvykUg" className="youtube fa fa-youtube fass"></a>
                            <a href="https://www.instagram.com/malikexpress.isb/?igshid=YmMyMTA2M2Y%3D" className="instagram fa fa-instagram fass"></a>




                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    );
}

export default ConfirmedPayment;
