import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import LatestBlog from '../Home/LatestBlogs';
import Testimonials from '../Home/Testimonials';
import Tours from '../Layout/Tours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tick from '../../Icons/Utility/icon_check.png'
import '../Tours/CSS/TourCss.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Typography from '@mui/material/Typography';
import support from '../../Icons/Utility/thumb.png'
import phone from '../../Icons/Utility/phone.png'
import star from '../../Icons/Utility/star.png'
import car from '../../Icons/Utility/car.png'
import { Link } from 'react-router-dom';



function DubaiPackages() {
   
    const Dotcircle =  <svg xmlns="http://www.w3.org/2000/svg" height="1em" style={{marginBottom:"3px"}} viewBox="0 0 512 512"><path d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z"/></svg>
    const False= <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
    const googleMapIframe = `<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d462560.6828253527!2d54.89781885981499!3d25.076280446988985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2s!4v1690190481401!5m2!1sen!2s" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`;
    const Timer = <svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:"4px"}} height="23px" viewBox="0 0 512 512"> <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg>
    const Hotel=<svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:"4px"}} height="23px" viewBox="0 0 512 512"><path d="M0 32C0 14.3 14.3 0 32 0H480c17.7 0 32 14.3 32 32s-14.3 32-32 32V448c17.7 0 32 14.3 32 32s-14.3 32-32 32H304V464c0-26.5-21.5-48-48-48s-48 21.5-48 48v48H32c-17.7 0-32-14.3-32-32s14.3-32 32-32V64C14.3 64 0 49.7 0 32zm96 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H112c-8.8 0-16 7.2-16 16zM240 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H240zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H368c-8.8 0-16 7.2-16 16zM112 192c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H112zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H240c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V208c0-8.8-7.2-16-16-16H368zM328 384c13.3 0 24.3-10.9 21-23.8c-10.6-41.5-48.2-72.2-93-72.2s-82.5 30.7-93 72.2c-3.3 12.8 7.8 23.8 21 23.8H328z"/></svg>
    const Calender =<svg xmlns="http://www.w3.org/2000/svg" style={{marginBottom:"4px"}} height="23px" viewBox="0 0 448 512"><path d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"/></svg>
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <meta name="title" content="Tour Package Dubai | Holiday Packages from Pakistan"></meta>
                <meta name="description" content={"Malik Express Travel & Tours offer the best Dubai tour package from Pakistan. Get the best rates for Turkey, Maldives, Thailand and Europe holiday packages. "} data-react-helmet="true" />
                <meta name="keywords" content={"tour packages Pakistan, tour packages, tour packages in Pakistan, tour packages for Pakistan, tour packages Pakistan, tour packages to Dubai, tour packages Dubai, tour packages for Dubai, tour packages from Pakistan, tour packages for Pakistan, tour package to Dubai, tourist visa for Dubai from Pakistan, how to apply for Dubai visit visa, how to apply for Dubai visit visa from Pakistan, tourist visa in Dubai price, holiday packages from Pakistan"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/tour-package-dubai" />
            </Helmet>
            <MainMenu></MainMenu>

            <section className="page-cover" id="cover-faq">
                <div className="container" style={{ marginTop: "100px" }}>
                    <div className="row">
                        <div className="col-md-12">
                            <><h1 className="text-left" style={{ fontWeight: "bold",color:"white",  marginTop: "40px", marginBottom: "20px" }}>Tour Package Dubai - A Captivating Journey with Malik Express Travel & Tours</h1>

                                <ul className="breadcrumb">

                                    <li className="breadcrumb-item"><Link to="/" className="nav-link">Home  <span style={{color:"white"}}>/   Dubai Tour</span></Link></li>
                                </ul>
                            </>
                        </div>
                    </div>
                </div>
            </section>
            <br></br>
            <br></br>
            <br></br>
            <div className="container">
                <h2 className="text-left" style={{ fontWeight: "bold", fontSize: "25px,", marginTop: "40px", marginBottom: "60px" }}>Dubai Tour Package - Unveil the Glamour of the Desert Jewel</h2>
                <div className="row w-100 mb-5 col-lg-8 col-sm-12">
                    <div className="col-12 col-lg-4 margint"style={{textAlign:"left"}}>
                        {Timer}<span style={{fontSize:"18px",marginTop:"3px"}}className="ml-3">Duration:6 days</span>
                    </div>
                    <div className="col-12 col-lg-4 margint"style={{textAlign:"left"}}>
                    {Hotel}<span style={{fontSize:"18px"}} className="ml-3"> Type:Luxury Tour</span>
                    </div>
                    <div className="col-12 col-lg-4 margint"style={{textAlign:"left"}}>
                    {Calender}<span style={{fontSize:"18px"}} className="ml-3">1st June</span>
                    </div>
                </div>
                <div className="row">
                    <div className="my-auto col-lg-8 col-sm-12">
                        <div className="m-auto">
                            <img src="../images/tours/large/dubaipackages.jpg" style={{ borderRadius: "15px" }} className="w-100 m-auto" alt="dubai tours" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 d-lg-block d-none">
                        <div className="">
                            <img src="../images/tours/Dubai.jpg" style={{ borderRadius: "15px" }} className="w-100 m-auto" alt="dubai tours" />
                        </div>
                        <div className="mt-4">
                            <img src="../images/tours/dubaisecond.jpg" style={{ borderRadius: "15px" }} className="w-100 m-auto" alt="dubai tours" />
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className=" col-lg-8 col-sm-12 mt-5">
                        <div className="">
                            <div className=""style={{marginTop:"40px" }}>
                                <h2 id="HEADING" style={{ fontSize: "25px", textAlign: "left", fontWeight: "bolder" }} >
                                Dubai Tour Package
                                </h2>
                            </div>
                            <p style={{ textAlign: "left" }} className="mt-5">
                            Discover the enchanting allure of this city with our meticulously crafted Dubai Tour Package. At Malik Express Travel & Tours, we invite you to experience the dynamic fusion of modernity and tradition in this iconic City of the United Arab Emirates. Our Tour Package for Dubai offers an immersive adventure where you can explore the majestic skyscrapers, delve into the rich Emirati culture, and bask in the luxurious ambience that defines this glamorous destination.
                            </p>

                            <p style={{ textAlign: "left" }} className="mt-5">
                            From the awe-inspiring Burj Khalifa, the world's tallest building, to the historic Dubai Creek and the opulent Palm Jumeirah, our Dubai Tour takes you on a captivating journey through the city's top attractions. Enjoy the thrill of dune bashing during the Desert Safari, savour a delectable dinner on a Dhow Cruise along Dubai Creek, and discover the cultural gems of the neighbouring emirate, Abu Dhabi.
                            </p>

                            <div className="row mt-5">
                                <div className="col-lg-4 col-sm-12" style={{ textAlign: "left" }}>
                                    <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>6 Nights Package Includes</span>
                                </div>
                                <div className="col-lg-8 col-sm-12 text-left">
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> 6 nights’ Accommodation</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> Daily Breakfast</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> Return Airport transfers</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> Dhow Cruise Dinner</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> Dubai city Tour</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> Desert Safari with BBQ Dinner</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> With 3* Property&nbsp; 37,215/- per person Sharing Twin room</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""style={{color:"#565656"}}> With 4* Property&nbsp; 42,500/- per person Sharing Twin room</span>
                                    </div>
                                </div>

                            </div>
                            <hr></hr>

                            <div className="row mt-5">
                                <div className="col-lg-4 col-sm-12" style={{ textAlign: "left" }}>
                                    <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>6 Nights Package Excludes</span>
                                </div>
                                <div className="col-lg-8 col-sm-12 text-left"style={{lineHeight:"30px"}}>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>6 nights’ Accommodation</span>  </span>
                                    </div>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>Daily Breakfast</span> </span>
                                    </div>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>Return Airport transfers</span></span>
                                    </div>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>Dhow Cruise Dinner</span> </span>
                                    </div>
                                    <div className="m-auto">
                                        <span  className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>Dubai city Tour</span> </span>
                                    </div>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>Desert Safari with BBQ Dinner</span> </span>
                                    </div>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>With 3* Property&nbsp; 37,215/- per person Sharing Twin room</span> </span>
                                    </div>
                                    <div className="m-auto">
                                        <span className="mt-2">{False}<span className="ml-3"style={{color:"#565656"}}>With 4* Property&nbsp; 42,500/- per person Sharing Twin room</span> </span>
                                    </div>
                                </div>

                            </div>
                            <hr></hr>

                          
                            <div className="" style={{ textAlign: "left"}}>
                                <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>6 Nights Dubai Package Cost Per Head</span>
                                <ol className="custom-ol mt-3">
                                    <li className="mt-auto"> {Dotcircle} <span className="mt-1"style={{color:"#565656"}}>$510 only(including Meal)</span></li>
                                </ol>
                            </div>

                            <div className="" style={{ textAlign: "left", marginTop: "65px" }}>
                                <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>Dubai Tour Main Highlights</span>
                                <ol className="custom-ol mt-3">
                                    <li>{Dotcircle} <span style={{color:"#565656"}}> Visit Zinvali Dam, Ananuri Church, Gudauri, Gergeti Church, and Mineral Spring Mountain</span> </li>
                                    <li>{Dotcircle} <span style={{color:"#565656"}}>Tsalka Tour, visit Paranavi and Tsalka Lakes, walk on Diamond Bridge, venture into Dashbash Canyon</span> </li>
                                    <li>{Dotcircle} <span style={{color:"#565656"}}>Mtskheta Tour, visit Javari Monastery, Mtskheta, Svetitskhoveli Cathedral, and Uplistsikhe Caves</span> </li>
                                    <li>{Dotcircle} <span style={{color:"#565656"}}>Tbilisi Tour, visit Sameba Church, Narikla Fort, Peace Bridge, Georgian Mother Statue, Sulfur Bath area, Love Lock Bridge, Mtashminda Park, and The Chronicles of Georgia</span> </li>

                                </ol>
                            </div>



                        </div>

                        <hr style={{marginTop:"50px"}}></hr>
                        <div className=""style={{marginTop:"50px"}}>
                            <div className="text-left " style={{ fontSize: "18px",fontWeight:"bolder" }}>
                            Dubai Tour Itinerary
                            </div>
                            <p className="text-left ">
                            Immerse yourself in a world of luxury, adventure, and culture as we take you on an unforgettable journey through the bustling metropolis of the United Arab Emirates.
                            </p>
                            <Accordion style={{ marginTop: "50px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px",color:"#565656" }}>
                                        Day 1: Arrival in Dubai 
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">Upon your arrival at Dubai International Airport, our friendly representative will welcome you and assist with smooth hotel check-in. Take some time to relax and freshen up before venturing out to explore the city. </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px",color:"#565656" }}>
                                        Day 2: Dubai City Tour 
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">Get ready for an exciting Dubai City Tour, where we'll visit the magnificent Burj Khalifa, the tallest building in the world. Enjoy breathtaking panoramic city views from the observation deck on the 124th floor. Next, we'll head to the Dubai Mall, a shopper's paradise, and experience the captivating Dubai Fountain show. Our tour to Dubai will continue with a visit to the historic Dubai Creek, followed by the enchanting Jumeirah Mosque and the opulent Palm Jumeirah Island. </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                       
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px",color:"#565656" }}>
                                        Day 3: Desert Safari and Dhow Cruise Dinner
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">Embark on an unforgettable Desert Safari adventure! Feel the exhilaration of dune bashing, guided by our skilled drivers, as they take you on an awe-inspiring adventure through the shimmering golden dunes. Enjoy camel rides, sand boarding, and capture the mesmerizing sunset over the Arabian Desert. In the evening, we'll take you on a romantic Dhow Cruise along Dubai Creek, where you can indulge in a delectable dinner while serenaded by live traditional performances. </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px",color:"#565656" }}>
                                        Day 4: Abu Dhabi City Tour 
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">Today, we'll explore the neighbouring emirate of Abu Dhabi. Visit the awe-inspiring Sheikh Zayed Grand Mosque, a true masterpiece of Islamic architecture. Discover the cultural heritage of the UAE at the Heritage Village and immerse yourself in the luxury of the Emirates Palace Hotel. Later, we'll visit the futuristic Yas Island, home to Ferrari World and Yas Waterworld - two world-class theme parks. </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary
                                 expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px",color:"#565656" }}>
                                        Day 5: Departure
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">As your unforgettable Dubai Tour ends, take a moment to savour the cherished memories crafted during your stay. After indulging in a delectable breakfast, complete your check-out from the hotel and make your way to Dubai International Airport, where your journey back home awaits. </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                          <div className="mt-3">
                        <p className="text-left">At Malik Express Travel & Tours, we are committed to crafting exceptional travel experiences, ensuring your Dubai Tour is extraordinary. Book your Dubai Tour Package with us, and let us take care of every detail while you savour the delights of this vibrant city. Dubai awaits your arrival! </p>
                        </div>
                        </div>
                        <hr style={{marginTop:"50px"}}></hr>

                        <div className="" style={{marginTop:"50px"}}>
                            <div className="text-left " style={{ fontSize: "18px", fontWeight: "bolder" }}>
                                Map
                            </div>
                            <div className="mt-5">
                            <div dangerouslySetInnerHTML={{ __html: googleMapIframe }} />
                            </div>
                        </div>
                        <hr style={{marginTop:"50px"}}></hr>
                        <div className=""style={{marginTop:"50px"}}>
                            <div className="text-left " style={{ fontSize: "18px",fontWeight:"bolder" }}>
                           FAQ's Dubai Travel Package 
                            </div>
                            <Accordion style={{ marginTop: "50px" }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", color:"#565656" }}>
                                            Is Only Dubai city is Included in travel
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">Yes </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", color:"#565656" }}>
                                            Are there any extra charges for Meal or Meal included 
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">3 Time meal will be provided </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                       
                            <Accordion >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", color:"#565656" }}>
                                          If Lost in the City What will you do
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">You need to contact on the number 09234778788 </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                           
                        </div>




                    </div>
                    <div className="col-lg-4 col-sm-12 mt-5">
                        <div className="card"style={{borderRadius:"10px"}}>
                            <h3 className="ml-4 text-left mt-3" style={{fontWeight:"bold"}}>Book with Confidence</h3>

                            <div className="" style={{marginTop:"20px"}}>
                                <div className="row ml-5">
                                    <img src={support} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Best price guaranteed</span>
                                </div>

                                <div className="row ml-5 mt-3">
                                    <img src={phone} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Customer care available 24/7</span>
                                </div>

                                <div className="row ml-5 mt-3">
                                    <img src={star} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Hand-picked Tours & Activities</span>
                                </div>

                                <div className="row ml-5 mt-3 mb-5">
                                    <img src={car} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Free Travel Insureance</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <br />
            <hr></hr>
            <br />
            <Testimonials></Testimonials>

            {/* <CruiseOffers></CruiseOffers> */}

            <LatestBlog></LatestBlog>
            <Footer></Footer>
        </>
    )
}

export default DubaiPackages;