import React from 'react';
import './VipUmrah.css';

const VipUmrah = () => {
 return (
  <div className="economy-packages">
    <div className='outer-margin'>
    <div className = "vip-border">
      <div className='vip-margin'>
  <div className="economy-heading">
    <h2>VIP UMRAH PACKAGES</h2>
    <p>
    Our VIP Umrah packages elevate your journey to a realm of exceptional comfort and convenience. These packages are crafted to enhance your spiritual experience while offering the utmost luxury.
    </p>
  </div>
  <div className="economy-row">
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-plane"></i></span>
        <div className="economy-feature-text">
          <h4>VIP Transport</h4>
          <p>Enjoy the finest transportation options, ensuring a hassle-free and comfortable journey.</p>
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-hotel"></i></span>
        <div className="economy-feature-text">
          <h4>Luxury Accommodations</h4>
          <p>Stay in premium hotels, featuring top-notch amenities, and impeccable service.</p>
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-shield"></i></span>
        <div className="economy-feature-text">
          <h4>Exclusive Guidance</h4>
          <p>Benefit from the expertise of our dedicated guides who ensure a seamless pilgrimage.</p>
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-map-marker"></i></span>
        <div className="economy-feature-text">
          <h4>Customized Itineraries</h4>
          <p>Tailored to your preferences, with flexibility to accommodate your unique needs.</p>
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i className="fa fa-universal-access"></i></span>
        <div className="economy-feature-text">
          <h4>VIP Access</h4>
          <p>Skip the lines and enjoy priority access to holy sites, enhancing your spiritual journey.</p>
        </div>
      </div>
    </div>
    <div className="economy-col">
      <div className="economy-feature-block">
        <span><i class="fa fa-heartbeat"></i></span>
        <div className="economy-feature-text">
          <h4>Medical Support</h4>
          <p>Access to on-call medical professionals for any health-related needs during your trip.</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  </div>
  </div>
  <div className="economy-description">
    <p>
      <b>At Malik Express Travel & Tours, we firmly believe that every believer should have the opportunity to fulfill their spiritual obligations without financial strain. Our commitment to providing affordable Umrah packages ensures that your sacred journey is not just an aspiration but a tangible reality. As you conclude your search for the perfect Umrah package, rest assured that with us, your pilgrimage is not just affordable; it’s a transformative experience. Discover the essence of true devotion without compromising your budget. Let us be your guide as you embark on this spiritual adventure, where faith meets affordability. Your pilgrimage begins here, with Malik Express Travel & Tours.</b>
    </p>
  </div>
 
</div>
  ); 
};


export default VipUmrah;
