import React, { useEffect, useRef } from 'react';
import UmrahEnquiryForm from './UmrahEnquiryForm';
import UmrahPackages from './UmrahPackages';
import TopContent from './TopContent';
import Journey from './Journey';
import MiddleContent from './MiddleContent';
import VipUmrah from './VipUmrah';
import EnquiryForm from './EnquiryForm';
import Head from './Head';
import ToursSlider from './TourSlider';
import UmrahCitySection from './UmrahCitySection';
import LatestBlogs from '../Home/LatestBlogs';
import Testimonials from '../Home/Testimonials';
import Newsletters from '../Home/Newsletters';
import Footer from '../Home/Footer';
import MainMenu from '../Layout/MainMenu';
const RouteForm = () => {
  const umrahPackagesRef = useRef(null);

  useEffect(() => {
    // Scroll to UmrahPackages component when component mounts
    if (umrahPackagesRef.current) {
      umrahPackagesRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, []);

  return (
    <div>
      <MainMenu />
      <EnquiryForm />
      <Head />
      <ToursSlider />
      <UmrahCitySection />
      <div ref={umrahPackagesRef}>
        <UmrahPackages />
      </div>
      <TopContent />
      <Journey />
      <MiddleContent />
      <VipUmrah />
      <LatestBlogs />
      <Testimonials />
      <Newsletters />
      <Footer />
    </div>
  );
};

export default RouteForm;
