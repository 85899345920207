import React from 'react'
import { useState } from 'react';

import { FaAngleDown } from 'react-icons/fa';

const FaqsFaisalabad = () => {

    const [openSection, setOpenSection] = useState(null);
    const handleSectionClick = (sectionId, event) => {
        // Prevent the default behavior of anchor tag
        event.preventDefault();
        // Toggle the openSection state
        setOpenSection(openSection === sectionId ? null : sectionId);
    };
  return (
    <div id="services-section">
    <div class="page-heading mar_main" >
        <h2>FAQs About Customized Umrah Packages from Faisalabad</h2>
        {/* <blockquote>
            Travel insurance is a crucial aspect of planning any trip, offering peace of mind and financial protection against unforeseen events. At <a style={{ color: '#000' }} href="https://malikexpress.com/travel-agency-contact-number">Malik Express Travel Agency </a>, we understand the importance of comprehensive travel insurance for travelers from Pakistan. Here's everything you need to know about travel insurance, especially when traveling to Schengen countries and beyond.                            </blockquote> */}
        <br />
        <hr class="heading-line" />
    </div>
    <div className="service-block-1 mar_main2" >
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse1"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse1", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    What are customized Umrah packages from Faisalabad?
                        <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>

            <div id="collapse1" className={`panel-collapse collapse ${openSection === "collapse1" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Customized Umrah packages from Faisalabad are tailored travel plans that allow you to personalize various aspects of your pilgrimage, including accommodation, transportation, and itinerary, according to your preferences and needs.
                  </p>
                </div>
            </div>
        </div>


        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse2"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse2", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    How can I customize my Umrah package from Faisalabad?
                    <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse2" className={`panel-collapse collapse ${openSection === "collapse2" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    You can customize your Umrah package from Faisalabad by choosing specific hotels, transportation options, and additional services like guided tours or Ziyarat. Our team will work with you to create a package that meets your requirements.

                    </p>                                    </div>
            </div>
        </div>

        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse3"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse3", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Can I select my own travel dates for a customized Umrah package from Faisalabad? <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse3" className={`panel-collapse collapse ${openSection === "collapse3" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Yes, customized Umrah packages from Faisalabad allow you to choose your travel dates, providing flexibility to fit your schedule and preferences.


                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse4"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse4", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Are there specific accommodation options available for customized packages from Faisalabad?
   <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse4" className={`panel-collapse collapse ${openSection === "collapse4" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Yes, you can choose from a variety of accommodation options, ranging from budget to luxury hotels, to ensure your comfort during your stay in Makkah and Madinah.
                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse5"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse5", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Is transportation included in customized Umrah packages from Faisalabad?
                                                              <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse5" className={`panel-collapse collapse ${openSection === "collapse5" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Yes, transportation can be included in your customized package from Faisalabad, with options for private transfers or shared transportation to suit your preferences.

                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse6"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse6", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Can I include Ziyarat in my customized package from Faisalabad?

        <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse6" className={`panel-collapse collapse ${openSection === "collapse6" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Absolutely! You can include Ziyarat in your customized package from Faisalabad to visit sacred sites and historical locations in Makkah and Madinah.


                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse7"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse7", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    How do I book a customized Umrah package from Faisalabad?
                    <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse7" className={`panel-collapse collapse ${openSection === "collapse7" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    To book a customized Umrah package from Faisalabad, you can contact us directly. Our experienced team will assist you in creating a package that aligns with your needs and preferences.
                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse8"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse8", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Are there any additional costs for customizing my Umrah package from Faisalabad?

                    <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse8" className={`panel-collapse collapse ${openSection === "collapse8" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    The cost of a customized Umrah package may vary depending on the options you choose. Our team will provide you with a detailed breakdown of costs based on your selections.


                    </p>   </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse9"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse9", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Can I travel with a group in a customized package from Faisalabad?     <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse9" className={`panel-collapse collapse ${openSection === "collapse9" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Yes, you can choose to travel with a group or as an individual in a customized package. Group travel can offer cost savings and enhanced companionship during the journey.




                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse10"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse10", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    What support is available during the Umrah journey?
                              <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse10" className={`panel-collapse collapse ${openSection === "collapse10" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}> 
Our team provides comprehensive support throughout your journey, including assistance with visa processing, accommodation, transportation, and any other services you require to ensure a smooth and fulfilling experience.

                    </p>                                    </div>
            </div>
        </div>
       
    

    </div>
</div>
  )
}

export default FaqsFaisalabad;