import React, { useState, useEffect, useRef } from "react";
import { Helmet } from 'react-helmet';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import LatestBlog from '../Home/LatestBlogs';
import Testimonials from '../Home/Testimonials';
import Tours from '../Layout/Tours';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tick from '../../Icons/Utility/icon_check.png'
import '../Tours/CSS/TourCss.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import support from '../../Icons/Utility/thumb.png'
import phone from '../../Icons/Utility/phone.png'
import star from '../../Icons/Utility/star.png'
import car from '../../Icons/Utility/car.png'

function ThailandPackages(){
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return(
<>
<Helmet>
                <title >{"Thailand Packages"}</title>
                
                <meta name="description" content={"Thailand Packages"} data-react-helmet="true" />
                <meta name="keywords" content={"Thailand Packages"} data-react-helmet="true" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <MainMenu></MainMenu>
      <MainMenu></MainMenu>
            <br></br>
            <br></br>
            <br></br>
            <div className="container">
                <h1 className="text-left" style={{ fontWeight: "bold", fontSize: "25px,", marginTop: "40px", marginBottom: "60px" }}>6 Days Dubai Tour Package - Book Dubai Tours</h1>

                <div className="row">
                    <div className="my-auto col-lg-8 col-sm-12">
                        <div className="m-auto">
                            <img src="../images/tours/large/dubaipackages.jpg" style={{ borderRadius: "15px" }} className="w-100 m-auto" alt="dubai tours" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12">
                        <div className="">
                            <img src="../images/tours/Dubai.jpg" style={{ borderRadius: "15px" }} className="w-100 m-auto" alt="dubai tours" />
                        </div>
                        <div className="mt-4">
                            <img src="../images/tours/dubaisecond.jpg" style={{ borderRadius: "15px" }} className="w-100 m-auto" alt="dubai tours" />
                        </div>
                    </div>

                </div>

                <div className="row">
                    <div className=" col-lg-8 col-sm-12 mt-5">
                        <div className="">
                            <div className=""style={{marginTop:"40px" }}>
                                <h2 id="HEADING" style={{ fontSize: "25px", textAlign: "left", fontWeight: "bolder" }} >
                                    6 nights’ Accommodation in dubai with Breakfast Travel Package
                                </h2>
                            </div>
                            <p style={{ textAlign: "left" }} className="mt-5">
                                Are you yearning for a memorable and fulfilling vacation in Georgia? Look no further than Wanderluxe Travels for the ultimate Georgia tour package. Their 5 Days Georgia Tours are carefully crafted to show you the best of what Georgia offers. From the quaint cobblestone streets of Tbilisi to the stunning natural beauty of Kazbegi, you’ll discover the country’s rich culture and history. With Wanderluxe Travels, every detail is taken care of, allowing you to sit back, relax and enjoy every moment of your trip. Choose from various packages that cater to your interests and budget, and let the Wanderluxe team take you on an unforgettable journey through Georgia. Book your Georgia tour with Wanderluxe Travels today and embark on an adventure of a lifetime.
                            </p>

                            <div className="row mt-5">
                                <div className="col-lg-6 col-sm-12" style={{ textAlign: "left" }}>
                                    <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>6 Nights Peckage Includes</span>
                                </div>
                                <div className="col-lg-6 col-sm-12 text-left">
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> 6 nights’ Accommodation</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> Daily Breakfast</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> Return Airport transfers</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> Dhow Cruise Dinner</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> Dubai city Tour</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> Desert Safari with BBQ Dinner</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> With 3* Property&nbsp; 37,215/- per person Sharing Twin room</span>
                                    </div>
                                    <div className="m-auto">
                                        <img className="m-auto mr-2 pt-2" src={Tick} style={{ height: "25px", width: "19px" }}></img>
                                        <span className=""> With 4* Property&nbsp; 42,500/- per person Sharing Twin room</span>
                                    </div>
                                </div>

                            </div>
                            <hr></hr>
                            {/* <div className="row justify-content-between">
                                <div className="ml-3">
                                    <input type="button" data-toggle="modal" data-target="#myModal" className="btn btn-orange btn-lg" value="BOOK NOW" />
                                </div>
                                <div>
                                    <span>From <span style={{ fontWeight: "bold" }}>PKR&nbsp;37,215</span></span>
                                </div>

                            </div> */}
                            <div className="" style={{ textAlign: "left"}}>
                                <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>6 Nights Dubai Package Cost Per Head</span>
                                <ol className="custom-ol mt-3">
                                    <li >$510 only(including Meal)</li>
                                </ol>
                            </div>

                            <div className="" style={{ textAlign: "left", marginTop: "65px" }}>
                                <span className="" style={{ fontWeight: "bolder", fontSize: "18px" }}>Dubai Tour Main Highlights</span>
                                <ol className="custom-ol mt-3">
                                    <li >Visit Zinvali Dam, Ananuri Church, Gudauri, Gergeti Church, and Mineral Spring Mountain</li>
                                    <li >Tsalka Tour, visit Paranavi and Tsalka Lakes, walk on Diamond Bridge, venture into Dashbash Canyon</li>
                                    <li >Mtskheta Tour, visit Javari Monastery, Mtskheta, Svetitskhoveli Cathedral, and Uplistsikhe Caves</li>
                                    <li >Tbilisi Tour, visit Sameba Church, Narikla Fort, Peace Bridge, Georgian Mother Statue, Sulfur Bath area, Love Lock Bridge, Mtashminda Park, and The Chronicles of Georgia</li>

                                </ol>
                            </div>



                        </div>

                        <hr style={{marginTop:"50px"}}></hr>
                        <div className=""style={{marginTop:"50px"}}>
                            <div className="text-left " style={{ fontSize: "18px",fontWeight:"bolder" }}>
                            6 Days Dubai Travel Itinerary
                            </div>
                            <Accordion style={{ marginTop: "50px" }}>
                                <AccordionSummary

                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
                                            Day 01:Arrival
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">This is the Text </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary

                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
                                            Day 02:Departure
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">This is the Departure text </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                       
                            <Accordion >
                                <AccordionSummary

                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
                                            Day 03:Airport
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">This is the Departure text </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary

                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
                                            Day 04:Beach
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">This is the Beach text </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
                                            Day 05:Burj Khalifa
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">This is the Departure text </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion >
                                <AccordionSummary

                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className="">
                                        <div className="text-left" style={{ fontSize: "16px", fontWeight: "bold", color: "black" }}>
                                            Day 06:The End
                                        </div>
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>
                                        <div className="d-flex">
                                            <ol className="m-2" style={{ listStyleType: "none" }}>
                                                <li className="text-left">This is the Departure text </li>
                                            </ol>
                                        </div>

                                    </Typography>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </div>
                    <div className="col-lg-4 col-sm-12 mt-5">
                        <div className="card"style={{borderRadius:"10px"}}>
                            <h3 className="ml-4 text-left mt-3" style={{fontWeight:"bold"}}>Book with Confidence</h3>

                            <div className="" style={{marginTop:"20px"}}>
                                <div className="row ml-5">
                                    <img src={support} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Best price guaranteed</span>
                                </div>

                                <div className="row ml-5 mt-3">
                                    <img src={phone} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Customer care available 24/7</span>
                                </div>

                                <div className="row ml-5 mt-3">
                                    <img src={star} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Hand-picked Tours & Activities</span>
                                </div>

                                <div className="row ml-5 mt-3 mb-5">
                                    <img src={car} style={{height:"25px",width:"25px"}}></img>
                                    <span className="ml-4">Free Travel Insureance</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <br />
            <hr></hr>
            <br />
        <Testimonials></Testimonials>
        
        {/* <CruiseOffers></CruiseOffers> */}
        
        <LatestBlog></LatestBlog>
        <Footer></Footer>
      </>
    )
}

export default ThailandPackages;