import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
//import moment from "moment";
import Tab from "@mui/material/Tab";
import ArticleIcon from "@mui/icons-material/Article";
import FlightIcon from "@mui/icons-material/Flight";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import { Margin } from "@mui/icons-material";
function Servicelink() {
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [countries, setCountries] = useState([]);
    const navigate = useNavigate();
    const [showAddCountry, setShowAddCountry] = useState(false);
    const [resultMsg, setResultMsg] = useState('');
    const [selectedItem, setSelectedItem] = useState('schengen');
    const [selectedItems, setSelectedItems] = useState(null);
    const [minDate, setMinDate] = useState(getTodayDate());
    const [departureDate, setDepartureDate] = useState('');
    const [arrivalDate, setArrivalDate] = useState('');
    const [dob, setDob] = useState('');
    const [age, setAge] = useState({ years: 0, months: 0, days: 0 });
    const [selectedDate, setSelectedDate] = useState(null);

    useEffect(() => {
        fetchCountries();
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded

        }, 2000); // Adjust the duration as needed

    }, []);


    const selectItems = (itemIds) => {
        setSelectedItems(itemIds);
    };

    const selectItem = (item) => {
        setSelectedItem(item);
    };
    async function fetchCountries() {
        await axios
            .get(apiUrl + "/api/FlightSearch/countries")
            .then((response) => {
                const returnData = response.data;
                //retData= response.data;
                // console.log(response.data);
                setTimeout(() => {
                    setCountries(response.data);
                }, 2000);
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const onlick = () => {
        navigate('/TravelSearch', { state: { selectedItems } });
    }
    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const handleDepartureDateChange = (event) => {
        const selectedDate = event.target.value;
        setDepartureDate(selectedDate);

        // Update the minimum arrival date to be the selected departure date
        setArrivalDate(selectedDate);
    };
    const handleArrivalDateChange = (event) => {
        const selectedDate = event.target.value;
        setArrivalDate(selectedDate);
    };

    const maxDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-mm-dd" format

    useEffect(() => {
        if (dob) {
            calculateAge();
        }
    }, [dob]);

    const handleDateChange = (e) => {
        setDob(e.target.value);
        setSelectedDate(dob);
    };
    const isDateSelected = selectedDate !== null;
    const MyIconVisa =
        '<img src="../images/icons/visa.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';
    const MyIconFlight =
        '<img src="../images/icons/airplane.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';


    const calculateAge = () => {
        const birthDate = new Date(dob);
        const currentDate = new Date();

        let ageInYears = currentDate.getFullYear() - birthDate.getFullYear();
        let ageInMonths = currentDate.getMonth() - birthDate.getMonth();
        let ageInDays = currentDate.getDate() - birthDate.getDate();

        // Adjust age if birthday hasn't occurred yet this year
        if (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            ageInYears--;
            ageInMonths += 12;
        }

        // Calculate age in days, considering different month lengths
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        ageInDays = ageInDays < 0 ? ageInDays + daysInMonth : ageInDays;

        setAge({ years: ageInYears, months: ageInMonths, days: ageInDays });
    };

    const handleAddCountryClick = () => {
        setShowAddCountry(!showAddCountry);
    };
    const [value, setValue] = useState("3");
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [activeTab, setActiveTab] = useState('tab1');

    // Function to handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };
    return (
        <>
            <Helmet>
                <title >{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>

                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (
                <section className="innerpage-wrapper">
                    <div id="travel-insurance" className="innerpage-section-padd">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-8 col-sm-6 cnn" style={{ padding: '0px', }}>


                                    <div className="col-12 col-md-12 col-lg-12" style={{ padding: '0px' }}>
                                        <div className="tabs">
                                            <div
                                                style={{ borderTopLeftRadius: '20px' }}
                                                className={`tab ${activeTab === 'tab1' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('tab1')}
                                            >
                                                Types of immigration
                                            </div>
                                            <div
                                                className={`tab ${activeTab === 'tab2' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('tab2')}
                                            >
                                                Living & working in Austria
                                            </div>
                                            <div
                                                className={`tab ${activeTab === 'tab3' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('tab3')}
                                            >
                                                Service and links
                                            </div>
                                            <div
                                              style={{ borderTopRightRadius: '20px' }}
                                                className={`tab ${activeTab === 'tab4' ? 'active' : ''}`}
                                                onClick={() => handleTabClick('tab4')}
                                            >
                                                FAQ
                                            </div>
                                        </div>
                                        <div className="row" style={{ margin: '0px' }}>
                                            <div class="col-12 col-md-12 col-lg-3 " style={{ padding: '0px' }}>
                                                <div className="carddss">
                                                    <div class="subnav" id="subnav">

                                                        <ul>
                                                            <li className="active first">
                                                                <a
                                                                    href="#"
                                                                    title="Points calculator"

                                                                >
                                                                    Points calculator
                                                                </a>
                                                            </li>
                                                            <li className="active first">
                                                                <a
                                                                    href="#"
                                                                    title="Points calculator"

                                                                >
                                                                    EURES job search
                                                                </a>
                                                            </li>
                                                            <li className="active first">
                                                                <a
                                                                    href="#"
                                                                    title="Points calculator"

                                                                >
                                                                    Addresses and links
                                                                </a>
                                                            </li>
                                                            <li className="active first">
                                                                <a
                                                                    href="#"
                                                                    title="Points calculator"

                                                                >
                                                                    Laws and provisions
                                                                </a>
                                                            </li>
                                                            <li className="active first">
                                                                <a
                                                                    href="#"
                                                                    title="Points calculator"

                                                                >
                                                                    Downloads
                                                                </a>
                                                            </li>
                                                            <li className="active first">
                                                                <a
                                                                    href="#"
                                                                    title="Points calculator"

                                                                >
                                                                    Editorial
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>


                                                <div className="carddss mt-8" style={{ background: 'rgb(238, 238, 238)' }}>

                                                    <div className="service">
                                                        <h2 style={{ color: 'rgb(68, 68, 68)', background:'rgb(204, 204, 204)',display: 'flex', justifyContent: 'center' }}>Service</h2>

                                                        <ul>
                                                            <li>
                                                                <a                                                                  
                                                                    title="Red-White-Red Card"                                                            
                                                                >
                                                                    Red-White-Red Card
                                                                </a>
                                                            </li>
                                                            <li >
                                                                <a                                                                  
                                                                    title="Red-White-Red Card"                                                                                                                                 
                                                                >
                                                                    FAQ
                                                                </a>
                                                            </li>
                                                            <li >
                                                                <a                                                                  
                                                                    title="Red-White-Red Card"                                                                                                                                
                                                                >
                                                                    Points calculator
                                                                </a>
                                                            </li>
                                                            <li >
                                                                <a                                                                 
                                                                    title="Red-White-Red Card"                                                                                                                            
                                                                >
                                                                 Addresses and links
                                                                </a>
                                                            </li>
                                                            <li >
                                                                <a  
                                                                style={{borderBottom:'0px'}}                                                                 
                                                                    title="Red-White-Red Card"                                                                                                             
                                                                >
                                                                Download
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-12 col-lg-8 " style={{ padding: '0px' }}>
                                                <div className="tsearch" style={{ padding: '15px' }}>
                                                    <h1 style={{ color: '#ef9807' }}>Points calculator</h1>
                                                    <p>
                                                        The Red-White-Red Card scheme regulates the immigration of qualified
                                                        third-country nationals to Austria according to a criteria-based model.
                                                        In order to receive a Red-White-Red Card, the applicant has to achieve
                                                        the sufficient amount of points (e.g. for education, professional experience,
                                                        age, language skills).</p>
                                                    <p style={{ marginTop: '20px' }}>
                                                        The points calculator is a service for your first orientation only.
                                                        It does not constitute an application and does not replace the evaluation
                                                        by the Austrian authorities.
                                                    </p>
                                                    <p style={{ marginTop: '20px' }}>
                                                        If you apply for a Red-White-Red Card, the Austrian Public Employment Service (AMS)
                                                        will evaluate your application and the documents submitted according to the respective criteria,
                                                        and will award points. This evaluation by the AMS is decisive for the entire further proceedings.
                                                    </p>
                                                    <p style={{ marginTop: '20px' }}>
                                                        Here you can check – without commitment – if you would achieve the required amount of points.

                                                    </p>
                                                    <div className="row mt-4">
                                                        <div className="col-md-8">
                                                            <div className="form-group">
                                                                
                                                                <div className="travel-form-dropdown">
                                                                    <div className="travel-form-dropdown-in">
                                                                        <div className="travel-form-dropdown-btn form-group status-success">
                                                                            <select
                                                                                className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                            >
                                                                                <option value="">Please select</option>
                                                                                <option value="hq">Very Highly Qualified Workers</option>
                                                                                <option value="sp">Skilled Workers in Shortage Occupations</option>
                                                                                <option value="kw">Other Key Workers</option>
                                                                                <option value="su">Start-up Founders</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group" style={{ display: 'flex', justifyContent: 'end' }}>
                                                                <button type="submit" class="btn2 btn-orange">Next</button>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="col-12 col-md-12 col-lg-3 " style={{ padding: '0px' }}>
                                    <div id="2" className="cardds" style={{ marginTop: '100px' }}>
                                        <div className="h-proo"  >
                                            <h2 style={{ color: '#e5b622' }}>Search:</h2>


                                            <div className="form-group">
                                                <input type="text" required className="form-control" placeholder="Search term" name="fname" />
                                            </div>

                                            <div class="form-group" style={{ display: 'flex', justifyContent: 'end' }}><button type="submit" class="btn2 btn-orange">Search</button></div>

                                        </div>
                                    </div>

                                    <div className="cardds mt-10">
                                        <div className="h-proo">
                                            <h2 style={{ color: '#e5b622' }}>Quick Check</h2>

                                            <div className="form-group">
                                                <p>In Austria, I'd like to...</p>


                                                <div className="form-check pt-2 pb-2">
                                                    <input type="radio" className="form-check-input custom-radio" id="option1" name="austriaOption" />
                                                    <label className="form-check-label ml-2" htmlFor="option1">work</label>
                                                </div>

                                                <div className="form-check pt-2 pb-2">
                                                    <input type="radio" className="form-check-input custom-radio" id="option2" name="austriaOption" />
                                                    <label className="form-check-label ml-2" htmlFor="option2">move to my familiy</label>
                                                </div>
                                                <div className="form-check pt-2 pb-2">
                                                    <input type="radio" className="form-check-input custom-radio" id="option3" name="austriaOption" />
                                                    <label className="form-check-label ml-2" htmlFor="option3">study</label>
                                                </div>
                                                <div className="form-check pt-2 pb-2">
                                                    <input type="radio" className="form-check-input custom-radio" id="option4" name="austriaOption" />
                                                    <label className="form-check-label ml-2" htmlFor="option4">start a business</label>
                                                </div>


                                            </div>

                                            <div class="form-group" style={{ display: 'flex', justifyContent: 'end' }}>
                                                <button type="submit" class="btn2 btn-orange">Start</button>
                                            </div>
                                        </div>

                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>
                </section>
            )}

            <Footer></Footer>
        </>
    );

}

export default Servicelink;