import React from 'react';
import './Pagination.css';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = [];

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <ul className="pagination">
      {pageNumbers.map((page) => (
        <li
          key={page}
          className={currentPage === page ? 'page-item active' : 'page-item'}
          onClick={() => onPageChange(page)}
        >
          <button className="page-link">{page}</button>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
