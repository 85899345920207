import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect ,useRef} from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';


function International_data() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const playerRef = useRef(null);


    
    useEffect(() => {
      // Load the YouTube Player API script
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  
      // Initialize YouTube player when API is ready
      window.onYouTubeIframeAPIReady = initPlayer;
  
      // Clean up function
      return () => {
        window.onYouTubeIframeAPIReady = null;
      };
    }, []);
  
    // Function to initialize YouTube player
    const initPlayer = () => {
      if (!playerRef.current) {
        playerRef.current = new window.YT.Player('youtube-player', {
          height: '400',
          width: '315',
          videoId: 'Qdbl4PnMYDQ',
          playerVars: {
            autoplay: 1,
            mute: 1
          },
          events: {
            onStateChange: onPlayerStateChange
          }
        });
      }
    };
  
    // Function to handle player state change events
    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        // Load a new video after current video ends
        playerRef.current.loadVideoById({
          videoId: 'Qdbl4PnMYDQ',
          startSeconds: 0,
          suggestedQuality: 'hd720'
        });
      }
    };
   
    return (
<>
<section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
              <p style={{fontWeight:'bold',color:'#fff'}}>Explore Global Adventures Safely with</p>
                <h1 style={{fontWeight:'bold',color:'#fff'}}>
                International Travel Insurance 
                </h1>
              <hr  style={{borderColor:'#fff'}} className="heading-line" />
              <br />
              <blockquote style={{color:'white'}}>
              International Travel Insurance is your passport to worry-free adventures around the globe. It provides comprehensive coverage for a wide range of travel risks, including medical emergencies, trip cancellations, lost baggage, and more. With Malik Express Travel & Tours, you can secure your journey with our tailored international travel insurance plans designed to meet the needs of modern travelers.
                            <br />
             
                            Prepare for your journey across borders with Malik Express Travel & Tours' International Travel Insurance online. Whether you're planning a solo expedition, a family vacation, or a business trip, our tailored travel insurance options are designed to meet the diverse needs of modern travelers. With Malik Express, your safety and security are our top priorities, allowing you to immerse yourself fully in the wonders of global exploration.             
             
              </blockquote>
            </div>
            </div>
          </div>
        </div>
      </section>


      <section>
            
            
            <div className="plan-800 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center">
        <div className="col-md-7  order-2 order-md-1">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Who Benefits from International Travel Insurance Plans?</h2>
                <blockquote>

                Our International Travel Insurance plans are perfect for globetrotters, adventurers, families, and business travelers alike. Whether you're exploring iconic landmarks, immersing yourself in diverse cultures, or sealing deals in boardrooms across the world, our insurance coverage ensures that you're protected against unexpected setbacks and emergencies.
                      </blockquote>
            </div>
            
        </div>
      
     <div className="col-md-4 order-1 order-md-2" >
            <div>
          
    <div className="planimg" id="youtube-player" />

            </div>
        </div>
            
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>

<section className="plan-800 pt-xs-20 ">
        <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Key Features of International Travel Insurance Plans</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="container">
    <div className="row justify-content-center">
        <div className="grid grid-cols-12 gap-4 md:gap-6 py-[1.875rem] md:pt-10 md:pb-0">
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/Comprehensive Coverage.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Comprehensive Coverage</h3>
      <p className="text-14 leading-1-4 md:text-16">Enjoy extensive coverage for medical expenses, emergency evacuation, trip interruptions, and more</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/Global Assistance.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Global Assistance</h3>
      <p className="text-14 leading-1-4 md:text-16">Access 24/7 emergency assistance services, including medical consultations and travel advice, wherever you are in the world.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/Flexible Options.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Flexible Options</h3>
      <p className="text-14 leading-1-4 md:text-16">Choose from a variety of insurance plans tailored to your travel needs, including single-trip, multi-trip, and family coverage options.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/Easy Online Purchase.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Easy Online Purchase</h3>
      <p className="text-14 leading-1-4 md:text-16">Purchase your international travel insurance conveniently online from Pakistan, with instant policy issuance and hassle-free processing</p>
    </div>
    </div>
    </div>
</div>


                    </div>

                </div>
            </div>
</section>


        <section>
            
            
            <div className="plan-80 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
        <div className="col-md-7  order-2 order-md-1">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Why Choose Malik Express Travel & Tours</h2>
                <blockquote>

                With Malik Express Travel & Tours, you can travel with confidence, knowing that you're backed by a trusted partner in travel insurance. Our commitment to customer satisfaction, competitive premiums, and seamless online experience sets us apart as the preferred choice for travelers seeking reliable international travel insurance coverage. Embark on your global adventures with peace of mind, knowing that you're protected by Malik Express Travel & Tours every step of the way.

                      </blockquote>
            </div>
            
        </div>
        <div className="col-md-5 order-1 order-md-2" >
            <div>
            <img
                                            src="../images/why choose us.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
        </div>
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>
        </>

    );

}



export default International_data;