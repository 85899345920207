import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect ,useRef} from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';


function StudentTravel() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const playerRef = useRef(null);

    useEffect(() => {
      // Load the YouTube Player API script
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  
      // Initialize YouTube player when API is ready
      window.onYouTubeIframeAPIReady = initPlayer;
  
      // Clean up function
      return () => {
        window.onYouTubeIframeAPIReady = null;
      };
    }, []);
  
    // Function to initialize YouTube player
    const initPlayer = () => {
      if (!playerRef.current) {
        playerRef.current = new window.YT.Player('youtube-player', {
          height: '400',
          width: '315',
          videoId: 'Qdbl4PnMYDQ',
          playerVars: {
            autoplay: 1,
            mute: 1
          },
          events: {
            onStateChange: onPlayerStateChange
          }
        });
      }
    };
  
    // Function to handle player state change events
    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        // Load a new video after current video ends
        playerRef.current.loadVideoById({
          videoId: 'Qdbl4PnMYDQ',
          startSeconds: 0,
          suggestedQuality: 'hd720'
        });
      }
    };
    return (
<>
<section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
              <p style={{fontWeight:'bold',color:'#fff'}}>Secure Study Adventures Abroad</p>
                <h1 style={{fontWeight:'bold',color:'#fff'}}>
                Buy Student Travel Insurance Online 
                </h1>
              <hr  style={{borderColor:'#fff'}} className="heading-line" />
              <br />
              <blockquote style={{color:'white'}}>
              Student Travel Insurance is a specialized insurance plan designed to provide coverage for students traveling abroad for educational purposes, internships, or exchange programs. It offers comprehensive protection against a range of travel risks, including medical emergencies, trip cancellations, and loss of belongings. At Malik Express Travel & Tours, we offer tailored Student Travel Insurance plans to ensure that students can pursue their academic endeavors with confidence and peace of mind.  
              </blockquote>
            </div>
            </div>
          </div>
        </div>
      </section>


      <section>
            
            
            <div className="plan-800 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center">
        <div className="col-md-7  order-2 order-md-1">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Who Benefits from Student Insurance Plans?</h2>
                <blockquote>

                Our Student Travel Insurance plans are ideal for students embarking on international study programs, exchange programs, or internships. Whether you're a high school student participating in a cultural exchange, a college student studying abroad for a semester, or a graduate student conducting research overseas, our insurance coverage ensures that you're protected against unforeseen emergencies and incidents during your time abroad.
                      </blockquote>
            </div>
            
        </div>
        <div className="col-md-4 order-1 order-md-2" >
            <div>
         
    <div className="planimg" id="youtube-player" />

            </div>
        </div>
        
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>

<section className="plan-800 pt-xs-20 ">
        <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Key Features of Student Travel Insurance Plans</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="container">
    <div className="row justify-content-center">
        <div className="grid grid-cols-12 gap-4 md:gap-6 py-[1.875rem] md:pt-10 md:pb-0">
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Medical Coverage.png"
        
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Medical Coverage</h3>
      <p className="text-14 leading-1-4 md:text-16">Ensure access to quality medical care and treatment in the event of illness or injury during your travels.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Trip Interruptions.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Trip Interruptions</h3>
      <p className="text-14 leading-1-4 md:text-16">Protect against unexpected trip interruptions, cancellations, or delays due to unforeseen circumstances.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Emergency Assistance.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Emergency Assistance</h3>
      <p className="text-14 leading-1-4 md:text-16">Access 24/7 emergency assistance services, including medical consultations, emergency evacuations, and travel advice, for added peace of mind.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/study interuptions.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Study Interruptions</h3>
      <p className="text-14 leading-1-4 md:text-16">Cover the cost of tuition fees or academic expenses in the event of study interruptions due to medical emergencies or other covered reasons.</p>
    </div>

    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Flexible Options.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Flexible Options</h3>
      <p className="text-14 leading-1-4 md:text-16">Choose from a range of coverage options and policy durations to suit your specific travel plans and academic requirements.</p>
    </div>

    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Easy Online Purchase.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Easy Online Purchase</h3>
      <p className="text-14 leading-1-4 md:text-16">Purchase Student Travel Insurance conveniently online from Pakistan, with instant policy issuance and seamless processing for a hassle-free experience.</p>
    </div>
    </div>
    </div>
</div>


                    </div>

                </div>
            </div>
</section>


        <section>
            
            
            <div className="plan-80 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
        
<div className="col-md-5" >
            <div>
            <img
                                            src="../images/why choose us.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
        </div>
        <div className="col-md-7">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Why Choose Malik Express Travel & Tours?</h2>
                <blockquote>

                With Malik Express Travel & Tours, students can pursue their academic aspirations abroad with confidence, knowing that they're backed by reliable and comprehensive insurance coverage. Our commitment to providing quality service, competitive premiums, and convenient online purchase options makes us the preferred choice for students seeking reliable protection for their international travels. Embark on your educational journey abroad with peace of mind, knowing that you're covered by Malik Express Travel & Tours every step of the way.

                      </blockquote>
            </div>
            
        </div>
      
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>
        </>

    );

}



export default StudentTravel;