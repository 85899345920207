import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

function Work() {
   
 
    return (
        <>
            <Helmet>
                <title>{"Malik Express Travel Agency in Islamabad Pakistan | Travel Agents"}</title>
                <meta name="description" content={"Malik Express - best travel agency in Islamabad, Pakistan providing travel services flight booking, visa, tour packages, travel insurance. Expert travel agents. "} data-react-helmet="true" />
                <meta name="keywords" content={"Travel services, travel agents Pakistan, travel agency in Islamabad Pakistan, travel agents in Islamabad Pakistan, travel agency near me, travel agents, travel agencies in Islamabad, travel agency Islamabad, travel agencies, travel agency in Pakistan, travel agencies Islamabad, travel agency Pakistan, travel agents in Pakistan, travel agents Islamabad, travel agencies in Pakistan, travel agencies Pakistan"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com" />
            </Helmet>
            <section className="py-10 md:py-[3.75rem] bg-[#F9FAFC]">
      <div className="bg-img-flight">
        <div className="container">
          <div className="flex flex-col lg:flex-row justify-between lg:gap-[6.25rem]">
            <div className="lg:max-w-[37.5rem]">
              <h2 className="text-28 lg:text-44 leading-1-2 mb-4 font-bold">How It Works</h2>
              <p className="text-16 md:text-18 leading-1-4 mb-4">With our experience, we simplify the process so that you can get your needs in the best way, easiest and most convenient.</p>
              <a title="Find your plan" className="text-red-gradient text-14 md:text-16 leading-1-4 flex items-center gap-3" href="/online-travel-insurance-pakistan">
                <span>Find your plan</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path d="M8.70532 0.705315C8.31578 0.31578 7.68422 0.31578 7.29468 0.705316V0.705316C6.9054 1.0946 6.90511 1.72568 7.29405 2.11531L12.17 7H0.999999C0.447714 7 0 7.44772 0 8V8C0 8.55228 0.447715 9 1 9H12.17L7.29405 13.8847C6.90511 14.2743 6.9054 14.9054 7.29468 15.2947V15.2947C7.68422 15.6842 8.31578 15.6842 8.70532 15.2947L14.6667 9.33333C15.403 8.59695 15.403 7.40305 14.6667 6.66667L8.70532 0.705315Z" fill="#e5b815"></path>
                </svg>
              </a>
              <img className="mobile-only mt-[1.875rem] mb-10 -ml-[16px]"
      alt="insurance"
      
      loading="lazy"
      width={320}
      height={137}
      decoding="async"
      
      sizes="(max-width: 768px) 100vw, 100vw"
    //  srcSet="https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=640&q=75 640w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=750&q=75 750w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=828&q=75 828w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=1080&q=75 1080w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=1200&q=75 1200w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=1920&q=75 1920w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=2048&q=75 2048w, https://d2welvdu9aysdk.cloudfront.net/uploads/insurance-destination/plane.png?w=3840&q=75 3840w"
      src="../images/airplane.png"
      style={{ color: 'transparent', objectFit: 'cover' }}
    />
            </div>

            <div className="flex flex-col md:max-w-[35rem] gap-y-[1.875rem] md:gap-y-[4.125rem] relative">
              <div className="flex flex-start items-center gap-x-5 md:gap-x-[1.875rem] last:mt-4">
                <div className="shrink-0 bg-[#F9FAFC]">
                  <img alt="Choose Suitable Plan" title="Choose Suitable Plan" loading="lazy" width="80" height="80" decoding="async" data-nimg="1" className="w-[56px] h-[56px] md:w-[80px] md:h-[80px]" style={{color: "transparent", objectFit: "cover"}}  src="../images/tap.png" />
                </div>
                <h3 className="text-20 md:text-28 font-semibold leading-1-2">Choose Suitable Plan</h3>
              </div>
             
<div className="flex flex-start items-center gap-x-5 md:gap-x-[1.875rem] last:mt-4">
  <div className="shrink-0 bg-[#F9FAFC]">
    <img alt="Purchase Online & Make Payment" title="Purchase Online & Make Payment" loading="lazy" width="80" height="80" decoding="async" data-nimg="1" className="w-[56px] h-[56px] md:w-[80px] md:h-[80px]" style={{color: "transparent", objectFit: "cover"}} src="../images/box.png" />
  </div>
  <h3 className="text-20 md:text-28 font-semibold leading-1-2">Purchase Online & Make Payment</h3>
</div>
<div className="flex flex-start items-center gap-x-5 md:gap-x-[1.875rem] last:mt-4">
  <div className="shrink-0 bg-[#F9FAFC]">
    <img alt="Receive Travel Insurance" title="Receive Travel Insurance" loading="lazy" width="80" height="80" decoding="async" data-nimg="1" className="w-[56px] h-[56px] md:w-[80px] md:h-[80px]" style={{color: "transparent", objectFit: "cover"}} src="../images/insurance slip.png" />
  </div>
  <h3 className="text-20 md:text-28 font-semibold leading-1-2">Receive Travel Insurance</h3>
</div>
<div className="flex flex-start items-center gap-x-5 md:gap-x-[1.875rem] last:mt-4">
  <div className="shrink-0 bg-[#F9FAFC]">
    <img alt="Enjoy Your Trip" title="Enjoy Your Trip" loading="lazy" width="80" height="80" decoding="async" data-nimg="1" className="w-[56px] h-[56px] md:w-[80px] md:h-[80px]" style={{color: "transparent", objectFit: "cover"}} src="../images/luggage.png" />
  </div>
  <h3 className="text-20 md:text-28 font-semibold leading-1-2">Enjoy Your Trip</h3>
</div>
<div className="w-[6px] md:w-2 absolute top-0 bottom-44 sm:bottom-16 left-[37px] md:left-[39px] -z-10">
  <img alt="dot" title="dot" loading="lazy" decoding="async" data-nimg="fill" style={{position: "absolute", height: "100%", width: "100%", left: "0", top: "0", right: "0", bottom: "0", objectFit: "contain", color: "transparent"}} sizes="100vw" srcSet="https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=640&amp;q=75 640w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=750&amp;q=75 750w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=828&amp;q=75 828w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=1080&amp;q=75 1080w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=1200&amp;q=75 1200w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=1920&amp;q=75 1920w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=2048&amp;q=75 2048w, https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=3840&amp;q=75 3840w" src="https://d2welvdu9aysdk.cloudfront.net/uploads/how-it-works/line.png?w=3840&amp;q=75" />
</div>

            </div>
                 
</div>

        </div>
      </div>
      <div class="mlk-row mlk-row-center mlk-row-middle">
  <button
    type="submit"
    style={{ marginTop: '20px' }}
    onClick={() => window.location.href = "/online-travel-insurance-pakistan"}
    className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base"
  >
    <span>Book Now</span>
  </button>
</div>
 
    </section>
        </>
    );
}

export default Work;
