import React, { useEffect, useState } from 'react';
import Features from './Features';
import InnerPage from './InnerPage';
import Testimonials from './Testimonials';
import FlightOffers from './FlightOffers';
import LatestBlog from './LatestBlogs';
import { Helmet } from 'react-helmet';
import PK from "../../Icons/airlines/PF.png";
import Blue from "../../Icons/airlines/PA.png";
import Fly from "../../Icons/airlines/jinnah.png";
import Serene from "../../Icons/airlines/serenee.png";
import Emirates from "../../Icons/airlines/EK.png";
import PIA from "../../Icons/airlines/PIA.png";
import Qatar from "../../Icons/airlines/QR.png";
import Saudi from "../../Icons/airlines/SV.png";
import Arabia from "../../Icons/airlines/G9.png";
import Dubai from "../../Icons/airlines/FZ.png";
import PopularAirLine from './PopularAirLine';

function Home() {
    const [startIndex, setStartIndex] = useState(0);
    const images = [
        PIA, PK, Serene, Blue, Fly, Dubai, Arabia, Qatar, Saudi, Emirates
    ];
    const visibleImages = images.slice(startIndex, startIndex + 7);

    const handleLeftArrowClick = () => {
        if (startIndex === 0) {
            setStartIndex(images.length - 7);
        } else {
            setStartIndex(startIndex - 1);
        }
    };

    const handleRightArrowClick = () => {
        if (startIndex === images.length - 7) {
            setStartIndex(0);
        } else {
            setStartIndex(startIndex + 1);
        }
    };

    useEffect(() => {
        const leftArrow = document.querySelector('.left-arrow');
        const rightArrow = document.querySelector('.right-arrow');
        const container = document.querySelector('.ant-row');

        leftArrow.addEventListener('click', () => {
            if (container.scrollLeft > 0) {
                container.scrollTo({
                    left: container.scrollLeft - 5,
                    behavior: 'smooth'
                });
            }
        });

        rightArrow.addEventListener('click', () => {
            if (container.scrollLeft < container.scrollWidth - container.clientWidth) {
                container.scrollTo({
                    left: container.scrollLeft + 5,
                    behavior: 'smooth'
                });
            }
        });
    }, []);

    return (
        <>
            <Helmet>
                <title>{"Malik Express Travel Agency in Islamabad Pakistan | Travel Agents"}</title>
                <meta name="description" content={"Malik Express - best travel agency in Islamabad, Pakistan providing travel services flight booking, visa, tour packages, travel insurance. Expert travel agents. "} data-react-helmet="true" />
                <meta name="keywords" content={"Travel services, travel agents Pakistan, travel agency in Islamabad Pakistan, travel agents in Islamabad Pakistan, travel agency near me, travel agents, travel agencies in Islamabad, travel agency Islamabad, travel agencies, travel agency in Pakistan, travel agencies Islamabad, travel agency Pakistan, travel agents in Pakistan, travel agents Islamabad, travel agencies in Pakistan, travel agencies Pakistan"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com" />
            </Helmet>
            <Features />
            <FlightOffers />
            <InnerPage />
            <PopularAirLine />
            <Testimonials />
         
            <LatestBlog />
            <div className='patner' style={{paddingBottom:'40px'}}>
        <div className="ant-col ant-col-24" style={{ display: 'flex' }}>
                <div className="z88t1wotdI4A1DpntAdLs row" style={{ display: 'inline', overflowX: 'auto' }}>
                    <div className="ant-row ant-row-no-wrap ant-row-middle xs:px-4 sm:px-4 mb-4 row" style={{ marginLeft: "-12px", marginRight: "-12px", rowGap: "12px", display: 'flex' }}>
                        <div className="ant-col ant-col-6" style={{ paddingLeft: "12px", paddingRight: "12px" }}>
                            <h1 className="ant-typography font-normal text-3xl mb-1">Featured Partners</h1>
                            <h1 className="ant-typography font-normal text-2xl text-gray-8 mt-0 mb-0">Domestic &amp; International</h1>
                        </div>
                        <div className="arrow left-arrow" style={{ paddingLeft: '35px', paddingRight: '10px', alignSelf: 'center' }} onClick={handleLeftArrowClick}>
                            <a href="#!">
                                <i className="fa fa-angle-left" style={{ alignContent: 'center', display: 'flex' }}></i>
                            </a>
                        </div>
                        {visibleImages.map((image, index) => (
                            <div key={index} className="text-left" style={{ paddingLeft: '20px', flex: '0 0 auto' }}>
                                <img height='auto' width="80px" className="p-1" src={image} alt={`Airline ${startIndex + index}`} />
                            </div>
                        ))}
                        <div className="arrow right-arrow" style={{ paddingLeft: '35px', paddingRight: '20px', alignSelf: 'center' }} onClick={handleRightArrowClick}>
                            <a href="#!">
                                <i className="fa fa-angle-right" style={{ alignContent: 'center', display: 'flex' }}></i>
                            </a>
                        </div>
                    </div>
                </div>
       
        </div>
      </div>
            <section className="innerpage-wrapper">
                <div id="why-us">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="page-heading innerpage-heading">
                                    <h2>Discover Your Next Adventure with Malik Express Travel & Tours</h2>
                                    <hr className="heading-line" />
                                    <blockquote>
                                        Embark on a journey of discovery and adventure with Malik Express Travel & Tours. Whether you're seeking relaxation on pristine beaches, cultural immersion in exotic locales, or spiritual fulfilment on a pilgrimage, we are here to make your travel dreams a reality.
                                        <br />
                                        Don't let your travel dreams remain fantasies. Contact the best travel agency in Islamabad today and start your journey towards unforgettable experiences.
                                    </blockquote>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
