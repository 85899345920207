import React, {useState} from 'react'
import {Link,Routes, Route,BrowserRouter as Router} from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Drawer,Menu,SubMenu } from 'antd';
import { color } from '@mui/system';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

library.add(faUser);




function MainMenu(){
    const { SubMenu } = Menu;

    //   const [openSubMenu, setOpenSubMenu] = useState(null);
    
    //   const handleSubMenuClick = (e) => {
    //     setOpenSubMenu(e.key);
    //   }


    const [selectedMenuItem, setSelectedMenuItem] = useState(null);

        const isMobile = useMediaQuery({ maxWidth: 768 });
        const [open, setOpen] = useState(false);
        const showDrawer = () => {
          setOpen(true);
        };
        const onClose = () => {
          setOpen(false);
        };

        const menuItems = [
            
            {
                key: 'Home',
                title: 'HOME',
                url:'/'
              },
            {
              key: 'Flights',
              title: 'FLIGHT',
              url:'/cheap-flights-booking'
            },
             {
              key: 'Visas',
              title: 'Visas',
              url:'/visa-consultancy'
            },
            {
              key: 'Tours',
              title: 'Tours',
              url:'/tour-packages-from-pakistan'
            },
            {
              key: 'blog',
              title: 'Blog',
              url:'/travel-blog'
            },
            {
              key: 'TI',
              title: 'Travel Insurance',
              url:'/online-travel-insurance-pakistan'
            },
            {
              key: 'umrah',
              title: 'Umrah Packages',
              url:'/umrah-packages-from-pakistan'
            },
            {
              key: 'AboutUs',
              title: 'About Us',
              url:'/travel-agency-in-islamabad'
            },
            {
              key: 'ContactUs',
              title: 'Contact Us',
              url:'/travel-agency-contact-number'
            }
            
          /*   {
              key: 'item2',
              title: 'Item 2',
              subMenuItems: [
                {
                  key: 'sub-menu-item-3',
                  title: 'Sub Menu Item 3'
                },
                {
                  key: 'sub-menu-item-4',
                  title: 'Sub Menu Item 4'
                }
              ]
            } */
          ];
          const Url = "https://malikexpress.com/";
          const ImageUrl=Url+ "images/logo mex.svg";
          const [openSubMenu, setOpenSubMenu] = useState(null);

          const handleSubMenuClick = (e) => {
            setOpenSubMenu(e.key);
          }
        
          const getCurrentSubMenuItems = () => {
            const currentItem = menuItems.find(item => item.key === openSubMenu);
            if (currentItem) {
              return currentItem.subMenuItems || [];
            }
            return [];
          }


    return(
<>
{!isMobile ? (
  <>
            <nav className="navbar navbar-expand-xl sticky-top navbar-custom navbar-transparent white-color main-navbar p-1" style={{ background: "black", height: "80px" }} id="mynavbar-1">
          <div className="container">

          <div className='d-flex w-100  justify-content-between'>
                <div className=''>
                  <a href='https://malikexpress.com/' > 
                    <img src={ImageUrl} alt="malik express logo" />
                    </a>
                </div>
                <div className='my-auto'>
                <span className='' style={{ fontStyle: 'italic', color: '#e5b815',fontSize: '23px', fontFamily: 'Pristina', textAlign: 'left' }}>30 years of providing excellent Services</span>

                </div>
                <div className='my-auto'>
                  <div className='row'>
                    <div className='m-auto'>
                    <a  href="http://103.152.255.8:9000/Account/Login" target="_blank" style={{ color: "#e5b815", fontWeight: 'normal' }}>Sign In</a>
                  </div>
                  </div>
                  <div className='row'>
                <p className="navbar-nav ml-auto " style={{ color: "white" }}>(051) 111 77 88 00</p>
                </div>
                </div>
             
              
            </div>
          </div>

        </nav>
        
       
        <nav className="navbar navbar-expand-xl stickytopbar navbar-custom navbar-transparent white-color main-navbar p-1" style={{ background: "white", height: "40px" }} id="mynavbar-1">
          <div className="container">

            <div className='row mx-auto' style={{alignContent:'center'}}>
            <div className="collapse navbar-collapse" id="myNavbar1">
              <ul className="navbar-nav ml-auto " style={{ color: "black" }}>
                <li className="nav-item">
                <Link className="nav-link" to="/" style={{ color: "#e5b815", fontWeight: 'normal' }}>Home</Link>
                </li>

                <li className="nav-item"><Link className="nav-link" to="/cheap-flights-booking" style={{ color: "#e5b815", fontWeight: 'normal' }}>Flights</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/hotel-booking" style={{ color: "#e5b815", fontWeight: 'normal' }}>Hotels</Link></li>
                {/* <li className="nav-item"><Link className="nav-link" to="/online-travel-insurance-pakistan" style={{ color: "#e5b815", fontWeight: 'normal' }}>Travel Insurance</Link></li> */}
                <li class="nav-item dropdown">
                <Link className="nav-link" to="/online-travel-insurance-pakistan" style={{ color: "#e5b815", fontWeight: 'normal' }}>Travel Insurance
                <span><i class="fa fa-angle-down"></i></span> 
                </Link>                            <ul class="dropdown-menu">
                                <li><Link class="dropdown-item" to="/international-travel-insurance-online" style={{ color: "#e5b815", fontWeight: 'normal' }}>International Travel Insurance</Link></li>
                                <li><Link class="dropdown-item" to="/visitor-travel-insurance-online" style={{ color: "#e5b815", fontWeight: 'normal' }}>Visitor Travel Insurance</Link></li>
                                <li><Link class="dropdown-item" to="/business-travel-insurance-online" style={{ color: "#e5b815", fontWeight: 'normal' }}>Business Travel Insurance</Link></li>
                                <li><Link class="dropdown-item" to="/student-travel-insurance-online" style={{ color: "#e5b815", fontWeight: 'normal' }}>Student Travel Insurance</Link></li>
                                {/* <li><Link class="dropdown-item" to="/covid-covered-travel-insurance-online" style={{ color: "#e5b815", fontWeight: 'normal' }}>Covid-covered Travel Insurance</Link></li> */}
                                <li><Link class="dropdown-item" to="/travel-insurance-for-schengen-visa-online" style={{ color: "#e5b815", fontWeight: 'normal' }}>Travel Insurance for Schengen Visa</Link></li>
                            </ul>
                        </li>
                <li className="nav-item"><Link className="nav-link" to="/visa-consultancy" style={{ color: "#e5b815", fontWeight: 'normal' }}>Visa</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/tour-packages-from-pakistan" style={{ color: "#e5b815", fontWeight: 'normal' }}> Tour Packages</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/travel-blog" style={{ color: "#e5b815", fontWeight: 'normal' }}>Blog</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/umrah-packages-from-pakistan" style={{ color: "#e5b815", fontWeight: 'normal' }}>Umrah Packages</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/travel-agency-in-islamabad" style={{ color: "#e5b815", fontWeight: 'normal' }}>About Us</Link></li>
                <li className="nav-item"><Link className="nav-link" to="/travel-agency-contact-number" style={{ color: "#e5b815", fontWeight: 'normal' }}>Contact Us</Link></li>
              
              </ul>
            </div>
            </div>
          </div>

        </nav></>
): <></>}
            {isMobile ? (
                <>
                      <nav className="navbar navbar-expand-xl sticky-top navbar-custom navbar-transparent white-color main-navbar p-1" style={{ background: "black", height: "80px" }} id="mynavbar-1">
                      <div className="container">
  <div className=' d-flex w-100 justify-content-between'>
    <button className="navbar-toggler ml-2" style={{ color: "white" }} onClick={showDrawer} type="submit" aria-controls="navbarSupportedContent" id="sidebarCollapse">
      <i className="fa fa-navicon py-1"></i>
    </button>
    <span>
      <img src={ImageUrl} alt="malik express" />
      <div className='' style={{ fontStyle: 'italic', color: '#e5b815', fontSize: '10px', fontFamily: 'Pristina', textAlign: 'left' }}>30 years of providing excellent Services</div>
    
    </span>

    <div className='my-auto justify-content-end d-lg-none mr-2' style={{ color: "white" }}>
      <FontAwesomeIcon icon="user" className="text-white" size="lg" />
    </div>
    <Drawer title="Malik Express" style={{ color: "white" }} placement="right" onClose={onClose} open={open}>
      <Menu
        mode="inline"
        onClick={handleSubMenuClick}
      >
        {menuItems.map((item) => (
          item.subMenuItems ? (
            <SubMenu key={item.key} title={item.title}>
              {item.subMenuItems.map((subItem) => (
                <Menu.Item key={subItem.key}>
                  <Link to={subItem.url}>{subItem.title}</Link>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key}>
              <Link to={item.url}>{item.title}</Link>
            </Menu.Item>
          )
        ))}
      </Menu>
      {openSubMenu && (
        <Menu
          mode="inline"
          onClick={handleSubMenuClick}
          selectedKeys={[openSubMenu]}
        >
          {getCurrentSubMenuItems().map((subItem) => (
            <Menu.Item key={subItem.key}>
              <Link to={subItem.url}>{subItem.title}</Link>
            </Menu.Item>
          ))}
        </Menu>
      )}
    </Drawer>

  </div>


</div>

</nav>
                </>
            ) : (
                <>

                </>
            )}

</>
    )
}

export default MainMenu;