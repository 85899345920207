
import React from 'react'
import './Lahore.css';
import { useState, useRef } from 'react';

import { FaAngleDown } from 'react-icons/fa';



import DaysFoot from './DaysFoot';
import EnquiryForm from './EnquiryForm';
import SevenData from './SevenData';
import Journey from './Journey';
import UmrahPackages from './UmrahPackages';
import SevenTitle from './SevenTitle';
import FaqsFile from './FaqsFile';
import LahoreContent from './LahoreContent';
import PagesContent from './PagesContent';
import ItineraryContent from './ItineraryContent';
import Inclusion from './Inclusion';
import TenDaysTitle from './TenDaysTitle';
import FifteenDaysTitle from './FifteenDaysTitle';
import TwentyDaysTitle from './TwentyDaysTitle';
import TwentyFiveTitle from './TwentyFiveTitle';
import TenData from './TenData';
import FifteenData from './FifteenData';
import TwentyOneData from './TwentyOneData';
import TwentyEightData from './TwentyEightData';
import CityChooseUs from './CityChooseUs';
import MainMenu from '../Layout/MainMenu';
import Testimonials from '../Home/Testimonials';
import LatestBlogs from '../Home/LatestBlogs';
import Footer from '../Home/Footer';
import Newsletters from '../Home/Newsletters';
import { Helmet } from 'react-helmet';


const Lahore = () => {
  const sevenTitleRef = useRef(null);
  const enquiryFormRef = useRef(null);
  const tenTitleRef = useRef(null);
  const fifteenTitleRef = useRef(null);
  const twentyOneTitleRef = useRef(null);
   const twentyEightTitleRef = useRef(null);


  const scrollToEnquiryForm = () => {
    
    if (enquiryFormRef.current) {
      enquiryFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
 
  return (
    <>
      <Helmet>
                <title >{"Customized Umrah Packages from Islamabad | Ramadan Packages"}</title>
                
                <meta name="description" content={"Malik Express offers range of customized Umrah Packages from Islamabad Pakistan. Umrah Visa, Family Umrah Packages & 7 days, 10 days, 20 days, 30 days packages."} data-react-helmet="true" />
                <meta name="keywords" content={"umrah packages, customized umrah packages, umrah visa price, 28 days umrah package from Islamabad, 7 days umrah package from Islamabad, umrah packages for 7 days, umrah packages for family, umrah packages 7 days, 30 days umrah package from Islamabad, umrah economy packages, VIP umrah packages"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/umrah-packages-from-islamabad" />
            </Helmet>
    <MainMenu />
    <section className="page-cover" id="cover-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="https://malikexpress.com/">Home</a></li>
                                <li className="breadcrumb-item">Umrah Packages</li>
                                <li className="breadcrumb-item">Lahore</li>
                            </ul>
                            
                            <h1 className="page-title" style={{marginTop: "30px"}}>2024 Umrah Packages from Lahore</h1>
                           
                        </div>
                    </div>
                </div>
            </section>
     <PagesContent city = "Lahore" text1 = "At Malik Express Travel & Tours, we take immense pride in offering comprehensive and well-organized Umrah packages that cater to the diverse needs of our fellow Muslims from Pakistan. Our packages are meticulously designed to ensure a smooth and hassle-free journey, allowing you to focus entirely on your spiritual devotion." text2 = "We are excited to announce that our Umrah packages for 2024 are now available! If you aspire to embark on a sacred journey to Makkah and Madinah, look no further. Malik Express Travel & Tours is delighted to offer a wide range of packages to suit your preferences and needs. Our typical Umrah package includes the following components:"/>
    <div className="banner-sec with-side-img" style={{marginTop: "40px"}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-6">
          <div className="detail-holder">
            <div className="text-holder">
              <h2>Customized Umrah Packages from Lahore</h2>
              <p>
              Malik Express Travel & Tours is your trusted partner for embarking on a spiritual journey with our carefully crafted Umrah Packages from Lahore. As your trusted partner in religious travel, we are dedicated to making your Umrah experience seamless, memorable, and spiritually enriching. Our meticulously crafted Umrah Packages from Lahore cater to the diverse needs of pilgrims, offering a range of durations from 7 to 28 days. Whether you are seeking a brief yet fulfilling pilgrimage or a month-long spiritual retreat, our packages are designed to provide exceptional comfort and comprehensive services. Let us take care of the logistics while you focus on your devotion and spiritual growth.
              </p>
              <div className="city-button">
        <button className='city-btn1' type='submit' style={{background: "#fff", color: "black"}} onClick={scrollToEnquiryForm}>Enquire Now</button>
 
       </div>
            </div>
           
            <div className="img-holder">
              <figure>
                <img src="images/PckgIcon/LAHORE UMRAH PACKAGES.jpg" alt="Umrah Packages from Lahore" />
              </figure>
            </div>
            
          </div>
        </div>
      </div>
     
    </div>

  </div>
 


{/* <ItineraryContent/> */}
<Inclusion/>
      <EnquiryForm ref={enquiryFormRef} />
    
      <UmrahPackages sevenTitleRef={sevenTitleRef} tenTitleRef={tenTitleRef} fifteenTitleRef={fifteenTitleRef} twentyOneTitleRef={twentyOneTitleRef} twentyEightTitleRef= {twentyEightTitleRef} enquiryFormRef= {enquiryFormRef} />
      
      <SevenTitle citySeven= "Lahore" paragraphSeven = "Our 7 Days Umrah Package is perfect for those who wish to perform their pilgrimage within a short period. This package includes all essential services to ensure a fulfilling spiritual experience. You'll stay in comfortable 3-star accommodations, enjoy guided tours, and have ample time to perform your religious duties in both Makkah and Madinah."   ref={sevenTitleRef} />
  <SevenData/>
<TenDaysTitle cityTen = "Lahore" paragraphTen = "The 10 Days Umrah Package offers a slightly longer stay, allowing for a more relaxed and in-depth experience. This package includes accommodation in 4-star hotels, ensuring a comfortable stay. You'll have more time to explore the holy cities, participate in spiritual lectures, and visit additional historical sites." ref={tenTitleRef}/>
<TenData/>
<FifteenDaysTitle cityFifteen ="Lahore" paragraphFifteen = "For those who wish to immerse themselves fully in the spiritual journey, our 15 Days Umrah Package is ideal. With extended stays in both Makkah and Madinah, this package allows for thorough exploration and deeper spiritual connection. Enjoy the comfort of 4-star accommodations and guided tours of all significant sites." ref={fifteenTitleRef}/>
<FifteenData />
<TwentyDaysTitle cityTwentyOne = "Lahore" paragraphTwentyOne ="The 21 Days Umrah Package is designed for pilgrims who seek an extended and enriching experience. With 21 days, you can fully embrace the spiritual ambiance of the holy cities. This package includes 4-star hotel accommodations, detailed guided tours, and comprehensive ziyarat of all major religious landmarks." ref={twentyOneTitleRef}/>
<TwentyOneData/>
<TwentyFiveTitle cityTwentyEight = "Lahore" paragraphTwentyEight = "Our most comprehensive package, the 28 Days Umrah Package, is perfect for those who wish to spend an entire month in devotion and reflection. This package offers luxurious 5-star accommodations, detailed itineraries, and exclusive guided tours. It is designed to provide an unmatched spiritual journey with ample time to perform all rituals and explore both Makkah and Madinah thoroughly." ref={twentyEightTitleRef}/>
<TwentyEightData/>
<CityChooseUs from = 'Lahore' />

<FaqsFile/>
    <DaysFoot/>
    <Testimonials />
    <LatestBlogs />
    <Newsletters />
    <Footer />
  </>
  )
}

export default Lahore