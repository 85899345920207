import React, { forwardRef } from 'react'

const FifteenDaysTitle = forwardRef((props,ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>15 Days Umrah Package from {props.cityFifteen}</h2>
                    <p>{props.paragraphFifteen}</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default FifteenDaysTitle