import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { event } from "jquery";
//import moment from "moment";
import { DatePicker } from "react-rainbow-components";
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';
import PK from "../../Icons/airlines/PF.png";
import Blue from "../../Icons/airlines/PA.png";
import Fly from "../../Icons/airlines/jinnah.png";
import Serene from "../../Icons/airlines/serenee.png";
import Emirates from "../../Icons/airlines/EK.png";
import PIA from "../../Icons/airlines/PIA.png";
import Qatar from "../../Icons/airlines/QR.png";
import Saudi from "../../Icons/airlines/SV.png";
import Arabia from "../../Icons/airlines/G9.png";
import Dubai from "../../Icons/airlines/FZ.png";
import Work from "./Work";
import Benefits from "./Benefits";
import TravelPlan from "./TravelPlan";
import Countries from "./Countries";
import International_data from "./International_data";
import StudentTravel from "./StudentTravel";

function Student_Travel() {
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [countries, setCountries] = useState([]);
    const [country, setCountry] = useState('')
    const [country1, setCountry1] = useState('-1')
    const [area, setArea] = useState('SCHENGEN')
    const navigate = useNavigate();
    const [showAddCountry, setShowAddCountry] = useState(false);
    const [resultMsg, setResultMsg] = useState('');
    const [responsee, setResponsee] = useState('')
    const [insurancetype, setInsurancetype] = useState('1');
    const [passengertype, setpassengertype] = useState(null);
    const [minDate, setMinDate] = useState(getTodayDate());
    const [departureDate, setDepartureDate] = useState(new Date());
    const [arrivalDate, setArrivalDate] = useState(() => {
        const nextDay = new Date(departureDate);
        nextDay.setDate(nextDay.getDate() + 1);
        return nextDay;
    });
    const defaultDate = subYears(new Date(), 18); // This will set the default date to be 18 years ago

    const [dob, setDob] = useState(defaultDate);

    const [age, setAge] = useState({ years: 0, months: 0, days: 0 });
    const [selectedDate, setSelectedDate] = useState(null);
    const [openSection, setOpenSection] = useState(null);
    console.log('area', area)

    const [duration, setDuration] = useState('7 Days')
    const [durations, setDurations] = useState('')


    const [formvalue, setFormvalue] = useState({
        Insurancetype: '1',
        Country1: '',
        Country2: '-1',
        DepartureDate: '',
        Duration: '7',
        MaxStay:'7',
        Passengertype: '',
        Dob: '',
    });


    console.log('form value is', formvalue)
    console.log('responcee', responsee)

    const url = apiUrl + "/api/Insurance";
    useEffect(() => {
        fetchCountries();
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded

        }, 100); // Adjust the duration as needed

    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const selectItems = (itemIds) => {
        setpassengertype(itemIds);

        // Check if Passengertype is "family" and set Dob to null
        if (itemIds.includes("family")) {
            setFormvalue({ ...formvalue, Passengertype: itemIds, Dob: null });
        } else {
            // If Passengertype is not "family," update Passengertype only
            setFormvalue({ ...formvalue, Passengertype: itemIds });
        }
    };
    const selectItem = (item, name) => {
        setInsurancetype(item);
        setArea(name)

        setFormvalue({ ...formvalue, Insurancetype: item });

    };
    async function fetchCountries() {
        await axios
            .get(apiUrl + "/api/FlightSearch/countries")
            .then((response) => {
                const returnData = response.data;
                //retData= response.data;
                // console.log(response.data);
                setTimeout(() => {
                    setCountries(response.data);
                }, 2000);
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const onlick = () => {
        navigate('/TravelSearch', { state: { passengertype } });
    }
    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDepartureDateChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setDepartureDate(formattedDate)
        setFormvalue((prevFormvalue) => ({ ...prevFormvalue, DepartureDate: formattedDate }));
    };

    const handleArrivalDateChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setArrivalDate(formattedDate)
        setFormvalue((prevFormvalue) => ({ ...prevFormvalue, ArrivalDate: formattedDate }));
    };
    const maxDate = new Date().toISOString().split('T')[0]; // Get current date in "yyyy-mm-dd" format

    useEffect(() => {
        if (dob) {
            calculateAge();
        }
    }, [dob]);

    const handleDateChange = (date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        setSelectedDate(date);

        // Check if age is less than 18 years
        const eighteenYearsAgo = subYears(new Date(), 18);
        if (date > eighteenYearsAgo) {
            // Date is less than 18 years ago
            alert("You must be at least 18 years old.");
            setSelectedDate(null); // Reset selected date
        } else {
            // Date is at least 18 years ago
            setDob(formattedDate);
            setFormvalue({ ...formvalue, Dob: formattedDate });
        }
    };
    const isDateSelected = selectedDate !== null;

    const calculateAge = () => {
        const birthDate = new Date(dob);
        const currentDate = new Date();

        let ageInYears = currentDate.getFullYear() - birthDate.getFullYear();
        let ageInMonths = currentDate.getMonth() - birthDate.getMonth();
        let ageInDays = currentDate.getDate() - birthDate.getDate();

        // Adjust age if birthday hasn't occurred yet this year
        if (currentDate.getMonth() < birthDate.getMonth() ||
            (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
            ageInYears--;
            ageInMonths += 12;
        }

        // Calculate age in days, considering different month lengths
        const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
        ageInDays = ageInDays < 0 ? ageInDays + daysInMonth : ageInDays;

        setAge({ years: ageInYears, months: ageInMonths, days: ageInDays });
    };
    const handleAddCountryClick = () => {
        setShowAddCountry(!showAddCountry);
    };
    const handlecountry = (event) => {
        setCountry(event.target.options[event.target.selectedIndex].text);
        setFormvalue({ ...formvalue, Country1: event.target.options[event.target.selectedIndex].text });
    };

    const handlecountry2 = (event) => {
        setCountry1(event.target.options[event.target.selectedIndex].text);
        setFormvalue({ ...formvalue, Country2: event.target.options[event.target.selectedIndex].text });
    };
    function postData() {

        const instance = axios.create({
            baseURL: url,
            timeout: 10000,
            headers: { "X-Custom-Header": "foobar" },
        });

        if (formvalue.Insurancetype != undefined) {
            if (formvalue.Insurancetype.length < 1) {
                alert("Please select insurance type.");
                return;
            }
        } else {
            alert("Please select insurance type.");
            return;
        }

        if (formvalue.Country1 != undefined) {
            if (formvalue.Country1.length < 1) {
                alert("Please select country.");
                return;
            }
        } else {
            alert("Please select country.");
            return;
        }

        if (formvalue.Country2 != undefined) {
            if (formvalue.Country2.length < 1) {
                alert("Please select second country.");
                return;
            }
        } else {
            alert("Please select second country.");
            return;
        }
        if (formvalue.DepartureDate != undefined) {
            if (formvalue.DepartureDate.length < 10) {
                alert("Please provide departure date.");
                return;
            }
        } else {
            alert("Please provide  departure date.");
            return;
        }
        if (formvalue.Duration != undefined) {
            if (formvalue.Duration.length < 1) {
                alert("Please provide Duration");
                return;
            }
        } else {
            alert("Please provide Duration");
            return;
        }

        if (formvalue.Passengertype != undefined) {
            if (formvalue.Passengertype.length < 5) {
                alert("Please select passenger type.");
                return;
            }

            // Check if the passenger type is "family"
            if (formvalue.Passengertype.toLowerCase() === "family") {
                setDob('')


            } else {
                // If it's not "family," proceed with date of birth validation
                if (formvalue.Dob != undefined) {
                    if (formvalue.Dob.length < 10) {
                        alert("Please provide valid date of birth.");
                        return;
                    }
                } else {
                    alert("Please provide valid date of birth.");
                    return;
                }
            }
        } else {
            alert("Please select passenger type.");
            return;
        }


        const jsonData = JSON.stringify(formvalue);
        console.log('json is', jsonData)
        axios
            .post(url + "/selectpackage", jsonData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setResponsee(response.data)

                if (response.status === 200) {
                    const data = response.data;
                    navigate('/TravelSearch', { state: { passengertype, data, departureDate, durations, country, country1, dob, area } });
                } else {

                    console.error("Unexpected response status:", response.status);
                }

            })
            .catch((error) => console.error(error));
    }
    const handleDuration = (event) => {
        const selectedValue = event.target.value;
        const selectedMaxStay = event.target.options[event.target.selectedIndex].getAttribute('MaxStay');
        
        setDuration(selectedValue);
        setDurations(event.target.options[event.target.selectedIndex].text);
        setFormvalue({ ...formvalue, Duration: selectedValue, MaxStay: selectedMaxStay });
    };

    const handleClick = (e) => {
        postData();
    };

    const handleSectionClick = (sectionId, event) => {
        // Prevent the default behavior of anchor tag
        event.preventDefault();
        // Toggle the openSection state
        setOpenSection(openSection === sectionId ? null : sectionId);
    };

    return (
        <>
            <Helmet>
                <title >{"Student Travel Insurance Plans Online by Malik Express "}</title>

                <meta name="description" content={"Explore comprehensive Student Travel Insurance plans from Pakistan. Secure your journey with medical emergencies, trip cancellations, & more coverage. "} data-react-helmet="true" />
                <meta name="keywords" content={"Travel insurance for students, travel insurance plans, travel insurance, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance coverage, travel insurance cost "} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/student-travel-insurance-online" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (

                <section className="innerpage-wrapper">
                    <div id="travel-insurance" className="innerpage-section-padd">
                        <div className="container">
                            <div className="row">

                                <div className="col-md-5 col-sm-6 col con d-flex align-items-center justify-content-center">

                                    <div className="col-12 col-md-6 col-lg-12" >

                                        <div className="tsearch">

                                            <form id="frm_contact" name="frm_contact" >
                                                <h3 style={{ textAlign: 'center', padding: '10px' }}>Get the Best Student Travel Insurance</h3>


                                                <div className="form-group">
                                                    <ul id="LeftTopButtonss" className="mlk-col mlk-col-24 mb-4">
                                                        <li
                                                            id="1"
                                                            className={`mlk-tag mlk-tag3 ${insurancetype === '1' ? 'selected' : ''}`}
                                                            onClick={() => selectItem('1', 'SCHENGEN')}
                                                        >
                                                            <a
                                                                id="1"
                                                                className={`mlk-typography2 font-secondary ${insurancetype === '1' ? 'selected' : ''}`}
                                                                onClick={() => selectItem('1', 'SCHENGEN')}
                                                            >
                                                                SCHENGEN
                                                            </a>
                                                        </li>
                                                        <li
                                                            id="2"
                                                            className={`mlk-tag mlk-tag3 ${insurancetype === '2' ? 'selected' : ''}`}
                                                            onClick={() => selectItem('2', 'REST OF WORLD')}
                                                        >
                                                            <a
                                                                id="2"
                                                                className={`mlk-typography2 font-secondary ${insurancetype === '2' ? 'selected' : ''}`}
                                                                onClick={() => selectItem('2', 'REST OF WORLD')}
                                                            >
                                                                REST OF THE WORLD
                                                            </a>
                                                        </li>
                                                        <li
                                                            id="3"
                                                            className={`mlk-tag mlk-tag3 ${insurancetype === '3' ? 'selected' : ''}`}
                                                            onClick={() => selectItem('3', 'WORLDWIDE')}
                                                        >
                                                            <a
                                                                id="3"
                                                                className={`mlk-typography2 font-secondary ${insurancetype === '3' ? 'selected' : ''}`}
                                                                onClick={() => selectItem('3', 'WORLDWIDE')}
                                                            >
                                                                WORLDWIDE
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    {insurancetype === '1' && (

                                                        <p style={{ color: 'red', fontSize: 'smaller' }}>(Excluding USA, CANADA, AUSTRALIA, NEW ZEALAND)</p>

                                                    )}
                                                    {insurancetype === '2' && (

                                                        <p style={{ color: 'red', fontSize: 'smaller' }}>(Excluding USA, CANADA, AUSTRALIA, NEW ZEALAND)</p>

                                                    )}
                                                </div>

                                                <div className="row">
                                                    <div className="col-md-8">



                                                        {insurancetype === '1' ? (
                                                            <div className="form-group">
                                                                <div className="travel-form-dropdown">
                                                                    <div className="travel-form-dropdown-in">
                                                                        <div className="travel-form-dropdown-btn form-group status-success">
                                                                            <select
                                                                                className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                                onChange={handlecountry}
                                                                                value={country}
                                                                            >
                                                                                <option value="" disabled="" selected="">
                                                                                    Countries travelling to
                                                                                </option>
                                                                                <option value="Austria">Austria</option>
                                                                                <option value="Belgium">Belgium</option>
                                                                                <option value="Czech Republic">Czech Republic</option>
                                                                                <option value="Denmark">Denmark</option>
                                                                                <option value="Estonia">Estonia</option>
                                                                                <option value="Finland">Finland</option>
                                                                                <option value="France">France</option>
                                                                                <option value="Germany">Germany</option>
                                                                                <option value="Greece">Greece</option>
                                                                                <option value="Hungary">Hungary</option>
                                                                                <option value="Iceland">Iceland</option>
                                                                                <option value="Italy">Italy</option>
                                                                                <option value="Latvia">Latvia</option>
                                                                                <option value="Liechtenstein">Liechtenstein</option>
                                                                                <option value="Lithuania">Lithuania</option>
                                                                                <option value="Luxembourg">Luxembourg</option>
                                                                                <option value="Malta">Malta</option>
                                                                                <option value="Netherlands">Netherlands</option>
                                                                                <option value="Norway">Norway</option>
                                                                                <option value="Poland">Poland</option>
                                                                                <option value="Portugal">Portugal</option>
                                                                                <option value="Slovakia">Slovakia</option>
                                                                                <option value="Slovenia">Slovenia</option>
                                                                                <option value="Spain">Spain</option>
                                                                                <option value="Sweden">Sweden</option>
                                                                                <option value="Switzerland">Switzerland</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="form-group">

                                                                <div className="travel-form-dropdown">
                                                                    <div className="travel-form-dropdown-in">
                                                                        <div className="travel-form-dropdown-btn form-group status-success">
                                                                            <select
                                                                                className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                                onChange={handlecountry}
                                                                                value={country} // Set value to country state directly
                                                                            >
                                                                                <option value="" disabled="" selected="">
                                                                                    Countries travelling to
                                                                                </option>
                                                                                {countries.map((option) => (
                                                                                    insurancetype !== '2' && insurancetype !== '1' || !['USA', 'Canada', 'Australia', 'United States', 'New Zealand'].includes(option.Value) ? (
                                                                                        <option key={option.Id} value={option.Value}>
                                                                                            {option.Value} {/* Use option.Value as both value and display text */}
                                                                                        </option>
                                                                                    ) : null
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}

                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">

                                                            <button type="button" className="btn2 btn-orange" onClick={handleAddCountryClick}>
                                                                {showAddCountry ? 'Hide Country' : 'Add Country'}
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                {showAddCountry && (
                                                    <div className="form-group">

                                                        <div className="travel-form-dropdown">
                                                            <div className="travel-form-dropdown-in">
                                                                <div className="travel-form-dropdown-btn form-group status-success">
                                                                    <select
                                                                        className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                        onChange={handlecountry2}
                                                                        value={country1}
                                                                    >
                                                                        <option value="" disabled="" selected="">
                                                                            Second Country to travelling
                                                                        </option>


                                                                        {countries.map((option) => (
                                                                            insurancetype !== '2' && insurancetype !== '1' || !['USA', 'Canada', 'Australia', 'United States', 'New Zealand'].includes(option.Value) ? (
                                                                                <option key={option.Id} value={option.Value}>
                                                                                    {option.Value}
                                                                                </option>
                                                                            ) : null
                                                                        ))}



                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

     


                                                <div className="row">
                                                    <div className="col-6 col-md-6">
                                                        <div>
                                                            <label className="mb-2">Departure Date</label>
                                                            <DatePicker

                                                                selected={departureDate}
                                                                value={departureDate}
                                                                onChange={handleDepartureDateChange}
                                                                dateFormat="dd/MM/yyyy"
                                                                className="dpd3"
                                                                id="departureDate"
                                                                name="departureDate"
                                                                placeholderText="Departure"
                                                                required
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                yearDropdownItemNumber={60}
                                                                scrollableYearDropdown
                                                                showMonthYearDropdown={false}
                                                                minDate={subYears(new Date(), 0)}
                                                                maxDate={addYears(new Date(), 15)}
                                                            />



                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-md-6">
                                                            {insurancetype === '1' && (
                                                                <div className="form-group">
                                                                    <label className="mb-2">Duration</label>
                                                                    <div className="travel-form-dropdown">
                                                                        <div className="travel-form-dropdown-in">
                                                                            <div className="travel-form-dropdown-btn form-group status-success">
                                                                                <select
                                                                                    className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                                    onChange={handleDuration}
                                                                                    value={duration}
                                                                                >
                                                                                   

                                                                                    <option value="7" MaxStay="7">7 Days</option>
                                                                                    <option value="10" MaxStay="10">10 Days</option>
                                                                                    <option value="15" MaxStay="15">15 Days</option>
                                                                                    <option value="21" MaxStay="21">21 Days</option>
                                                                                    <option value="31" MaxStay="31">31 Days</option>
                                                                                    <option value="62" MaxStay="62">62 Days</option>
                                                                                    <option value="92" MaxStay="92">92 Days</option>
                                                                                    <option value="180" MaxStay="92">180 Days</option>
                                                                                    <option value="365" MaxStay="92">365 Days</option>
                                                                                    <option value="730" MaxStay="92">2 Years</option>



                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {insurancetype === '2' && (
                                                                <div className="form-group">
                                                                    <label className="mb-2">Duration</label>
                                                                    <div className="travel-form-dropdown">
                                                                        <div className="travel-form-dropdown-in">
                                                                            <div className="travel-form-dropdown-btn form-group status-success">
                                                                                <select
                                                                                    className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                                    onChange={handleDuration}
                                                                                    value={duration}
                                                                                >

                                                                                    <option value="7" MaxStay="7">7 Days</option>
                                                                                    <option value="10" MaxStay="10">10 Days</option>
                                                                                    <option value="15" MaxStay="15">15 Days</option>
                                                                                    <option value="21" MaxStay="21">21 Days</option>
                                                                                    <option value="31" MaxStay="31">31 Days</option>
                                                                                    <option value="62" MaxStay="62">62 Days</option>
                                                                                    <option value="92" MaxStay="92">92 Days</option>
                                                                                    <option value="180" MaxStay="92">180 Days</option>
                                                                                    <option value="365" MaxStay="92">365 Days</option>



                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {insurancetype === '3' && (
                                                                <div className="form-group">
                                                                    <label className="mb-2">Duration</label>
                                                                    <div className="travel-form-dropdown">
                                                                        <div className="travel-form-dropdown-in">
                                                                            <div className="travel-form-dropdown-btn form-group status-success">
                                                                                <select
                                                                                    className="ts select-selected darkgray semibold form-control select2-hidden-accessible"
                                                                                    onChange={handleDuration}
                                                                                    value={duration}
                                                                                >

                                                                                    <option value="7" MaxStay="7">7 Days</option>
                                                                                    <option value="10" MaxStay="10">10 Days</option>
                                                                                    <option value="15" MaxStay="15">15 Days</option>
                                                                                    <option value="21" MaxStay="21">21 Days</option>
                                                                                    <option value="31" MaxStay="31">31 Days</option>
                                                                                    <option value="62" MaxStay="62">62 Days</option>
                                                                                    <option value="92" MaxStay="92">92 Days</option>
                                                                                    <option value="180" MaxStay="92">180 Days</option>
                                                                                    <option value="365" MaxStay="92">365 Days</option>
                                                                                    <option value="730" MaxStay="92">2 Years</option>
                                                                                    <option value="180" MaxStay="c-180">180 Days (Consecutive)</option>
                                                                                    <option value="365" MaxStay="c-365">365 Days (Consecutive)</option>
                                                                                    <option value="272" MaxStay="c-272">272 Days (Consecutive)</option>


                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                </div>


                                                <div className="form-group">
                                                    <label className="mb-2">Passenger Type</label>
                                                    <ul id="LeftButtonss" className="mlk-col mlk-col-24 mb-4">
                                                        <li
                                                            id="individual"
                                                            className={`mlk-tag1 mlk-tag4 ${passengertype === 'individual' ? 'selecteds' : ''}`}
                                                            onClick={() => selectItems('individual')}
                                                        >
                                                            <a
                                                                className="mlk-typography4 font-secondary"
                                                                onClick={() => selectItems('individual')}
                                                            >
                                                                Individual
                                                            </a>
                                                        </li>

                                                        <li
                                                            id="family"
                                                            className={`mlk-tag1 mlk-tag4 ${passengertype === 'family' ? 'selecteds' : ''}`}
                                                            onClick={() => selectItems('family')}
                                                        >
                                                            <a
                                                                className="mlk-typography4 font-secondary"
                                                                onClick={() => selectItems('family')}
                                                            >
                                                                Family
                                                            </a>
                                                        </li>
                                                        <li
                                                            id="student"
                                                            className={`mlk-tag1 mlk-tag4 ${passengertype === 'student' ? 'selecteds' : ''}`}
                                                            onClick={() => selectItems('student')}
                                                        >
                                                            <a
                                                                className="mlk-typography4 font-secondary"
                                                                onClick={() => selectItems('student')}
                                                            >
                                                                Student
                                                            </a>
                                                        </li>

                                                    </ul>

                                                    {passengertype === 'individual' && (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-6 col-md-6">
                                                                    <div>
                                                                        <label>Date of Birth</label>

                                                                        <DatePicker
                                                                            selected={selectedDate}
                                                                            value={dob}
                                                                            onChange={handleDateChange}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="dpd3"
                                                                            id="dob_0"
                                                                            name="dob_0"
                                                                            placeholderText="Date of Birth"
                                                                            required
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            yearDropdownItemNumber={60}
                                                                            scrollableYearDropdown
                                                                            showMonthYearDropdown={false}
                                                                            minDate={subYears(new Date(), 100)} // This sets the minimum date 60 years ago
                                                                            maxDate={subYears(new Date(), 18)}

                                                                        />
                                                                    </div>
                                                                </div>
                                                                {isDateSelected && (
                                                                    <div className="col-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label></label>
                                                                            <p className="percentage_age d-flex justify-content-center align-items-center">
                                                                                {`${age.years} years`}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="form-group mb-4">
                                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                                                    <li><p style={{ fontSize: '95%' }}>For Person aged between 66 and 70 Years, Increase 50%</p></li>
                                                                    <li><p style={{ fontSize: '95%' }}>For Person aged between 70 and 80 Years, Increase 75%</p></li>
                                                                    <li><p style={{ fontSize: '95%' }}>For Person aged between 85 and 80 Years, Increase 100%</p></li>
                                                                </ul>
                                                                <p><b>Note: Extra Premium 4% will be charged in case of Non-Filer</b></p>
                                                            </div>
                                                        </div>

                                                    )}
                                                    {passengertype === 'student' && (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-6 col-md-6">
                                                                    <div>
                                                                        <label>Date of Birth</label>

                                                                        <DatePicker
                                                                            selected={selectedDate}
                                                                            value={dob}
                                                                            onChange={handleDateChange}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className="dpd3"
                                                                            id="dob_0"
                                                                            name="dob_0"
                                                                            placeholderText="Date of Birth"
                                                                            required
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            yearDropdownItemNumber={60}
                                                                            scrollableYearDropdown
                                                                            showMonthYearDropdown={false}
                                                                            minDate={subYears(new Date(), 100)} // This sets the minimum date 60 years ago
                                                                            maxDate={subYears(new Date(), 18)}

                                                                        />

                                                                    </div>
                                                                </div>
                                                                {isDateSelected && (
                                                                    <div className="col-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label></label>
                                                                            <p className="percentage_age d-flex justify-content-center align-items-center">
                                                                                {`${age.years} years`}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                    )}

                                                    {passengertype === 'family' && (
                                                        <div>
                                                            <div className="row">
                                                                <div className="col-6 col-md-6">
                                                                    <div>
                                                                        <label>Date of Birth</label>

                                                                        <DatePicker
                                                                            selected={selectedDate}
                                                                            onChange={handleDateChange}
                                                                            value={dob}
                                                                            dateFormat="dd/MM/yyyy"
                                                                            className=" dpd3"
                                                                            id="dob_0"
                                                                            name="dob_0"
                                                                            placeholderText="Date of Birth"
                                                                            required
                                                                            showYearDropdown
                                                                            showMonthDropdown
                                                                            yearDropdownItemNumber={60}
                                                                            scrollableYearDropdown
                                                                            showMonthYearDropdown={false}
                                                                            minDate={subYears(new Date(), 100)} // This sets the minimum date 60 years ago
                                                                            maxDate={subYears(new Date(), 18)} // This sets the maximum date to 18 years ago
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {isDateSelected && (
                                                                    <div className="col-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label></label>
                                                                            <p className="percentage_age d-flex justify-content-center align-items-center">
                                                                                {`${age.years} years`}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>

                                                            <div className="form-group mb-4">
                                                                <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                                                    <li><p style={{ fontSize: '95%' }}>For Person aged between 66 and 70 Years, Increase 50%</p></li>
                                                                    <li><p style={{ fontSize: '95%' }}>For Person aged between 70 and 80 Years, Increase 75%</p></li>
                                                                    <li><p style={{ fontSize: '95%' }}>For Person aged between 85 and 80 Years, Increase 100%</p></li>
                                                                </ul>
                                                                <p><b>Note: Extra Premium 4% will be charged in case of Non-Filer</b></p>
                                                            </div>



                                                        </div>
                                                        // <div className="form-group mb-4">
                                                        //     <p><b>Note:</b> If any of the traveler is above 64 years old, apply for an individual travel policy for them. Family includes You, Spouse and up to 4 children (below 18).</p>
                                                        //     <p><b>Extra Premium 4% will be charged in case of Non-Filer</b></p>

                                                        // </div>
                                                    )}
                                                </div>


                                                <div className="col-md-12 text-center" id="result_msg"></div>
                                                <a style={{ height: '50px', justifyContent: 'center', alignItems: 'center', display: 'flex', color: '#fff' }} className="btn btn-orange btn-block" onClick={handleClick}>View Quotes</a>
                                            </form>
                                            <br />
                                            <br />


                                        </div>
                                    </div>


                                </div>
                                <div className="fn col-md-7 col-sm-6  col" style={{ textAlign: 'center' }}>
                                    <div>
                                        <img
                                            src="../images/student travel insurance.png"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto', width: '-webkit-fill-available' }}
                                        />
                                    </div>
                                    <div className="">
                                        <h3 className="black semibold f20 mt-4">
                                            Instant Policy Issuance.Coverage up to US$100,000
                                        </h3>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                    <StudentTravel />
                    <Countries />
                    <Benefits />
                    <Work />






                    <div id="services-section" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '40px' }}>
                        <div class="page-heading" >
                            <h2>Our Travel Insurance Partners</h2>

                            <hr class="heading-line" />
                        </div>
                        <div className="" style={{ display: 'flex', justifyContent: 'center', }}>
                            <img height='auto' width="120px" style={{ marginRight: '20px' }} className="p-1" src="../images/CSI-Logo.png" alt={`Airline`} />
                            <img height='auto' width="120px" className="p-1" src="../images/UIC.png" alt={`Airline`} />
                        </div>
                    </div>

                    <div id="services-section">
                        <div class="page-heading mar_main" >
                            <h2>FAQs About Travel Insurance Pakistan</h2>
                            <blockquote>
                                Travel insurance is a crucial aspect of planning any trip, offering peace of mind and financial protection against unforeseen events. At Malik Express Travel Agency, we understand the importance of comprehensive travel insurance for travelers from Pakistan. Here's everything you need to know about travel insurance, especially when traveling to Schengen countries and beyond.                            </blockquote>
                            <br />
                            <hr class="heading-line" />
                        </div>
                        <div className="service-block-1 mar_main2" >
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse1"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse1", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            What is travel insurance, and why is it important for travelers in Pakistan?
                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>

                                <div id="collapse1" className={`panel-collapse collapse ${openSection === "collapse1" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Travel insurance is a financial product designed to cover unexpected expenses while traveling, such as medical emergencies, trip cancellations, and lost luggage. For travelers from Pakistan, it provides essential protection against various risks during their journeys.                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse2"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse2", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            Does travel insurance cover medical emergencies, including Covid-related issues, for travelers to Schengen countries?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse2" className={`panel-collapse collapse ${openSection === "collapse2" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Yes, travel insurance policies offered by Malik Express provide coverage for medical emergencies, including those related to Covid-19, when traveling to Schengen countries and other destinations.
                                        </p>                                    </div>
                                </div>
                            </div>

                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse3"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse3", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            Can I purchase travel insurance specifically for Schengen visa requirements from Malik Express?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse3" className={`panel-collapse collapse ${openSection === "collapse3" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Absolutely, Malik Express offers travel insurance plans tailored to meet the specific requirements of Schengen visa applications. These plans fulfill the mandatory insurance criteria set by Schengen countries for visa issuance.
                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse4"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse4", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            What types of travel insurance plans does Malik Express offer for students traveling abroad?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse4" className={`panel-collapse collapse ${openSection === "collapse4" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Malik Express provides specialized travel insurance plans designed to cater to the unique needs of students traveling abroad for educational purposes. These plans typically offer   extended coverage for academic-related activities and medical emergencies.
                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse5"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse5", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            How does travel insurance for Schengen visa applicants differ from regular travel insurance?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse5" className={`panel-collapse collapse ${openSection === "collapse5" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Travel insurance for Schengen visa applicants typically includes specific coverage requirements mandated by Schengen countries, such as minimum coverage amounts and specific types of coverage. Malik Express ensures that its Schengen visa insurance plans comply with these requirements.
                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse6"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse6", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>

                                            Does Malik Express provide online purchase options for travel insurance in Pakistan?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse6" className={`panel-collapse collapse ${openSection === "collapse6" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Yes, Malik Express offers convenient online purchase options for travel insurance, allowing travelers from Pakistan to browse and select suitable insurance plans from the comfort of their homes.
                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse7"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse7", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            Are there specific requirements for travel insurance when applying for a Schengen visa from Pakistan?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse7" className={`panel-collapse collapse ${openSection === "collapse7" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Yes, Schengen visa applicants from Pakistan are required to provide proof of travel insurance that meets certain criteria, including coverage for medical emergencies, repatriation, and minimum coverage amounts. Malik Express offers Schengen visa insurance plans that fulfill these requirements.
                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse8"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse8", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            What medical expenses are covered under travel insurance policies offered by Malik Express?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse8" className={`panel-collapse collapse ${openSection === "collapse8" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Malik Express travel insurance policies typically cover a wide range of medical expenses, including hospitalization, emergency medical treatment, doctor's fees, prescription medications, and medical evacuation, depending on the chosen plan.

                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse9"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse9", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            Can travelers customize their travel insurance plans based on their destination and needs?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse9" className={`panel-collapse collapse ${openSection === "collapse9" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Yes, travelers have the flexibility to customize their travel insurance plans based on their destination, duration of travel, specific coverage requirements, and personal preferences. Malik Express offers a variety of customizable options to suit individual needs.

                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse10"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse10", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            How does travel insurance from Malik Express compare in terms of coverage and price for Schengen visa applicants?                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse10" className={`panel-collapse collapse ${openSection === "collapse10" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Malik Express offers competitively priced travel insurance plans that provide comprehensive coverage tailored to the specific needs of Schengen visa applicants. With affordable premiums and extensive coverage options, travelers can enjoy peace of mind while meeting visa requirements.

                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse11"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse11", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 20 }}>
                                            How will I receive the policy?
                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse11" className={`panel-collapse collapse ${openSection === "collapse11" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Once you have successfully purchased travel insurance, your policy will be promptly delivered to you electronically via email. This comprehensive document will encompass all pertinent details of your coverage, including policy limits, terms, and contact information for assistance. Should you prefer a hard copy of the policy or delivery via alternative platforms such as WhatsApp, please feel free to contact our dedicated support team, and we will accommodate your request promptly.
                                        </p>                                    </div>
                                </div>
                            </div>
                            <div className="faqcard">
                                <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                                    <a
                                        data-toggle="collapse"
                                        href="#collapse12"
                                        style={{ textAlign: 'left', color: '#000' }}
                                        onClick={(event) => handleSectionClick("collapse12", event)}
                                    >
                                        <h3 className="black semibold" style={{ fontSize: 19, paddingBottom: '10px' }}>
                                            How do I know the policy issued is genuine?
                                            <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                                        </h3>
                                    </a>
                                </div>
                                <div id="collapse12" className={`panel-collapse collapse ${openSection === "collapse12" ? "show" : ""}`}>
                                    <div className="card-body">
                                        <p style={{ textAlign: 'left' }}>
                                            Rest assured, all policies issued by Malik Express Travel & Tours are authentic and recognized. You can verify the authenticity of your policy by cross-checking the details provided with our records. Additionally, you can reach out to our dedicated customer support team for any clarification or assistance regarding your policy.
                                        </p>                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>



            )}

            <Footer></Footer>
        </>
    );

}



export default Student_Travel;