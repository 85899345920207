import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';


import PK from "../../Icons/airlines/PK.png"
import Blue from "../../Icons/airlines/PA.png"
import Fly from "../../Icons/airlines/jinnah.png"
import Serene from "../../Icons/airlines/serenee.png"
import Askari from "../../Icons/airlines/ask.png"
import Sial from "../../Icons/airlines/PF.png"
import Emirates from "../../Icons/airlines/EK.png"
import Qatar from "../../Icons/airlines/QR.png"
import Saudi from "../../Icons/airlines/SV.png"
import Turkey from "../../Icons/airlines/TK.png"
import Etihad from "../../Icons/airlines/EY.png"
import American from "../../Icons/airlines/AA.png"
import Arabia from "../../Icons/airlines/AAA.png"
import China from "../../Icons/airlines/CA.png"
import Malindo from "../../Icons/airlines/OD.png"
import British from "../../Icons/airlines/British.jpg"
import Cathay from "../../Icons/airlines/CX.png"
import Sri from "../../Icons/airlines/UL.png"
import Thai from "../../Icons/airlines/TG.png"
import Dubai from "../../Icons/airlines/FZ.png"
import Gulf from "../../Icons/airlines/GF.png"
import Kenya from "../../Icons/airlines/KU.png"
import Kuwait from "../../Icons/airlines/LH.png"
import Oman from "../../Icons/airlines/WY.png"
import KLM from "../../Icons/airlines/CL.png"
import Singapore from "../../Icons/airlines/SQ.png"
import Asia from "../../Icons/airlines/AK.png"
import Austria from "../../Icons/airlines/Aus.png"
import Easy from "../../Icons/airlines/3K.png"
import Canada from "../../Icons/airlines/can.png"


import TabIconone from "../../Icons/TabIcons/1.png";
import TabIcontwo from "../../Icons/TabIcons/2.png";
import TabIconthree from "../../Icons/TabIcons/3.png";
import TabIconfour from "../../Icons/TabIcons/4.png";
import TabIconfive from "../../Icons/TabIcons/5.png";

function Benefits() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    function SendCustomRequest(CityName) {
        const url = apiUrl + "/api/VisaSearch/CountryGet?term=" + CityName;
        // console.log(url);
        axios
            .get(url)
            .then((response) => {
                const data = response.data;
                // navigate('/visas/' + CityName + '-visa', { state: { props: data } });
            })
            .catch((error) => console.error("Error: ${error}"));
    }
    return (

        <section id="services-section">
            {/* <div class="container">
                            <div class="row">
                                <div class="col-md-12">
                    <div class="page-heading">
                                        <h2 style={{ fontWeight: 'bold' }}>Schengen Visa Travel Insurance from Pakistan </h2>
                                        <hr class="heading-line" />
                                        <blockquote>
                                        Embarking on your Schengen journey? Ensure you're equipped with more than just a passport. Malik Express brings you exclusive travel insurance coverage tailored for Schengen visa holders, offering unparalleled benefits and peace of mind.
                                        <br />
                                        With our Schengen-approved policies, you're not just covered; you're protected with a comprehensive medical insurance plan boasting a whopping minimum coverage of US$50,000. But that's not all – with Malik Express, you can elevate that coverage to an impressive US$100,000. From emergency hospital visits to medical evacuations, trip interruptions, and even accidental death and dismemberment, we've got you covered for all eventualities.
                                        <br />
                                        Discover the freedom to explore an array of breathtaking destinations across Schengen countries, including Hungary, Austria, Belgium, Czech Republic, Denmark, Estonia, Finland, France, Germany, Greece, Iceland, Italy, Latvia, Liechtenstein, Lithuania, Luxembourg, Malta, Netherlands, Norway, Poland, Portugal, Slovakia, Slovenia, Spain, Sweden, and Switzerland. With Malik Express as your trusted partner, your Schengen visa isn't just a gateway to adventure; it's a ticket to unparalleled protection and peace of mind.                                      
                                                                                </blockquote>
                                        <br />
                                      
                                    </div>
                                    </div>
                                    </div>
                                    </div> */}
            
            <div className="pbb-80 pt-xs-20" >
                <section className="coverage">
                    <div className="container">
                        <div class="page-heading">

                            <h2>The Benefits of Travel Insurance</h2>
                            <blockquote>
                                Explore peace of mind knowing that Malik Express offers comprehensive travel insurance, safeguarding your journey with coverage tailored to your needs.
                            </blockquote>
                            <br />
                            <hr class="heading-line" />
                        </div>
                        <div className="coverage-wrap">
                            <div className="coverage-wrap-item">
                                <h3>Visa Applying</h3>
                                <img width="72" height="72" loading="lazy" src="https://d1ubwt7z1ubyyw.cloudfront.net/uploads/icon-visa-applying-1671080136.svg" alt="Visa Applying" title="Visa Applying" />
                                <p><i>
                                    Streamline your visa application process with Malik Express, as our travel insurance meets the requirements for various visa applications, ensuring a hassle-free experience.
                                </i></p>
                            </div>

                            <div className="coverage-wrap-item">
                                <h3>Emergency Medical & Hospitalization</h3>
                                <img width="72" height="72" loading="lazy" src="https://d1ubwt7z1ubyyw.cloudfront.net/uploads/insurance-hospital-1657251888.png" alt="Emergency Medical & Hospitalization" title="Emergency Medical & Hospitalization" />
                                <p><i>
                                    Rest easy knowing that Malik Express provides extensive coverage for emergency medical expenses and hospitalization during your travels, ensuring you're protected wherever you go.
                                </i></p>
                            </div>
                            <div className="coverage-wrap-item">
                                <h3>Transportation Benefits</h3>
                                <img width="72" height="72" loading="lazy" src="https://d1ubwt7z1ubyyw.cloudfront.net/uploads/insurance-ambulance-1657251858.png" alt="Transportation Benefits" title="Transportation Benefits" />
                                <p><i>
                                    Enjoy added convenience with Malik Express travel insurance, which includes transportation benefits such as coverage for transportation to medical facilities and repatriation in case of emergencies.
                                </i></p>
                            </div>
                            <div className="coverage-wrap-item">
                                <h3>Trip Delays, Trip Interruption, Lost Baggage</h3>
                                <img width="72" height="72" loading="lazy" src="https://d1ubwt7z1ubyyw.cloudfront.net/uploads/insurance-baggage-1657251888.png" alt="Trip Delays, Trip Interruption, Lost Baggage" title="Trip Delays, Trip Interruption, Lost Baggage" />
                                <p><i>
                                    Experience worry-free travel with Malik Express, as our insurance covers trip delays, interruptions, and lost baggage, ensuring you're supported throughout your journey.                                </i></p>
                            </div>

                        </div>
                    </div>
                </section>
            </div>

            {/* <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Benefits & Coverage of Our Travel Insurance </h2>
                            <hr className="heading-line" />


                        </div>

                        <div className="container">
    <div className="row justify-content-center">
        <div className="col-md-5 con" style={{marginRight:'20px'}}>
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Medical Benefits:</h2>
                <ul style={{ listStyleType: 'disc',marginLeft: "15px" }}>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Medical Expenses & Hospitalization Abroad: $50,000</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Repatriation in case of illness or accident: Actual Expenses</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Repatriation of Mortal Remains: Actual Expenses</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Emergency Dental Care: $600</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Deductible on Medical & Dentals: $100</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Return of dependent children: Actual Expenses</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Travel of one Immediate Family Member: $100/Day-Max 1000</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Relay of urgent Messages: Unlimited</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Long Distance Medical information Services: Unlimited</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Medical Referral: Actual Expenses</span>
                    </li>
                </ul>
            </div>
        </div>
        <div className="col-md-5 con">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Travel Benefits: </h2>
                <ul style={{ listStyleType: 'disc' ,marginLeft: "15px"}}>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>24/7 World Wide Assistance Services: Included</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Loss of Passport (Excess $ 25 EEL): $300</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>In-Flight Loss of Checked in Baggage: $300</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Covid Cover T & C apply: US$ 2000</span>
                    </li>
                    <li className="text-left">
                        <span style={{ fontWeight: "bolder", marginLeft: "5px" }}>Accidental Death (During travel using recognized means of transport, planes, public buses, taxies, etc. Deductible $ 100: $15000</span>
                    </li>
                    
                </ul>
            </div>
        </div>
    </div>
</div>


                    </div>

                </div>
            </div> */}
  <div className="pbb-80 pt-xs-20" >
                <section className="coverage">
                    <div className="container">
                        <div class="page-heading">

                            <h2>Benefits & Coverage of Our Travel Insurance </h2>
                           
                            <hr class="heading-line" />
                        </div>
                        <div className="coverage-wrap">
                            <div className="coverage-wrap-items">
                                <h3>Medical Benefits:</h3>
                                <p>

                                <ul style={{ listStyleType: 'disc',marginLeft: "15px" }}>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>Medical Expenses & Hospitalization Abroad: $50,000</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Repatriation in case of illness or accident: Actual Expenses</i>
                    </li>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>Repatriation of Mortal Remains: Actual Expenses</i>
                    </li>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>Emergency Dental Care: $600</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Deductible on Medical & Dentals: $100</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Return of dependent children: Actual Expenses</i>
                    </li>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>Travel of one Immediate Family Member: $100/Day-Max 1000</i>
                    </li>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>Relay of urgent Messages: Unlimited</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Long Distance Medical information Services: Unlimited</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Medical Referral: Actual Expenses</i>
                    </li>
                </ul>
                                </p>
                            </div>

                            <div className="coverage-wrap-items">
                                <h3>Travel Benefits: </h3>
                                <p>
                <ul style={{ listStyleType: 'disc' ,marginLeft: "15px"}}>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>24/7 World Wide Assistance Services: Included</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Loss of Passport (Excess $ 25 EEL): $300</i>
                    </li>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>In-Flight Loss of Checked in Baggage: $300</i>
                    </li>
                    <li className="text-left">
                        <i style={{ marginLeft: "5px" }}>Covid Cover T & C apply: US$ 2000</i>
                    </li>
                    <li className="text-left">
                        <i style={{  marginLeft: "5px" }}>Accidental Death (During travel using recognized means of transport, planes, public buses, taxies, etc. Deductible $ 100: $15000</i>
                    </li>
                    
                </ul>
                                    </p>
                            </div>
                           
                            
                        </div>
                    </div>
                </section>
            </div>

        </section>
    );

}



export default Benefits;