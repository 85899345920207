import React from 'react'
import Features from "../Home/Features";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import {  useEffect } from 'react';

function PrivacyPolicy() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <>
         <MainMenu></MainMenu>
            <section className="page-cover" id="cover-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="page-title">Privacy Policy</h1>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item">Privacy </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="innerpage-wrapper">
            <div id="testimonials-page" class="innerpage-section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="page-heading">
                                <h2>Privacy Policy</h2>
                                <hr class="heading-line" />
                            </div>

                            <div class="carousel slide" data-ride="carousel" id="quote-carousel">
                                <h2> Payment Mode</h2>
                                <h3>Online</h3>
                                <p>
                                malikexpress accepts Visa and Mastercard credit and debit cards through our payment gateway. We are integrated with Mastercard Internet Gateway System, a world-class payment system with the highest global standards for payment security. We are PCI DSS compliant and have technology and systems in place to ensure that your credit card information is transferred directly to the bank so that it is never stored in our database. malikexpress is a certified Symantec site. Symantec is a world leader in online security with a focus on helping organizations in securing their information. Symantec owns and operates Norton security products. If our bank declines a credit card charge for any reason, it is the responsibility of the customer to coordinate with our Customer Service team to make payment through cash, credit, or debit card.
                                </p>


                                <h2> Payment Mode</h2>
                                <h3>Offline</h3>
                                <p>
                                 alikexpress accepts payment through transfer of funds to our bank account. Customer’s also have the option of visiting our branches to make payment through cash, credit, or debit card.

                                </p>

                                <h3>Order Confirmation</h3>
                                <p>
                                An online order should be considered confirmed and guaranteed when a Travel Voucher / Travel E-document email is received from malikexpress confirming that your order has been confirmed with the supplier. If you do not receive the Travel Voucher / Travel E-document email, please kindly call our Customer Service team with your booking details.
                                </p>

                                <h3>Delivery of Products & Services</h3>
                                <p>
                                Airline e-tickets are delivered to customers via email. Airline e-tickets contain unique confirmation numbers such as a Passenger Number Record and Ticket Number. Passengers are required by the airline and by the Civil Aviation Authority to show a hard copy of their e-ticket to enter airports in Pakistan and to check in at the airline desk. Airlines will give you a boarding pass after checking your e-ticket and mandatory documents such as passport or national identity card.

                                </p>

                                <h3>Refund Procedure</h3>
                                <p>
                                In case you are not able to utilize your ticket, you are required to claim your refund with malikexpress within 60 days from the travel date. Refund claims must be made by emailing or calling our Customer Service center. Please note that refunds will always be in accordance to the supplier (airline/hotel/tour operator) rules and policies. Refunds should be made to customers within 30 business days from date of refund/cancellation request. Payments made through credit card will be refunded directly to the card, while payments made through other modes will be paid through cheque. Please note that Sastaticket.pk fees, if any, will not be refundable.

                                </p>

                                <h3>Promotion Codes</h3>
                                <p>
                                malikexpress frequently offers promotional discounts through a voucher system. malikexpress members or those customers that have registered on our website to receive special offers will receive promotions, discounts and vouchers that we believe will be of interest to you. Please note that vouchers are the property of malikexpress and we maintain the right to modify or terminate promotions, discounts, and vouchers without any notice. Any such modifications or terminations will be considered final and will not be disputable.

                                </p>

                                <h3>Currency</h3>
                                <p>
                                malikexpress uses payment in PKR
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

            <Features></Features>
            <Footer></Footer>

        </>
    )
}

export default PrivacyPolicy;