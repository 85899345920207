
import React from 'react';
import './SevenPackages.css';

const SevenPackages = ({ packageData = {} }) => {
    

    const { name = '', hotels = [], inclusions = [], price = '' } = packageData;

    return (
        <>
       
        <div className="col-lg-3 col-md-3 col-sm-12">
        <div className="unique-package-detail">
            <span className="unique-package-name">{name}</span>
            {hotels.map((hotel, index) => (
                <div className="unique-hotel-detail" key={index}>
                    <div className="unique-image-holder">
                        <figure>
                            <img alt="" src={hotel.imageUrl} />
                        </figure>
                    </div>
                    <div className="unique-text-holder">
                        <strong>{hotel.name}</strong> <span>{hotel.description}</span>
                    </div>
                </div>
            ))}
            <ul className="unique-inclusions-list" style={{ marginBottom: '15px' }}>
                {inclusions.map((inclusion, index) => (
                    <li key={index}>
                 <i className='fa fa-check' style={{color:'#e5b622',marginRight:'5px'}}></i>
                        {inclusion.name}
                    </li>
                ))}
            </ul>
            <div className="unique-price-box">
                <strong><em>RS.</em> {price}</strong>
            </div>
        </div>
    </div>
    </>
    );
};

export default SevenPackages;
