import React from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Box, Typography, Tab, Tabs,useMediaQuery } from '@mui/material';
// Features from "../Home/Features";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import moment from "moment";
import Pagination from './Pagination';

function Blog ()
{
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [blogs, setBlogs] = useState([]);
    const baseImageUrl = "https://malikexpress.com/images/post/";
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15; // You can adjust this to your needs.
    const [totalPages, setTotalPages] = useState(0);

    async function fetchData() {
        // setLoading(true);
        const url = apiUrl + '/api/Blog/list';
        // console.log(url);
        await axios
            .get(url)
            .then((response) => {
                const data = response.data;
                setBlogs(data);
                setTotalPages(Math.ceil(response.data.length / itemsPerPage));
                setLoading(false);
       console.log(data);
            })
            .catch((error) => console.error("Error: ${error}"));
    }

    useEffect(() => {

        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded
            fetchData();
           // console.log(blogs);
        }, 2000); // Adjust the duration as needed

    }, []);

    const onPageChange = (page) => {
        setCurrentPage(page);
      };
    
      const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentBlogPosts = blogs.slice(startIndex, endIndex);

    async function SendRequest(e) { 
        //console.log(blogs);
        let post = blogs.filter((item) => item.PostId == e);
       // console.log(post[0].Slug);
     navigate('/Blog/Post/' + post[0].Slug, { state: { props: post } });         
    }

    const [formData, setFormData] = useState({
        txt_name: '',
        txt_email: '',
        txt_phone: '',
        txt_message: '',
      });
      const [resultMsg, setResultMsg] = useState('');
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post( apiUrl + '/api/ContactUs/savemessage', formData);
          setResultMsg(response.data.message); // You can customize this based on your API response structure
          setFormData({
            txt_name: '',
            txt_email: '',
            txt_phone: '',
            txt_message: '',
          });
          alert("We have received your message. Our rep. will contact you soon. Thank you");
         
        } catch (error) {
          setResultMsg('An error occurred while sending the data.');
        }
      };

return (
<>
<Helmet>
                <title >{"Malik Express Travel Blog | Travel Guides, Tips & Insights"}</title>

                <meta name="description" content={"Malik Express Travel Blog - Your destination for travel guides, tips, and insights. Discover Pakistan's northern areas, Turkey, Dubai, Thailand, and more. "} data-react-helmet="true" />
                <meta name="keywords" content={"Travel guide, travel tips, travel guide of Pakistan, travel blog site, travel blog website, travel blog Pakistan, travel guide of Pakistan northern areas, travel guide turkey, travel guide Dubai, travel guide Pakistan northern areas, travel guide for Thailand, travel blog on Dubai, travel guide Istanbul"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/travel-blog" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
          

            {!isLoading && (
                    <div>
                         
     <section class="page-cover" id="cover-blog-details">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <h1 class="page-title">Malik Express</h1>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item">Post</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        <section id="best-features" className="banner-padding orange-features"  style={{borderBottom: 'solid',color:'rgb(255, 193, 7)'}}>
        <div className="container">
            <div className='row'>
            <h2 className='page-heading  m-auto' style={{color:'white'}}>Welcome to Our Travel Blog – Travel Guides, Tips & Tales</h2>
            </div>
            
          <div className="row">            
          <p style={{color:'white'}}>
          Buckle up for a journey of exploration, adventure, and discovery as we present the Malik Express Travel & Tours travel blog. Our aim is to be your ultimate travel guide, helping you navigate the world of wanderlust with expert travel tips, insights, and captivating narratives. From unraveling the mystical charm of Pakistan's northern areas to guiding you through the vibrant streets of Istanbul, and offering you insider information about the allure of Dubai and the exotic allure of Thailand, our travel blog is your passport to a wealth of travel knowledge. 
                </p>

          </div>
        </div>
      </section>
        <section className="innerpage-wrapper">

            <div id="blog-listings" className="innerpage-section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-9 col-xl-9 content-side">
                            <div className="space-right">
                               
           {currentBlogPosts.map((blog) => (                             
                            <div className="main-block blog-post blog-list">
                                    <div className="main-img blog-post-img">
                                        
                                            <img src={`${baseImageUrl}${blog.DefaultImage}`} className="img-fluid"
                                                alt="blog-post" />
                                        
                                        <div className="main-mask blog-post-info">
                                            <ul className="list-inline list-unstyled blog-post-info">
                                                <li className="list-inline-item"><span><i  style={{color:'white'}}
                                                            className="fa fa-calendar"></i></span>{moment(blog.PublishedDate).format("DD-MMMM-yyyy")}</li>
                                                <li className="list-inline-item"><span><i style={{color:'white'}} className="fa fa-user"></i></span>By:
                                                {blog.Author}</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="blog-post-detail">
                                        <h2 className="blog-post-title"> <a
                                                    key={blog.PostId}
                                                    id={blog.PostId}
                                                    onClick={(e) => SendRequest(e.target.id)}
                                                  >{blog.Title}</a></h2>

                                        <p>{blog.ShortDescriptions}</p>
                                        
                                        <a
                                                    className="btn btn-orange"
                                                    key={blog.PostId}
                                                    id={blog.PostId}
                                                    onClick={(e) => SendRequest(e.target.id)}
                                                  >
                                                    View More </a>
                                    </div>
                                </div>

                            ))}

                               {/*  <div className="pages">
                                    <ol className="pagination justify-content-center">
                                        <li><span aria-hidden="true"><i
                                                        className="fa fa-angle-left"></i></span></li>
                                        <li className="active">1</li>
                                        <li>2</li>
                                        <li>3</li>
                                        <li>4</li>
                                        <li><span aria-hidden="true"><i
                                                        className="fa fa-angle-right"></i></span></li>
                                    </ol>
                                </div> */}
 <div> <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      /></div>
                            </div>
                        </div>

                        <div className="col-12 col-md-12 col-lg-3 col-xl-3 side-bar blog-sidebar right-side-bar">
                       
                            <div className="row">                             

                                <div className="col-12 col-md-6 col-lg-12">
                                  
                                        <h2 className="side-bar-heading">Contact Form</h2>

                                        <div className="contact-form">
                                        <form id="frm_contact" name="frm_contact" onSubmit={handleSubmit}>

<div className="form-group">
    <input type="text" className="form-control" placeholder="Name" name="txt_name"  value={formData.txt_name}  onChange={handleChange}
        id="txt_name" />
</div>

<div className="form-group">
    <input type="email" className="form-control" placeholder="Email" name="txt_email" value={formData.txt_email}  onChange={handleChange}
        id="txt_email" />
</div>

<div className="form-group">
    <input type="text" className="form-control" placeholder="Phone Number" name="txt_phone" value={formData.txt_phone}  onChange={handleChange}
        id="txt_phone" />
</div>

<div className="form-group">
    <textarea className="form-control" rows="4" placeholder="Your Message"  value={formData.txt_message}  onChange={handleChange}
        name="txt_message" id="txt_message"></textarea>
</div>
<div className="col-md-12 text-center" id="result_msg"></div>
<button name="submit" id="submit" className="btn btn-orange btn-block">Send</button>
</form>
<br />
<br />                                                                        

                                    </div>
                                </div>


                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="side-bar-block contact">
                                        <h2 className="side-bar-heading">Contact Us</h2>
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-envelope"></i></span></div>
                                            <div className="text">
                                                <p>info@malikexpress.com</p>
                                            </div>
                                        </div>
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-phone"></i></span></div>
                                            <div className="text">
                                                <p>(051) 111 77 88 00</p>
                                            </div>
                                        </div>

                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-map-marker"></i></span></div>
                                            <div className="text">
                                                <p>Office no.2, Blue Area, Islamabad</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="side-bar-block follow-us">
                                        <h2 className="side-bar-heading">Follow Us</h2>
                                        <ul className="list-unstyled list-inline">
                                        <li className="list-inline-item">
                                        <a href="https://www.facebook.com/malikexpress/">
                                            <span><i className="fa fa-facebook"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://twitter.com/malik_express">
                                            <span><i className="fa fa-twitter"></i></span>
                                        </a>
                                    </li>

                                    <li className="list-inline-item">
                                        <a href="https://www.pinterest.com/malikexpress/">
                                            <span><i className="fa fa-pinterest-p"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.instagram.com/malikexpress.isb/?igshid=YmMyMTA2M2Y%3D">
                                            <span><i className="fa fa-instagram"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.linkedin.com/in/malik-express-805888138/">
                                            <span><i className="fa fa-linkedin"></i></span>
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a href="https://www.youtube.com/channel/UCv5a82ncf9ZV2HxRAcvykUg">
                                            <span><i className="fa fa-youtube-play"></i></span>
                                        </a>
                                    </li>
                                        </ul>
                                    </div>
                                </div>

                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="side-bar-block tags">
                                        <h2 className="side-bar-heading">Tags</h2>
                                        <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item">SPA
                                            </li>
                                            <li className="list-inline-item">Restaurant</li>
                                            <li className="list-inline-item">Searvices</li>
                                            <li className="list-inline-item">Wifi
                                            </li>
                                            <li className="list-inline-item">Tv
                                            </li>
                                            <li className="list-inline-item">Rooms
                                            </li>
                                            <li className="list-inline-item">Pools
                                            </li>
                                            <li className="list-inline-item">Work
                                            </li>
                                            <li className="list-inline-item">Sports
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        </div> 
        )}
        <Footer></Footer>
</>

)
}
export default Blog;