import React from 'react';
import SevenPackages from './SevenPackages';
import { useState, useEffect } from 'react';
import { Carousel } from 'react-bootstrap';
const FifteenData = () => {
    const packages = [
       
        
        // Fourth
        {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'MANZIL AL FATEH  ',
                    description: '700-800 MTR / AL GHAZA SIDE - shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'SAFA TOWER',
                    description: '700-800 MTR / BEAR MASJID BILAL - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '221,000',
        },
        // Fifth
         {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'JOHRA AL MAJD',
                    description: '700-800MTR / AL HIJRAH ROAD SIDE- shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'DREAM MADINAH ',
                    description: '600MTR / ALI IBN ABI TALIB ROAD- shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '226,500',
        },
                //Eight
        {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'MELLA 1 & MELLA 2',
                    description: '650-700 MTR /IBRAHIM KHALIL ROAD- shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'KANOOZ AL TAQWA ',
                    description: '350-400 MTR / BAB AL SALAM ROAD - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '241,500',
        },
         //Ten
         {
            name: 'Economy',
            hotels: [
                {
                    imageUrl: 'images/umrah/Ramazan umrah.png',
                    name: 'MATHER AL JEWAR',
                    description: '450-500 MTR/ AL HIJRAH ROAD - shuttle',
                },
                {
                    imageUrl: 'images/PckgIcon/MadniIcon.png',
                    name: 'BIR AL EIMAN',
                    description: '200-250 MTR /SOUTH MARKAZIA - shuttle',
                },
            ],
             inclusions: [
            { name: 'Flights'},
            { name: 'Hotel' },
            { name: 'Visa' },
            { name: 'Transport'}
        ],
            price: '251,000',
        },
     
    ];

    const getVisiblePackages = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1200) {
            return 4;
        } else if (screenWidth >= 768) {
            return 2;
        } else {
            return 1;
        }
    };

    const [visiblePackages, setVisiblePackages] = useState(getVisiblePackages());

    useEffect(() => {
        const handleResize = () => {
            setVisiblePackages(getVisiblePackages());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const packageChunks = [];
    for (let i = 0; i < packages.length; i += visiblePackages) {
        packageChunks.push(packages.slice(i, i + visiblePackages));
    }
    const handleDownloadCSI = () => {
        const pdfUrl = '../../images/umrahpackages.pdf';
        const anchor = document.createElement('a');
        anchor.href = pdfUrl;
        anchor.download = 'UmrahPackages-2024.pdf';
        anchor.click();
    };

    return (
        <section className='unique-packages-section'>
        
                <div className='container'>
                  
                <div className='row'>
                    <Carousel indicators={true} controls={false} interval={null}>
                        {packageChunks.map((chunk, index) => (
                            <Carousel.Item key={index}>
                                <div className="d-flex">
                                    {chunk.map((pkg, pkgIndex) => (
                                        <SevenPackages key={pkgIndex} packageData={pkg} />
                                    ))}
                                </div>
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
              

                </div>
                  
                <div className="unique-center">
                    <p>
                        <a target="_blank" rel="noopener noreferrer">
                            <button onClick={handleDownloadCSI}>
                                <b>15 Days Economy Umrah Packages PDF Download</b>
                            </button>
                        </a>
                    </p>
                </div>
            
        </section>
    );
};

export default FifteenData;