import React, { useParams } from "react";
import { Helmet } from 'react-helmet';
import TextField from '@mui/material/TextField';
import { useState, useEffect, useRef } from 'react';
import MainMenu from "../Layout/MainMenu";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { Link, useLocation } from 'react-router-dom';
import TabPanel from '@mui/lab/TabPanel';
import Features from "../Home/Features";
import Footer from "../Home/Footer";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { Upload } from 'antd';
import ArticleIcon from "@mui/icons-material/Article";

function VisaSearchResult() {

    const data = [ 
        {label: "Afghanistan", value: "Afghanistan"}, 
        {label: "land Islands", value: "landIslands"}, 
        {label: "Albania", value: "Albania"}, 
        {label: "Algeria", value: "Algeria"}, 
        {label: "American Samoa", value: "AmericanSamoa"}, 
        {label: "AndorrA", value: "AndorrA"}, 
        {label: "Angola", value: "Angola"}, 
        {label: "Anguilla", value: "Anguilla"}, 
        {label: "Antarctica", value: "Antarctica"}, 
        {label: "Antigua and Barbuda", value: "AntiguaandBarbuda"}, 
        {label: "Argentina", value: "AR"}, 
        {label: "Armenia", value: "AM"}, 
        {label: "Aruba", value: "AW"}, 
        {label: "Australia", value: "AU"}, 
        {label: "Austria", value: "AT"}, 
        {label: "Azerbaijan", value: "AZ"}, 
        {label: "Bahamas", value: "BS"}, 
        {label: "Bahrain", value: "BH"}, 
        {label: "Bangladesh", value: "BD"}, 
        {label: "Barbados", value: "BB"}, 
        {label: "Belarus", value: "BY"}, 
        {label: "Belgium", value: "BE"}, 
        {label: "Belize", value: "BZ"}, 
        {label: "Benin", value: "BJ"}, 
        {label: "Bermuda", value: "BM"}, 
        {label: "Bhutan", value: "BT"}, 
        {label: "Bolivia", value: "BO"}, 
        {label: "Bosnia and Herzegovina", value: "BA"}, 
        {label: "Botswana", value: "BW"}, 
        {label: "Bouvet Island", value: "BV"}, 
        {label: "Brazil", value: "BR"}, 
        {label: "British Indian Ocean Territory", value: "IO"}, 
        {label: "Brunei Darussalam", value: "BN"}, 
        {label: "Bulgaria", value: "BG"}, 
        {label: "Burkina Faso", value: "BF"}, 
        {label: "Burundi", value: "BI"}, 
        {label: "Cambodia", value: "KH"}, 
        {label: "Cameroon", value: "CM"}, 
        {label: "Canada", value: "CA"}, 
        {label: "Cape Verde", value: "CV"}, 
        {label: "Cayman Islands", value: "KY"}, 
        {label: "Central African Republic", value: "CF"}, 
        {label: "Chad", value: "TD"}, 
        {label: "Chile", value: "CL"}, 
        {label: "China", value: "CN"}, 
        {label: "Christmas Island", value: "CX"}, 
        {label: "Cocos (Keeling) Islands", value: "CC"}, 
        {label: "Colombia", value: "CO"}, 
        {label: "Comoros", value: "KM"}, 
        {label: "Congo", value: "CG"}, 
        {label: "Congo, The Democratic Republic of the", value: "CD"}, 
        {label: "Cook Islands", value: "CK"}, 
        {label: "Costa Rica", value: "CR"}, 
        {label: "Cote D\"Ivoire", value: "CI"}, 
        {label: "Croatia", value: "HR"}, 
        {label: "Cuba", value: "CU"}, 
        {label: "Cyprus", value: "CY"}, 
        {label: "Czech Republic", value: "CZ"}, 
        {label: "Denmark", value: "DK"}, 
        {label: "Djibouti", value: "DJ"}, 
        {label: "Dominica", value: "DM"}, 
        {label: "Dominican Republic", value: "DO"}, 
        {label: "Ecuador", value: "EC"}, 
        {label: "Egypt", value: "EG"}, 
        {label: "El Salvador", value: "SV"}, 
        {label: "Equatorial Guinea", value: "GQ"}, 
        {label: "Eritrea", value: "ER"}, 
        {label: "Estonia", value: "EE"}, 
        {label: "Ethiopia", value: "ET"}, 
        {label: "Falkland Islands (Malvinas)", value: "FK"}, 
        {label: "Faroe Islands", value: "FO"}, 
        {label: "Fiji", value: "FJ"}, 
        {label: "Finland", value: "FI"}, 
        {label: "France", value: "FR"}, 
        {label: "French Guiana", value: "GF"}, 
        {label: "French Polynesia", value: "PF"}, 
        {label: "French Southern Territories", value: "TF"}, 
        {label: "Gabon", value: "GA"}, 
        {label: "Gambia", value: "GM"}, 
        {label: "Georgia", value: "GE"}, 
        {label: "Germany", value: "DE"}, 
        {label: "Ghana", value: "GH"}, 
        {label: "Gibraltar", value: "GI"}, 
        {label: "Greece", value: "GR"}, 
        {label: "Greenland", value: "GL"}, 
        {label: "Grenada", value: "GD"}, 
        {label: "Guadeloupe", value: "GP"}, 
        {label: "Guam", value: "GU"}, 
        {label: "Guatemala", value: "GT"}, 
        {label: "Guernsey", value: "GG"}, 
        {label: "Guinea", value: "GN"}, 
        {label: "Guinea-Bissau", value: "GW"}, 
        {label: "Guyana", value: "GY"}, 
        {label: "Haiti", value: "HT"}, 
        {label: "Heard Island and Mcdonald Islands", value: "HM"}, 
        {label: "Holy See (Vatican City State)", value: "VA"}, 
        {label: "Honduras", value: "HN"}, 
        {label: "Hong Kong", value: "HK"}, 
        {label: "Hungary", value: "HU"}, 
        {label: "Iceland", value: "IS"}, 
        {label: "India", value: "IN"}, 
        {label: "Indonesia", value: "ID"}, 
        {label: "Iran, Islamic Republic Of", value: "IR"}, 
        {label: "Iraq", value: "IQ"}, 
        {label: "Ireland", value: "IE"}, 
        {label: "Isle of Man", value: "IM"}, 
        {label: "Israel", value: "IL"}, 
        {label: "Italy", value: "IT"}, 
        {label: "Jamaica", value: "JM"}, 
        {label: "Japan", value: "JP"}, 
        {label: "Jersey", value: "JE"}, 
        {label: "Jordan", value: "JO"}, 
        {label: "Kazakhstan", value: "KZ"}, 
        {label: "Kenya", value: "KE"}, 
        {label: "Kiribati", value: "KI"}, 
        {label: "Korea, Democratic People\"S Republic of", value: "KP"}, 
        {label: "Korea, Republic of", value: "KR"}, 
        {label: "Kuwait", value: "KW"}, 
        {label: "Kyrgyzstan", value: "KG"}, 
        {label: "Lao People\"S Democratic Republic", value: "LA"}, 
        {label: "Latvia", value: "LV"}, 
        {label: "Lebanon", value: "LB"}, 
        {label: "Lesotho", value: "LS"}, 
        {label: "Liberia", value: "LR"}, 
        {label: "Libyan Arab Jamahiriya", value: "LY"}, 
        {label: "Liechtenstein", value: "LI"}, 
        {label: "Lithuania", value: "LT"}, 
        {label: "Luxembourg", value: "LU"}, 
        {label: "Macao", value: "MO"}, 
        {label: "Macedonia, The Former Yugoslav Republic of", value: "MK"}, 
        {label: "Madagascar", value: "MG"}, 
        {label: "Malawi", value: "MW"}, 
        {label: "Malaysia", value: "MY"}, 
        {label: "Maldives", value: "MV"}, 
        {label: "Mali", value: "ML"}, 
        {label: "Malta", value: "MT"}, 
        {label: "Marshall Islands", value: "MH"}, 
        {label: "Martinique", value: "MQ"}, 
        {label: "Mauritania", value: "MR"}, 
        {label: "Mauritius", value: "MU"}, 
        {label: "Mayotte", value: "YT"}, 
        {label: "Mexico", value: "MX"}, 
        {label: "Micronesia, Federated States of", value: "FM"}, 
        {label: "Moldova, Republic of", value: "MD"}, 
        {label: "Monaco", value: "MC"}, 
        {label: "Mongolia", value: "MN"}, 
        {label: "Montenegro", value: "ME"},
        {label: "Montserrat", value: "MS"},
        {label: "Morocco", value: "MA"}, 
        {label: "Mozambique", value: "MZ"}, 
        {label: "Myanmar", value: "MM"}, 
        {label: "Namibia", value: "NA"}, 
        {label: "Nauru", value: "NR"}, 
        {label: "Nepal", value: "NP"}, 
        {label: "Netherlands", value: "NL"}, 
        {label: "Netherlands Antilles", value: "AN"}, 
        {label: "New Caledonia", value: "NC"}, 
        {label: "New Zealand", value: "NZ"}, 
        {label: "Nicaragua", value: "NI"}, 
        {label: "Niger", value: "NE"}, 
        {label: "Nigeria", value: "NG"}, 
        {label: "Niue", value: "NU"}, 
        {label: "Norfolk Island", value: "NF"}, 
        {label: "Northern Mariana Islands", value: "MP"}, 
        {label: "Norway", value: "NO"}, 
        {label: "Oman", value: "OM"}, 
        {label: "Pakistan", value: "PK"}, 
        {label: "Palau", value: "PW"}, 
        {label: "Palestinian Territory, Occupied", value: "PS"}, 
        {label: "Panama", value: "PA"}, 
        {label: "Papua New Guinea", value: "PG"}, 
        {label: "Paraguay", value: "PY"}, 
        {label: "Peru", value: "PE"}, 
        {label: "Philippines", value: "PH"}, 
        {label: "Pitcairn", value: "PN"}, 
        {label: "Poland", value: "PL"}, 
        {label: "Portugal", value: "PT"}, 
        {label: "Puerto Rico", value: "PR"}, 
        {label: "Qatar", value: "QA"}, 
        {label: "Reunion", value: "RE"}, 
        {label: "Romania", value: "RO"}, 
        {label: "Russian Federation", value: "RU"}, 
        {label: "RWANDA", value: "RW"}, 
        {label: "Saint Helena", value: "SH"}, 
        {label: "Saint Kitts and Nevis", value: "KN"}, 
        {label: "Saint Lucia", value: "LC"}, 
        {label: "Saint Pierre and Miquelon", value: "PM"}, 
        {label: "Saint Vincent and the Grenadines", value: "VC"}, 
        {label: "Samoa", value: "WS"}, 
        {label: "San Marino", value: "SM"}, 
        {label: "Sao Tome and Principe", value: "ST"}, 
        {label: "Saudi Arabia", value: "SA"}, 
        {label: "Senegal", value: "SN"}, 
        {label: "Serbia", value: "RS"}, 
        {label: "Seychelles", value: "SC"}, 
        {label: "Sierra Leone", value: "SL"}, 
        {label: "Singapore", value: "SG"}, 
        {label: "Slovakia", value: "SK"}, 
        {label: "Slovenia", value: "SI"}, 
        {label: "Solomon Islands", value: "SB"}, 
        {label: "Somalia", value: "SO"}, 
        {label: "South Africa", value: "ZA"}, 
        {label: "South Georgia and the South Sandwich Islands", value: "GS"}, 
        {label: "Spain", value: "ES"}, 
        {label: "Sri Lanka", value: "LK"}, 
        {label: "Sudan", value: "SD"}, 
        {label: "Suriname", value: "SR"}, 
        {label: "Svalbard and Jan Mayen", value: "SJ"}, 
        {label: "Swaziland", value: "SZ"}, 
        {label: "Sweden", value: "SE"}, 
        {label: "Switzerland", value: "CH"}, 
        {label: "Syrian Arab Republic", value: "SY"}, 
        {label: "Taiwan, Province of China", value: "TW"}, 
        {label: "Tajikistan", value: "TJ"}, 
        {label: "Tanzania, United Republic of", value: "TZ"}, 
        {label: "Thailand", value: "TH"}, 
        {label: "Timor-Leste", value: "TL"}, 
        {label: "Togo", value: "TG"}, 
        {label: "Tokelau", value: "TK"}, 
        {label: "Tonga", value: "TO"}, 
        {label: "Trinidad and Tobago", value: "TT"}, 
        {label: "Tunisia", value: "TN"}, 
        {label: "Turkey", value: "TR"}, 
        {label: "Turkmenistan", value: "TM"}, 
        {label: "Turks and Caicos Islands", value: "TC"}, 
        {label: "Tuvalu", value: "TV"}, 
        {label: "Uganda", value: "UG"}, 
        {label: "Ukraine", value: "UA"}, 
        {label: "United Arab Emirates", value: "AE"}, 
        {label: "United Kingdom", value: "GB"}, 
        {label: "United States", value: "US"}, 
        {label: "United States Minor Outlying Islands", value: "UM"}, 
        {label: "Uruguay", value: "UY"}, 
        {label: "Uzbekistan", value: "UZ"}, 
        {label: "Vanuatu", value: "VU"}, 
        {label: "Venezuela", value: "VE"}, 
        {label: "Viet Nam", value: "VN"}, 
        {label: "Virgin Islands, British", value: "VG"}, 
        {label: "Virgin Islands, U.S.", value: "VI"}, 
        {label: "Wallis and Futuna", value: "WF"}, 
        {label: "Western Sahara", value: "EH"}, 
        {label: "Yemen", value: "YE"}, 
        {label: "Zambia", value: "ZM"}, 
        {label: "Zimbabwe", value: "Zimbabwe"} 
        ]

    const location = useLocation();
    const [pathName, setPathName] = useState(null);
    const [visaData, setVisaData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [gender, setgender] = React.useState("");
    const [DOB, setDOB] = React.useState();
    const [value, setCountryValue] = useState("Select Country");
    const [birthcountry, setBirthCountryValue] = useState("Select Country");
    const [firstname, setFirstNameValue] = useState("");
    const [Employementvalue, setemployment] = React.useState("");
    const [visaValue, setVisaValue] = useState();
   
    const [email, setEmailValue] = useState("");
    const [mobile, setMobileValue] = useState("");
    const [homeaddress1, setHomeAddressValue] = useState("");

    const [officeaddress1, setOfficeAddressValue] = useState("");
    const [officeaddress2, setOfficeAddress2Value] = useState("");
    const [officepostalcode, setOfficePostalCodeValue] = useState("");
    const [officecontact, setOfficeContactValue] = useState("");
    const [Officecity, setOfficeCityValue] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;

    // const apiUrl = "https://localhost:7173";
    const [imageData, setImageData] = useState([]);
    const [newvalue, setValue] = useState();


    useEffect(() => {

        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded
        }, 2000); // Adjust the duration as needed

        if (location.state !== null) {
            setVisaData(location.state.props);
            setValue(location.state.props.visas[0].Id);
            setDocument(location.state.props.document);
            setCountry(location.state.props.Country);
            setFaqs(location.state.props.faqs)
            setData()

        }
        else {
            let tmp = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
            const cName = tmp.split('-');
            const url = apiUrl + "/api/VisaSearch/CountryGet?term=" + cName[0].replace('/', '');
            // console.log(url);
            axios
                .get(url)
                .then((response) => {
                    const data = response.data;
                    setVisaData(data);
                    setData();
                })
                .catch((error) => console.error("Error: ${error}"));

        }
    }, []);

    const navigate = useNavigate();
    const [fileList, setFileList] = useState([]);
    const [visas, setVisas] = useState([]); //visaData.visas;
    const [faqs, setFaqs] = useState([]);;
    const [document, setDocument] = useState(); //visaData.document;
    const [country, setCountry] = useState(); //visaData.Country;
    const [title, setTitle] = useState('My Page Title');
    const [keyword, setkeywords] = useState('My Page keywords');
    const [metaDescription, setMetaDescription] = useState(
        'This is my page description.'
    );
    const SetDOB = (newValue) => {
        setDOB(newValue);
      };
      const handleVisaChange = (event) => {
        setVisaValue(event.target.value);
    };
    const handleEmployementChange = (event) => {
        setemployment(event.target.value);
    };
    function setData() {
        if (visaData !== null) {
            if (visaData.visas !== null) {
                setVisas(visaData.visas);
                if (visaData.visas[0] !== null) {
                    setVisas(visaData.visas[0].Id);
                }
            }
            if (visaData.faqs !== null) {
                setFaqs(visaData.faqs);
            }
            if (visaData.document !== null) {
                setDocument(visaData.document);
            }
            if (visaData.Country !== null) {
                setCountry(visaData.Country);
                //console.log(country);
                setTitle(visaData.Country.Title);
                setMetaDescription(visaData.Country.MetaDescription);
                setkeywords(visaData.Country.keywords);
            }

            const mystyle = {
                // background: 'linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(${imageUrl}) 100% 100%',
                backgroundImage: `url(data:image/jpeg;base64,${visaData.Country.DefaultImage})`,
            }
        }

    }
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    function SendVisaRequest() {

        if (!firstname) {
            setErrorMessage('Name is required');
            return;
          }
          if (!email) {
            setErrorMessage('Email is required');
            return;
          }
          if (!mobile) {
            setErrorMessage('Contact is required');
            return;
          }

        setLoading(true);
        const formData = new FormData();
        formData.append('FirstName',firstname);
        formData.append('Email',email);
        formData.append('CountryId',country.Id);
        formData.append('VisaId',visaValue);
        formData.append('Mobile',mobile);

        formData.append('DOB',DOB);
        formData.append('Birthcountry',birthcountry);
        formData.append('HomeAddress1',homeaddress1);
        formData.append('Employement',Employementvalue);

        formData.append('OfficeAddress1',officeaddress1);
        formData.append('OfficeAddress2',officeaddress2);
        formData.append('OfficePostalCode',officepostalcode);
        formData.append('OfficeContact',officecontact);
        formData.append('OfficeCity',Officecity);
        formData.append('OfficeCountry',value);
debugger
       
    fileList.forEach(file => {
        formData.append('files', file.originFileObj);
      });
        const axiosInstance = axios.create({
            baseURL: apiUrl + '/api/VisaRequest'
          });
          axiosInstance.post('/AddVisaRequest', formData,{ headers: {
            'Content-Type': 'multipart/form-data'
          }, onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        console.log(`Upload progress: ${progress}%`);
      },})
          .then(response => {
            navigate('/VisaThankYou');
          })
          .catch(error => {
            debugger
            console.error(error);
          });
    }


    const SendRequest = (event, param) => {
        debugger
        const documents = document.filter(doc => doc.VisaID == param);

        var visaId = param;
        const Data = [
            { country, documents, visaId }
        ];
        navigate('/VisaPersonDetails', { state: { props: Data } });
    };



    return (
        < >
            <Helmet>
                <title >{title}</title>
                <meta name="description" content={keyword} data-react-helmet="true" />
                <meta name="keywords" content={metaDescription} data-react-helmet="true" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
            <MainMenu></MainMenu>

            <div>
                {isLoading && <div className="loader"></div>}

                {/* Render the content when isLoading is false */}
                {!isLoading && (
                    <div>


                        {!visaData ? <></> : <>
                            <section className="page-cover" id="cover-faq">
                                <div className="container" style={{ marginTop: "100px" }}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {!country ? <></> :
                                                <><h1 className="page-title row ml-3">Get {country.Name} Visa From Pakistan | {country.Name} Visa</h1>
                                                    <ul className="breadcrumb">
                                                        <li className="breadcrumb-item"><Link to="/" className="nav-link">Home</Link></li>
                                                        <li className="breadcrumb-item mt-2">{country.Name} Visa</li>
                                                    </ul>
                                                    <img style={{ width: "77px", height: "50px", marginTop: "20px" }} src={`data:image/jpeg;base64,${country.FlagImage}`} />
                                                </>}
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <section className="container border shadow" style={{ marginTop: "50px" }}>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-8 col-lg-8 border p-0">
                                        <Box sx={{ width: '100%', typography: 'body1' }}>
                                            <TabContext value={newvalue} >
                                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                    <TabList style={{  }} onChange={handleTabChange} aria-label="">
                                                        {
                                                            visaData.visas.map((tab, index) => (
                                                                <Tab className="CustomTopClasses" icon={<ArticleIcon />} style={{borderRadius: "0px 11px 0px 0px",minWidth:"115px"}} key={index} label={tab.VisaName} value={tab.Id} />
                                                            
                                                                ))
                                                        }
                                                    </TabList>
                                                </Box>
                                                {
                                                    visaData.visas.map((tab, index) => (

                                                        <TabPanel key={tab.Id} value={tab.Id}   >
                                                          
                                                            <div className="">
                                                                <div className="textCenter " style={{ fontSize: "25px" }}>
                                                                    Visa Requirements For the Citizens of Pakistan
                                                                </div>

                                                                <div className="ml-5 mt-3">
                                                                    <ol className="ml-3">

                                                                        {visaData.document
                                                                            .filter(item => item.VisaID == tab.Id)
                                                                            .map(item => (
                                                                                <li className="text-left">{item.description}</li>
                                                                            ))}
                                                                    </ol>
                                                                </div>
                                                               
                                                            </div>

                                                            <div className="mt-5">
                                                                <div className="textCenter " style={{ fontSize: "25px" }}>
                                                                    Visa Fees For the Citizens of Pakistan
                                                                </div>
                                                            </div>


                                                            {/* <div className="d-none d-md-block d-lg-block  border border-2 m-3 p-4">

                                                                <table className="table_padd w-100">
                                                                    <thead className="border-bottom">
                                                                        <tr>
                                                                            <th className="nowrap">Type of visa
                                                                            </th>
                                                                            <th>
                                                                                <span className="th">Validity
                                                                                </span>
                                                                            </th>
                                                                            <th>
                                                                                <span className="th">Processing
                                                                                </span>

                                                                            </th>
                                                                            <th className="price_hide nowrap">
                                                                                <span className="th">Embassy fee</span>
                                                                            </th>
                                                                            <th className="price_hide nowrap">Service fee</th>
                                                                            <th className="price_hide total_cost nowrap">Stay Duration
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="mt-3">
                                                                        <tr className="odd">

                                                                            <td className="nowrap alignl visa_type_desc pt-3" rowSpan="1" style={{ paddingLeft: "8px" }}>
                                                                                <span title="Single Entry" className="span_t">{tab.VisaType}
                                                                                </span>
                                                                            </td>
                                                                            <td className="nowrap validity_desc" rowSpan="1">{tab.Validity}
                                                                            </td>
                                                                            <td className="nowrap">{tab.ProcessingTime}
                                                                            </td>
                                                                            <td className="price_hide positive_value nowrap">{tab.VisaFees}
                                                                            </td>
                                                                            <td className="price_hide positive_value nowrap">{tab.ServiceFees}
                                                                            </td>
                                                                            <td className="price_hide positive_value nowrap">{tab.DurationOfStay}
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div> */}

                                                            <div className="border border-2 m-3 p-4">
                                                                <label style={{fontWeight:"bold"}}>Here are the Details of Visa</label>
                                                                <table className="table_padd w-100"style={{lineHeight:"3"}}>
                                                                    <tbody>
                                                                        <tr className="border">
                                                                            <th className="nowrap">Type of visa:</th>
                                                                            <td>{tab.VisaType}</td>
                                                                        </tr>
                                                                        <tr className="border">
                                                                            <th>Validity:</th>
                                                                            <td>{tab.Validity}</td>
                                                                        </tr>
                                                                        <tr className="border">
                                                                            <th>Processing Time:</th>
                                                                            <td>{tab.ProcessingTime}</td>
                                                                        </tr>
                                                                        <tr className="border">
                                                                            <th>Embassy fee:</th>
                                                                            <td>{tab.VisaFees}</td>
                                                                        </tr>
                                                                        <tr className="border mt-3">
                                                                            <th>Service Fee:</th>
                                                                            <td>{tab.ServiceFees}</td>
                                                                        </tr>
                                                                        <tr className="border">
                                                                            <th>Stay Duration:</th>
                                                                            <td>{tab.DurationOfStay}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </TabPanel>
                                                    ))
                                                }
                                            </TabContext>
                                        </Box>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-4 col-lg-4"style={{backgroundColor:"#faa61a"}}>
                                        <div className="mlk-col mlk-col-24 mt-5">
                                            <h1 className="mlk-typography font-normal font-normal text-xl mb-6  text-white">Apply Now</h1>
                                        </div>
                                        
                                        <div className="p-3">
                                            <div className="padding-left: 12.5px; padding-right: 12.5px; row-gap: 10px;"style={{backgroundColor:"white !important"}}>
                                                <Box className="pr-3 pl-3" >
                                                    <FormControl fullWidth>
                                                        <InputLabel id="VisaSelect">Select Visa</InputLabel>

                                                        <Select required
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            className="bg-white"
                                                            value={visaValue}
                                                            label="Visa Select"
                                                            onChange={handleVisaChange}
                                                        >
                                                            {
                                                                visaData.visas.map((visa, index) => (
                                                                    <MenuItem value={visa.Id}>{visa.VisaName}</MenuItem>
                                                                ))
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            </div>
                                        </div>
                                        <div className="p-3">
                                            <div className="mlk-card-body">
                                                <div id="contactDetailForm" className="mlk-form mlk-form-vertical mlk-form-hide-required-mark">
                                                    <div className="" style={{ paddingLeft: "12.5px", paddingRight: "12.5px", rowGap: "10px" }}  >

                                                        <TextField value={firstname} required style={{backgroundColor:"white !important"}}
                                                            onChange={event => setFirstNameValue(event.target.value)}
                                                            id="FirstName" label="Full Name" variant="outlined" className="w-100 mt-3 bg-white" />

                                                        <TextField id="Email" value={email} required style={{backgroundColor:"white !important"}}
                                                            onChange={event => setEmailValue(event.target.value)}
                                                            label="Email" variant="outlined" className="w-100 mt-3 bg-white" />
                                                        <TextField id="outlined-basic" value={mobile} required style={{backgroundColor:"white !important"}}
                                                            onChange={event => setMobileValue(event.target.value)}
                                                            label="Mobile Number" variant="outlined" className="w-100 mt-3 bg-white" />
                                                        <TextField id="HomeAddress" value={homeaddress1} style={{backgroundColor:"white !important"}}
                                                            onChange={event => setHomeAddressValue(event.target.value)}
                                                            label="Address" variant="outlined" className="w-100 mt-3 bg-white" />

                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    label="Date Of Birth"
                                                                    inputFormat="MM/DD/YYYY"
                                                                    style={{backgroundColor:"white "}}
                                                                    value={DOB}
                                                                    className="mt-5 bg-white"
                                                                    onChange={SetDOB}
                                                                    renderInput={(params) => <TextField {...params} />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                        <Autocomplete
                                                            id="Country Select"
                                                            options={data} required
                                                            value={birthcountry}
                                                            style={{backgroundColor:"white !important"}}
                                                            className="mt-5 bg-white"
                                                            onChange={(event, newValue) => {
                                                                setBirthCountryValue(newValue.label);
                                                            }}
                                                            renderInput={(params) => <TextField {...params} variant='standard' />}
                                                        />
                                                        <Box>
                                                            <FormControl fullWidth className="mt-5">
                                                                <InputLabel id="EmployementSelect">Employement Selector</InputLabel>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id=""
                                                                    className="bg-white"
                                                                    style={{backgroundColor:"white !important"}}
                                                                    value={Employementvalue}
                                                                    label="Employement"
                                                                    onChange={handleEmployementChange}
                                                                >
                                                                    <MenuItem value={'Employed'}>Employed</MenuItem>
                                                                    <MenuItem value={'UnEmployed'}>Un Employed</MenuItem>

                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <div className="mlk-col mlk-col-24 mt-5">
                                                            <h1 className="mlk-typography font-normal font-normal text-xl mb-6 "style={{color:"white"}}>Upload Required Documents</h1>
                                                        </div>
                                                        <Upload
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    className=""
                                                    style={{backgroundColor:"white !important"}}
                                                    onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                                    accept=" .jpg,.png,.pdf"
                                                // onPreview={handlePreview}
                                                >
                                                    {fileList.length < document.length && '+ Upload'}
                                                </Upload>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="m-4">
                                            <div>
                                                <p>
                                                    <a onClick={event => SendVisaRequest(event,visaValue )} id="apply_link_1_false_320" className="btn btn-lg rounded pl-5 pr-5" style={{ lineHeight: "2.5", backgroundColor: "#4759b3" }}>
                                                        <span >
                                                            <span style={{ verticalAlignment: "inherit",color:"white" }}>Apply online</span>
                                                        </span>
                                                    </a>
                                                </p>
                                            </div>
                                            <div >
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </section>

                            <section className="innerpage-wrapper text-left mt-5">
                                <div className="pt-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h3 style={{ color: "#faa61a" }}>{visaData.Country.Name}</h3>
                                                <div className="" dangerouslySetInnerHTML={{ __html: visaData.Country.Description }}>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="innerpage-wrapper text-left " style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                                <div className=" pt-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12" dangerouslySetInnerHTML={{ __html: visaData.Country.TermsConditions }}>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="innerpage-wrapper text-left " style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                                <div className=" pt-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12" dangerouslySetInnerHTML={{ __html: visaData.Country.TopPlaces }}>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            <section className="innerpage-wrapper text-left mt-5">
                                <div className="pt-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="" dangerouslySetInnerHTML={{ __html: visaData.Country.Conclusion }}>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <section className="innerpage-wrapper text-left " style={{ paddingTop: "30px", paddingBottom: "20px" }}>
                                <div className=" pt-2">
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <h2 style={{ color: "#faa61a" }}>FAQ's</h2>
                                                {
                                                    faqs.map((faq, index) => (
                                                        <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography> {faq.Question}</Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography>
                                                                    <div className="col-md-12">
                                                                        <p>{faq.Answer} </p>
                                                                    </div>
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </>}
                        <Features></Features>
                        <Footer></Footer>
                    </div>
                )}
            </div>




        </>

    )
}
export default VisaSearchResult;