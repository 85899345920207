import React, {useEffect,useState} from 'react'
import { TextField, Box, Typography, Tab, Tabs,useMediaQuery } from '@mui/material';

import TabIconone from "../../Icons/TabIcons/1.png";
import TabIcontwo from "../../Icons/TabIcons/2.png";
import TabIconthree from "../../Icons/TabIcons/3.png";
import TabIconfour from "../../Icons/TabIcons/4.png";
import TabIconfive from "../../Icons/TabIcons/5.png";
import { FaPlane,FaGlobe ,FaShieldAlt,FaHotel, FaPassport,FaTicketAlt, FaKaaba,FaCertificate, FaClock, FaUserFriends, FaUserCog, FaEdit, FaBox, FaHeadset, FaRoad} from 'react-icons/fa';

function   FlightOffers(){
    const [tabIndex, setTabIndex] = useState(0);
    const [isMobile, setDesktop] = useState(window.innerWidth < 700);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };
    return(
        <section className="innerpage-wrapper">
        <div id="why-us">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Expert Travel Agents in Islamabad</h2>
                            <hr className="heading-line" />
                            <blockquote>At Malik Express Travel & Tours, our team of expert travel agents in Islamabad is dedicated to crafting personalized travel solutions that exceed your expectations. With extensive knowledge of the travel industry and a keen understanding of your needs, our travel agents are committed to providing unparalleled service and assistance throughout your journey. From expert guidance on destination selection to seamless booking arrangements and personalized itinerary planning, our agents are with you every step of the way, ensuring a stress-free and unforgettable travel experience.
                            </blockquote>
                            <br />
                            
                        </div>

                        <div className="">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12"
                                id="why-us-tabs">
                                <Box>
                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Tabs value={tabIndex} onChange={handleTabChange}
                                            sx={{
                                                '& .MuiTabs-indicator': { backgroundColor: '#e5b815',height:'0px' },
                                                '& .MuiTab-root': { color: 'black'},
                                                '& .Mui-selected': { color: '#e5b815' },
                                            }}
                                            orientation={isMobile ? 'vertical' : 'horizontal'} // Set orientation based on device
                                        >

                                            <Tab className="col mt-3"style={{borderRadius:"20px", border:'2px solid rgb(229, 184, 21)'}} label="Extensive Travel Services " />
                                            <Tab className="col mt-3"style={{borderRadius:"20px",border:'2px solid rgb(229, 184, 21)',marginLeft:'10px'}} label="Why Choose Malik Express Travel & Tours?" />
                                        </Tabs>
                                    </Box>
                                    <Box sx={{ padding: 2 }}>
                                        {tabIndex === 0 && (
                                            <>
                                            <div className="row justify-content-center">
                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Ticket Booking Online</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaTicketAlt size={80} color="#e5b815" /> 
                                                    </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      Experience the convenience of booking your flights online with Malik Express Travel & Tours. Our user-friendly platform allows you to effortlessly search, compare, and reserve tickets to your desired destinations.                                                              </p>
                                                    </div>
                                                </div>

                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Visa Consultancy</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaPassport size={80} color="#e5b815" /> 
                                                    </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      Navigate the complexities of visa applications with ease with our professional visa consultancy services. Our experienced team guides you through the process, ensuring a smooth and hassle-free experience.                                                              </p>
                                                    </div>
                                                </div>
                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Hotel Booking</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaHotel  size={80} color="#e5b815" /> 
                                                    </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      From luxury resorts to budget accommodations, we offer a comprehensive range of hotel booking options to suit every preference and budget. Let us help you find the perfect accommodation for your next adventure.                                                              </p>
                                                    </div>
                                                </div>
                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Travel Insurance</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaShieldAlt size={80} color="#e5b815" /> 
                                                    </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      Protect your journey with our comprehensive travel insurance plans. From medical emergencies to trip cancellations, our insurance coverage provides peace of mind and security for your travels.                                                              </p>
                                                    </div>
                                                </div>
                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Domestic Tours</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaPlane size={80} color="#e5b815" />                                                            </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      Explore the beauty and diversity of Pakistan with our curated domestic tour packages. From the majestic mountains of the north to the pristine beaches of the south, our tours showcase the best of Pakistan's landscapes and culture.                                                              </p>
                                                    </div>
                                                </div>

                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>International Tours</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaGlobe size={80} color="#e5b815" />
                                                    </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      Embark on global adventures with our international tour packages. Whether you're dreaming of exploring exotic destinations in Asia, Europe, or beyond, our tours offer unforgettable experiences and memories to last a lifetime.                                                              </p>
                                                    </div>
                                                </div>
                                                <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                   <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Umrah Packages</h3>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <FaKaaba size={80} color="#e5b815" />
                                                    </div>
                                                    <div className="main-info tour-info">
                                                        <div className="main-title tour-title">

                                                        </div>
                                                    </div>
                                                    <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                      <p>
                                                      Fulfil your religious obligations with our meticulously crafted Umrah packages. Designed to provide a seamless and spiritually enriching journey, our packages cater to every aspect of your Umrah pilgrimage.
                                                      </p>        
                                                                                                          </div>
                                                </div>
                                            </div>
                                        </>
                                        )}
                                        {tabIndex === 1 && (
                                            <>
                                                <div className="row justify-content-center">
                                                    <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                       <h3 style={{marginTop:"10px",marginBottom:"20px"}}>35+ Years of Experience</h3>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FaClock size={80} color="#e5b815" /> 
                                                        </div>
                                                        <div className="main-info tour-info">
                                                            <div className="main-title tour-title">

                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginTop:"13px",marginBottom:"30px" }}>
                                                          <p>
                                                          With over three decades of experience in the travel industry, Malik Express Travel & Tours brings unparalleled expertise and insight to every journey. Our long-standing presence in the market is a testament to our commitment to excellence and customer satisfaction.                                                                  </p>
                                                        </div>
                                                    </div>

                                                    <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                       <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Expert Team</h3>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FaUserFriends size={80} color="#e5b815" /> 
                                                        </div>
                                                        <div className="main-info tour-info">
                                                            <div className="main-title tour-title">

                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                          <p>
                                                          Our team comprises seasoned travel professionals who are passionate about crafting unforgettable travel experiences. With in-depth destination knowledge and a dedication to customer service, our team ensures that every aspect of your journey is meticulously planned and executed.                                                                  </p>
                                                        </div>
                                                    </div>

                                                    <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                       <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Personalized Services</h3>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FaUserCog size={80} color="#e5b815" /> 
                                                        </div>
                                                        <div className="main-info tour-info">
                                                            <div className="main-title tour-title">

                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginTop:"10px",marginBottom:"30px" }}>
                                                          <p style={{fontSize:'15px'}}>
                                                          At Malik Express Travel & Tours, we understand that every traveller is unique. That's why we offer personalized services tailored to your preferences and requirements. From customized itinerary planning to special requests and accommodations, we go above and beyond to ensure that your travel experience is exactly as you envision.                                                                  </p>
                                                        </div>
                                                    </div>
                                                    <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                       <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Tailored Packages</h3>
                                                        <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FaBox size={80} color="#e5b815" /> 
                                                        </div>
                                                        <div className="main-info tour-info">
                                                            <div className="main-title tour-title">

                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginTop:"25px",marginBottom:"30px" }}>
                                                          <p>
                                                          Whether you're planning a romantic getaway, a family vacation, or a group adventure, we offer a wide range of tailored packages to suit your needs. From luxury escapes to budget-friendly options, our packages are designed to cater to every traveller’s preferences and budget.                                                                                                                           
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                       <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Exceptional Customer Service</h3>
                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FaHeadset size={80} color="#e5b815" /> 
                                                        </div>
                                                        <div className="main-info tour-info">
                                                            <div className="main-title tour-title">

                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginTop:"3px",marginBottom:"30px" }}>
                                                          <p style={{fontSize:'14px'}}>
                                                          Our commitment to customer satisfaction is at the heart of everything we do. From the moment you reach out to us to the completion of your journey, our team is dedicated to providing exceptional customer service and support. We're here to address your questions, accommodate your requests, and ensure that your travel experience exceeds your expectations.
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #e5b815",minHeight:"390px" }}>
                                                       <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Seamless Travel Experience</h3>
                                                        <div  style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <FaRoad size={80} color="#e5b815" /> 
                                                        </div>
                                                        <div className="main-info tour-info">
                                                            <div className="main-title tour-title">

                                                            </div>
                                                        </div>
                                                        <div className="text-center" style={{ marginTop:"30px",marginBottom:"30px" }}>
                                                          <p style={{fontSize:'15px'}}>
                                                          With Malik Express Travel & Tours, you can enjoy a seamless travel experience from start to finish. From hassle-free booking processes to expertly crafted itineraries and on-the-ground support, we take care of every detail so you can focus on creating unforgettable memories.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        
                                    </Box>
                                </Box>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}

export default FlightOffers;