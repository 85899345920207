import React from 'react'
import './umrahcity.css'

const UmrahCitySection = () => {
  return (
    <div>
       <div className="umh-city-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="umh-heading">
                            <h2>Get Your Customized Umrah Package from Your Own City</h2>
                            <p>Embark on a spiritually enriching journey with ease and convenience! At Malik Express Travel & Tours, we offer customized Umrah packages tailored to your needs, all starting from your very own city. Enjoy hassle-free travel arrangements, including flights, accommodations, and guided tours, ensuring a seamless and fulfilling Umrah experience. Let us take care of the details so you can focus on your spiritual journey.</p>
                        </div>
                        <ul className="umh-city-list">
                            <li>
                                <a href="/Lahore">
                                    <figure><img src="images/PckgIcon/lahore.png " alt="lahore" /></figure>
                                    <strong>Lahore</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Karachi'>
                                    <figure><img src="images/PckgIcon/karachi 2.png" alt="karachi" /></figure>
                                    <strong>Karachi</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Islamabad'>
                                    <figure><img src="images/PckgIcon/islamabad.png " alt="islamabad" /></figure>
                                    <strong>Islamabad</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Quetta'>
                                    <figure><img src="images/PckgIcon/quetta.png " alt="quetta" /></figure>
                                    <strong>Quetta</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Peshawar'>
                                    <figure><img src="images/PckgIcon/peshawar.png " alt="peshawar" /></figure>
                                    <strong>Peshawar</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Multan'>
                                    <figure><img src="images/PckgIcon/multan.png " alt="multan" /></figure>
                                    <strong>Multan</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Faisalbad'>
                                    <figure><img src="images/PckgIcon/faisalabad.png " alt="faisalabad" /></figure>
                                    <strong>Faisalabad</strong>
                                </a>
                            </li>
                            <li>
                                <a href='/Sialkot'>
                                    <figure><img src="images/PckgIcon/sialkot.png " alt="sialkot" /></figure>
                                    <strong>Sialkot</strong>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default UmrahCitySection
