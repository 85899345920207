import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect,useRef } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';


function SchengenTrave() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const playerRef = useRef(null);

    useEffect(() => {
      // Load the YouTube Player API script
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  
      // Initialize YouTube player when API is ready
      window.onYouTubeIframeAPIReady = initPlayer;
  
      // Clean up function
      return () => {
        window.onYouTubeIframeAPIReady = null;
      };
    }, []);
  
    // Function to initialize YouTube player
    const initPlayer = () => {
      if (!playerRef.current) {
        playerRef.current = new window.YT.Player('youtube-player', {
          height: '400',
          width: '315',
          videoId: 'Qdbl4PnMYDQ',
          playerVars: {
            autoplay: 1,
            mute: 1
          },
          events: {
            onStateChange: onPlayerStateChange
          }
        });
      }
    };
    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        // Load a new video after current video ends
        playerRef.current.loadVideoById({
          videoId: 'Qdbl4PnMYDQ',
          startSeconds: 0,
          suggestedQuality: 'hd720'
        });
      }
    };
    return (
<>
<section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
              <p style={{fontWeight:'bold',color:'#fff'}}>Explore Europe with Confidence</p>
                <h1 style={{fontWeight:'bold',color:'#fff'}}>
                Online Travel Insurance for Schengen Visa from Pakistan
                </h1>
              <hr  style={{borderColor:'#fff'}} className="heading-line" />
              <br />
              <blockquote style={{color:'white'}}>
              Travel Insurance for Schengen Visa is a mandatory requirement for individuals traveling to the Schengen Area in Europe. It provides essential coverage for medical emergencies, repatriation, and other unforeseen events during your trip, ensuring that you meet the visa requirements and travel safely within the Schengen Zone. At Malik Express Travel & Tours, we offer specialized Travel Insurance plans tailored to the specific needs of Schengen Visa applicants, providing comprehensive coverage and peace of mind for your European adventures.
             
              </blockquote>
            </div>
            </div>
          </div>
        </div>
      </section>


      <section>
            
            
            <div className="plan-800 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center">
        <div className="col-md-7  order-2 order-md-1">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Who Benefits from Travel Insurance Plans for Schengen Visa?</h2>
                <blockquote>

                Our Travel Insurance for Schengen Visa plans are ideal for individuals applying for Schengen Visas for tourism, business, or other purposes. Whether you're planning a leisurely vacation in Paris, embarking on a business trip to Berlin, or exploring the historic landmarks of Rome, our insurance coverage ensures that you're protected against unexpected emergencies and incidents during your time in the Schengen Area.                      
                      </blockquote>
            </div>
            
        </div>
    
        
        <div className="col-md-4 order-1 order-md-2" >
            <div>
       
    <div className="planimg" id="youtube-player" />

            </div>
        </div>
           
       
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>

<section className="plan-800 pt-xs-20 ">
        <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Key Features of International Travel Insurance Plans</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="container">
    <div className="row justify-content-center">
        <div className="grid grid-cols-12 gap-4 md:gap-6 py-[1.875rem] md:pt-10 md:pb-0">
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Medical Coverage.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Medical Coverage</h3>
      <p className="text-14 leading-1-4 md:text-16">Ensure that you have access to quality medical care and treatment in the event of illness or injury during your trip to Europe.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/repartation.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Repatriation</h3>
      <p className="text-14 leading-1-4 md:text-16">Cover the cost of repatriation to your home country in case of serious illness, injury, or death while traveling in the Schengen Zone.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Trip Interruptions.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Trip Interruptions</h3>
      <p className="text-14 leading-1-4 md:text-16">Protect against unexpected trip interruptions, cancellations, or delays due to unforeseen circumstances.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/Emergency Assistance.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Emergency Assistance</h3>
      <p className="text-14 leading-1-4 md:text-16">Access 24/7 emergency assistance services, including medical consultations, emergency evacuations, and travel advice, for added peace of mind.</p>
    </div>

    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="./images/schengen visa.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Schengen Visa Compliance</h3>
      <p className="text-14 leading-1-4 md:text-16">Meet the visa requirements by providing proof of Travel Insurance coverage with your Schengen Visa application.</p>
    </div>
    </div>
    </div>
</div>


                    </div>

                </div>
            </div>
</section>


        <section>
            
            
            <div className="plan-80 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
<div className="col-md-5" >
            <div>
            <img
                                            src="../images/why choose us.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
        </div>
        <div className="col-md-7">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Why Choose Malik Express Travel & Tours?</h2>
                <blockquote>

                At Malik Express Travel & Tours, we specialize in providing reliable and comprehensive Travel Insurance solutions for Schengen Visa applicants. With our easy online purchase process and competitive premiums, you can obtain the necessary insurance coverage quickly and conveniently from Pakistan, ensuring a smooth and hassle-free travel experience in Europe. Travel with confidence and explore Europe's enchanting destinations knowing that you're protected by Malik Express Travel & Tours every step of the way.
                      </blockquote>
            </div>
            
        </div>
     
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>
        </>

    );

}



export default SchengenTrave;