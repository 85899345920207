import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import { FaPlus, FaMinus, FaTimes, FaEnvelope } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBInput,
    MDBRadio,
    MDBRow,
} from "mdb-react-ui-kit";
import { Button } from "antd";
import { FaAngleDown } from 'react-icons/fa';
import { Quickreply } from "@mui/icons-material";
import CryptoJS from 'crypto-js';

function SelectPaymntUniversal() {
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [timeout, setTimeout] = useState('');
    const [jazzcash, setJazzcash] = useState('')
    const [kuickpay, setkuickpay] = useState('')
    const [openSection, setOpenSection] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const {formvalue, United, Premiumplus, passengertype, arrivalDate, departureDate, plan, dob,BookingID } = location.state || {};
    const [selectedPayment, setSelectedPayment] = useState('jazzcash');
    const [paymentDetails, setPaymentDetails] = useState({});
    const [paymentDetail, setPaymentDetail] = useState({});
console.log(United)


    const payment = async () => {
        try {
            if (formvalue.First_name && passengertype && Premiumplus && Premiumplus && BookingID) {
            const response = await axios.get(`${apiUrl}/api/PaymentGateway/payment?PaxName=${formvalue.First_name}&GrandTotal=${((passengertype === 'family' ? Premiumplus : Premiumplus))}&BookingID=${BookingID}`);
            const fetchedPaymentDetails = response.data;
           
            setPaymentDetail(fetchedPaymentDetails); 
            return true;
            }
            else {
                setTimeout(payment(), 1000); // Retry after 1 second if conditions are not met
                return false; // Indicate that Kuickpay is not successful yet
              }
        } catch (error) {
           
        }
    };
    const GrandTotal=   ((passengertype === 'family' ? Premiumplus : Premiumplus) * 0.85).toFixed(0)

   const today = new Date();
   const year = today.getFullYear();
   const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
   const day = String(today.getDate()).padStart(2, '0');
   
   const currentDate = `${year}-${month}-${day}`;
    const Kuickpay = async () => {
        try {
            if (BookingID && GrandTotal && formvalue.Mobile_no && formvalue.Email) {
            const response = await axios.post(' https://testcheckout.kuickpay.com/api/KPToken', {
                institutionID: '01234',
                kuickpaySecuredKey: 'xWX+A8qbYkLgHf3e/pu6PZiycOGc0C/YXOr3XislvxI='
               //live  z/Em87zZ9tOUsx9VJWR5shqpwD3eygxm30+1kEyVbnQ=
                // test xWX+A8qbYkLgHf3e/pu6PZiycOGc0C/YXOr3XislvxI=
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const authToken = response.data.auth_token;
            const payload = {
                OrderID: BookingID,
                MerchantName: 'Malik Air Express',
                Amount: Premiumplus,
                TransactionDescription: 'wellcome',
                CustomerMobileNumber: formvalue.Mobile_no,
                CustomerEmail: formvalue.Email,
                SuccessUrl: 'http://localhost:3000/ConfirmedPayment/',
                FailureUrl: 'http://localhost:3000/ConfirmedPayment/',
                OrderDate: currentDate,
                CheckoutUrl: 'http://localhost:3000/ConfirmedPayment/',
                Token: authToken,
                GrossAmount: '0',
                TaxAmount: '0',
                Discount: '100',
                InstitutionID: '01234'
            };
            const signature = CryptoJS.MD5( '01234' + BookingID + Premiumplus + 'xWX+A8qbYkLgHf3e/pu6PZiycOGc0C/YXOr3XislvxI=').toString();
            payload.Signature = signature;
            
            setPaymentDetails(payload);
            return true;
         } else {
                setTimeout(Kuickpay, 1000); 
                return false; 
              }
        } catch (error) {
            //console.error('Error:', error.message);
        }
    };
    
    useEffect(() => {
        payment();
       Kuickpay();
   }, [selectedPayment]);

    useEffect(() => {
        setTimeout(() => {
          
            setIsLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onPrevious = () => {
        navigate('/TravelInsurance_Booking', { state: { United } });
    }

    const onPrevious1 = () => {
        navigate('/Beneficiary_Details', { state: { United } });
    }

    const onPrevious2 = () => {
        navigate('/Review_Universal', { state: {formvalue, United, Premiumplus, passengertype, arrivalDate, departureDate, plan, dob,BookingID   } });
    }

    const handleJazzcash =  async () => {
        const kuickpaySuccess = await payment(); // Wait for Kuickpay to complete
        if (kuickpaySuccess) {
        document.getElementById("FormPGS").submit();
    } else {
        // Handle the case where Kuickpay was not successful
        // Maybe show a message or retry Kuickpay
      }
    };

    const handleKuickpay = async () => {
        const kuickpaySuccess = await Kuickpay(); // Wait for Kuickpay to complete
        if (kuickpaySuccess) {
        document.getElementById("FormPG").submit();
    } else {
        // Handle the case where Kuickpay was not successful
        // Maybe show a message or retry Kuickpay
      }
    };
   


    const handleSectionClick = (sectionId, event) => {
        // Prevent the default behavior of anchor tag
        event.preventDefault();
        // Toggle the openSection state
        setOpenSection(openSection === sectionId ? null : sectionId);
    };
    return (
        <>
            <Helmet>
                <title>{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>
                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (
                <section className="innerpage-wrapper">
                    <div className="card">
                        <div className="tab-navigation">
                            <div id="travel-tabs">
                                <ul className="nav2 nav-tabs t-payment-tab" role="tablist">
                                    <li className="p-info-li">
                                        <a role="tab" onClick={onPrevious} className="cartTabBtn">
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Personal Details</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="s-no">
                                        <a role="tab" className="cartTabBtn" onClick={onPrevious1} data-toggle="tab">
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Beneficiary Details</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="review-info-li">
                                        <a role="tab" onClick={onPrevious2} className="cartTabBtn" >
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Review Information</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a role="tab" className="cartTabBtn">
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Select Payment Option</h5>
                                            </div>
                                        </a>
                                    </li>
                                  
                                </ul>
                            </div>
                        </div>
                    </div>

                    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#eee" }}>
                        <MDBRow className="d-flex justify-content-center " style={{ marginTop: '20px' }}>
                            <MDBCol md="8" lg="6" xl="7">
                                <MDBCard className="rounded-3">
                                    <MDBCardBody className="mx-1 my-2">
                                        <h2 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>Payment Method</h2>

                                        <div style={{ display: 'ruby-text' }}>
                                            <div className="d-flex pb-3 col-md-8 ">
                                                <div className="rounded border d-flex justify-content-between align-items-center w-100 p-3">
                                                    <div className="row pl-2">
                                                        <input
                                                            type="radio"
                                                            style={{ borderRadius: '50%', width: '1.25rem', height: '1.25rem', marginTop: '0.125em', marginRight: '4px' }}
                                                            name="radioNoLabel"
                                                            id="jazzcash"
                                                            checked={selectedPayment === 'jazzcash'}
                                                            onChange={() => setSelectedPayment('jazzcash')}
                                                        />
                                                        <p className="mb-0 pl-1">
                                                            Pay via Credit/Debit Card
                                                        </p>
                                                    </div>
                                                    <img src="../images/Jazz.svg" alt='payment' style={{ width: '95px', marginRight: '10px' }} />
                                                </div>
                                            </div>
                                            <div className="d-flex pb-3 col-md-8 ">
                                                <div className="rounded border d-flex justify-content-between align-items-center w-100 p-3">
                                                    <div className="row pl-2">
                                                        <input
                                                            type="radio"
                                                            style={{ borderRadius: '50%', width: '1.25rem', height: '1.25rem', marginTop: '0.125em', marginRight: '4px' }}
                                                            name="radioNoLabel"
                                                            id="kuickpay"
                                                            checked={selectedPayment === 'kuickpay'}
                                                            onChange={() => setSelectedPayment('kuickpay')}
                                                        />
                                                        <p className="mb-0">
                                                            {" "} KuickPay
                                                        </p>
                                                    </div>
                                                    <img src="../images/KuickPay.svg" alt='payment' style={{ width: '90px', transform: 'rotate(-18deg)' }} />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="center2 pt-5 pb-5 ml-2">


                                            {selectedPayment === 'jazzcash' ? (
                                                <PaymentForms paymentDetail={paymentDetail} />
                                            ) : selectedPayment === 'kuickpay' ? (
                                                <PaymentForm paymentDetails={paymentDetails} />
                                            ) : null}
                                            <button className="btn btn-orange" size="lg" onClick={selectedPayment === 'jazzcash' ? handleJazzcash : selectedPayment === 'kuickpay' ? handleKuickpay : null}>
                                                Pay amount
                                            </button>
                                           
                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="8" lg="6" xl="3">
                                <MDBCard className="rounded-3">
                                    <MDBCardBody className="mx-1 my-2">
                                        <div className="tumb">
                                            <img
                                                className="lozad"
                                                src="../images/CSI-Logo.png"
                                                alt="Habib Insurance Gold - Worldwide"
                                            />
                                        </div>


                                        {United && United.length > 0 ? (
                                                    <div className="containerrs">
                                                        <div className="left-corner">
                                                            Plan:
                                                        </div>
                                                        <div className="right-corner">
                                                            {plan}
                                                        </div>

                                                        <div className="left-corner">
                                                            Coverage Upto:
                                                        </div>
                                                        <div className="right-corner">
                                                            {`${United[0].Duration} days`}
                                                        </div>

                                                        <div className="left-corner">
                                                            Premium:
                                                        </div>
                                                        <div className="right-corner">
                                                            <p className="pricce">
                                                            <b>{`Rs.${Premiumplus}`}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                    </MDBCardBody>
                                </MDBCard>
                                <MDBCard className="rounded-3">
                                    <MDBCardBody>
                                        <div style={{ paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                                            <a
                                                data-toggle="collapse"
                                                href="#collapse1"
                                                style={{ textAlign: 'left', color: '#000' }}
                                                onClick={(event) => handleSectionClick("collapse1", event)}
                                            >

                                                <h2 className="black semibold" style={{ fontSize: 18 }}>
                                                    How will I get the policy?
                                                    <FaAngleDown style={{ float: 'right', marginTop: 0, fontSize: 20, }} />
                                                </h2>
                                            </a>
                                            <div id="collapse1" className={`panel-collapse collapse ${openSection === "collapse1" ? "show" : ""}`}>

                                                <p style={{ textAlign: 'left', color: '#000' }}>
                                                Once you have successfully purchased travel insurance, your policy will be promptly delivered to you electronically via email. This comprehensive document will encompass all pertinent details of your coverage, including policy limits, terms, and contact information for assistance. Should you prefer a hard copy of the policy or delivery via alternative platforms such as WhatsApp, please feel free to contact our dedicated support team, and we will accommodate your request promptly.                                                </p>

                                            </div>
                                        </div>
                                        <div style={{ paddingTop: '10px', }}>

                                            <a
                                                data-toggle="collapse"
                                                href="#collapse2"
                                                style={{ textAlign: 'left', color: '#000' }}
                                                onClick={(event) => handleSectionClick("collapse2", event)}
                                            >
                                                <h2 className="black semibold" style={{ fontSize: 18 }}>
                                                How do I know the policy issued is genuine?
                                                    <FaAngleDown style={{ float: 'right', marginTop: 0, fontSize: 20, }} />
                                                </h2>
                                            </a>
                                            <div id="collapse2" className={`panel-collapse collapse ${openSection === "collapse2" ? "show" : ""}`}>

                                                <p style={{ textAlign: 'left', color: '#000' }}>
                                                Rest assured, all policies issued by Malik Express Travel & Tours are authentic and recognized. You can verify the authenticity of your policy by cross-checking the details provided with our records. Additionally, you can reach out to our dedicated customer support team for any clarification or assistance regarding your policy.                                                </p>

                                            </div>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>
            )}
            <Footer></Footer>
        </>
    );
}

const PaymentForm = ({ paymentDetails }) => {
    return (
        <form id="FormPG" action="https://testcheckout.kuickpay.com/api/Redirection" method="POST">
            {Object.keys(paymentDetails).map((key) => {
                const fieldName = key;
                const fieldValue = paymentDetails[key];
                return (
                    <input
                        key={fieldName}
                        type="hidden"
                        id={fieldName}
                        name={fieldName}
                        value={fieldValue}
                    />
                );
            })}
        </form>
    );
};

const PaymentForms = ({ paymentDetail }) => {
    return (
        <form id="FormPGS" action="https://payments.jazzcash.com.pk/CustomerPortal/transactionmanagement/merchantform" method="POST">
            {Object.keys(paymentDetail).map((key) => {
                const fieldName = key;
                const fieldValue = paymentDetail[key];
                return (
                    <input
                        key={fieldName}
                         type="hidden"
                        id={fieldName}
                        name={fieldName}
                        value={fieldValue}
                    />
                );
            })}
        </form>
    );
};

export default SelectPaymntUniversal;
