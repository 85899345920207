import React, { useState, useEffect, useRef } from "react";
import SearchBarMain from "../Layout/SearchBar";
import { useLocation } from "react-router-dom";
import GalileoFlights from "./GalileoFlights";
import FlightFilters from "./FlightFilters";
import Hitit from "./Hitit";
import '../../CustomCss.css';
import MainMenu from "../Layout/MainMenu";
import Footer from '../Home/Footer';
//import axios from 'axios';

function SearchResult() {
  const apiUrl = process.env.REACT_APP_API_URL;
  var SearchType = 1;

  const [childAData, setChildAData] = useState(null);

  const onDataAvailableInChildA = (props) => {
    setChildAData(props);
  };

  const [selectedComponent, setSelectedComponent] = useState(null);
  const [selectedFlight, setSelectedFlight] = useState(null);

  const onSelectFlight = (component) => {
    setSelectedComponent(component);
    // setSelectedFlight(flightId);
  };

  const CardHeader = {
    backgroundColor: 'rgba(0,0,0,.03)'
  };

  const SelectedCardHeader = {
    backgroundColor: 'skyblue'
  };
  const location = useLocation();
  const searchData = location.state.props;

  const SearchBarValues={
    departureCity: searchData.departureCity,
    arrivalCity: searchData.arrivalCity,
    StartDate: searchData.StartDate,
    EndDate: searchData.EndDate,
    Adults: searchData.Adults,
    Children: searchData.Children,
    Infants: searchData.Infants,
    FlightType: searchData.FlightType,
  }

  const GalileoFlightsData = {
    FlightClass: searchData.FlightClass,
    isDisplayed: { childAData },

    FlightType: searchData.FlightType,
    departureCity: searchData.departureCity,
    arrivalCity: searchData.arrivalCity,
    StartDate: searchData.StartDate,
    EndDate: searchData.EndDate,
    Adults: searchData.Adults,
    Children: searchData.Children,
    Infants: searchData.Infants,
    MultiFirstDepartureCity: searchData.MultiFirstDepartureCity,
    MultiFirstArrivalCity: searchData.MultiFirstArrivalCity,
    MultiSecondDepartureCity: searchData.MultiSecondDepartureCity,
    MultiSecondArrivalCity: searchData.MultiSecondArrivalCity,
    MultiThirdDepartureCity: searchData.MultiThirdDepartureCity,
    MultiThirdArrivalCity: searchData.MultiThirdArrivalCity,
    MultiFourthDepartureCity: searchData.MultiFourthDepartureCity,
    MultiFourthArrivalCity: searchData.MultiFourthArrivalCity,
    MultiFifthDepartureCity: searchData.MultiFifthDepartureCity,
    MultiFifthArrivalCity: searchData.MultiFifthArrivalCity,
    MultiSixthDepartureCity: searchData.MultiSixthDepartureCity,
    MultiSixthArrivalCity: searchData.MultiSixthArrivalCity,
    StartMultiFirstDate: searchData.StartMultiFirstDate,
    StartMultiSecondDate: searchData.StartMultiSecondDate,
    StartMultiThirdDate: searchData.StartMultiThirdDate,
    StartMultiFourthDate: searchData.StartMultiFourthDate,
    StartMultiFifthDate: searchData.StartMultiFifthDate,
    StartMultiSixthDate: searchData.StartMultiSixthDate,

    // Add your additional data properties here
  };

  const [stopsData, setStopsData] = useState([]);
  const [carriersData, setCarriersData] = useState([]);

  
  const [stopsHitItData, setHitItStopsData] = useState([]);
  const [carriersHitItData, setHitItCarriersData] = useState([]);
  const [selectedStops, setSelectedStops] = useState({});
  const [selectedCarriers, setSelectedCarriers] = useState({});

  const updateHitItStopsData = (stops) => {
    setHitItStopsData(stops);
  };

  const updateHitItCarriersData = (carriers) => {
   // debugger
    setHitItCarriersData(carriers);

  };
  // Callback functions to update stopsData and carriersData from ChildComponent1 and ChildComponent2
  const updateStopsData = (stops) => {
    const hitdata=stopsHitItData;
    let combinedData = stops;
    // .concat("0");
    combinedData = [...new Set(combinedData)].sort();
    setStopsData(combinedData);
  };

  const updateCarriersData = (carriers) => {
    //debugger
    let combinedData;
    if(childAData!==null)
    {
     combinedData = carriers.concat("PK");
    }
    else{
      combinedData = carriers;
    }
    combinedData = [...new Set(combinedData)].sort();
    setCarriersData(combinedData);
  };

 

  // Callback function to update selectedCheckboxes from ChildComponent3
  const updateSelectedStops = (selected) => {
    setSelectedStops(selected);
  };
  const updateSelectedCarriers = (selected) => {
    setSelectedCarriers(selected);
  };
  const mystyle = {
    background:
      "linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(images/malikexpress_background.jpg) 50% 0%",
    backgroundsize: "contain",
  };
  return (
    <>
      <MainMenu></MainMenu>
      <section className="flexslider-container" id="flexslider-container-2" style={{paddingTop:'20px'}}>
      <div className="pb-5">
        <div className="card SearchBarCSS" style={{ margin: "40px 100px 0px 100px" }}>
          <SearchBarMain SearchBarValues={SearchBarValues}></SearchBarMain>
        </div>

        <FlightFilters stopsData={stopsData}
          carriersData={carriersData}
          selectedStops={selectedStops}
          selectedCarriers={selectedCarriers}
          updateSelectedStops={updateSelectedStops}
          updateSelectedCarriers={updateSelectedCarriers}></FlightFilters>
      </div>
</section>
      <div id="flight-listings" className="innerpage-section-padding">
        <div className="NoMargin" style={{margin: "10px 100px 0px"}}>
          {selectedComponent === null ? (
            <>
              <Hitit props={GalileoFlightsData} onDataAvailable={onDataAvailableInChildA} isDisplayed={childAData}
                onSelectFlight={onSelectFlight} updateHitItStopsData={updateHitItStopsData} updateHitItCarriersData={updateHitItCarriersData} selectedStops={selectedStops} selectedCarriers={selectedCarriers}
              ></Hitit>

              <GalileoFlights GalileoFlightsData={GalileoFlightsData} isDisplayed={childAData} onSelectFlight={onSelectFlight} updateStopsData={updateStopsData} updateCarriersData={updateCarriersData} selectedStops={selectedStops} selectedCarriers={selectedCarriers}></GalileoFlights>
           
            </>
          ) : (
            <>
              {selectedComponent === "A" ? (
                <> 
                <Hitit props={GalileoFlightsData} onDataAvailable={onDataAvailableInChildA} isDisplayed={childAData}
                  onSelectFlight={onSelectFlight} updateHitItStopsData={updateHitItStopsData} updateHitItCarriersData={updateHitItCarriersData}
                ></Hitit>
                </>
              ) : (
                <>
                <GalileoFlights GalileoFlightsData={GalileoFlightsData} isDisplayed={childAData} onSelectFlight={onSelectFlight} updateStopsData={updateStopsData} updateCarriersData={updateCarriersData}></GalileoFlights>
                </>
              )}
            </>
          )}

        </div>
      </div>
      <Footer></Footer>
    </>
  );
}
export default SearchResult;
