import React, { forwardRef } from 'react'

const TwentyEightPackage = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>28 Days Umrah Packages</h2>
                    <p>Dedicate a significant period to your spiritual journey with our 28 Days Umrah Packages. This package offers a full month of spiritual enrichment, with ample time to perform Umrah, participate in religious activities, and explore the rich history of Makkah and Madinah. Our all-inclusive package covers Umrah visa processing, flights, top-tier accommodations, and efficient transportation. With a detailed itinerary that includes visits to major religious sites and participation in spiritual lectures, you will have the opportunity to connect deeply with your faith. The 28-day package is ideal for those seeking a comprehensive and life-changing pilgrimage experience, allowing for profound reflection and devotion.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default TwentyEightPackage;