
import React, { useParams } from "react";
import { Helmet } from 'react-helmet';
import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import MainMenu from "../Layout/MainMenu";
import Features from "../Home/Features";
import Footer from "../Home/Footer";
import moment from "moment";
import axios from "axios";

function Post()
{
    const location = useLocation();
    const [post, setPost] = useState([]);
    let tmp = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
    const [isLoading, setIsLoading] = useState(true);
    const baseImageUrl = "https://malikexpress.com/images/post/";
    const [resultMsg, setResultMsg] = useState('');

    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {

        setTimeout(() => {
          
            if (location.state !== null) {
                setPost(location.state.props);
               // console.log(post);
                        }
                        else {
                            let tmp = location.pathname.slice(location.pathname.lastIndexOf("/"), location.pathname.length);
                          //  const cName = tmp.split('-');
                            const url = apiUrl + "/api/Blog/post?term=" + tmp.replace('/', '');
                            // console.log(url);
                            axios
                                .get(url)
                                .then((response) => {
                                    const data = response.data;
                                    setPost(data);
                                     console.log(post);
                                })
                                .catch((error) => console.error("Error: ${error}"));
                
                        }
                        setIsLoading(false); // Set isLoading to false once the content is loaded
        }, 2000); // Adjust the duration as needed

    }, []);

    const [formData, setFormData] = useState({
        txt_name: '',
        txt_email: '',
        txt_phone: '',
        txt_message: '',
      });

      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        try {
          const response = await axios.post( apiUrl + '/api/ContactUs/savemessage', formData);
          setResultMsg(response.data.message); // You can customize this based on your API response structure
          setFormData({
            txt_name: '',
            txt_email: '',
            txt_phone: '',
            txt_message: '',
          });
          alert("We have received your message. Our rep. will contact you soon. Thank you");
         
        } catch (error) {
          setResultMsg('An error occurred while sending the data.');
        }
      };

    return(
<>

<Helmet>
                <title >{"Visa Consultancy | e visa services | Visa Agents Pakistan"}</title>

                <meta name="description" content={"Get the best visa consultancy and e visa services at Malik Express Travel & Tours. Best visa consultants in Islamabad, Pakistan. Contact the best visa agents. "} data-react-helmet="true" />
                <meta name="keywords" content={"visa services, visa assistance, e visa from Pakistan, visa services in Pakistan, umrah e visa from Pakistan, visa services Pakistan, visa agents near me, visa services for Dubai, visa agents, e visa for Pakistani passport, agents for visa, visa agents in Islamabad, visa agents in Pakistan, consultant visa, visa consultancy services, visa consultants in Pakistan, visa consultants Islamabad, visa consultants near me, visa consultancy near me, visa consultancy, consultancy for visa, best visa consultants in Pakistan, visa consultant Pakistan, visa consultation, visa for umrah from Pakistan"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/Blog/Post" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}

            {!isLoading && (
                 <div>
                    <section class="page-cover" id="cover-blog-details">
            <div class="container">
                <div class="row">
                    <div class="col-sm">
                        <h1 class="page-title">Malik Express</h1>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">Home</li>
                            <li class="breadcrumb-item">Post</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>

        {!post ? <></> : post.map((p) => ( 
        <section class="innerpage-wrapper">
            <div id="blog-details" class="innerpage-section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-9 col-xl-9 content-side">
                            <div class="space-right">

                                <div class="blog-post">
                                    <div class="main-img blog-post-img">
                                        <img src={`${baseImageUrl}${p.DefaultImage}`} class="img-fluid" alt="blog-post" />
                                        <div class="main-mask blog-post-info">
                                            <ul class="list-inline list-unstyled blog-post-info">
                                                <li class="list-inline-item list-inline-item"><span><i
                                                            class="fa fa-calendar"></i></span>{moment(p.PublishedDate).format("DD-MMMM-yyyy")}</li>
                                                <li class="list-inline-item list-inline-item"><span><i
                                                            class="fa fa-user"></i></span>By: {p.Author}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div class="blog-post-detail">
                                        <h2 class="blog-post-title">{p.Title}</h2>
                                        <div dangerouslySetInnerHTML={{__html: p.Description}} />                                        
                                    </div>
                                </div>

                     

                            </div>
                        </div>

                        <div class="col-12 col-md-12 col-lg-3 col-xl-3 side-bar blog-sidebar right-side-bar">
                            
                        <div className="row">                             

<div className="col-12 col-md-6 col-lg-12">
  
        <h2 className="side-bar-heading">Contact Form</h2>

        <div className="contact-form">
        <form id="frm_contact" name="frm_contact" onSubmit={handleSubmit}>

<div className="form-group">
<input type="text" className="form-control" placeholder="Name" name="txt_name"  value={formData.txt_name}  onChange={handleChange}
id="txt_name" />
</div>

<div className="form-group">
<input type="email" className="form-control" placeholder="Email" name="txt_email" value={formData.txt_email}  onChange={handleChange}
id="txt_email" />
</div>

<div className="form-group">
<input type="text" className="form-control" placeholder="Phone Number" name="txt_phone" value={formData.txt_phone}  onChange={handleChange}
id="txt_phone" />
</div>

<div className="form-group">
<textarea className="form-control" rows="4" placeholder="Your Message"  value={formData.txt_message}  onChange={handleChange}
name="txt_message" id="txt_message"></textarea>
</div>
<div className="col-md-12 text-center" id="result_msg"></div>
<button name="submit" id="submit" className="btn btn-orange btn-block">Send</button>
</form>
<br />
<br />                                                                        

    </div>
</div>


<div className="col-12 col-md-6 col-lg-12">
    <div className="side-bar-block contact">
        <h2 className="side-bar-heading">Contact Us</h2>
        <div className="c-list">
            <div className="icon"><span><i className="fa fa-envelope"></i></span></div>
            <div className="text">
                <p>info@malikexpress.com</p>
            </div>
        </div>
        <div className="c-list">
            <div className="icon"><span><i className="fa fa-phone"></i></span></div>
            <div className="text">
                <p>(051) 111 77 88 00</p>
            </div>
        </div>

        <div className="c-list">
            <div className="icon"><span><i className="fa fa-map-marker"></i></span></div>
            <div className="text">
                <p>Office no.2, Blue Area, Islamabad</p>
            </div>
        </div>
    </div>
</div>
</div>

<div className="row">

<div className="col-12 col-md-6 col-lg-12">
    <div className="side-bar-block follow-us">
        <h2 className="side-bar-heading">Follow Us</h2>
        <ul className="list-unstyled list-inline">
        <li className="list-inline-item">
        <a href="https://www.facebook.com/malikexpress/">
            <span><i className="fa fa-facebook"></i></span>
        </a>
    </li>
    <li className="list-inline-item">
        <a href="https://twitter.com/malik_express">
            <span><i className="fa fa-twitter"></i></span>
        </a>
    </li>

    <li className="list-inline-item">
        <a href="https://www.pinterest.com/malikexpress/">
            <span><i className="fa fa-pinterest-p"></i></span>
        </a>
    </li>
    <li className="list-inline-item">
        <a href="https://www.instagram.com/malikexpress.isb/?igshid=YmMyMTA2M2Y%3D">
            <span><i className="fa fa-instagram"></i></span>
        </a>
    </li>
    <li className="list-inline-item">
        <a href="https://www.linkedin.com/in/malik-express-805888138/">
            <span><i className="fa fa-linkedin"></i></span>
        </a>
    </li>
    <li className="list-inline-item">
        <a href="https://www.youtube.com/channel/UCv5a82ncf9ZV2HxRAcvykUg">
            <span><i className="fa fa-youtube-play"></i></span>
        </a>
    </li>
        </ul>
    </div>
</div>

<div className="col-12 col-md-6 col-lg-12">
    <div className="side-bar-block tags">
        <h2 className="side-bar-heading">Tags</h2>
        <ul className="list-unstyled list-inline">
            <li className="list-inline-item">SPA
            </li>
            <li className="list-inline-item">Restaurant</li>
            <li className="list-inline-item">Searvices</li>
            <li className="list-inline-item">Wifi
            </li>
            <li className="list-inline-item">Tv
            </li>
            <li className="list-inline-item">Rooms
            </li>
            <li className="list-inline-item">Pools
            </li>
            <li className="list-inline-item">Work
            </li>
            <li className="list-inline-item">Sports
            </li>
        </ul>
    </div>
</div>

</div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        ))}

<Footer></Footer>
</div>
            )}
</>
    );
}

export default Post;