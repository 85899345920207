import React from "react";
import TextField from '@mui/material/TextField';
import Features from "../Home/Features";
import { Upload } from 'antd';
import Footer from "../Home/Footer";
import axios from "axios";
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MainMenu from "../Layout/MainMenu";
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {useNavigate,useLocation} from "react-router-dom";



function VisaPersonDetails() {

    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_URL;

    const [gender, setgender] = React.useState("");
    const [Employementvalue, setemployment] = React.useState("");
    const [DOB, setDOB] = React.useState();
    const [value, setValue] = useState("Select Country");
    const [birthcountry, setBirthCountryValue] = useState("Select Country");
    const [firstname, setFirstNameValue] = useState("");
   
    const [email, setEmailValue] = useState("");
    const [mobile, setMobileValue] = useState("");
    const [homeaddress1, setHomeAddressValue] = useState("");

    const [officeaddress1, setOfficeAddressValue] = useState("");
    const [officeaddress2, setOfficeAddress2Value] = useState("");
    const [officepostalcode, setOfficePostalCodeValue] = useState("");
    const [officecontact, setOfficeContactValue] = useState("");
    const [Officecity, setOfficeCityValue] = useState("");
    const [loading, setLoading] = useState(false);

    const [errorMessage, setErrorMessage] = useState('');
    const data = [ 
        {label: "Afghanistan", value: "Afghanistan"}, 
        {label: "land Islands", value: "landIslands"}, 
        {label: "Albania", value: "Albania"}, 
        {label: "Algeria", value: "Algeria"}, 
        {label: "American Samoa", value: "AmericanSamoa"}, 
        {label: "AndorrA", value: "AndorrA"}, 
        {label: "Angola", value: "Angola"}, 
        {label: "Anguilla", value: "Anguilla"}, 
        {label: "Antarctica", value: "Antarctica"}, 
        {label: "Antigua and Barbuda", value: "AntiguaandBarbuda"}, 
        {label: "Argentina", value: "AR"}, 
        {label: "Armenia", value: "AM"}, 
        {label: "Aruba", value: "AW"}, 
        {label: "Australia", value: "AU"}, 
        {label: "Austria", value: "AT"}, 
        {label: "Azerbaijan", value: "AZ"}, 
        {label: "Bahamas", value: "BS"}, 
        {label: "Bahrain", value: "BH"}, 
        {label: "Bangladesh", value: "BD"}, 
        {label: "Barbados", value: "BB"}, 
        {label: "Belarus", value: "BY"}, 
        {label: "Belgium", value: "BE"}, 
        {label: "Belize", value: "BZ"}, 
        {label: "Benin", value: "BJ"}, 
        {label: "Bermuda", value: "BM"}, 
        {label: "Bhutan", value: "BT"}, 
        {label: "Bolivia", value: "BO"}, 
        {label: "Bosnia and Herzegovina", value: "BA"}, 
        {label: "Botswana", value: "BW"}, 
        {label: "Bouvet Island", value: "BV"}, 
        {label: "Brazil", value: "BR"}, 
        {label: "British Indian Ocean Territory", value: "IO"}, 
        {label: "Brunei Darussalam", value: "BN"}, 
        {label: "Bulgaria", value: "BG"}, 
        {label: "Burkina Faso", value: "BF"}, 
        {label: "Burundi", value: "BI"}, 
        {label: "Cambodia", value: "KH"}, 
        {label: "Cameroon", value: "CM"}, 
        {label: "Canada", value: "CA"}, 
        {label: "Cape Verde", value: "CV"}, 
        {label: "Cayman Islands", value: "KY"}, 
        {label: "Central African Republic", value: "CF"}, 
        {label: "Chad", value: "TD"}, 
        {label: "Chile", value: "CL"}, 
        {label: "China", value: "CN"}, 
        {label: "Christmas Island", value: "CX"}, 
        {label: "Cocos (Keeling) Islands", value: "CC"}, 
        {label: "Colombia", value: "CO"}, 
        {label: "Comoros", value: "KM"}, 
        {label: "Congo", value: "CG"}, 
        {label: "Congo, The Democratic Republic of the", value: "CD"}, 
        {label: "Cook Islands", value: "CK"}, 
        {label: "Costa Rica", value: "CR"}, 
        {label: "Cote D\"Ivoire", value: "CI"}, 
        {label: "Croatia", value: "HR"}, 
        {label: "Cuba", value: "CU"}, 
        {label: "Cyprus", value: "CY"}, 
        {label: "Czech Republic", value: "CZ"}, 
        {label: "Denmark", value: "DK"}, 
        {label: "Djibouti", value: "DJ"}, 
        {label: "Dominica", value: "DM"}, 
        {label: "Dominican Republic", value: "DO"}, 
        {label: "Ecuador", value: "EC"}, 
        {label: "Egypt", value: "EG"}, 
        {label: "El Salvador", value: "SV"}, 
        {label: "Equatorial Guinea", value: "GQ"}, 
        {label: "Eritrea", value: "ER"}, 
        {label: "Estonia", value: "EE"}, 
        {label: "Ethiopia", value: "ET"}, 
        {label: "Falkland Islands (Malvinas)", value: "FK"}, 
        {label: "Faroe Islands", value: "FO"}, 
        {label: "Fiji", value: "FJ"}, 
        {label: "Finland", value: "FI"}, 
        {label: "France", value: "FR"}, 
        {label: "French Guiana", value: "GF"}, 
        {label: "French Polynesia", value: "PF"}, 
        {label: "French Southern Territories", value: "TF"}, 
        {label: "Gabon", value: "GA"}, 
        {label: "Gambia", value: "GM"}, 
        {label: "Georgia", value: "GE"}, 
        {label: "Germany", value: "DE"}, 
        {label: "Ghana", value: "GH"}, 
        {label: "Gibraltar", value: "GI"}, 
        {label: "Greece", value: "GR"}, 
        {label: "Greenland", value: "GL"}, 
        {label: "Grenada", value: "GD"}, 
        {label: "Guadeloupe", value: "GP"}, 
        {label: "Guam", value: "GU"}, 
        {label: "Guatemala", value: "GT"}, 
        {label: "Guernsey", value: "GG"}, 
        {label: "Guinea", value: "GN"}, 
        {label: "Guinea-Bissau", value: "GW"}, 
        {label: "Guyana", value: "GY"}, 
        {label: "Haiti", value: "HT"}, 
        {label: "Heard Island and Mcdonald Islands", value: "HM"}, 
        {label: "Holy See (Vatican City State)", value: "VA"}, 
        {label: "Honduras", value: "HN"}, 
        {label: "Hong Kong", value: "HK"}, 
        {label: "Hungary", value: "HU"}, 
        {label: "Iceland", value: "IS"}, 
        {label: "India", value: "IN"}, 
        {label: "Indonesia", value: "ID"}, 
        {label: "Iran, Islamic Republic Of", value: "IR"}, 
        {label: "Iraq", value: "IQ"}, 
        {label: "Ireland", value: "IE"}, 
        {label: "Isle of Man", value: "IM"}, 
        {label: "Israel", value: "IL"}, 
        {label: "Italy", value: "IT"}, 
        {label: "Jamaica", value: "JM"}, 
        {label: "Japan", value: "JP"}, 
        {label: "Jersey", value: "JE"}, 
        {label: "Jordan", value: "JO"}, 
        {label: "Kazakhstan", value: "KZ"}, 
        {label: "Kenya", value: "KE"}, 
        {label: "Kiribati", value: "KI"}, 
        {label: "Korea, Democratic People\"S Republic of", value: "KP"}, 
        {label: "Korea, Republic of", value: "KR"}, 
        {label: "Kuwait", value: "KW"}, 
        {label: "Kyrgyzstan", value: "KG"}, 
        {label: "Lao People\"S Democratic Republic", value: "LA"}, 
        {label: "Latvia", value: "LV"}, 
        {label: "Lebanon", value: "LB"}, 
        {label: "Lesotho", value: "LS"}, 
        {label: "Liberia", value: "LR"}, 
        {label: "Libyan Arab Jamahiriya", value: "LY"}, 
        {label: "Liechtenstein", value: "LI"}, 
        {label: "Lithuania", value: "LT"}, 
        {label: "Luxembourg", value: "LU"}, 
        {label: "Macao", value: "MO"}, 
        {label: "Macedonia, The Former Yugoslav Republic of", value: "MK"}, 
        {label: "Madagascar", value: "MG"}, 
        {label: "Malawi", value: "MW"}, 
        {label: "Malaysia", value: "MY"}, 
        {label: "Maldives", value: "MV"}, 
        {label: "Mali", value: "ML"}, 
        {label: "Malta", value: "MT"}, 
        {label: "Marshall Islands", value: "MH"}, 
        {label: "Martinique", value: "MQ"}, 
        {label: "Mauritania", value: "MR"}, 
        {label: "Mauritius", value: "MU"}, 
        {label: "Mayotte", value: "YT"}, 
        {label: "Mexico", value: "MX"}, 
        {label: "Micronesia, Federated States of", value: "FM"}, 
        {label: "Moldova, Republic of", value: "MD"}, 
        {label: "Monaco", value: "MC"}, 
        {label: "Mongolia", value: "MN"}, 
        {label: "Montenegro", value: "ME"},
        {label: "Montserrat", value: "MS"},
        {label: "Morocco", value: "MA"}, 
        {label: "Mozambique", value: "MZ"}, 
        {label: "Myanmar", value: "MM"}, 
        {label: "Namibia", value: "NA"}, 
        {label: "Nauru", value: "NR"}, 
        {label: "Nepal", value: "NP"}, 
        {label: "Netherlands", value: "NL"}, 
        {label: "Netherlands Antilles", value: "AN"}, 
        {label: "New Caledonia", value: "NC"}, 
        {label: "New Zealand", value: "NZ"}, 
        {label: "Nicaragua", value: "NI"}, 
        {label: "Niger", value: "NE"}, 
        {label: "Nigeria", value: "NG"}, 
        {label: "Niue", value: "NU"}, 
        {label: "Norfolk Island", value: "NF"}, 
        {label: "Northern Mariana Islands", value: "MP"}, 
        {label: "Norway", value: "NO"}, 
        {label: "Oman", value: "OM"}, 
        {label: "Pakistan", value: "PK"}, 
        {label: "Palau", value: "PW"}, 
        {label: "Palestinian Territory, Occupied", value: "PS"}, 
        {label: "Panama", value: "PA"}, 
        {label: "Papua New Guinea", value: "PG"}, 
        {label: "Paraguay", value: "PY"}, 
        {label: "Peru", value: "PE"}, 
        {label: "Philippines", value: "PH"}, 
        {label: "Pitcairn", value: "PN"}, 
        {label: "Poland", value: "PL"}, 
        {label: "Portugal", value: "PT"}, 
        {label: "Puerto Rico", value: "PR"}, 
        {label: "Qatar", value: "QA"}, 
        {label: "Reunion", value: "RE"}, 
        {label: "Romania", value: "RO"}, 
        {label: "Russian Federation", value: "RU"}, 
        {label: "RWANDA", value: "RW"}, 
        {label: "Saint Helena", value: "SH"}, 
        {label: "Saint Kitts and Nevis", value: "KN"}, 
        {label: "Saint Lucia", value: "LC"}, 
        {label: "Saint Pierre and Miquelon", value: "PM"}, 
        {label: "Saint Vincent and the Grenadines", value: "VC"}, 
        {label: "Samoa", value: "WS"}, 
        {label: "San Marino", value: "SM"}, 
        {label: "Sao Tome and Principe", value: "ST"}, 
        {label: "Saudi Arabia", value: "SA"}, 
        {label: "Senegal", value: "SN"}, 
        {label: "Serbia", value: "RS"}, 
        {label: "Seychelles", value: "SC"}, 
        {label: "Sierra Leone", value: "SL"}, 
        {label: "Singapore", value: "SG"}, 
        {label: "Slovakia", value: "SK"}, 
        {label: "Slovenia", value: "SI"}, 
        {label: "Solomon Islands", value: "SB"}, 
        {label: "Somalia", value: "SO"}, 
        {label: "South Africa", value: "ZA"}, 
        {label: "South Georgia and the South Sandwich Islands", value: "GS"}, 
        {label: "Spain", value: "ES"}, 
        {label: "Sri Lanka", value: "LK"}, 
        {label: "Sudan", value: "SD"}, 
        {label: "Suriname", value: "SR"}, 
        {label: "Svalbard and Jan Mayen", value: "SJ"}, 
        {label: "Swaziland", value: "SZ"}, 
        {label: "Sweden", value: "SE"}, 
        {label: "Switzerland", value: "CH"}, 
        {label: "Syrian Arab Republic", value: "SY"}, 
        {label: "Taiwan, Province of China", value: "TW"}, 
        {label: "Tajikistan", value: "TJ"}, 
        {label: "Tanzania, United Republic of", value: "TZ"}, 
        {label: "Thailand", value: "TH"}, 
        {label: "Timor-Leste", value: "TL"}, 
        {label: "Togo", value: "TG"}, 
        {label: "Tokelau", value: "TK"}, 
        {label: "Tonga", value: "TO"}, 
        {label: "Trinidad and Tobago", value: "TT"}, 
        {label: "Tunisia", value: "TN"}, 
        {label: "Turkey", value: "TR"}, 
        {label: "Turkmenistan", value: "TM"}, 
        {label: "Turks and Caicos Islands", value: "TC"}, 
        {label: "Tuvalu", value: "TV"}, 
        {label: "Uganda", value: "UG"}, 
        {label: "Ukraine", value: "UA"}, 
        {label: "United Arab Emirates", value: "AE"}, 
        {label: "United Kingdom", value: "GB"}, 
        {label: "United States", value: "US"}, 
        {label: "United States Minor Outlying Islands", value: "UM"}, 
        {label: "Uruguay", value: "UY"}, 
        {label: "Uzbekistan", value: "UZ"}, 
        {label: "Vanuatu", value: "VU"}, 
        {label: "Venezuela", value: "VE"}, 
        {label: "Viet Nam", value: "VN"}, 
        {label: "Virgin Islands, British", value: "VG"}, 
        {label: "Virgin Islands, U.S.", value: "VI"}, 
        {label: "Wallis and Futuna", value: "WF"}, 
        {label: "Western Sahara", value: "EH"}, 
        {label: "Yemen", value: "YE"}, 
        {label: "Zambia", value: "ZM"}, 
        {label: "Zimbabwe", value: "Zimbabwe"} 
        ]
    
        const location = useLocation();
        const Datavisa = location.state.props;
        const country=Datavisa[0].country;
        const document=Datavisa[0].documents;
        const visaId=Datavisa[0].visaId;
       
        const [fileList, setFileList] = useState([]);


//Handle the values of the antd input

useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


    const handleEmployementChange = (event) => {
        setemployment(event.target.value);
    };

    const handleChange = (event) => {
        setgender(event.target.value);
    };
    const SetDOB = (newValue) => {
        setDOB(newValue);
      };
     
      function SendVisaRequest() {

        if (!firstname) {
            setErrorMessage('First Name is required');
            return;
          }
          if (!email) {
            setErrorMessage('Email is required');
            return;
          }
          if (!mobile) {
            setErrorMessage('Contact is required');
            return;
          }
          if (!birthcountry) {
            setErrorMessage('Contact is required');
            return;
          }

        setLoading(true);
        const formData = new FormData();
        formData.append('FirstName',firstname);
        formData.append('Email',email);
        formData.append('CountryId',country.Id);
        formData.append('VisaId',visaId);
        formData.append('Mobile',mobile);
        formData.append('Gender',gender);

        formData.append('DOB',DOB);
        formData.append('Birthcountry',birthcountry);
        formData.append('HomeAddress1',homeaddress1);

        formData.append('OfficeAddress1',officeaddress1);
        formData.append('OfficeAddress2',officeaddress2);
        formData.append('OfficePostalCode',officepostalcode);
        formData.append('OfficeContact',officecontact);
        formData.append('OfficeCity',Officecity);
        formData.append('OfficeCountry',value);

       
    fileList.forEach(file => {
        formData.append('files', file.originFileObj);
      });
        const axiosInstance = axios.create({
            baseURL: apiUrl + '/api/VisaRequest'
          });
          axiosInstance.post('/AddVisaRequest', formData,{ headers: {
            'Content-Type': 'multipart/form-data'
          }, onUploadProgress: (progressEvent) => {
        const progress = (progressEvent.loaded / progressEvent.total) * 100;
        console.log(`Upload progress: ${progress}%`);
      },})
          .then(response => {
            navigate('/VisaThankYou');
          })
          .catch(error => {
            debugger
            console.error(error);
          });
    }
  
    return (
        <>
            <MainMenu></MainMenu>
            <section>
                <form>
                <div className="mlk-row _3GWrpaw3wi45JODN9FOgTJ" style={{ rowGap: "0px",margin:"50px" }}>
                {errorMessage && <p>{errorMessage}</p>}
                   
                        <div className="mlk-col mlk-col-24">
                            <h1 className="mlk-typography font-normal font-normal text-xl mb-6 text-blue-6">Person Details</h1>
                        </div>
                        <div className="mlk-col mlk-col-24">
                            <div className="mlk-card mlk-card-bordered p-3">
                                <div className="mlk-card-body">
                                    <div id="contactDetailForm" className="mlk-form mlk-form-vertical mlk-form-hide-required-mark">
                                        <div className="" style={{ paddingLeft: "12.5px", paddingRight: "12.5px", rowGap: "10px" }}  >
                                          
                                            <TextField value={firstname} required
                                                onChange={event => setFirstNameValue(event.target.value)}
                                                id="FirstName" label="First Name" variant="outlined" className="w-100 mt-3" />

                                            <TextField id="Email" value={email} required
                                                onChange={event => setEmailValue(event.target.value)}
                                                label="Email" variant="outlined" className="w-100 mt-3" />
                                            <TextField id="outlined-basic" value={mobile} required
                                                onChange={event => setMobileValue(event.target.value)}
                                                label="Mobile Number" variant="outlined" className="w-100 mt-3" />

                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={3}>
                                                    <DesktopDatePicker
                                                        label="Date Of Birth"
                                                        inputFormat="MM/DD/YYYY"
                                                        value={DOB}
                                                        className="mt-5"
                                                        onChange={SetDOB}
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </Stack>
                                            </LocalizationProvider>
                                            <Autocomplete
                                                    id="Country Select"
                                                    options={data} required
                                                    value={birthcountry}
                                                    className="mt-5"
                                                    onChange={(event, newValue) => {
                                                        setBirthCountryValue(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} variant='standard' />}
                                                />

                                        </div>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mlk-col mlk-col-24 mt-5">
                            <h1 className="mlk-typography font-normal font-normal text-xl mb-6 text-blue-6">Address Details</h1>
                        </div>

                        <div className="mlk-col mlk-col-24">
                            <div className="mlk-card mlk-card-bordered p-3">
                                <div className="mlk-card-body">
                                    <div id="contactDetailForm" className="mlk-form mlk-form-vertical mlk-form-hide-required-mark">
                                    <div className="" style={{ paddingLeft: "12.5px", paddingRight: "12.5px", rowGap: "10px" }}  >
                                      
                                    <TextField id="HomeAddress" value={homeaddress1}
                                                    onChange={event => setHomeAddressValue(event.target.value)}
                                                    label="Home Address 1" variant="outlined" className="w-100 mt-5" />

                                            <FormControl fullWidth className="mt-5">
                                                <InputLabel id="EmployementSelect">Employement Selector</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={Employementvalue}
                                                    className="mt-3"
                                                    label="Employement"
                                                    onChange={handleEmployementChange}
                                                >
                                                    <MenuItem value={'Employed'}>Employed</MenuItem>
                                                    <MenuItem value={'UnEmployed'}>Un Employed</MenuItem>

                                                </Select>
                                            </FormControl>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Employementvalue === 'Employed' && (
                            <>
                                <div className="mlk-col mlk-col-24 mt-5">
                                    <h1 className="mlk-typography font-normal font-normal text-xl mb-6 text-blue-6">Employement Details</h1>
                                </div>

                                <div className="mlk-col mlk-col-24">
                                    <div className="mlk-card mlk-card-bordered p-3">
                                        <div className="mlk-card-body">
                                            <div id="contactDetailForm" className="mlk-form mlk-form-vertical mlk-form-hide-required-mark">
                                                <div className="row justify-content-between" style={{ paddingLeft: "12.5px", paddingRight: "12.5px", rowGap: "10px" }}  >
                                                    <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6" style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }} >
                                                        <TextField id="OfficeAddress" value={officeaddress1}
                                                            onChange={event => setOfficeAddressValue(event.target.value)}
                                                            label="Office Address 1" variant="outlined" className="w-100" />
                                                    </div>

                                                    <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6" style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }} >
                                                        <TextField id="OfficeAddress2" value={officeaddress2}
                                                            onChange={event => setOfficeAddress2Value(event.target.value)}
                                                            label="Office Address 2" variant="outlined" className="w-100" />
                                                    </div>

                                                    <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6" style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }} >
                                                        <TextField id="OfficeCity" value={Officecity}
                                                            onChange={event => setOfficeCityValue(event.target.value)}
                                                            label="City" variant="outlined" className="w-100" />
                                                    </div>
                                                </div>

                                                <div className="row justify-content-between mt-5" style={{ paddingLeft: "12.5px", paddingRight: "12.5px", rowGap: "10px" }}  >
                                                    <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6" style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }} >
                                                        <TextField id="PostalCode" value={officepostalcode}
                                                            onChange={event => setOfficePostalCodeValue(event.target.value)}
                                                            label="Postal Code" variant="outlined" className="w-100" />
                                                    </div>

                                                    <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6" style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }} >
                                                        <TextField id="PhoneNumber" value={officecontact}
                                                            onChange={event => setOfficeContactValue(event.target.value)}
                                                            label="Contact Number" variant="outlined" className="w-100" />
                                                    </div>

                                                    <div className="mt-5 mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6" style={{ paddingLeft: "12.5px", paddingRight: "12.5px" }} >
                                                        <Autocomplete
                                                            id="CitySelect"
                                                            options={data}
                                                            value={value}
                                                            onChange={(event, newValue) => {
                                                                setValue(newValue);
                                                            }}
                                                            renderInput={(params) => <TextField {...params} variant='standard' />}
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}

                        <div className="mlk-col mlk-col-24 mt-5">
                            <h1 className="mlk-typography font-normal font-normal text-xl mb-6 text-blue-6">Required Documents</h1>
                        </div>
                        <>
                            <div className="mlk-col mlk-col-24 ">
                                <div className="mlk-card mlk-card-bordered p-3">
                                    <div className="mlk-card-body">
                                        <div className="justify-content-center d-flex">
                                            <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-6 mlk-col-lg-6 ">
                                                {/* {document.map((doc, index) => (
                                                <div key={index}>
                                                    <div className="mt-2">
                                                        <label>Please Attach Your {doc.Name} Image</label>
                                                        <input type="file" name="myfiles[]"  onChange={handleImageChange(index,{Id:doc.Id,Name:doc.Name})}  />
                                                        <br />
                                                        <br />
                                                    </div>
                                                  
                                                </div>
                                            ))} */}
                                                <Upload
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onChange={({ fileList: newFileList }) => setFileList(newFileList)}
                                                    accept=" .jpg,.png,.pdf"
                                                // onPreview={handlePreview}
                                                >
                                                    {fileList.length <= document.length && '+ Upload'}
                                                </Upload>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="justify-content-center" style={{ color: "red", fontSize: "20px" }}>Please Upload the data in Following Formats:</div>
                                    <div className="justify-content-center" style={{ color: "red" }}>Upload Images in PNG or JPG format</div>
                                    <div className="justify-content-center" style={{ color: "red" }}>Upload other Files in pdf format</div>

                                    <div className="justify-content-center"style={{ color: "green" }}>
                                        Required Documents
                                        <div className="justify-content-center d-flex">
                                            <ol className="justify-content-center">
                                                 {document.map((doc, index) => (
                                                 <li className="">{doc.description}</li>
                                            ))}
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>

                        <div className="mlk-col mlk-col-24">
                            <div className="mlk-card mlk-card-bordered p-3 justify-content-center d-flex">
                                <button type="submit" className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base">

                                    <span role="img" aria-label="search" className="anticon anticon-search">
                                        <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                            <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                        </svg>
                                    </span>
                                    <span>  {loading ? 'Uploading...' : 'Send Details'}  </span>

                                </button>
                            </div>
                        </div>
                       
                </div>
                </form>
            </section>

            <Features></Features>
            <Footer></Footer>
        </>
    )
}
export default VisaPersonDetails;