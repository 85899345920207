
import React, {useEffect} from 'react';
import { Helmet } from 'react-helmet';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import LatestBlog from '../Home/LatestBlogs';
import Testimonials from '../Home/Testimonials';

//import axios from "axios";
import {Link, useLocation , useNavigate} from "react-router-dom";

function UmrahHotels(){
    const location = useLocation();
    const UmrahHotels = location.state.props;

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    //console.log(UmrahHotels);
    return (
<>
<Helmet>
                <title >{"Umrah Packages"}</title>
                
                <meta name="description" content={"Umrah Packages"} data-react-helmet="true" />
                <meta name="keywords" content={"Umrah Packages"} data-react-helmet="true" />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <MainMenu></MainMenu>
      <br></br>
      <br></br>

        <section>
            <div className='container'>
            <div className='p-2'>
                    <h1>Umrah Hotels in {UmrahHotels[0].C_Name}</h1>
                    </div>
                <div className='row border rounded justify-content-between'>
                    
                <div class="booking-item">
                {UmrahHotels.map((hotels) => (
                    <>
                        <div class="row mt-2">
                            <div class="col-md-3">
                                <div class="booking-item-img-wrap">
                                    <img img src={"../images/umarhahotels/" + hotels.Images} width="200" height={100} alt="umrah hotels" /><br />
                                    <span class="booking-item-last-booked">
                                   <b>{hotels.U_Hotels}</b>
                                </span>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <span class="booking-item-last-booked">
                                   <b>Category:</b> {hotels.Cat_Name}
                                </span>
                                <br />
                                <span class="booking-item-last-booked">
                                    <b>Location:</b> {hotels.Location}
                                </span>
                                <br />
                                <span class="booking-item-last-booked">
                                    <b>Distance:</b> {hotels.DistanceMeter}
                                </span>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <span class="booking-item-price-from">Sharing: </span><span class="booking-item-price">
                                        {hotels.SharingORQuint}
                                        </span><span> SAR / Total</span>
                                        <br />
                                    </div>
                                    <div class="col-md-6">
                                        <span class="booking-item-price-from">Quad: </span><span class="booking-item-price">
                                        {hotels.Quad}
                                        </span><span> SAR / Total</span>
                                        <br />
                                    </div>
                                    <div class="col-md-6">
                                        <span class="booking-item-price-from">Triple: </span><span class="booking-item-price">
                                        {hotels.Triple}
                                        </span><span> SAR / Total</span>
                                        <br />
                                    </div>
                                    <div class="col-md-6">
                                        <span class="booking-item-price-from">Double: </span><span class="booking-item-price">
                                        {hotels.Doublee}
                                        </span><span> SAR / Total</span>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr /></>
                          ))}
                    </div>
               
                </div>
            </div>
        </section>
        <br />
        <Testimonials></Testimonials>
        
        {/* <CruiseOffers></CruiseOffers> */}
        
        <LatestBlog></LatestBlog>
        <Footer></Footer>
</>
    )
}

export default UmrahHotels;