import React, { forwardRef } from 'react'

const CustomPackageC = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>Make Your Own Custom Umrah Package </h2>
                    <p>At Malik Express Travel & Tours, we understand that each pilgrim has unique needs and preferences. Our Make Your Own Custom Umrah Package option allows you to tailor your pilgrimage experience to your specific requirements. Choose from a variety of accommodations, transportation options, and itinerary preferences to create a personalized journey that aligns with your spiritual goals. Whether you desire a short trip or an extended stay, we offer flexibility in travel dates, hotel choices, and additional services like guided tours and Ziyarat. Our experienced team is dedicated to providing you with the support and guidance you need to ensure a seamless and memorable Umrah experience. Let us help you design a pilgrimage that perfectly suits your needs and enhances your spiritual journey</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default CustomPackageC;