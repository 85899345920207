
import React from 'react'
import './head.css';

const Head = () => {
  return (
  
    
    <div className="package-block-heading umrah-head">
      <div className="container">
        <div className="it-title">
          <h1> Discover Affordable Umrah Packages from Pakistan with Malik Express Travel & Tours</h1>
          <p style={{color:'#000'}}>Embark on a spiritual journey like no other with Malik Express Travel & Tours' Customized Umrah Packages. We understand that every pilgrim has unique needs and preferences, which is why we offer tailored packages to suit your specific requirements. From selecting the best travel dates to arranging luxurious accommodations and seamless transport, our dedicated team ensures every detail is meticulously planned. Experience a hassle-free and spiritually enriching Umrah journey designed just for you</p>
        </div>
      </div>
    </div>
   
  )
}

export default Head
