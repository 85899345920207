import React from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Features from "../Home/Features";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MainMenu from "../Layout/MainMenu";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";
import Slider from '../Layout/Slider';
import Footer from '../Home/Footer';

function Flights() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>

        <meta
          name="description"
          content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://malikexpress.com/cheap-flights-booking" />
      </Helmet>

      <MainMenu></MainMenu>

      <Slider></Slider>
      
      <section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1>
                Get Cheap Flights Now with Malik Express Travel & Tours
                </h1>
                <hr className="heading-line" />
              </div>

              <p style={{color:'white'}}>
              Taking cheap flights from Pakistan has never been easier - thanks to Malik Express Travel & Tours. With cheap tickets starting from the lowest fare, Malik Express Travel & Tours makes it effortless to get your flight booking online and on the go. With amazing discounts on international tickets, there’s no better place to find cheap flights for where you want to go. Whether you’re planning a quick weekend overnight getaway or a longer holiday abroad, Malik Express Travel & Tours has got you covered with cheap ticket options that will surely fit into your budget. Start planning today to enjoy great flight deals and cheap airline tickets with Malik Express!
              </p>
            </div>
          </div>
        </div>
      </section>

         

      <section id="best-features" className="banner-padding "  style={{borderBottom: 'solid',color:'rgb(255, 193, 7)'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-plane"></i>
                </span>
                <h3>Ticket Booking Online </h3>
                <p>
                At Malik Express Travel & Tours, ticket booking online is made easy now! Our ticket booking services offer unbeatable value and great flight deals on air tickets that's hard to come by. You get to book ticket online with ease, saving you precious time and money while securing yourself the best ticket deals there is. We make sure our ticket prices stay low without compromising quality travel experience while getting you the best cheap flights so you can enjoy the hustle and bustle of new cities and sightseeing at its fullest. Enjoy the convenience with us and compare ticket prices across different airlines for your travels to get the best deal on cheap flights. Don't miss out on saving money with Malik Express Travel & Tours 
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-thumbs-up"></i>
                </span>
                <h3>Domestic Flights Pakistan </h3>
                <p>
                Malik Express Travel & Tours is your one-stop shop for domestic flight booking in Pakistan. With our advanced booking system and domestic air ticket prices tailored to fit your budget, you can book domestic flights across the country with ease. Whether it's a sightseeing trip or an important business meeting we have the perfect ticket at unbeatable airline fares, leaving you free to enjoy the journey. Booking your domestic air ticket with us will make your travel experience smoother and more rewarding so that nothing gets in the way of your destination dreams.                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-suitcase"></i>
                </span>
                <h3>International Flights Booking </h3>
                <p>
                Planning international travel? Look no further than Malik Express Travel & Tours for all your international flight booking needs. We offer a variety of international flight options from economy to business class and everything in between, all at competitive rates. Our experienced staff are fully trained to assist in finding the best international connections and fares to wherever you are headed. We have been helping customer’s book international flights since 1932 and continue to provide the same superior customer service today. Whether you're ready to book your international flight immediately or just browsing options, Malik Express Travel & Tours should be your first choice!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Flights;
