import React from 'react'

const CityChooseUs = (props) => {
  return (
    <>
    
    <section id="best-features1" className="banner-padding" style={{ color: 'rgb(255, 193, 7)' }}>
      <div className="container">
        <div className="row">
          <div className='container'>
          <h2 className="j-heading m-auto" style={{ color: 'black', fontSize: "36px" }}>Why Choose Malik Express Travel & Tours?</h2>
          <p style={{textAlign: "center"}}>Malik Express Travel & Tours is dedicated to providing a hassle-free and spiritually enriching Umrah experience. Our comprehensive packages ensure that all aspects of your journey are taken care of, allowing you to focus on your spiritual growth. With our experienced team, you can rest assured that your pilgrimage will be a memorable and fulfilling experience.</p>
          <br></br>
          <p>Book your Umrah Package from {props.from} with Malik Express Travel & Tours today and embark on a journey of a lifetime!</p>
       </div>
       </div>
        <div className="row feature-row">
          <div className="col-md-6 col-lg-4">
            <div className="b-feature-block" style={{background: 'white', color: 'black'}} >
            <span><img src="images/umrah/Visa.png" alt="Suitcase Icon" style={{ color: 'rgb(255, 193, 7)' }} /></span>
              <h3 style={{ color: 'black' }}>Affordable Umrah Visa Price - Fulfill Your Spiritual Calling</h3>
              <p style={{ color: 'black'}} >Cost should never be a barrier to fulfilling your spiritual calling. We offer Umrah packages that not only align with your faith but also with your budget. Our competitive Umrah visa prices make this sacred journey accessible to all, ensuring that nothing stands between you and your spiritual aspirations.</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4">
            <div className="b-feature-block thumb-u" style={{background: '#e5b622', color: 'white', MarginTop: '20px !important'}}>
            <span><img src='images/umrah/Ramazan umrah.png' alt="Suitcase Icon" style={{ color: 'rgb(255, 193, 7)' }} /></span>
              <h3 style={{ color: 'white' }}>Ramadan Umrah Packages - Elevate Your Spiritual Experience</h3>
              <p style={{color:'#fff'}}> Observing Umrah during the holy month of Ramadan is a dream for many. Our specialized Ramadan Umrah packages are thoughtfully designed to enhance your spiritual experience during this sacred time. With accommodation close to the Holy Mosque, your journey is not only blessed but also incredibly convenient.</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-4" >
            <div className="b-feature-block" style={{background: 'white', color: 'black'}}>
            <span><img src="images/umrah/umrah packages.png" alt="Suitcase Icon" style={{ color: 'rgb(255, 193, 7)' }} /></span>
              <h3 style={{ color: 'black' }}>Family Umrah Packages - Journey Together, Pray Together</h3>
              <p style={{ color: 'black'}}>At Malik Express Travel & Tours, we understand the importance of family in your Umrah journey. Our Umrah packages for families are crafted to provide every member, young and old, with the ideal spiritual experience. Strengthen your bonds while strengthening your faith.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    
    </>
  )
}

export default CityChooseUs