import React from 'react'

function Newsletters(){
    return(
<section id="newsletter-2" className="newsletter">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-5 col-xl-5">
                        <h2>Subscribe Our Newsletter</h2>
                    </div>

                    <div className="col-12 col-md-12 col-lg-7 col-xl-7">
                        <form>
                            <div className="form-group">
                                <div className="input-group">
                                    <input type="email"
                                           className="form-control input-lg"
                                           placeholder="Enter your email address"
                                           required />
                                    <span className="input-group-btn">
                                        <button className="btn btn-lg">
                                            <i className="fa fa-envelope"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Newsletters;