import React from 'react';

const FlightTimeConverter = ({ minutes }) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return (
    <div>
      <p>{hours}h {remainingMinutes}m</p>
    </div>
  );
};

export default FlightTimeConverter;