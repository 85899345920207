import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import { FaPlus, FaMinus, FaTimes, FaEnvelope } from 'react-icons/fa'; // Import the icons from the react-icons library
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Beneficiary_Details() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [countries, setCountries] = useState([]);
    const [timeout, setTimeout] = useState('')
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [responcee, setResponcee] = useState('')

    const { data, singleFee, familyFee, passengertype, arrivalDate, departureDate, InsuranceType, dob } = location.state || {};
    //console.log('selected is', )
    const GrandTotal = ((passengertype === 'family' ? familyFee : singleFee) * 0.85).toFixed(0)

    const url = apiUrl + "/api/Insurance";
    const [formvalue, setFormValue] = useState({
        First_nameB: '',
        Last_nameB: '',
        RelationB: '',
        CnicB: 'null',
        Mobile_noB: 'null',
        AddressB: 'null',

    });

    useEffect(() => {
        const { formvalue: initialFormValue } = location.state || {};
        if (initialFormValue) {
            setFormValue(initialFormValue);
        }
    }, []);
    //console .log('update from value is',formvalue)

    useEffect(() => {
        fetchCountries();
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded

        }, 2000); // Adjust the duration as needed

    }, []);


    const [resultMsg, setResultMsg] = useState('');

    const [selectedItems, setSelectedItems] = useState(null);

    const selectItems = (itemIds) => {
        setSelectedItems(itemIds);
    };
    const [selectedItem, setSelectedItem] = useState('single');

    const selectItem = (item) => {
        setSelectedItem(item);
    };
    async function fetchCountries() {
        await axios
            .get(apiUrl + "/api/FlightSearch/countries")
            .then((response) => {
                const returnData = response.data;
                //retData= response.data;
                // console.log(response.data);
                setTimeout(() => {
                    setCountries(response.data);
                }, 2000);
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const [isFlightDetailsVisible, setFlightDetailsVisible] = useState(false);
    const [icon, setIcon] = useState(<FaPlus />); // Initialize with the "plus" icon
    const [enquireVisible, setEnquireVisible] = useState(false);

    const toggleFlightDetails = () => {
        setFlightDetailsVisible(!isFlightDetailsVisible);

        // Change the icon based on visibility
        setIcon(isFlightDetailsVisible ? <FaPlus /> : <FaMinus />);
        setEnquireVisible(false)
    };

    const toggleEnquireDetails = () => {

        setEnquireVisible(!enquireVisible);
        setFlightDetailsVisible(false)
        setIcon(<FaPlus />)

    };
    const handleCloseClick = () => {
        setEnquireVisible(false);
    };
    const onNext = () => {
        navigate('/Review_Information');
    }
    const onEdit = () => {
        navigate('/online-travel-insurance-pakistan');
    }
    const onPrevious = () => {
        navigate('/TravelInsurance_Booking', { state: { data, singleFee, familyFee, passengertype, arrivalDate, departureDate, InsuranceType, dob } });
    }
    function postData() {
        setLoading(true);
        const instance = axios.create({
            baseURL: url,
            timeout: 10000,
            headers: { "X-Custom-Header": "foobar" },
        });

        if (formvalue.First_nameB != undefined) {
            if (formvalue.First_nameB.length < 2) {
                alert("Please provide First name at least 2 characters.");
                setLoading(false)
                return;

            }
        } else {
            alert("Please provide First name at least 2 characters.");
            setLoading(false)
            return;
        }

        if (formvalue.Last_nameB != undefined) {
            if (formvalue.Last_nameB.length < 2) {
                alert("Please provide last name at least 2 characters.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide last name at least 2 characters.");
            setLoading(false)
            return;
        }
        if (formvalue.RelationB != undefined) {
            if (formvalue.RelationB.length < 1) {
                alert("Please provide Relation.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide Relation.");
            setLoading(false)
            return;
        }
        // if (formvalue.CnicB != undefined) {
        //     if (formvalue.CnicB.length < 13) {
        //         alert("Please provide Cnic number at least 13 characters.");
        //         setLoading(false)
        //         return;
        //     }
        // } else {
        //     alert("Please provide Cnic number at least 13 characters.");
        //     setLoading(false)
        //     return;
        // }
        // if (formvalue.Mobile_noB != undefined) {
        //     if (formvalue.Mobile_noB.length < 11) {
        //         alert("Please provide  Mobile number Ex : 03101111112.");
        //         setLoading(false)
        //         return;
        //     }
        // } else {
        //     alert("Please provide  Mobile number Ex : 03101111112.");
        //     setLoading(false)
        //     return;
        // }
        // if (formvalue.AddressB != undefined) {
        //     if (formvalue.AddressB.length < 1) {
        //         alert("Please provide AddressB.");
        //         setLoading(false)
        //         return;
        //     }
        // } else {
        //     alert("Please provide AddressB.");
        //     setLoading(false)
        //     return;
        // }


        const jsonData = JSON.stringify(formvalue);
        //console.log('json is',jsonData)
        axios
            .post(url + "/insurancebooking", jsonData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setResponcee(response.data)


                if (response.status === 200) {
                    const BookingID = response.data
                    //console.log('booking',BookingID)
                    navigate('/Review_Information', { state: { formvalue, data, singleFee, familyFee, passengertype, arrivalDate, departureDate, InsuranceType, BookingID, dob } });


                } else {

                    console.error("Unexpected response status:", response.status);
                }

            })
            .catch((error) => console.error(error));
    }
    const handleClick = (e) => {
        postData();
    };

    return (

        <>
            <Helmet>
                <title >{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>

                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (

                <section className="innerpage-wrapper">




                    <div className="card">
                        <div className="tab-navigation">
                            <div id="travel-tabs">
                                <ul className="nav2 nav-tabs t-payment-tab" role="tablist">
                                    <li className="p-info-li">
                                        <a role="tab"
                                            onClick={onPrevious}
                                            className="cartTabBtn">
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Personal Details</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a
                                            role="tab"
                                            className="cartTabBtn"

                                            data-toggle="tab"

                                        >
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Beneficiary Details</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="review-info-li">
                                        <a role="tab" className="cartTabBtn" >
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Review Information</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="select-payment-li">
                                        <a role="tab" className="cartTabBtn">
                                            <div className="s-no">
                                            </div>
                                            <div className="tab-name">
                                                <h5>Select Payment Option</h5>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>



                    <div id="travel-insurance" className="innerpage-section-paddingg">
                        <div className="container">





                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-9 mt-2">
                                    <form>
                                        <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>
                                            <div style={{ display: 'none' }}> 3210330EK.png</div>
                                            <div className="card2 mt-0" style={{ borderRadius: '20px' }}>
                                                <h2 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>Beneficiary Details:</h2>


                                                <div className="row m-4">
                                                    <p className="ppp">A beneficiary is a close relative such as Father, Brother, Spouse etc who gets paid by the insurance company in
                                                        case of claim due to death of insured person or in other cases as covered by your insurance policy</p>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">First Name <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" required className="form-control" placeholder=""
                                                                name="First_nameB"
                                                                value={formvalue.First_nameB || ''}
                                                                onChange={(e) => setFormValue({ ...formvalue, First_nameB: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Last Name <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" required className="form-control" placeholder=""
                                                                name="Last_nameB"
                                                                value={formvalue.Last_nameB || ''}
                                                                onChange={(e) => setFormValue({ ...formvalue, Last_nameB: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Beneficiary Relation <span style={{ color: 'red' }}>*</span></label>
                                                            <input
                                                                type="text"
                                                                required
                                                                className="form-control"
                                                                placeholder=""
                                                                name="RelationB"
                                                                value={formvalue.RelationB || ''}
                                                                onChange={(e) => setFormValue({ ...formvalue, RelationB: e.target.value })}
                                                            />
                                                        </div>


                                                    </div>
                                                    {/* <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">CNIC</label>
                                                            <input type="text" required className="form-control" placeholder=""
                                                                name="CnicB"
                                                                value={formvalue.CnicB || ''}
                                                                onChange={(e) => setFormValue({ ...formvalue, CnicB: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Contact Number</label>
                                                            <input type="tel" required className="form-control" placeholder=""
                                                                name="Mobile_noB"
                                                                value={formvalue.Mobile_noB || ''}
                                                                onChange={(e) => setFormValue({ ...formvalue, Mobile_noB: e.target.value })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Address</label>
                                                            <input type="text" required className="form-control" placeholder=""
                                                                name="AddressB"
                                                                value={formvalue.AddressB || ''}
                                                                onChange={(e) => setFormValue({ ...formvalue, AddressB: e.target.value })}
                                                            />
                                                        </div>
                                                    </div> */}
                                                </div>
                                                <div className="btn-controler">
                                                    <div className="center2 pt-5 pb-5 mr-1">
                                                        <button
                                                            onClick={onPrevious}
                                                            style={{ background: '#6c757d' }}
                                                            type="button"
                                                            className=" btn-lg btn-secondary fn semibold"
                                                        >
                                                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                                                            Previous
                                                        </button>
                                                    </div>
                                                    <div className="center2 pt-5 pb-5 ml-2">
                                                        <button
                                                            onClick={handleClick}
                                                            type="button"
                                                            className="btn-lg btn-orange fn semibold"
                                                            disabled={loading} // Disable the button when loading
                                                        >
                                                            {loading ? (
                                                                <span>Loading...</span>
                                                            ) : (
                                                                <>
                                                                    Next
                                                                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                                                </>
                                                            )}
                                                        </button>
                                                    </div>
                                                </div>







                                            </div>
                                        </div>
                                    </form>


                                </div>
                                <div class="col-12 col-md-12 col-lg-3 mb-2">
                                    <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>

                                        <div className="card mt-2" style={{ borderRadius: '20px' }}>
                                            <div className="h-pro">
                                                <div className="tumb">
                                                    <img
                                                        className="lozad"
                                                        src="../images/CSI-Logo.png"
                                                        alt="Habib Insurance Gold - Worldwide"
                                                    />
                                                </div>


                                                {data && data.length > 0 ? (
                                                    <div className="containerrs">
                                                        <div className="left-corner">
                                                            Medical cover:
                                                        </div>
                                                        <div className="right-corner">
                                                            {`$${data[0].MedicalExpense}`}
                                                        </div>

                                                        <div className="left-corner">
                                                            Coverage Upto:
                                                        </div>
                                                        <div className="right-corner">
                                                            {`${data[0].Duration} days`}
                                                        </div>

                                                        <div className="left-corner">
                                                            Premium:
                                                        </div>
                                                        <div className="right-corner">
                                                            <p className="pricce">
                                                                <b>{`Rs.${((passengertype === 'family' ? familyFee : singleFee) * 0.85).toFixed(0)}`}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}




                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            )}

            <Footer></Footer>
        </>
    );

}

export default Beneficiary_Details;