import React, { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import {Link, useLocation , useNavigate} from "react-router-dom";
import moment from "moment";
import axios from "axios";
//import '../../../src/CssFiles/bootstrap-4.4.1.min.css';

function FlightBookingCard(props){

//    const location = useLocation();
//    console.log(location);
//    console.log(props);


//   const queryParameters = new URLSearchParams(window.location.search)
//   const pp_ResponseCode = queryParameters.get("pp_ResponseCode")
//   const pp_ResponseMessage = queryParameters.get("pp_ResponseMessage")
//   let bookingId = queryParameters.get("pp_BillReference")

//   const [data, setData] = useState(null);

//   bookingId="1260";
//   const apiUrl = process.env.REACT_APP_API_URL;

//   const url = apiUrl + "/api/HitItFlightSearch";

//    async function fetchData1() {
//     await axios
//     .get(url + "/flightbookingdetails?BookingID=" + bookingId)
//     .then((response) => {
//    const returnData = response.data;
//     //retData= response.data;
//   console.log(response.data);
//     setTimeout(() => {
//         setData(response.data);
        
//       }, 2000);
//    // console.log(data);    
//     }) 
//       .catch((error) => {
//         console.log(error);
//       });
//   } 

//   useEffect(() => {  
//     window.scrollTo(0, 0);
//         fetchData1();
//   }, []);

//   function formatTimeToAMPM(inputTime) {
//     if(inputTime!==""){
//         const parts = inputTime.split(":"); // Split the input time into hours, minutes, and seconds
//         const date = new Date();
//         date.setHours(parseInt(parts[0], 10)); // Set hours
//         date.setMinutes(parseInt(parts[1], 10)); // Set minutes
//         date.setSeconds(parseInt(parts[2], 10)); // Set seconds
//         const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
//         return formattedTime; // Return the formatted time
//     }
//   }
    return(
        <>
        <section className="innerpage-wrapper">
        <div className="innerpage-section-padding">
        <div className="container SlipBackground p-0"style={{maxWidth:"960px"}}>
            <div className="w-100 m-0 pt-2 pl-2 MainSlip"style={{fontWeight:"600",color:"white"}}>  <h3>BOOKING DETAILS</h3></div>
            <div className="w-100 m-0 pt-2 pl-2"style={{color:"#0867d2",backgroundColor:"#d0dadf",fontWeight:"600" }}><span>Islamabad - London</span></div>
          <div className="">
                            <div className="d-flex justify-content-between col m-5 w-100">
                                <div className="">
                                    <label>PNR ID:</label>
                                    <h2>
                                        8ZAUI0M
                                       {/* {data ? data.Reservation[0].PNR : ""} */}
                                    </h2>
                                </div>
                               <div className="">

                               </div>
                               
                                <span className="float-right mr-5">
                                    <label className="">Total Amount:</label>
                                    <h3>
                                        40000
                                        {/* {data ? data.Reservation[0].GrandTotal : ""} */}
                                    </h3>
                                </span>
                            </div>
                          
          
                            <div className="row m-5">
                                <div className="col-lg-4 col-sm-12">
                                    <span><span>Passenger Name:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>
                                        {/* {data ? data.PaxList[0].FirstName : ""} {data ? data.PaxList[0].LastName:""} */}
                                        Asif Kiani
                                        </span></span>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <span><span>Gender:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>
                                        {/* {data ? (data.PaxList[0].Gender ? "Male" : "Female") : ""}  */}
                                        Male
                                        </span></span>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <span><span>DOB:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>
                                        {/* {data ? moment(data.PaxList[0].DOB).format("DD MMMM YYYY") : ""} */}
                                       15 August 2023
                                        </span></span>
                                </div>
                            </div>

                            <div className="row m-5">
                                <div className="col-lg-4 col-sm-12">
                                    <span><span>Document #:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>
                                        {/* {data ? data.PaxList[0].Document : ""} */}
                                        6110478789878
                                         </span></span>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <span><span>DOE:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>
                                        {/* {data ? moment(data.PaxList[0].DOE).format("DD MMMM YYYY") : ""} */}
                                        15 August 2024
                                        </span></span>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <span><span>Booking Date:</span> <span style={{ fontWeight: "600", fontSize: "18px" }}>
                                        {/* {data ? moment(data.Reservation[0].BookingDate).format("DD MMMM YYYY") : ""} */}
                                        15 August 2024
                                        </span></span>
                                </div>
                            </div>
          </div>
            <div className="row col-12 p-0 m-0"style={{backgroundColor:"#eeeeee"}}>
                            <div className="col-lg-1 col-sm-12 p-2 ml-5">
                                <div className="">
                                     PK-2398
                                    {/* { data ? data.flightSegments[0].OperatingAirlineName:""} - { data ? data.flightSegments[0].FlightNumber:""} */}
                                </div>
                                <div className="">
                                    <img
                                        src={"images/airlines/PK.png"}
                                        style={{ width: "47px", height: "30px" }}
                                        alt="flight-img"
                                    />

                                </div>
                            </div>
            <div className="col-5 p-2">
                <div className=""style={{fontWeight:"bolder"}}>DEPART:</div>
                <div className="" style={{lineHeight:"initial"}}>Islamabad International (ISB)</div>
                {/* { data ? moment(data.flightSegments[0].DepartureDate).format("dddd DD MMMM YYYY") :""}  
                {" "}
                { data ? ( formatTimeToAMPM(data.flightSegments[0].DepartureTime)  ):""} */}
                Thursday 24 August 2023 05:00AM
            </div>
            <div className="col-5 p-2">
            <div className=""style={{fontWeight:"bolder"}}>ARRIV:</div>
                <div className="" style={{lineHeight:"initial"}}>Istanbul International (IST)</div>
                {/* { data ? moment(data.flightSegments[0].ArrivalDate).format("dddd DD MMMM YYYY") :""}  
                {" "}
                { data ? ( formatTimeToAMPM(data.flightSegments[0].ArrivalTime)  ):""} */}
                Saturday 26 August 2023 05:00AM

            </div>
            </div>
            

            <div className="row">
                <div className="ml-5">
                <span> <span className="font-weight-bold"style={{color:"#6e6464"}}>Duration:</span> <span style={{color:"#6e6464"}}>5h 55m</span></span>

                <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Cabin Class:</span> <span style={{color:"#6e6464"}}>Economy (Y)</span></span>
                
                <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Airline:</span> <span style={{color:"#6e6464"}}>Pakistan Airlines</span></span>

                <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Stop:</span> <span style={{color:"#6e6464"}}>0</span></span>

                <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Baggage Allowance:</span> <span style={{color:"#6e6464"}}>40 Kilograms</span></span>

                </div>
            </div>


             <div className="row col-12 p-0 m-0"style={{backgroundColor:"#eeeeee"}}>
                            <div className="col-lg-1 col-sm-12 p-2 ml-5">
                                <div className="">
                                    PK-2312
                                </div>
                                <div className="">
                                    <img
                                        src={"images/airlines/PK.png"}
                                        style={{ width: "47px", height: "30px" }}
                                        alt="flight-img"
                                    />

                                </div>
                            </div>
            <div className="col-5 p-2">
                <div className=""style={{fontWeight:"bolder"}}>DEPART:</div>
                <div className="" style={{lineHeight:"initial"}}>Istanbul International (IST)</div>
                <div className=""style={{lineHeight:"initial"}}>Thursday 29 August 2023 05:05AM</div>
            </div>
            <div className="col-5 p-2">
            <div className=""style={{fontWeight:"bolder"}}>ARRIV:</div>
                <div className="" style={{lineHeight:"initial"}}>Islamabad International (ISB)</div>
                <div className=""style={{lineHeight:"initial"}}>Thursday 30 August 2023 05:05AM</div>
            </div>
            </div>
            

            <div className="row">
                <div className="ml-5">
                <span> <span className="font-weight-bold"style={{color:"#6e6464"}}>Duration:</span> <span style={{color:"#6e6464"}}>5h 55m</span></span>

                <span className="ml-2"> <span className="font-weight-bold"style={{color:"#6e6464"}}>Cabin Class:</span> <span style={{color:"#6e6464"}}>Economy (Y)</span></span>
                
                <span className="ml-2"> <span className="font-weight-bold" style={{color:"#6e6464"}}>Airline:</span> <span style={{color:"#6e6464"}}>Pakistan Airlines</span></span>

                <span className="ml-2"> <span className="font-weight-bold" style={{color:"#6e6464"}}>Stop:</span> <span style={{color:"#6e6464"}}>0</span></span>

                <span className="ml-2"> <span className="font-weight-bold" style={{color:"#6e6464"}}>Baggage Allowance:</span> <span style={{color:"#6e6464"}}>40 Kilograms</span></span>

                </div>
            </div>
        </div>
        </div>
        </section>
        </>
    )
}

export default FlightBookingCard;