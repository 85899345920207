import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import { FaPlus, FaMinus, FaTimes, FaEnvelope, FaAngleDown } from 'react-icons/fa'; // Import the icons from the react-icons library
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { data } from "jquery";
import CryptoJS from 'crypto-js';
//import { FaAngleDown } from 'react-icons/fa';
function Review_Universal() {
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [countries, setCountries] = useState([]);
    const [timeout, setTimeout] = useState('')
    const navigate = useNavigate();
    const location = useLocation();
    const [booking, setBooking] = useState('')
    const [bookingfamily, setBookingfamily] = useState('')
    const [openSection, setOpenSection] = useState(null);
    const [paymentDetail, setPaymentDetail] = useState({});
    const [selectedPayment, setSelectedPayment] = useState('payfast');
    const { formvalue, United, arrivalDate, departureDate, passengertype, area, Premium, plan, dob,BookingID} = location.state || {};
    const Premiumplus = (Premium * 1.15).toFixed(0)
    const [vendor, setVendor] = useState('')
   console.log('vendor is a',formvalue.Vendor)
    const Booking = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/Insurance/getuicdetails/?id=${BookingID}`);
            if (response.status === 200) {
                setBooking(response.data)
              
            } else {

                console.error("Error: bookind is not conformed ");
            }

        } catch (error) {
            console.error('Error: bookind is not conformed');
        }
    };
    const Bookingfamily = async () => {
        try {

            const response = await axios.get(`${apiUrl}/api/Insurance/getuicpaxlist/?id=${BookingID}`);

            if (response.status === 200) {
                setBookingfamily(response.data)
                //  console.log('data is family',response.data)

            } else {

                console.error("Error: bookind is not conformed ");
            }

        } catch (error) {
            console.error('Error: bookind is not conformed');
        }
    };
    useEffect(() => {
        setTimeout(() => {
            Booking()
            Bookingfamily()
            setIsLoading(false); // Set isLoading to false once the content is loaded
        }, 2000); // Adjust the duration as needed

    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const onPrevious = () => {
        navigate('/Unisersal_booking', { state: { United, arrivalDate, departureDate, passengertype, area, Premium, plan, dob } });
    }
  

    const handleProceed = () => {
        navigate('/SelectPaymnt', { state: { formvalue, United, arrivalDate, departureDate, passengertype, area, Premium, plan, dob,BookingID } });
    };
    const handleSectionClick = (sectionId, event) => {
        // Prevent the default behavior of anchor tag
        event.preventDefault();
        // Toggle the openSection state
        setOpenSection(openSection === sectionId ? null : sectionId);
    };
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const currentDates = formatDate(new Date());
    const Fastpay = async () => {
        try {
            if (BookingID && Premiumplus && formvalue.Mobile_no && formvalue.Email && formvalue.Vendor ) {
                const response = await fetch('https://api.malikexpress.com/api/payfast/GetAccessToken', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        merchant_id: '21277',
                        secured_key: '-VFew2B7oKZBitKsXjXnAJ5-FG'
                    })
                });

                const data = await response.json()

                const authToken = data.accesS_TOKEN;

                console.log('fast token ', authToken)
                const payload = {
                    BASKET_ID: `${BookingID.toString()}-${formvalue.Vendor }`,
                    ORDER_DATE: currentDates,
                    TOKEN: authToken,
                    CURRENCY_CODE: 'PKR',
                    MERCHANT_ID: '21277',
                    SUCCESS_URL: 'https://malikexpress.com/ConfirmedPayment/',
                    FAILURE_URL: 'https://malikexpress.com/ConfirmedPayment/',
                    CHECKOUT_URL: 'https://typedwebhook.tools/webhook/cfe4e40e-8c5c-4d5b-867a-017bce41070c',
                    CUSTOMER_EMAIL_ADDRESS: formvalue.Email,
                    CUSTOMER_MOBILE_NO: formvalue.Mobile_no,
                    TXNAMT: Premiumplus,
                    SIGNATURE: '',
                    VERSION: 'MERCHANT-CART-0.1',
                    TXNDESC: 'Item Purchased from Cart',
                    PROCCODE: '00',
                    TRAN_TYPE: 'ECOMM_PURCHASE',
                    STORE_ID: '',
                    RECURRING_TXN: 'true'
                };
                setPaymentDetail(payload);
                return true;
            } else {
                setTimeout(Fastpay, 1000);
                return false;
            }
        } catch (error) {
            //console.error('Error:', error.message);
        }
    };
    useEffect(() => {
        // FastpayToken();
        //  Kuickpay();
        Fastpay()
    }, [selectedPayment]);
    const handlepayfast = async () => {
        const kuickpaySuccess = await Fastpay(); // Wait for Kuickpay to complete
        if (kuickpaySuccess) {
            document.getElementById("FormPGS").submit();
        } else {
            // Handle the case where Kuickpay was not successful
            // Maybe show a message or retry Kuickpay
        }
    };
    return (

        <>
            <Helmet>
                <title >{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>

                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (

                <section className="innerpage-wrapper">
                    <div className="card">
                        <div className="tab-navigation">
                            <div id="travel-tabs">
                                <ul className="nav2 nav-tabs t-payment-tab" role="tablist">
                                    <li className="p-info-li">
                                        <a role="tab"
                                            onClick={onPrevious}
                                            className="cartTabBtn">
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Personal Details</h5>
                                            </div>
                                        </a>
                                    </li>

                                    <li className="review-info-li active">
                                        <a role="tab" className="cartTabBtn">
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Review Information</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="select-payment-li">
                                        <a role="tab" className="cartTabBtn">
                                            <div className="s-no">
                                            </div>
                                            <div className="tab-name">
                                                <h5>Select Payment Option</h5>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>




                    <div id="travel-insurance" className="innerpage-section-paddingg">
                        <div className="container">





                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-8 mt-2">
                                    <form>
                                        <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>
                                            <div style={{ display: 'none' }}> 3210330EK.png</div>
                                            <div className="card2 mt-0" style={{ borderRadius: '20px' }}>
                                                <h2 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>Review Information</h2>
                                                {booking && booking.length > 0 ? (
                                                    <>
                                                        <h3 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '10px', padding: '5px 5px' }}>Your Details:</h3>

                                                        <div className="row m-4" style={{ borderBottom: '1px solid #ccc', }}>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Full Name:</div>
                                                                    <div className="field-value">{booking[0].TravelerName}</div>
                                                                </div>
                                                            </div>

                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">CNIC:</div>
                                                                    <div className="field-value">{booking[0].TravelerNICNo}</div>
                                                                </div>
                                                            </div>

                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Date of Birth</div>
                                                                    <div className="field-value">{booking[0].TravelerDOB}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Contact Number</div>
                                                                    <div className="field-value">{booking[0].ContactNumber}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Purpose of Visit</div>
                                                                    <div className="field-value">{booking[0].PurposeOfVisit}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Passport Number</div>
                                                                    <div className="field-value">{booking[0].Passport}</div>
                                                                </div>
                                                            </div>

                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">City of Residence</div>
                                                                    <div className="field-value">{booking[0].CityofResidence}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Country</div>
                                                                    <div className="field-value">{booking[0].FirstCountry}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">GrandTotal</div>
                                                                    <div className="field-value"><b>PKR {booking[0].DiscountedPrice}</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-12">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Residential Address</div>
                                                                    <div className="field-value">{booking[0].ResidentialAddress}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-12">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Email</div>
                                                                    <div className="field-value">{booking[0].EmailAddress}</div>
                                                                </div>
                                                            </div>



                                                        </div>
                                                    </>
                                                ) : (
                                                    <div>Something went wrong please try again</div>
                                                )}
                                                {bookingfamily && bookingfamily.map((member, index) => (
                                                    <div key={index}>
                                                        {member.PaxType === "Adult" ? (
                                                            <h3 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '10px', padding: '5px 5px' }}>Second Adult:</h3>
                                                        ) : (
                                                            <h3 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '10px', padding: '5px 5px' }}>Child {index + 1}:</h3>
                                                        )}
                                                        <div className="row m-4" style={{ borderBottom: '1px solid #ccc' }}>
                                                            <div className="form-value-list col-md-6">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Full Name:</div>
                                                                    <div className="field-value">{member.FullName}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-6">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Date of Birth:</div>
                                                                    <div className="field-value">{member.DOB}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-6">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Passport Number:</div>
                                                                    <div className="field-value">{member.PassportNo}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}




                                                {booking && booking.length > 0 && (
                                                    <>
                                                        <h3 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '10px', padding: '5px 5px' }}>Beneficiary Details:</h3>
                                                        <div className="row m-4">
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">First Name:</div>
                                                                    <div className="field-value">{booking[0].BeneficiaryFirstName}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Last Name:</div>
                                                                    <div className="field-value">{booking[0].BeneficiaryLastName}</div>
                                                                </div>
                                                            </div>
                                                            <div className="form-value-list col-md-4">
                                                                <div className="inner">
                                                                    <div className="field-lbl">Beneficiary Relation:</div>
                                                                    <div className="field-value">{booking[0].BeneficiaryRelation}</div>
                                                                </div>
                                                            </div>
                                                          

                                                        </div>
                                                    </>

                                                )}
                                                <div className="btn-controler">

                                                    <div className="center2 pt-5 pb-5 ml-2">
                                                        <PaymentForms paymentDetail={paymentDetail} />

                                                        {booking && booking.length > 0 ? (
                                                            <button
                                                                id="proceedButton"
                                                                type="button"
                                                                className="btn-lg btn-orange fn semibold"
                                                                onClick={handlepayfast}
                                                            >
                                                                Buy Now
                                                            </button>
                                                        ) : (
                                                            <button
                                                                id="proceedButton"
                                                                type="button"
                                                                className="btn-lg btn-orange fn semibold"
                                                                disabled
                                                            >
                                                                Buy Now
                                                            </button>
                                                        )}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>


                                </div>

                                <div class="col-12 col-md-12 col-lg-4 mb-2">
                                    <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>

                                        <div className="card mt-2" style={{ borderRadius: '20px' }}>
                                            <div className="h-pro">
                                                <div className="tumb">
                                                    <img
                                                        className="lozad"
                                                        src="../images/UIC.png"
                                                        alt="Habib Insurance Gold - Worldwide"
                                                    />
                                                </div>
                                                <div className="row1 clearfix">
                                                    <div className="bold">
                                                        <h3 style={{ color: '#e5b622', fontFamily: 'Panton Bold', textAlign: 'center', marginTop: '20px' }}>Travelling Summary</h3></div>
                                                </div>
                                                <div className="containerrs" style={{ padding: '30px' }}>

                                                    <div className="top left-corner">Traveling To:</div>
                                                    <div className="bold bottom right-corner">

                                                        <b>{United.Area}</b>

                                                    </div>

                                                    <div className="top left-corner">Passenger Type:</div>
                                                    <div className="bold bottom right-corner">
                                                        <b>{passengertype}</b>
                                                    </div>

                                                    <div className="top left-corner"> Insurance Plan:</div>
                                                    <div className="bold bottom right-corner">
                                                        <b>{plan}</b>
                                                    </div>


                                                    <div className="top left-corner">Departing On:</div>
                                                    <div className="bold bottom right-corner"><b>{departureDate}</b></div>
                                                    
                                                    <div className="top left-corner">Returning On:</div>
                                                    <div className="bold bottom right-corner"><b>{arrivalDate}</b></div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>

                                        <div className="card mt-2" style={{ borderRadius: '20px' }}>
                                            <div className="h-pro">
                                                <div className="tumb">
                                                    <img
                                                        className="lozad"
                                                        src="../images/UIC.png"
                                                        alt="Habib Insurance Gold - Worldwide"
                                                    />
                                                </div>


                                                {United ? (
                                                    <div className="containerrs" style={{ padding: '30px' }}>

                                                        <div className="left-corner">
                                                            Medical cover:
                                                        </div>
                                                        <div className="right-corner">
                                                            {`$${'Covered'}`}
                                                        </div>
                                                        <br />
                                                        <div className="left-corner">
                                                            Coverage Upto:
                                                        </div>
                                                        <div className="right-corner">
                                                            {`${United.Duration} ${United.DurationUnit}`}
                                                        </div>
                                                        <br />
                                                        <div className="left-corner">
                                                            Premium:
                                                        </div>
                                                        <div className="right-corner">
                                                            <p className="pricce">
                                                                <b>{`Rs.${Premiumplus}`}</b>                                                    </p>
                                                        </div>
                                                        <br />
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}

                                            </div>
                                        </div>
                                    </div>

                                    <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>

                                        <div className="card mt-2" style={{ borderRadius: '20px' }}>
                                            <div className="h-pro">
                                                <div style={{ paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                                                    <a
                                                        data-toggle="collapse"
                                                        href="#collapse1"
                                                        style={{ textAlign: 'left', color: '#000' }}
                                                        onClick={(event) => handleSectionClick("collapse1", event)}
                                                    >

                                                        <h2 className="black semibold" style={{ fontSize: 18 }}>
                                                            How will I get the policy?
                                                            <FaAngleDown style={{ float: 'right', marginTop: 0, fontSize: 20, }} />
                                                        </h2>
                                                    </a>
                                                    <div id="collapse1" className={`panel-collapse collapse ${openSection === "collapse1" ? "show" : ""}`}>

                                                        <p style={{ textAlign: 'left', color: '#000' }}>
                                                            Once you have successfully purchased travel insurance, your policy will be promptly delivered to you electronically via email. This comprehensive document will encompass all pertinent details of your coverage, including policy limits, terms, and contact information for assistance. Should you prefer a hard copy of the policy or delivery via alternative platforms such as WhatsApp, please feel free to contact our dedicated support team, and we will accommodate your request promptly.                                                </p>

                                                    </div>
                                                </div>
                                                <div style={{ paddingTop: '10px', }}>

                                                    <a
                                                        data-toggle="collapse"
                                                        href="#collapse2"
                                                        style={{ textAlign: 'left', color: '#000' }}
                                                        onClick={(event) => handleSectionClick("collapse2", event)}
                                                    >
                                                        <h2 className="black semibold" style={{ fontSize: 18 }}>
                                                            How do I know the policy issued is genuine?
                                                            <FaAngleDown style={{ float: 'right', marginTop: 0, fontSize: 20, }} />
                                                        </h2>
                                                    </a>
                                                    <div id="collapse2" className={`panel-collapse collapse ${openSection === "collapse2" ? "show" : ""}`}>

                                                        <p style={{ textAlign: 'left', color: '#000' }}>
                                                            Rest assured, all policies issued by Malik Express Travel & Tours are authentic and recognized. You can verify the authenticity of your policy by cross-checking the details provided with our records. Additionally, you can reach out to our dedicated customer support team for any clarification or assistance regarding your policy.                                                </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>



                            </div>

                        </div>
                    </div>
                </section>

            )}

            <Footer></Footer>
        </>
    );

}
const PaymentForms = ({ paymentDetail }) => {
    return (
        <form id="FormPGS" action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction" method="POST">
            {Object.keys(paymentDetail).map((key) => {
                const fieldName = key;
                const fieldValue = paymentDetail[key];
                return (
                    <input
                        key={fieldName}
                        type="hidden"
                        id={fieldName}
                        name={fieldName}
                        value={fieldValue}
                    />
                );
            })}
        </form>
    );
};
export default Review_Universal;