import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import axios from 'axios';
import './autosuggest.css'; 

const AutocompleteNew2 = ({ onChange ,arrivalCity}) => {
  let arrCity='';
  if(arrivalCity !== undefined)
  {
    arrCity = arrivalCity;
  }
    const [value, setValue] = useState(arrCity);
    const [suggestions, setSuggestions] = useState([]);

  const apiUrl = process.env.REACT_APP_API_URL;


  const fetchSuggestions = async (query) => {
    try {
      if (query.length >= 3)
      {
        const url = apiUrl + "/api/FlightSearch/AirportName?term=" + query;
      const response = await axios.get(url);
      setSuggestions(response.data);
      }
    } catch (error) {
      console.error('Error fetching autocomplete suggestions:', error);
    }
  };

  const handleChange = (event, { newValue }) => {
    setValue(newValue);
    onChange(newValue);
  };

  const handleSuggestionsFetchRequested = ({ value }) => {
    fetchSuggestions(value);
  };

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const renderSuggestion = (suggestion) => {
    return <div>{suggestion}</div>;
  };

  const inputProps = {
    placeholder: 'Arrival', // Placeholder text
    value,
    onChange: handleChange,
  };

  return (
    <Autosuggest
    suggestions={suggestions}
    onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
    onSuggestionsClearRequested={handleSuggestionsClearRequested}
    getSuggestionValue={(suggestion) => suggestion}
    renderSuggestion={renderSuggestion}
    inputProps={inputProps}
  />
  );
};

export default AutocompleteNew2;
