import { forwardRef } from 'react';

const SevenTitle = forwardRef((props, ref) => {
    
    return (
        <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="umh-heading">
                            <h2>7 Days Umrah Package from {props.citySeven}</h2>
                            <p>{props.paragraphSeven}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SevenTitle;

