import React from 'react'
import Features from "../Home/Features";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
function TermsConditions() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Helmet>
                <title >{"Malik Express Travel & Tours | Terms and Conditions"}</title>
                <meta name="description" content={"Read our terms and conditions to learn more about our services and ensure a smooth journey. Discover how to make the most of your travel with Malik Express."} data-react-helmet="true" />
                <meta name="keywords" content={"Malik Express Travel & Tours, terms and condition"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/terms-and-condition" />
            </Helmet>
            <MainMenu></MainMenu>
            <section className="page-cover" id="cover-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="page-title">Terms & Conditions Malik Express</h1>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item">Terms & Conditions </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

            <section class="innerpage-wrapper mt-5">
            <div className="container mt-5">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading ">
                            <h2>Terms & Conditions</h2>
                            <hr className="heading-line" />
                        </div>

                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p> Welcome to Malik Express Travel & Tours! We are dedicated to providing quality travel and tour services to make your trip memorable.
                                Before you get started with our website, please read the following terms and conditions carefully. You agree to follow these terms to use Malik Express's website.
                            </p>
                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>General Terms</h2>
                            <hr className="heading-line" />
                        </div>

                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>We take pride in providing top-notch services related to travel and tours. Our website offers general information, which we strive to
                                keep as accurate as possible, but please note that we cannot guarantee its completeness or timeliness.</p>
                            <p>At Malik Express Travel & Tours, we are committed to meeting your needs by constantly improving. It is our ambition to offer exceptional customer
                                experience to our users by regularly updating and enhancing our website, content and services. As part of our dedication to innovation, we retain
                                the right to modify or discontinue any aspect of our site without prior notice.</p>
                            <p>We thank you for choosing Malik Express Travel & Tours and we are excited to embark on this journey together with you!</p>


                        </div>

                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>Intellectual Property</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>The content, design, and logo that comprise the Malik Express Travel & Tours website are protected under intellectual property laws. You must first obtain written permission to use, reproduce, modify, distribute, or display any aspect of our site. However, we invite you to print or download materials for personal, non-commercial use. Be mindful that unauthorized use of these materials may breach copyright, trademark, and other applicable laws.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>User Conduct</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>Welcome to our website! By using our services, you accept to comply with certain conduct standards. To ensure smooth operations, kindly observe the following:</p>
                            <ul>
                                <li>Provide accurate, up-to-date, and complete information.</li>
                                <li>Use the website only for legitimate purposes and abstain from illegal activities.</li>
                                <li>Be respectful of the individual rights of fellow users.</li>
                                <li>Refrain from meddling with the functioning of the website or its services.</li>
                            </ul>
                            <p>Thank you for your cooperation and happy browsing!</p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>Third-Party Links</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>As you traverse our website, you may encounter links to third-party websites that are neither owned nor operated by Malik Express Travel & Tours. Although we have no control over the content or practices of these websites, we urge you to review their terms and privacy policies before use. By using such links, you assume all risks associated with using these sites.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>Limitation of Liability</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>Discover a journey without limits with Malik Express Travel & Tours. While our experts strive for our content and site's accuracy, reliability, and availability, we do not make warranties or representations, expressed or implied. Hence, we assume no liability for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use of our website and services. Experience the best with us while disclaiming all warranties of any kind, including implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                            </p>
                        </div>
                    </div>
                </div>


                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>Privacy</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>We collect and use personal information following our Privacy Policy. By using our website, you consent to collecting and using your personal information as described in our Privacy Policy.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>Governing Law</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>The terms and conditions herein shall be governed by and construed following the laws of Pakistan, which wield exclusive jurisdiction over any disputes arising out of or in connection with these terms. Rest assured that our agreement is steadfastly binding, transparent, and subject to Pakistani governing laws which assure that we abide by the highest standards of professional conduct.
                            </p>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading mt-5">
                            <h2>Changes to the Terms and Conditions</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="carousel slide" data-ride="carousel" id="quote-carousel">
                            <p>You can count on Malik Express Travel & Tours to keep things running smoothly. We may occasionally update or modify these terms and conditions but rest assured we will always keep you in the loop. Make it a point to check back here periodically to stay informed. By continuing to use our website, you're essentially saying 'yes' to these terms. Don't hesitate to reach out to Malik Express's team if you have any queries or concerns regarding our services.
                            </p>
                        </div>
                    </div>
                </div>

            </div>
            </section>

            <Features></Features>
            <Footer></Footer>

        </>
    )
}

export default TermsConditions;