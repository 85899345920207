import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import { FaPlus, FaMinus, FaTimes, FaEnvelope } from 'react-icons/fa'; // Import the icons from the react-icons library
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from "react-rainbow-components";
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';

function Unisersal_booking() {

    const navigate = useNavigate();
    const location = useLocation();
    const { passengertype, area, Premium, plan, departureDate, arrivalDate, country, country1, United, dob,

    } = location.state || {};

    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = apiUrl + "/api/Insurance";
    const [countries, setCountries] = useState([]);
    const [timeout, setTimeout] = useState('')
    const [isFlightDetailsVisible, setFlightDetailsVisible] = useState(false);
    const [icon, setIcon] = useState(<FaPlus />); // Initialize with the "plus" icon
    const [enquireVisible, setEnquireVisible] = useState(false);
    const [fname, setFname] = useState('')
    const [lname, setLname] = useState('')
    const [cnic, setCnic] = useState('')
    const [cincdate, setCincdate] = useState('')
    const [dob1, setdob1] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [passport, setPassport] = useState('')
    const [visit, setVisit] = useState('')
    const [address, setAddress] = useState('')
    const [Bfname, setBfname] = useState('')
    const [Blname, setBlname] = useState('')
    const [Brelation, setBrelation] = useState('')
    const [city, setCity] = useState('')
    const [fullname, setFullname] = useState('')
    const [dob2, setDob2] = useState('')
    const [passport2, setPassport2] = useState('')
    const [loading, setLoading] = useState(false);
    const [responcee, setResponcee] = useState('')

    const [dobDisabled, setDobDisabled] = useState(!!dob);
    const Premiumplus = (Premium * 1.15).toFixed(0)

    console.log('united', United)
    const entryDateTime = new Date(); // Create a new Date object

    // Extract the required date and time information
    const year = entryDateTime.getFullYear(); // Get the year (YYYY)
    const month = entryDateTime.getMonth() + 1; // Get the month (0-11) and add 1 to convert to (1-12)
    const date = entryDateTime.getDate(); // Get the day of the month (1-31)
    const hours = entryDateTime.getHours(); // Get the hours (0-23)
    const minutes = entryDateTime.getMinutes(); // Get the minutes (0-59)
    const seconds = entryDateTime.getSeconds(); // Get the seconds (0-59)

    // Format the date and time as required
    const formattedEntryDateTime = `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;

    const [formvalue, setFormvalue] = useState({
        First_name: fname,
        Last_name: lname,
        Cnic: cnic,
        Dob: dob ? dob : null,
        Mobile_no: phone,
        Email: email,
        Passport_no: passport,
        Purpose_Visit: visit,
        Address: address,
        City: city,
        NoOfDays: United.Duration,
        AreaShortCode: area,
        Passengertype: passengertype,
        InsuranceId: '10',
        DepartureDate: departureDate,
        ArrivalDate: arrivalDate,
        Country1: country,
        Country2: country1,
        Original_Price: Premiumplus,
        Discount_Price: Premiumplus,
        Vendor: 'UIC',
        First_nameB: '',
        Last_nameB: '',
        RelationB: '',


    });
    const [formvalue2, setFormvalue2] = useState({});

    useEffect(() => {
        setFormvalue2({
            PDays: United.PDays,
            Duration: United.Duration,
            DurationUnit: United.DurationUnit,
            AreaShortCode: United.AreaShortCode,
            Area: United.Area,
            Plan: United.Plan,
            PlanType: United.PlanType,
            Premium: Premiumplus,
            AgePremIncrease: United.AgePremIncrease,
            GrossPremium: Premiumplus,
            AdvanceTax: United.AdvanceTax,
            TravelerName: `${formvalue.First_name} ${formvalue.Last_name}`,
            TravelerNICNo: formvalue.Cnic,
            TravelerNTNNo: null,
            IsTaxPayer: United.IsTaxPayer === '1' ? true : false,
            TotalPayablePremium: Premiumplus,
            TravelerDOB: formvalue.Dob,
            TravelerAge: null,
            Covid: "Covered",
            MedicalExpense: "Covered",
            DepartureDate: departureDate,
            ArrivalDate: arrivalDate,
            FirstCountry: country,
            SecondCountry: country1,
            ContactNumber: formvalue.Mobile_no,
            Passport: formvalue.Passport_no,
            ResidentialAddress: formvalue.Address,
            CityofResidence: formvalue.City,
            EmailAddress: formvalue.Email,
            EntryDateTime: formattedEntryDateTime,
            BeneficiaryFirstName: formvalue.First_nameB,
            BeneficiaryLastName: formvalue.Last_nameB,
            BeneficiaryRelation: formvalue.RelationB,
            BeneficiaryCNIC: null,
            BeneficiaryContactNumber: null,
            BeneficiaryAddress: null,
            DiscountedPrice: Premiumplus,
            PaymentMessage: null,
            PolicyNumber: null,
            PaymentStatus: false,
        });
    }, [formvalue]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    console.log('form values is', formvalue2)
    const handleInputChange = (fieldName, value) => {
        let formattedValue = value;

        // Format the date if the field is 'Dob'
        if (fieldName === 'Dob' && value instanceof Date) {
            formattedValue = format(value, 'yyyy-MM-dd') // Adjust the locale as needed
        }
        switch (fieldName) {
            case 'First_name':
                setFname(value);
                break;
            case 'Last_name':
                setLname(value);
                break;
            case 'Cnic':
                setCnic(value);
                break;

            case 'Dob':
                setdob1(formattedValue); // Use the formatted value
                break;
            case 'Mobile_no':
                setPhone(value);
                break;
            case 'Email':
                setEmail(value);
                break;
            case 'Passport_no':
                setPassport(value);
                break;
            case 'Purpose_Visit':
                setVisit(value);
                break;
            case 'Address':
                setAddress(value);
                break;
            case 'City':
                setCity(value);
                break;
            case 'First_nameB':
                setBfname(value);
                break;
            case 'Last_nameB':
                setBlname(value);
                break;
            case 'RelationB':
                setBrelation(value);
                break;

            default:
                break;
        }
        setFormvalue((prevValues) => ({
            ...prevValues,
            [fieldName]: formattedValue,
        }));
    };
    const handleChildInputChange = (fieldName, value, i) => {
        let formattedValue = value;

        // Format the date if the field is `DobC_${i}`
        if (fieldName === `DobC_${i}` && value instanceof Date) {
            formattedValue = format(value, 'yyyy-MM-dd'); // Adjust the locale as needed
        }

        setFormvalue2((prevValues) => ({
            ...prevValues,
            [fieldName]: formattedValue,
        }));
    };

    const handleAdultInputChange = (fieldName, value) => {
        let formattedValue = value;


        if (fieldName === 'DobA_1' && value instanceof Date) {
            formattedValue = format(value, 'yyyy-MM-dd'); // Adjust the locale as needed
        }

        setFormvalue2((prevValues) => ({
            ...prevValues,
            [fieldName]: formattedValue,
        }));
    };
    useEffect(() => {
        fetchCountries();
        setTimeout(() => {
            setIsLoading(false); // Set isLoading to false once the content is loaded

        }, 2000); // Adjust the duration as needed

    }, []);
    async function fetchCountries() {
        await axios
            .get(apiUrl + "/api/flightsearch/pakcities")
            .then((response) => {
                const returnData = response.data;
                //retData= response.data;
                // console.log(response.data);
                setTimeout(() => {
                    setCountries(response.data);
                }, 2000);
                // console.log(data);
            })
            .catch((error) => {
                console.log(error);
            });
    }
    const toggleFlightDetails = () => {
        setFlightDetailsVisible(!isFlightDetailsVisible);

        // Change the icon based on visibility
        setIcon(isFlightDetailsVisible ? <FaPlus /> : <FaMinus />);
        setEnquireVisible(false)
    };

    const toggleEnquireDetails = () => {

        setEnquireVisible(!enquireVisible);
        setFlightDetailsVisible(false)
        setIcon(<FaPlus />)

    };
    const handleCloseClick = () => {
        setEnquireVisible(false);
    };
    const onEdit = () => {
        navigate('/online-travel-insurance-pakistan');
    }

    function postData() {
        setLoading(true);
        const instance = axios.create({
            baseURL: url,
            timeout: 10000,
            headers: { "X-Custom-Header": "foobar" },
        });
        if (formvalue.First_name != undefined) {
            if (formvalue.First_name.length < 2) {
                alert("Please provide first name at least 2 characters.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide first name at least 2 characters.");
            setLoading(false)
            return;
        }
        if (formvalue.Last_name != undefined) {
            if (formvalue.Last_name.length < 1) {
                alert("Please provide last name at least 2 characters.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide last name at least 2 characters.");
            setLoading(false)
            return;
        }
        if (formvalue.Cnic != undefined) {
            if (formvalue.Cnic.length < 13) {
                alert("Please provide Cnic number at least 13 characters.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide Cnic number at least 13 characters.");
            setLoading(false)
            return;
        }

        if (formvalue.Dob != undefined) {
            if (formvalue.Dob.length < 10) {
                alert("Please provide Date of birth.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide  Date of birth");
            setLoading(false)
            return;
        }
        if (formvalue.Mobile_no != undefined) {
            if (formvalue.Mobile_no.length < 11) {
                alert("Please provide  Mobile number Ex : 03111276278.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide  Mobile number Ex : 03101111112.");
            setLoading(false)
            return;
        }
        // Check if Email field is not undefined or empty
        if (!formvalue.Email) {
            alert("Please provide an Email.");
            setLoading(false)
            return;
        } else {
            // Regular expression to validate email format
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!emailRegex.test(formvalue.Email)) {
                alert("Please provide a valid Email.");
                setLoading(false)
                return;
            }
        }

        if (formvalue.Passport_no != undefined) {
            if (formvalue.Passport_no.length < 6) {
                alert("Please provide Passport number at least 6 characters.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide Passport number at least 6 characters.");
            setLoading(false)
            return;
        }

        if (formvalue.Address != undefined) {
            if (formvalue.Address.length < 1) {
                alert("Please provide Address.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide Address.");
            setLoading(false)
            return;
        }
        if (formvalue.City != undefined) {
            if (formvalue.City.length < 1) {
                alert("Please select city.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please select city.");
            setLoading(false)
            return;
        }

        if (formvalue.First_nameB != undefined) {
            if (formvalue.First_nameB.length < 2) {
                alert("Please provide First name at least 2 characters.");
                setLoading(false)
                return;

            }
        } else {
            alert("Please provide First name at least 2 characters.");
            setLoading(false)
            return;
        }

        if (formvalue.Last_nameB != undefined) {
            if (formvalue.Last_nameB.length < 2) {
                alert("Please provide last name at least 2 characters.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide last name at least 2 characters.");
            setLoading(false)
            return;
        }
        if (formvalue.RelationB != undefined) {
            if (formvalue.RelationB.length < 1) {
                alert("Please provide Relation.");
                setLoading(false)
                return;
            }
        } else {
            alert("Please provide Relation.");
            setLoading(false)
            return;
        }



        const jsonData = JSON.stringify(formvalue2);
        console.log('json is',jsonData)
        axios
            .post(url + "/uicinsurancebooking", jsonData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                setResponcee(response.data)
                if (response.status === 200) {
                    const BookingID = response.data
                    console.log('booking', BookingID)
                    navigate('/Review_Universal', { state: { formvalue, United, arrivalDate, departureDate, passengertype, area, Premium, plan, dob, BookingID } });
                } else {

                    console.error("Unexpected response status:", response.status);
                }
            })
            .catch((error) => console.error(error));
    }
    const handleClick = (e) => {
        postData();
    };
    const [maxDate, setMaxDate] = useState(getTodayDate());

    function getTodayDate() {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const [showAdult, setShowAdult] = useState(false);
    const handleAddCountryClick = () => {
        setShowAdult(!showAdult);
    };
    const [numberOfChildren, setNumberOfChildren] = useState(1);
    const handleAddChild = () => {
        if (numberOfChildren < 4) {
            setNumberOfChildren(prevCount => prevCount + 1);
        }
    };
    const handleRemoveChild = (childIndex) => {
        setNumberOfChildren(prevCount => prevCount - 1);
    };
    const renderChildSections = () => {
        const childSections = [];

        for (let i = 1; i <= numberOfChildren; i++) {
            const childKey = `Child_${i}`;

            childSections.push(
                <div key={i}>
                    <h2 style={{ color: '#e5b622', position: 'relative', marginLeft: '17px', marginRight: '17px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>
                        {i === 1 ? 'Children Information:' : `Child ${i}:`}
                        {i > 1 && (
                            <span
                                style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: '#2a3b8f', fontSize: '25px', fontWeight: 'bold' }}
                                onClick={() => handleRemoveChild(i)}
                            >
                                &#x2715; {/* Close icon (×) */}
                            </span>
                        )}
                    </h2>
                    <div className="row m-4" style={{ borderBottom: '1px solid #ccc' }}>
                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="mb-2">Full Name</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder=""
                                    name={`FullnameC_${i}`} // Use dynamic keys for each child
                                    onChange={(e) => handleChildInputChange(`FullnameC_${i}`, e.target.value, i)}
                                />
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="mb-2">Date of Birth</label>

                                <DatePicker
                                    selected={formvalue2[`DobC_${i}`] ? new Date(formvalue2[`DobC_${i}`]) : new Date()}
                                    value={formvalue2[`DobC_${i}`]}
                                    onChange={(date) => handleChildInputChange(`DobC_${i}`, date, i)}
                                    dateFormat="dd/MM/yyyy"
                                    id={`DobC_${i}`}
                                    name={`DobC_${i}`}
                                    placeholderText="Date of Birth"
                                    required
                                    showYearDropdown
                                    showMonthDropdown
                                    yearDropdownItemNumber={100}
                                    scrollableYearDropdown
                                    showMonthYearDropdown={false}
                                    minDate={subYears(new Date(), 18)}
                                    maxDate={new Date()}
                                />


                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group">
                                <label className="mb-2">Passport Number</label>
                                <input
                                    type="text"
                                    required
                                    className="form-control"
                                    placeholder=""
                                    name={`PassportC_${i}`}
                                    onChange={(e) => handleChildInputChange(`PassportC_${i}`, e.target.value, i)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return childSections;
    };

    return (

        <>
            <Helmet>
                <title >{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>

                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (

                <section className="innerpage-wrapper">




                    <div className="card">

                        <div className="tab-navigation">
                            <div id="travel-tabs">
                                <ul className="nav2 nav-tabs t-payment-tab" role="tablist">
                                    <li className="p-info-li  active">
                                        <a role="tab" className="cartTabBtn">
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Personal Details</h5>
                                            </div>
                                        </a>
                                    </li>
                                   
                                    <li className="review-info-li">
                                        <a role="tab" className="cartTabBtn" >
                                            <div className="s-no">

                                            </div>
                                            <div className="tab-name">
                                                <h5>Review Information</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="select-payment-li">
                                        <a role="tab" className="cartTabBtn" >
                                            <div className="s-no">
                                            </div>
                                            <div className="tab-name">
                                                <h5>Select Payment Option</h5>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>

                    </div>



                    <div id="travel-insurance" className="innerpage-section-paddingg">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-9 mt-2">
                                    <form>
                                        <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>
                                            <div style={{ display: 'none' }}> 3210330EK.png</div>
                                            <div className="card2 mt-0" style={{ borderRadius: '20px' }}>
                                                <h2 style={{ color: '#e5b622', marginLeft: '17px', marginRight: '17px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>Personal Details:</h2>
                                                <div className="row m-4" style={{ borderBottom: '1px solid #ccc', }}>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">First Name</label>
                                                            <input type="text"
                                                                required className="form-control"
                                                                placeholder=""
                                                                name="name"
                                                                value={fname}
                                                                onChange={(e) => handleInputChange('First_name', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Last Name</label>
                                                            <input type="text"
                                                                required className="form-control"
                                                                placeholder=""
                                                                name="name"
                                                                value={lname}
                                                                onChange={(e) => handleInputChange('Last_name', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">CNIC</label>
                                                            <input type="text" required className="form-control" placeholder="" name="cnic"
                                                                value={cnic}
                                                                onChange={(e) => handleInputChange('Cnic', e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">

                                                        <div class="form-group"><label className="mb-2">Date of Birth</label>

                                                            <DatePicker
                                                                selected={formvalue.Dob ? new Date(formvalue.Dob) : null}
                                                                value={formvalue.Dob}
                                                                onChange={(date) => handleInputChange('DOB', date)}
                                                                dateFormat="dd/MM/yyyy"

                                                                id="dob_0"
                                                                name="dob_0"
                                                                placeholderText="Date of Birth"
                                                                required
                                                                showYearDropdown
                                                                showMonthDropdown
                                                                yearDropdownItemNumber={100}
                                                                scrollableYearDropdown
                                                                showMonthYearDropdown={false}
                                                                minDate={subYears(new Date(), 100)}
                                                                maxDate={subYears(new Date(), 0)}
                                                                disabled={dobDisabled}
                                                            />
                                                        </div>


                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Contact Number</label>
                                                            <input type="tel" required className="form-control" placeholder="" name="tel"
                                                                value={phone}
                                                                onChange={(e) => handleInputChange('Mobile_no', e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Purpose of Visit</label>
                                                            <select class="form-control" required="" name="purpose_of_visit" id="purpose_of_visit" aria-required="true"
                                                                value={visit}
                                                                onChange={(e) => handleInputChange('Purpose_Visit', e.target.value)}
                                                            >
                                                                <option value="" disabled="" selected="">Purpose of Visit:</option>
                                                                <option value="Business">Business</option>
                                                                <option value="Educational">Educational</option>
                                                                <option value="Holiday">Holiday</option>
                                                                <option value="Leisure">Leisure</option>
                                                                <option value="Official">Official</option>
                                                                <option value="Pilgrimage">Pilgrimage</option>
                                                                <option value="Professional">Professional</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Email Address</label>
                                                            <input type="email" required className="form-control" placeholder="" name="email"
                                                                value={email}
                                                                onChange={(e) => handleInputChange('Email', e.target.value)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Passport Number</label>
                                                            <input type="text" required className="form-control" placeholder="" name="passport"
                                                                value={passport}
                                                                onChange={(e) => handleInputChange('Passport_no', e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">Residential Address</label>
                                                            <input type="text" required className="form-control" placeholder="" name="Address"
                                                                value={address}
                                                                onChange={(e) => handleInputChange('Address', e.target.value)} />
                                                        </div>
                                                    </div>

                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="mb-2">City of Residence</label>
                                                            <select
                                                                className="form-control"
                                                                id="nationality_0"
                                                                name="nationality_0"
                                                                required
                                                                value={city}
                                                                onChange={(e) => handleInputChange('City', e.target.value)}
                                                            >
                                                                <option value="" selected>
                                                                    Select City
                                                                </option>
                                                                {countries.map((option) => (
                                                                    <option key={option.Id} value={option.Value}>
                                                                        {option.Value}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>



                                                    {passengertype == 'family' && (
                                                        <div className="col-md-4">

                                                            <div className="form-group">
                                                                <label style={{ color: 'red', width: '100%' }}>*Add Another Adult</label>
                                                                <button type="button" className="btn btn-orange mt-2" onClick={handleAddCountryClick}>
                                                                    Add Adult
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}

                                                </div>



                                                {showAdult && (
                                                    <div>
                                                        <h2 style={{ color: '#e5b622', position: 'relative', marginLeft: '17px', marginRight: '17px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>
                                                            Second Adult
                                                            <span
                                                                style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer', color: '#2a3b8f', fontSize: '25px', fontWeight: 'bold' }}
                                                                onClick={() => setShowAdult(false)}
                                                            >
                                                                &#x2715; {/* Close icon (×) */}
                                                            </span>

                                                        </h2>
                                                        <div className="row m-4" style={{ borderBottom: '1px solid #ccc', }}>

                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="mb-2">Full Name</label>
                                                                    <input type="text" required className="form-control" placeholder="" name="FullNameA_1" id="FullNameA_1"
                                                                        onChange={(e) => handleAdultInputChange(`FullNameA_1`, e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-md-4">

                                                                <div class="form-group"><label className="mb-2">Date of Birth</label>


                                                                    <DatePicker
                                                                        selected={formvalue2.DobA_1 ? new Date(formvalue2.DobA_1) : subYears(new Date(), 18)}
                                                                        value={formvalue2.DobA_1}
                                                                        onChange={(date) => handleAdultInputChange('DobA_1', date)}
                                                                        dateFormat="dd/MM/yyyy"
                                                                        id="DobA_1"
                                                                        name="DobA_1"
                                                                        placeholderText="Date of Birth"
                                                                        required
                                                                        showYearDropdown
                                                                        showMonthDropdown
                                                                        yearDropdownItemNumber={100}
                                                                        scrollableYearDropdown
                                                                        showMonthYearDropdown={false}
                                                                        minDate={subYears(new Date(), 100)} // This sets the minimum date 100 years ago
                                                                        maxDate={new Date()}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="form-group">
                                                                    <label className="mb-2">Passport Number</label>
                                                                    <input type="text" required className="form-control" placeholder="" name="PassportA_1" id="PassportA_1"
                                                                        onChange={(e) => handleAdultInputChange(`PassportA_1`, e.target.value)} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                                {passengertype == 'family' && (
                                                    <div>
                                                        {renderChildSections()}
                                                        <div className="col-md-5 ml-4">
                                                            <div className="form-group">
                                                                <label style={{ color: 'red', width: '100%' }}>*Add Up to Four Children (under 18 Years)</label>
                                                                <button type="button"
                                                                    className="btn btn-orange"
                                                                    onClick={handleAddChild}
                                                                    disabled={numberOfChildren === 3} // Disable the button when the maximum number of children is reached
                                                                >
                                                                    Add Child
                                                                </button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )}

                                                <div>
                                                    <h2 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>Beneficiary Details:</h2>


                                                    <div className="row m-4">
                                                        <p className="ppp">A beneficiary is a close relative such as Father, Brother, Spouse etc who gets paid by the insurance company in
                                                            case of claim due to death of insured person or in other cases as covered by your insurance policy</p>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="mb-2">First Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input type="text" required className="form-control" placeholder=""
                                                                    name="First_nameB"
                                                                    value={formvalue.First_nameB || ''}
                                                                    onChange={(e) => handleInputChange('First_nameB', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="mb-2">Last Name <span style={{ color: 'red' }}>*</span></label>
                                                                <input type="text" required className="form-control" placeholder=""
                                                                    name="Last_nameB"
                                                                    value={formvalue.Last_nameB || ''}
                                                                    onChange={(e) => handleInputChange('Last_nameB', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="form-group">
                                                                <label className="mb-2">Beneficiary Relation <span style={{ color: 'red' }}>*</span></label>
                                                                <input
                                                                    type="text"
                                                                    required
                                                                    className="form-control"
                                                                    placeholder=""
                                                                    name="RelationB"
                                                                    value={formvalue.RelationB || ''}
                                                                    onChange={(e) => handleInputChange('RelationB', e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>








                                                </div>
                                                <div className="center2 pt-5 pb-5">

                                                    <button
                                                        onClick={handleClick}
                                                        type="button"
                                                        className="btn-lg btn-orange fn semibold"
                                                        disabled={loading} // Disable the button when loading
                                                    >
                                                        {loading ? (
                                                            <span>Loading...</span>
                                                        ) : (
                                                            <>
                                                                Next
                                                                <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                                                            </>
                                                        )}
                                                    </button>
                                                </div>







                                            </div>


                                        </div>
                                    </form>



                                </div>
                                <div class="col-12 col-md-12 col-lg-3 mb-2">
                                    <div id="2" className="list-block main-block f-list-block" style={{ display: 'block' }}>

                                        <div className="card mt-2" style={{ borderRadius: '20px' }}>
                                            <div className="h-pro">
                                                <div className="tumb">
                                                    <img
                                                        className="lozad"
                                                        src="../images/UIC.png"
                                                        alt="Habib Insurance Gold - Worldwide"
                                                    />
                                                </div>

                                                {United ? (
                                                    <div className="containerrs">
                                                        <div className="left-corner">
                                                            Plan:
                                                        </div>
                                                        <div className="right-corner">
                                                            {plan}
                                                        </div>

                                                        <div className="left-corner">
                                                            Coverage Upto:
                                                        </div>
                                                        <div className="right-corner">
                                                            {`${United.Duration} days`}
                                                        </div>

                                                        <div className="left-corner">
                                                            Premium:
                                                        </div>
                                                        <div className="right-corner">
                                                            <p className="pricce">
                                                                <b>{`Rs.${Premiumplus}`}</b>
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>

            )}

            <Footer></Footer>
        </>
    );

}

export default Unisersal_booking;