import React, { useState, useEffect } from 'react';
import TourItem from './TourItem';
import { Carousel } from 'react-bootstrap';


const ToursSlider = () => {


    
    const tours = [
        {
            id: 1,
            imageUrl: 'images/umrah/1.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Hiba Hijra 6',
            imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Meezab International',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '192,000 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 2,
            imageUrl: 'images/umrah/8.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Al Kiswah Towers',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Dar Wadyar Madinah',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '199,000 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 3,
            imageUrl: 'images/umrah/3.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Manzil Al Fateh(Marwa side)',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Manazil Marajan',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '201,500 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 4,
            imageUrl: 'images/umrah/4.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Johrah Al Majid',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Safa Tower',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '207,000 /PP',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 5,
            imageUrl: 'images/umrah/5.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Diwan Al Bait',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Najoom Al Madinah',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '216,000 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 6,
            imageUrl: 'images/umrah/6.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Mella 1 & 2',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Anwar Taqwa',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '218,000 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 7,
            imageUrl: 'images/umrah/7.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Saif Al Majd',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Kanzool Al Taqwa',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '220,500 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 8,
            imageUrl: 'images/umrah/8.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Badar Al Massa',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Bir Al Eiman',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '238,500 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
        {
            id: 9,
            imageUrl: 'images/umrah/9.png',
            title: '21 Days Umrah Package',
            imageUrl2: 'images/PckgIcon/QabahIcon.png',
            makkahDays: 'Makkah 12 Nights',
            makkahHotel: 'Nawara Shams 2',
             imageUrl3: 'images/PckgIcon/madina.png ',
            madinahDays: 'Madinah 8 Nights',
            madinahHotel: 'Teif Nibras',
            rate: '(similar)',
            imageUrl4: 'https://www.perfectumrah.pk/images/3-star.png',
            price: '248,500 /PP ',
            currency: 'PKR',
            // link: '21-nights-umrah-package-01.php'
        },
    ];
    const getVisibleTours = () => {
        const screenWidth = window.innerWidth;
        if (screenWidth >= 1200) {
            return 3;
        } else if (screenWidth >= 768) {
            return 2;
        } else {
            return 1;
        }
    };

    const [visibleTours, setVisibleTours] = React.useState(getVisibleTours());

    React.useEffect(() => {
        const handleResize = () => {
            setVisibleTours(getVisibleTours());
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const tourChunks = [];
    for (let i = 0; i < tours.length; i += visibleTours) {
        tourChunks.push(tours.slice(i, i + visibleTours));
    }

    return (
        <section className='outer-sect'>
        <div className='container'>
          
            <div className="product-carousel">
                <Carousel indicators={true} controls={false} interval={null}>
                    {tourChunks.map((tourChunk, index) => (
                        <Carousel.Item key={index}>
                            <div className="d-flex">
                                {tourChunk.map((tour) => (
                                    <TourItem key={tour.id} tour={tour} />
                                ))}
                            </div>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
           
        </div>
         </section>
    );
};

export default ToursSlider;