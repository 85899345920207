import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Collapse, Tabs } from "antd";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import ImageSupport from "../../Icons/support.png"
import './Popup.css';

function Hitit({ props, onDataAvailable, isDisplayed, updateHitItStopsData, updateHitItCarriersData, selectedStops, selectedCarriers }) {
  const apiUrl = process.env.REACT_APP_API_URL;

  const CardHeader = {
    backgroundColor: 'rgba(0,0,0,.03)'
  };

  const SelectedCardHeader = {
    backgroundColor: 'skyblue'
  };

  let Parameters = props;


  let PKLogo = "images/airlines/PK.png"
  let joke = Parameters.isDisplayed;

  let FlightClass = Parameters.FlightClass;
  let FlightType = Parameters.FlightType;
  let departureCity = Parameters.departureCity;
  let arrivalCity = Parameters.arrivalCity;
  let StartDate = Parameters.StartDate;
  let EndDate = Parameters.EndDate;
  let Adults = Parameters.Adults;
  let Children = Parameters.Children;
  let Infants = Parameters.Infants;
  let MultiFirstDepartureCity = Parameters.MultiFirstDepartureCity;
  let MultiFirstArrivalCity = Parameters.MultiFirstArrivalCity;
  let MultiSecondDepartureCity = Parameters.MultiSecondDepartureCity;
  let MultiSecondArrivalCity = Parameters.MultiSecondArrivalCity;
  let MultiThirdDepartureCity = Parameters.MultiThirdDepartureCity;
  let MultiThirdArrivalCity = Parameters.MultiThirdArrivalCity;
  let MultiFourthDepartureCity = Parameters.MultiFourthDepartureCity;
  let MultiFourthArrivalCity = Parameters.MultiFourthArrivalCity;
  let MultiFifthDepartureCity = Parameters.MultiFifthDepartureCity;
  let MultiFifthArrivalCity = Parameters.MultiFifthArrivalCity;
  let MultiSixthDepartureCity = Parameters.MultiSixthDepartureCity;
  let MultiSixthArrivalCity = Parameters.MultiSixthArrivalCity;
  let StartMultiFirstDate = Parameters.StartMultiFirstDate;
  let StartMultiSecondDate = Parameters.StartMultiSecondDate;
  let StartMultiThirdDate = Parameters.StartMultiThirdDate;
  let StartMultiFourthDate = Parameters.StartMultiFourthDate;
  let StartMultiFifthDate = Parameters.StartMultiFifthDate;
  let StartMultiSixthDate = Parameters.StartMultiSixthDate;

  let depCity = departureCity.slice(-4);
  depCity = depCity.replace(')', '');
  const [origin, setOrigin] = useState("");

  let arrCity = arrivalCity.slice(-4);
  arrCity = arrCity.replace(')', '');

  let startDate = StartDate.replace('/', '-');

  startDate = startDate.replace('/', '-');

  let url = apiUrl + "/api/ndc/hititflights?tripType=OneWay&from=" + depCity + "&to=" + arrCity + "&adults=" + Adults + "&children=" + Children + "&infants=" + Infants + "&Dept=" + startDate + "&Arr=" + startDate + "&fromM_1=null&fromM_2=null&fromM_3=null&fromM_4=null&fromM_5=null&fromM_6=null&toM_1=null&toM_2=null&toM_3=null&toM_4=null&toM_5=null&toM_6=null&start=" + startDate + "&airline=null&userid=-1"  ;
 console.log('hiit url',url)

  const [isLoading, setIsLoading] = useState(false);
  const [deptOptionId, setDeptOptionId] = useState(-1);
  const [bookrescode, setBookResId] = useState("");

  const [cabin, setCabinId] = useState("");
  const [boundCode, setBoundCode] = useState("");
  const [segmentCode, setSegmentId] = useState("");
  const [DirectCode, setDirectId] = useState("");


  const [CardBg, setCardBg] = useState(CardHeader);

  const [fullname, setfullname] = useState([]);
  const [email, setemail] = useState([]);
  const [mobilenumber, setmobilenumber] = useState([]);
  const [lowfare, setlowfare] = useState([]);



  const [data, setData] = useState([]); //original Departure data
  const [originalData, setOriginalData] = useState([]); //Departure

  const [returnData, setreturnData] = useState([]); //Return
  const [originalReturnData, setoriginalReturnData] = useState([]); //Return

  const [DepButtonText, setDepButtonText] = useState("Book This");

  const { Panel } = Collapse;
  const [loading, setLoading] = useState(false);
  const [HideReturnFlights, setHideReturnFlights] = useState(true);
  const [HideDepartureFlights, setHideDepartureFlights] = useState(false);


  const [FiltersToggle, setFiltersToggle] = useState(false)

  const navigate = useNavigate();


  let adults = 0;
  let children = 0;
  let infants = 0;
  let Origin = "";
  let Destination = "";
  let duration = 0;
  let hours = 0;
  let minutes = 0;
  let divId = 1;


  let img = "";

  let DepDirectFlights;
  let DepConnectingMainFLights;
  let DepConnectingFLights;

  let RetDirectFlights;
  let RetConnectingMainFLights;
  let RetConnectingFLights;

  let FilterDepartureFlights;
  let FilterReturnFlights;

  const [Stops, setStops] = useState(['0', '1', '2']);
  //const [DepartureTimes, setDepartureTimes] = useState([]);
  const [Airlines, setAirlines] = useState(['PK']);

  const previousDataRef = useRef(null);

  // const [stopCheckboxes, setStopCheckboxes] = useState([ { id: '0', name: '0', label: '0', checked: false },]);
  const [stopCheckboxes, setStopCheckboxes] = useState([]);
  const [airlineCheckboxes, setairlineCheckboxes] = useState([]);

  const [formData, updateFormData] = React.useState([{ adults: "", childred: "", infants: "", BookingID: "" }]);
  const [Classvalue, setRadioValue] = useState("Economy");
  const [DepartureCity, setDepartureCity] = useState("");
  const [ArrivalCity, setArrivalCity] = useState("");
  const [FlightTypevalue, setFlightTypevalue] = useState("OneWay");
  const divRef = useRef(0);
  let flightType = "";

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [formValue, setformValue] = React.useState({
    fullName: "",
    mobileNum: "",
    email: "",
    URL: "",
    LowFare: ""
  });

  let lowFare = 0;
  const handleNameChange = () => {
    setfullname()
  }

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  function handleSavePopup() {
    const jsonData = JSON.stringify(formValue);
    axios
      .post(apiUrl + "/api/HitItFlightSearch/lowpricealert", jsonData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const jazzData = response.data;
      })
      .catch((error) => console.error(error));
    setIsPopupOpen(false);
  }

  function findObjectWithSmallestPrice(arr) {
    if (arr.length === 0) {
      return null; // Return null for an empty array, indicating no object found.
    }

    let smallestPriceObject = arr[0]; // Initialize with the first element
    const key = "TotalAmount_Text";

    for (let i = 1; i < arr.length; i++) {
      if (arr[i][key] < smallestPriceObject[key]) {
        smallestPriceObject = arr[i];
      }
    }
    return smallestPriceObject;
  }


  //let url = apiUrl + "/api/FlightSearch/Results?type=OneWay&fromM_1=%20null&fromM_2=%20null&fromM_3=%20null&fromM_4=%20null&fromM_5=%20null&fromM_6=%20null&toM_1=%20null&toM_2=%20null&toM_3=%20null&toM_4=%20null&toM_5=%20null&toM_6=null&from=" + departureCity + "&to=" + arrivalCity + "&start=" + StartDate + "&end=" + EndDate + "&airline=%20null&service=%20null&adults=" + Adults + "&children=" + Children + "&infants=" + Infants + "&flexdate=0&directconecting=0&multipleshow=0&stops=-1";

  useEffect(() => {
    if (previousDataRef.current !== props || previousDataRef.current == null) {
      if (FlightType === 2) {
        fetchReturnData();
      }
      else {
        fetchData();
      }
      previousDataRef.current = props;
    }
    else {
    }
  }, [props.departureCity, props.arrivalCity, props.StartDate, props.EndDate, props.FlightType]);

  function removeLetters(str) {
    return str.replace(/[a-z]/gi, "");
  }

  function renderStops(e) {
    if (e === '2') {
      return <span>2 Stops</span>;
    }
    else if (e === '1') {
      return <span>1 Stop</span>;
    }
    else if (e === '0') {
      return <span>Direct</span>;
    }
    else {
      return null;
    }
  }

  const HandleOneWayClick = (e) => {
    let ID = e;
    const filteredArray = data.filter(element => element.PaxJourney_Id === ID);

    //navigate('/FlightBooking',{state:{ prop1: filteredArray}});     

    navigate('/HititBooking', { state: { props: filteredArray, adults: Adults, children: Children, infants: Infants, origin: depCity, destination: arrCity,startDate:StartDate,endDate:EndDate, logo: PKLogo, flightType: 'OneWay' } });

  };

  const ShowPopup = () => {

  };

  const HandleReturnClick = (e) => {

    let array1 = [];
    let array2 = [];

    array1 = data.filter(element => element.PaxJourney_Id === deptOptionId && (element.FlightType === "DM" || element.FlightType === "DC"));

    array2 = originalReturnData.filter(element => element.PaxJourney_Id === e && (element.FlightType === "RM" || element.FlightType === "RC"));
    const filteredArray = [...array1, ...array2];

    //navigate('/FlightBooking',{state:{ prop1: filteredArray}});     

    navigate('/HititBooking', { state: { props: filteredArray, adults: Adults, children: Children, infants: Infants, origin: depCity, destination: arrCity, logo: PKLogo, flightType: 'Return' } });

  };



  const handleClick = (event) => {
    if (HideReturnFlights === false) {
      if (data !== undefined) {
        FilterDepartureFlights = data.filter(
          (flights) => flights.PaxJourney_Id === event
        );

      }
      setData(FilterDepartureFlights); //filter data
      // setBookResId(FlightResCode);
      setDeptOptionId(event);
      setCabinId(cabin);
      setBoundCode(boundCode);
      // setSegmentId(segmentID);
      // setDirectId(Direct);
      if (returnData !== undefined) {
        FilterReturnFlights = returnData.filter(
          (flights) => flights.PaxJourney_Id === event
        );
        // Parameters.onSelectFlight("A");
        setreturnData(FilterReturnFlights); // filter data
      }

      if (FilterReturnFlights !== undefined) {
        RetDirectFlights = FilterReturnFlights.filter((flights) => flights.Direct === "1");

        RetConnectingMainFLights = FilterReturnFlights.filter(
          (flights) => flights.Direct === "0" && flights.FlightType === "RM"
        );

        RetConnectingFLights = FilterReturnFlights.filter(
          (flights) => flights.Direct === "" && flights.FlightType === "RC"
        );

        setCardBg(SelectedCardHeader);
        setDepButtonText("Change This");
        setHideReturnFlights(true);
        //setHideDepartureFlights(false);
      }
    } else {
      setCardBg(CardHeader);
      setData(originalData);
      setreturnData(originalReturnData);
      setDepButtonText("Select This");
      setHideReturnFlights(false);
      //setHideDepartureFlights(true);
    }
  };

  async function fetchData() {
    setIsLoading(true);
    // if (flightType === 'Return') {
    //   fetchReturnData();
    // }
    if (FlightType === 1) {
      flightType = "OneWay";
      //  setFlightTypevalue("OneWay")
    }
    else if (FlightType === 2) {
      flightType = "Return";
      //   setFlightTypevalue("Return")
    }
    else {
      flightType = "Multi";
      //    setFlightTypevalue("Multi")
    }
    await axios
      .get(url)
      .then((response) => {
        const data = response.data;
        console.log(data);
        setData(data);
        setOriginalData(data);
        const IsEqual = data === "something went wrong";
        {
          IsEqual ? onDataAvailable(false) : onDataAvailable(true)
        }
        setHideDepartureFlights(true);
        setHideReturnFlights(false);
        setFlightTypevalue("OneWay");
        //Filters for Search Bar
        if (response.data.length > 30) {
          const airlines = data.map(row => row.CarrierDesigCode)
          const uniqueAirlines = [...new Set(airlines)].sort();
          updateHitItCarriersData(uniqueAirlines);
          setAirlines(uniqueAirlines);
        }

        setIsLoading(false);
        // updateCarriersData(uniqueAirlines);
      })
    // .catch((error) => console.error("Error: ${error}"));
  }
  let endDate = EndDate.replace('/', '-');
  endDate = endDate.replace('/', '-');

  const retUrl = apiUrl + "/api/ndc/hititreturnflights?tripType=Return&from=" + depCity + "&to=" + arrCity + "&adults=" + Adults + "&children=" + Children + "&infants=" + Infants +"&Dept="+ startDate + "&Arr=" + endDate + "&fromM_1=null&fromM_2=null&fromM_3=null&fromM_4=null&fromM_5=null&fromM_6=null&toM_1=null&toM_2=null&toM_3=null&toM_4=null&toM_5=null&toM_6=null&start=" + startDate + "&airline=null&userid=-1";

  //const retUrl = apiUrl + "/api/HitItFlightSearch/hititflights?tripType=OneWay&from=" + arrCity + "&to=" + depCity + "&adults=1&children=0&infants=0&Dept=" + endDate + "&Arr=" + endDate + "&fromM_1=null&fromM_2=null&fromM_3=null&fromM_4=null&fromM_5=null&fromM_6=null&toM_1=null&toM_2=null&toM_3=null&toM_4=null&toM_5=null&toM_6=null&start=" + endDate + "&airline=null";
  useEffect(() => {


    applyFiltering(originalData);
  }, [selectedStops, selectedCarriers]);

  const applyFiltering = (originalData) => {
    if (originalData && !originalData.includes("something went wrong")) {

      if (selectedCarriers !== undefined && selectedStops !== undefined) {
        const selectedStopsArray = Object.keys(selectedStops).filter(stopQuantity => selectedStops[stopQuantity]);
        const selectedCarrierArray = Object.keys(selectedCarriers).filter(code => selectedCarriers[code]);

        if (selectedStopsArray.length > 0 && selectedCarrierArray.length > 0) {
          const selectedStopsArray = Object.keys(selectedStops).filter(stopQuantity => selectedStops[stopQuantity]);
          let filteredFlightsData = originalData.filter(flight => selectedStopsArray.includes(flight.stopQuantity));
          filteredFlightsData = filteredFlightsData.filter(flight => selectedCarrierArray.includes(flight.code));
          // const filteredArray = data.filter(element => stopCheckboxes.includes(selectedStops));
          setData(filteredFlightsData);
        }
        else if (selectedStopsArray.length > 0) {
          let filteredFlightsData = originalData.filter(flight => selectedStopsArray.includes(flight.stopQuantity));
          setData(filteredFlightsData);
        }
        else if (selectedCarrierArray.length > 0) {
          let filteredFlightsData = originalData.filter(flight => selectedCarrierArray.includes(flight.code));
          setData(filteredFlightsData);
        }
        else {
          setData(originalData);
        }
      }
    }
  };

  async function fetchReturnData() {
    setIsLoading(true);
    setData([]);
    setOriginalData([]);
    await axios
      .get(retUrl)
      .then((response) => {
        const returnData = response.data;

            if (!returnData.includes("something went wrong")) {
              const departureFlights = returnData.filter(item => item.FlightType === 'DM' || item.FlightType === 'DC');
              const returnFlights = returnData.filter(item => item.FlightType === 'RM' || item.FlightType === 'RC');

              // Extract fares using map
              const fares = returnData.map((flight) => flight.TotalAmount_Text);

              // Find the lowest fare using Math.min
              const lowestFare = Math.min(...fares);
              setlowfare(lowestFare);
              setData(departureFlights);
              setOriginalData(departureFlights);

              let lowestGrandFare = findObjectWithSmallestPrice(returnData);
              lowFare = lowestGrandFare.GrandTotal;
              setformValue({
                ...formValue,
                LowFare: lowFare,
                URL: retUrl
              });

              setreturnData(returnFlights);
              setoriginalReturnData(returnFlights);
              setFlightTypevalue("Return");
              setDepButtonText("Select This");

              const IsEqual = data === "something went wrong";
              {
                IsEqual ? onDataAvailable(false) : onDataAvailable(true)
              }
              setHideDepartureFlights(true);
              setHideReturnFlights(false);
              //Filters for Search Bar
              if (response.data !== "something went wrong") {
                const airlines = data.map(row => row.CarrierDesigCode)
                const uniqueAirlines = [...new Set(airlines)].sort();
                updateHitItCarriersData(uniqueAirlines);
                setAirlines(uniqueAirlines);
              }
            }
        setIsLoading(false);
      });
  }


  if (data && !data.includes("something went wrong")) {
    if (data.length > 0) {
      if (FlightType === 1) {
        DepDirectFlights = data.filter((flights) => flights.Direct === "1");
        DepConnectingMainFLights = data.filter(
          (flights) => flights.Direct === "0" && flights.FlightType === "DM"
        );
        DepConnectingFLights = data.filter(
          (flights) => flights.Direct === "0" && flights.FlightType === "DC"
        );
      }
      else {
        DepDirectFlights = data.filter((flights) => flights.Direct === "1");
        DepConnectingMainFLights = data.filter(
          (flights) => flights.Direct === "0" && flights.FlightType === "DM"
        );

        DepConnectingFLights = data.filter(
          (flights) => flights.Direct === "" && flights.FlightType === "DC"
        );
      }
    }
  }

  if (data && !data.includes("something went wrong")) {
    if (returnData.length > 0) {
      RetDirectFlights = returnData.filter((flights) => flights.Direct === "1");

      RetConnectingMainFLights = returnData.filter(
        (flights) => flights.Direct === "0" && flights.FlightType === "RM"
      );

      RetConnectingFLights = returnData.filter(
        (flights) => flights.Direct === "" && flights.FlightType === "RC"
      );

      const filteredMainReturnFlights = RetConnectingMainFLights.map(mainFlight => {
        // Find the corresponding connecting flight based on criteria
        const connectingReturnFlight = RetConnectingFLights.find(connectingFlight =>
          mainFlight.PaxJourney_Id === connectingFlight.PaxJourney_Id
        );

        // If a connecting flight is found, return the main flight, otherwise return null
        return connectingReturnFlight ? mainFlight : null;
      });

      // Remove any null entries from the filteredMainFlights array
      RetConnectingMainFLights = filteredMainReturnFlights.filter(flight => flight !== null);
    }

  }

  const fetchOriginName = (departureCity) => {
    axios
      .get(
        apiUrl + "/api/FlightSearch/cityname?term=" +
        departureCity
      )
      .then((response) => {
        const returnData = response.data;
        setOrigin(returnData.Name);
        return returnData.Name;

      })
      .catch((error) => {
      });
  }

  const RemovePoints = (value) => {
    const finalvalue = value.replace(/\.?0+$/, '');
    return finalvalue;
  }
  const removeAlphabets = (str) => {

    const cleanedString = str.replace(/[a-zA-Z]/g, ''); // Remove alphabets using regular expression
    return cleanedString;
  };

  const removeFirstTwoCharactersSlice = (str) => {
    let removedString = "";
    if (str !== undefined) {
      removedString = str.slice(2); // Remove the first two characters using slice method
    }
    else {
      removedString = ""
    }
    return removedString;
  };

  const [fareBasisCode, setFareBasisCode] = useState(""); // Define state variable
  const [penality, setPenality] = useState(null)
  const saveFareBasisCode = async (code, dept, arri) => {
    setFareBasisCode(code);
    console.log('flight is', fareBasisCode, dept, arri);
  
    try {
      const response = await axios.get(
        `${apiUrl}/api/NDC/penalties?Origin=${depCity}&Destination=${arrCity}&FareBasisCode=${code}`
      );
      console.log('response', response);
  
      if (response.status === 200) {
        const data = response.data;
        console.log('data is', data);
        // Remove "FOR NO-SHOW" from the data
        const modifiedData = data.replace(/FOR NO-SHOW/g, '');
        setPenality(modifiedData);
      } else {
        // Handle error or show a message to the user
      }
    } catch (error) {
      console.error('Error fetching flight data:', error);
      // Handle error or show a message to the user
    }
  };
  


  return <>
    {isPopupOpen && (
      <div className="popup-overlay">
        <div className="popup-content">
          <h2>Low Fare Alert</h2>
          <p>Please provide following details to send you low fare alert for this search.</p>
          <div className="row" style={{marginLeft:'auto' ,marginRight:'auto'}}>
            <div className="col-md-6 m-auto">
              <div className="form-group ">
                <label>Full Name</label>
                <input type="text" className="form-control control-group" required id="fullName" name="fullName" onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="form-group">
                <label>Mobile Number</label>
                <input type="text" className="form-control control-group" required name="mobileNum" id="mobileNum" onChange={handleChange} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 m-auto">
              <div className="form-group">
                <label>Email Address</label>
                <input type="email" className="form-control control-group" id="email" required name="email" onChange={handleChange} />
              </div>
            </div>
          </div>


          <button className="btn btn-primary mr-4" onClick={handleSavePopup}>Save</button>
          <button className="btn btn-danger" onClick={handleClosePopup}>Close</button>
        </div>
      </div>
    )}
    <div className="row">

    </div>
    {isLoading && <div className="loader"></div>}

    {!isLoading && (
      <div className="row">
        <div className="col-12 col-md-9 col-lg-9 order-2 order-lg-1">
          {(() => {
            if (FlightType == '1') {
              return (
                <div className="content-side">
                  {/* {loading ? <>Loading..</> : <></>} */}
                  {/* Departure Flights */}
                  {HideDepartureFlights ? (
                    <div id="DepartureFlights">
                      <div>

                        {!DepDirectFlights ? <></> : DepDirectFlights.map((flights) => (
                          <form>

                            <div
                              id={divId}
                              ref={divRef}
                              style={{ display: "block" }}
                              className="list-block main-block f-list-block"
                            >

                              <input type="hidden" id="Offer_ID" name="Offer_ID" value={flights.OfferID} />
                              <input type="hidden" id="TransactionId" name="TransactionId" value={flights.TransactionId} />
                              <input type="hidden" id="Cabin" name="Cabin" value={flights.CabintypeName} />
                              <input type="hidden" id="ArrivalDateTime" name="ArrivalDateTime" value={flights.Arr_AircraftScheduledDateTime} />
                              <input type="hidden" id="Dep_locationCode" name="Dep_locationCode" value={flights.Dep_IATA_LocationCode} />
                              <input type="hidden" id="DepartureDateTime" name="DepartureDateTime" value={flights.Dep_AircraftScheduledDateTime} />
                              <input type="hidden" id="Arr_locationCode" name="Arr_locationCode" value={flights.Arr_IATA_LocationCode} />
                              <input type="hidden" id="ResBookDesigCode" name="ResBookDesigCode" value={flights.resBookDesigCode} />
                              <input type="hidden" id="fareComponentGroupList_Id" name="fareComponentGroupList_Id" value={flights.fareComponentGroupList_Id} />


                              <div style={{ display: "none" }}>
                                {" "}
                                {(divId = divId + 1)}
                                {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                {(img = flights.OwnerCode + ".png")}
                              </div>
                              <div className="card mt-0" style={{ borderRadius: "15px" }}>
                                <div className="p-1 card-header  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span className="text-left" style={{}}><span>{flights.CarrierName}</span></span>
                                  <span>Departures</span>
                                  <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(flights.TotalAmount_Text))}</b></p></div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <img
                                        src={"images/airlines/PK.png"}
                                        style={{ width: "60px", height: "60px" }}
                                        alt="flight-img"
                                      />
                                    </div>
                                    <div className="col-sm-6 mt-3">
                                      <div className="time">
                                        <div className="line line--tip">
                                          <strong className="mb-3"> {moment(flights.Dep_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )}</strong>
                                          <span
                                            data-airport-role="departure"
                                            className="code"
                                            data-leg-index="0"
                                          >

                                            {" "}
                                            {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`}

                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                        </div>
                                        <div className="line line--middle">
                                          <span className="icon-text">
                                            <b>Total</b>
                                            <br />
                                             {hours}h {minutes}m
                                            {/* {removeFirstTwoCharactersSlice(flights.Duration)} */}
                                          </span>
                                          <dom-if style={{ display: 'none' }}>
                                            <template is="dom-if"></template>
                                          </dom-if>
                                          <div className="direct-label">
                                            {renderStops(flights.stopsQuantity)}
                                          </div>
                                        </div>
                                        <div className="line line--tip">
                                          <strong className="mb-3">  {moment(flights.Arr_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )} </strong>
                                          <span
                                            data-airport-role="arrival"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}
                                            {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}
                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                          <div className="labels">
                                            <div
                                              style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                              <b></b>
                                              <span className="tooltip"></span>
                                            </div>
                                            <div
                                              style={{ cursor: 'auto', hidden: '' }}>
                                              <wego-icon
                                                className="small moon"
                                                icon="moon"
                                              ></wego-icon>
                                              <span className="tooltip">
                                                Overnight Flight
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">

                                      <a
                                        className="btn btn-orange"
                                        key={flights.PaxJourney_Id}
                                        id={flights.PaxJourney_Id}
                                        onClick={(e) => HandleOneWayClick(e.target.id)}
                                      >
                                        Book This
                                      </a>

                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-left">
                                  <Collapse defaultActiveKey={["1"]} ghost>
                                    <Panel header="Flight Details" key="0" onClick={() => saveFareBasisCode(flights.FareBasisCode,flights.Dep_IATA_LocationCode,flights.Arr_IATA_LocationCode)} >
                                      <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                          {
                                            label: "Itinerary",
                                            key: "1",
                                            children: (
                                              <>
                                                <div className="row">
                                                  <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                      <img
                                                        src={
                                                          "images/airlines/PK.png"
                                                        }
                                                        style={{
                                                          width: "60px",
                                                          height: "60px",
                                                        }}
                                                        alt="flight-img"
                                                      />
                                                    </li>
                                                    <div className="verticalline">
                                                      <li>
                                                        {" "}
                                                        <p className="block-title">
                                                          {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode}) `}to{" "}
                                                          {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Dep_AircraftScheduledDateTime
                                                            ).format("HH:mm")}{" "}
                                                          </span>
                                                          -{" "}
                                                          <span>
                                                            {/*  {hours} hours - {minutes}{" "}
                                                                    minutes */}
                                                            {removeFirstTwoCharactersSlice(flights.Duration)}
                                                          </span>{" "}
                                                          -
                                                          <span style={{ fontSize: "large", paddingLeft: "5px" }}>
                                                            <i className="fa fa-plane fa-flip-vertical"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Arr_AircraftScheduledDateTime
                                                            ).format("HH:mm")}
                                                          </span>
                                                        </p>
                                                      </li>
                                                      <li>
                                                        {flights.CarrierDesigCode} -{" "}
                                                        {flights.OperatingCarrierFlightNumberText}{" "}
                                                      </li>
                                                      <li>
                                                        {flights.CabintypeName} (
                                                        {flights.CabinTypeCode})
                                                      </li>
                                                    </div>
                                                    {/* <li>{flights.stopQuantity}</li> */}
                                                  </ul>
                                                </div>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Baggage",
                                            key: "2",
                                            children: (
                                              <>
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    Allowed Weight:{" "}
                                                    {flights.MaxWeightValue}{" "}
                                                    {flights.MaxWeightUnit}
                                                  </li>
                                                </ul>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Cancellation",
                                            key: "3",
                                            children: (
                                              <>
                                                <table>
                                                  <tbody>
                                                   
                                                    <tr>
                                                      <td style={{ width: "70%" }}>
                                                        <p>
                                                          {penality}
                                                        </p>
                                                      </td>
                                                      
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </>
                                            ),
                                          },
                                          
                                          ,
                                          {
                                            label: "Date Change",
                                            key: "4",
                                            children: (
                                              <>
                                                <table>
                                                  
                                                  <tr>
                                                    
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                      {penality}
                                                      </p>
                                                    </td>
                                                    
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                        ]}
                                      />
                                    </Panel>
                                  </Collapse>
                                </div>
                              </div>
                            </div>

                          </form>
                        ))}
                     
                     
                      </div>

                      <div>
                        {!DepConnectingMainFLights ? <></> : DepConnectingMainFLights.map((flights) => (
                          <form>
                            <div
                              id={divId}
                              style={{ display: "block" }}
                              className="list-block main-block f-list-block"
                            >
                              <div style={{ display: "none" }}>
                                {" "}
                                {(divId = divId + 1)}
                                {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                {(img = flights.Carrier + ".png")}

                                <input type="hidden" id="Offer_ID" name="Offer_ID" value={flights.OfferID} />
                                <input type="hidden" id="TransactionId" name="TransactionId" value={flights.TransactionId} />
                                <input type="hidden" id="Cabin" name="Cabin" value={flights.cabin} />
                                <input type="hidden" id="ArrivalDateTime" name="ArrivalDateTime" value={flights.Arr_AircraftScheduledDateTime} />
                                <input type="hidden" id="Dep_locationCode" name="Dep_locationCode" value={flights.Arr_IATA_LocationCode} />
                                <input type="hidden" id="DepartureDateTime" name="DepartureDateTime" value={flights.Dep_AircraftScheduledDateTime} />
                                <input type="hidden" id="Arr_locationCode" name="Arr_locationCode" value={flights.Dep_IATA_LocationCode} />
                                <input type="hidden" id="ResBookDesigCode" name="ResBookDesigCode" value={flights.RBD_Code} />
                                <input type="hidden" id="fareComponentGroupList_Id" name="fareComponentGroupList_Id" value={flights.fareComponentGroupList_Id} />

                              </div>
                              <div className="card mt-0" style={{ borderRadius: "15px" }} >
                                <div className="card-header p-1  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span className="text-left" style={{}}><span>{flights.CarrierName}</span></span>
                                  <span>Departure</span>
                                  <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(flights.TotalAmount_Text))}</b></p></div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <img
                                        src={"images/airlines/PK.png"}
                                        style={{ width: "60px", height: "60px" }}
                                        alt="flight-img"
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="time">
                                        <div className="line line--tip">
                                          <strong className="mb-3"> {moment(flights.Dep_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )}
                                          </strong>
                                          <span
                                            data-airport-role="departure"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}
                                            {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`}
                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                        </div>
                                        <div className="line line--middle">
                                          <span>
                                            <span className="icon-text">
                                               <b>Total</b>
                                                        <br />
                                                        {hours}h {minutes}m
                                              {/* {removeFirstTwoCharactersSlice(flights.journeyDuration)} */}
                                            </span>
                                          </span>
                                          <dom-if style={{ display: 'none' }}>
                                            <template is="dom-if"></template>
                                          </dom-if>
                                          <div className="direct-label">
                                            {renderStops(flights.Stops)}
                                          </div>
                                        </div>
                                        <div className="line line--tip">
                                          <strong className="mb-3">  {moment(flights.Arr_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )} </strong>
                                          <span
                                            data-airport-role="arrival"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}
                                            {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}
                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                          <div className="labels">
                                            <div
                                              style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                              <b></b>
                                              <span className="tooltip"></span>
                                            </div>
                                            <div
                                              style={{ cursor: 'auto', hidden: '' }}>
                                              <wego-icon
                                                className="small moon"
                                                icon="moon"
                                              ></wego-icon>
                                              <span className="tooltip">
                                                Overnight Flight
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">

                                      <a
                                        onClick={(e) => HandleOneWayClick(e.target.id)}
                                        key={flights.PaxJourney_Id}
                                        id={flights.PaxJourney_Id}
                                        className="btn btn-orange"
                                      >
                                        {DepButtonText}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-left">
                                  <Collapse defaultActiveKey={["1"]} ghost>
                                    <Panel header="Flight Details" key="0" onClick={() => saveFareBasisCode(flights.FareBasisCode,flights.Dep_IATA_LocationCode,flights.Arr_IATA_LocationCode)}>
                                      <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                          {
                                            label: "Itinerary",
                                            key: "1",
                                            children: (
                                              <>
                                                <div className="row">
                                                  <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                      <img
                                                        src={
                                                          "images/airlines/PK.png"
                                                        }
                                                        style={{
                                                          width: "60px",
                                                          height: "60px",
                                                        }}
                                                        alt="flight-img"
                                                      />
                                                    </li>
                                                    <div className="verticalline">
                                                      <li>
                                                        {" "}
                                                        <p className="block-title">
                                                          {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`} to {" "}
                                                          {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}

                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          <span style={{ fontSize: "large", paddingLeft: "5px" }}>
                                                            <i className="fa fa-plane"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Dep_AircraftScheduledDateTime
                                                            ).format("HH:mm")}{" "}
                                                          </span>
                                                          -{" "}
                                                          <span>
                                                            {/*  {hours} hours - {minutes}{" "}
                                                                    minutes */}
                                                            {removeFirstTwoCharactersSlice(flights.journeyDuration)}
                                                          </span>{" "}

                                                          <span style={{ fontSize: "large", paddingLeft: "5px" }}>
                                                            <i className="fa fa-plane fa-flip-vertical"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Arr_AircraftScheduledDateTime
                                                            ).format("HH:mm")}
                                                          </span>
                                                        </p>
                                                      </li>
                                                      <li>
                                                        {flights.CarrierDesigCode} -{" "}
                                                        {flights.OperatingCarrierFlightNumberText}{" "}
                                                      </li>
                                                      <li>
                                                        {flights.CabintypeName} (
                                                        {flights.CabinTypeCode})
                                                      </li>
                                                      <li>{flights.stopQuantity}</li>
                                                    </div>

                                                  </ul>
                                                </div>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Baggage",
                                            key: "2",
                                            children: (
                                              <>
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    Allowed Weight:{" "}
                                                    {flights.MaxWeightValue}{" "}
                                                    {flights.MaxWeightUnit}
                                                  </li>
                                                </ul>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Cancellation",
                                            key: "3",
                                            children: (
                                              <>
                                                <table>
                                                 
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                        {penality}
                                                      </p>
                                                    </td>
                                                   
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                          ,
                                          {
                                            label: "Date Change",
                                            key: "4",
                                            children: (
                                              <>
                                                <table>
                                                 
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                       {penality}
                                                      </p>
                                                    </td>
                                                  
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                        ]}
                                      />
                                      {DepConnectingFLights.filter(
                                        (cflights) =>
                                          cflights.PaxJourney_Id ===
                                          flights.PaxJourney_Id &&
                                          cflights.FlightType === "DC"

                                      ).map((cflights) => (
                                        <Tabs
                                          defaultActiveKey="1"
                                          items={[
                                            {
                                              label: "Itinerary",
                                              key: "1",
                                              children: (
                                                <>
                                                  <div className="row">
                                                    <ul
                                                      style={{ listStyle: "none" }}
                                                    >
                                                      <li>
                                                        <img
                                                          src={
                                                            "images/airlines/PK.png"
                                                          }
                                                          style={{
                                                            width: "60px",
                                                            height: "60px",
                                                          }}
                                                          alt="flight-img"
                                                        />
                                                      </li>
                                                      <div className="verticalline">
                                                        <li>
                                                          <span style={{ color: "black" }}>
                                                            {moment(cflights.Dep_AircraftScheduledDateTime).format(
                                                              "DD MMMM YYYY"
                                                            )}
                                                          </span>


                                                        </li>
                                                        <li>
                                                          {" "}
                                                          <p className="block-title">

                                                            {`${cflights.Dep_StationName} (${cflights.Dep_IATA_LocationCode}) `} to{" "}
                                                            {`${cflights.Arr_StationName} (${cflights.Arr_IATA_LocationCode})`}

                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span style={{ fontSize: "large" }}>
                                                              <i className="fa fa-plane"></i>
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: "black", paddingLeft: "5px"
                                                              }}
                                                            >
                                                              {moment(
                                                                cflights.Dep_AircraftScheduledDateTime
                                                              ).format("HH:mm")}{" "}
                                                            </span>
                                                            -{" "}
                                                            <span>
                                                              {/*  {hours} hours -{" "}
                                                                      {minutes} minutes */}
                                                              {removeFirstTwoCharactersSlice(flights.journeyDuration)}
                                                            </span>{" "}
                                                            <span style={{ fontSize: "large" }}>
                                                              <i className="fa fa-plane fa-flip-vertical"></i>
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: "black", paddingLeft: "5px"
                                                              }}
                                                            >
                                                              {moment(
                                                                cflights.Arr_AircraftScheduledDateTime
                                                              ).format("HH:mm")}
                                                            </span>
                                                          </p>
                                                        </li>
                                                        <li>
                                                          {cflights.CarrierDesigCode} -{" "}
                                                          {cflights.OperatingCarrierFlightNumberText}{" "}
                                                        </li>
                                                        <li>
                                                          {cflights.CabintypeName} (
                                                          {cflights.CabinTypeCode})
                                                        </li>
                                                      </div>
                                                    </ul>
                                                  </div>
                                                </>
                                              ),
                                            },
                                            {
                                              label: "Baggage",
                                              key: "2",
                                              children: (
                                                <>
                                                  <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                      Allowed Weight:{" "}
                                                      {cflights.MaxWeightValue}{" "}
                                                      {cflights.MaxWeightUnit}
                                                    </li>
                                                  </ul>
                                                </>
                                              ),
                                            },
                                            {
                                              label: "Cancellation",
                                              key: "3",
                                              children: (
                                                <>
                                                  <table>
                                                  
                                                    <tr>
                                                      <td style={{ width: "70%" }}>
                                                        <p>
                                                          {penality}
                                                        </p>
                                                      </td>
                                                      
                                                    </tr>
                                                  </table>
                                                </>
                                              ),
                                            },
                                            ,
                                            {
                                              label: "Date Change",
                                              key: "4",
                                              children: (
                                                <>
                                                  <table>
                                                    
                                                    <tr>
                                                      <td style={{ width: "70%" }}>
                                                        <p>
                                                         {penality}
                                                        </p>
                                                      </td>
                                                     
                                                    </tr>
                                                  </table>
                                                </>
                                              ),
                                            },
                                          ]}
                                        />
                                      ))}
                                    </Panel>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                          </form>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )
            }
            else if (FlightType == '2') {
              return (
                <div className="content-side">
                  {/* {loading ? <>Loading..</> : <></>} */}
                  {/* Departure Flights */}
                  {HideDepartureFlights ? (
                    <div id="DepartureFlights">
                      <div>
                        {!DepDirectFlights ? <></> : DepDirectFlights.map((flights) => (
                          <form>
                            <div
                              id={divId}
                              ref={divRef}
                              style={{ display: "block" }}
                              className="list-block main-block f-list-block"
                            >
                              <div style={{ display: "none" }}>
                                {" "}
                                {(divId = divId + 1)}
                                {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                {(img = flights.CarrierDesigCode + ".png")}
                                <input type="hidden" id="Offer_ID" name="Offer_ID" value={flights.OfferID} />
                                <input type="hidden" id="Option_Id_r" name="Option_Id_r" value={flights.Option_Id} />
                                <input type="hidden" id="TransactionId_r" name="TransactionId_r" value={flights.TransactionId} />
                                <input type="hidden" id="Cabin_r" name="Cabin_r" value={flights.CabintypeName} />
                                <input type="hidden" id="ArrivalDateTime_r" name="ArrivalDateTime_r" value={flights.Arr_AircraftScheduledDateTime} />
                                <input type="hidden" id="Dep_locationCode_r" name="Dep_locationCode_r" value={flights.Dep_StationName} />
                                <input type="hidden" id="DepartureDateTime_r" name="DepartureDateTime_r" value={flights.Dep_AircraftScheduledDateTime} />
                                <input type="hidden" id="Arr_locationCode_r" name="Arr_locationCode_r" value={flights.Arr_StationName} />
                                <input type="hidden" id="ResBookDesigCode_r" name="ResBookDesigCode_r" value={flights.RBD_Code} />
                                {/* <input type="hidden" id="fareComponentGroupList_Id_r" name="fareComponentGroupList_Id_r" value={flights.fareComponentGroupList_Id} /> */}

                              </div>
                              <div className="card mt-0" style={{ borderRadius: "15px" }} >
                                <div className="p-1 card-header  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span className="text-left" style={{}}><span>{flights.CarrierName}</span></span>
                                  <span>Departure</span>
                                  <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + (parseInt(flights.TotalAmount_Text))}</b></p></div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <img
                                        src={"images/airlines/PK.png"}
                                        style={{ width: "60px", height: "60px" }}
                                        alt="flight-img"
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="time">
                                        <div className="line line--tip">
                                          <strong className="mb-3"> {moment(flights.Dep_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )}</strong>
                                          <span
                                            data-airport-role="departure"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}
                                            {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`}

                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                        </div>
                                        <div className="line line--middle">
                                          <span>
                                            <span className="icon-text">
                                              <b>Total</b>
                                              <br />
                                              {removeFirstTwoCharactersSlice(flights.Duration)}
                                            </span>
                                          </span>
                                          <dom-if style={{ display: 'none' }}>
                                            <template is="dom-if"></template>
                                          </dom-if>
                                          <div className="direct-label">
                                            {renderStops(flights.Stops)}
                                          </div>
                                        </div>
                                        <div className="line line--tip">
                                          <strong className="mb-3">  {moment(flights.Arr_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )} </strong>
                                          <span
                                            data-airport-role="arrival"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}
                                            {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}

                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                          <div className="labels">
                                            <div
                                              style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                              <b></b>
                                              <span className="tooltip"></span>
                                            </div>
                                            <div
                                              style={{ cursor: 'auto', hidden: '' }}>
                                              <wego-icon
                                                className="small moon"
                                                icon="moon"
                                              ></wego-icon>
                                              <span className="tooltip">
                                                Overnight Flight
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">

                                      <a
                                        className="btn btn-orange"
                                        key={flights.PaxJourney_Id}
                                        id={flights.PaxJourney_Id}
                                        cla={flights.resBookDesigCode}
                                        onClick={(e) => handleClick(e.target.id)}
                                      >
                                        {DepButtonText}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-left">
                                  <Collapse defaultActiveKey={["1"]} ghost>
                                    <Panel header="Flight Details" key="0" onClick={() => saveFareBasisCode(flights.FareBasisCode,flights.Dep_IATA_LocationCode,flights.Arr_IATA_LocationCode)}>
                                      <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                          {
                                            label: "Itinerary",
                                            key: "1",
                                            children: (
                                              <>
                                                <div className="row">
                                                  <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                      <img
                                                        src={
                                                          "images/airlines/PK.png"
                                                        }
                                                        style={{
                                                          width: "60px",
                                                          height: "60px",
                                                        }}
                                                        alt="flight-img"
                                                      />
                                                    </li>
                                                    <div className="verticalline">
                                                      <li>
                                                        {" "}
                                                        <p className="block-title">
                                                          {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode}) `} to{" "}
                                                          {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}

                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Dep_AircraftScheduledDateTime
                                                            ).format("HH:mm")}{" "}
                                                          </span>
                                                          -{" "}
                                                          <span>
                                                            {removeFirstTwoCharactersSlice(flights.Duration)}
                                                          </span>{" "}

                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane fa-flip-vertical"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Arr_AircraftScheduledDateTime
                                                            ).format("HH:mm")}
                                                          </span>
                                                        </p>
                                                      </li>
                                                      <li>
                                                        {flights.CarrierDesigCode} -{" "}
                                                        {flights.OperatingCarrierFlightNumberText}{" "}
                                                      </li>
                                                      <li>
                                                        {flights.CabintypeName} (
                                                        {flights.CabinTypeCode})
                                                      </li>
                                                      <li>{flights.stopQuantity}</li>
                                                    </div>
                                                  </ul>
                                                </div>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Baggage",
                                            key: "2",
                                            children: (
                                              <>
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    Allowed Weight:{" "}
                                                    {flights.MaxWeightValue}{" "}
                                                    {flights.MaxWeightUnit}
                                                  </li>
                                                </ul>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Cancellation",
                                            key: "3",
                                            children: (
                                              <>
                                                <table>
                                                  
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                        {penality}
                                                      </p>
                                                    </td>
                                                   
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                          ,
                                          {
                                            label: "Date Change",
                                            key: "4",
                                            children: (
                                              <>
                                                <table>
                                                
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                        {penality}
                                                      </p>
                                                    </td>
                                                    
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                        ]}
                                      />
                                    </Panel>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                          </form>
                        ))}
                      </div>

                      <div>
                        {!DepConnectingMainFLights ? <></> : DepConnectingMainFLights.map((flights) => (
                          <form>
                            <div
                              id={divId}
                              style={{ display: "block" }}
                              className="list-block main-block f-list-block"
                            >
                              <div style={{ display: "none" }}>
                                {" "}
                                {(divId = divId + 1)}
                                {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                                {(img = flights.Carrier + ".png")}
                                <input type="hidden" id="Offer_ID" name="Offer_ID" value={flights.OfferID} />
                                <input type="hidden" id="TransactionId_r" name="TransactionId_r" value={flights.TransactionId} />
                                <input type="hidden" id="Cabin_r" name="Cabin_r" value={flights.CabintypeName} />
                                <input type="hidden" id="ArrivalDateTime_r" name="ArrivalDateTime_r" value={flights.Arr_AircraftScheduledDateTime} />
                                <input type="hidden" id="Dep_locationCode_r" name="Dep_locationCode_r" value={flights.Dep_IATA_LocationCode} />
                                <input type="hidden" id="DepartureDateTime_r" name="DepartureDateTime_r" value={flights.Dep_AircraftScheduledDateTime} />
                                <input type="hidden" id="Arr_locationCode_r" name="Arr_locationCode_r" value={flights.Arr_IATA_LocationCode} />
                                <input type="hidden" id="ResBookDesigCode_r" name="ResBookDesigCode_r" value={flights.CabinTypeCode} />
                                <input type="hidden" id="fareComponentGroupList_Id_r" name="fareComponentGroupList_Id_r" value={flights.fareComponentGroupList_Id} />
                              </div>
                              <div className="card mt-0" style={{ borderRadius: "15px" }} >
                                <div className="card-header p-1  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span className="text-left" style={{}}><span>{flights.CarrierName}</span></span>
                                  <span>Departure</span>
                                  <p style={{ color: 'black', fontSize: "20px" }}><b>{'PKR ' + parseInt((flights.TotalAmount_Text))}</b></p></div>
                                <div className="card-body">
                                  <div className="row">
                                    <div className="col-sm-3">
                                      <img
                                        src={"images/airlines/PK.png"}
                                        style={{ width: "60px", height: "60px" }}
                                        alt="flight-img"
                                      />
                                    </div>
                                    <div className="col-sm-6">
                                      <div className="time">
                                        <div className="line line--tip">
                                          <strong className="mb-3"> {moment(flights.Dep_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )}</strong>
                                          <span
                                            data-airport-role="departure"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}

                                            {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`}
                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                        </div>
                                        <div className="line line--middle">
                                          <span>
                                            <span className="icon-text">
                                              <b>Total</b>
                                              <br />
                                              {removeFirstTwoCharactersSlice(flights.Duration)}
                                            </span>
                                          </span>
                                          <dom-if style={{ display: 'none' }}>
                                            <template is="dom-if"></template>
                                          </dom-if>
                                          <div className="direct-label">
                                            {renderStops(flights.Stops)}
                                          </div>
                                        </div>
                                        <div className="line line--tip">
                                          <strong className="mb-3">  {moment(flights.Arr_AircraftScheduledDateTime).format(
                                            "HH:mm"
                                          )} </strong>
                                          <span
                                            data-airport-role="arrival"
                                            className="code"
                                            data-leg-index="0"
                                          >
                                            {" "}
                                            {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}


                                            <span className="tooltip">
                                              Click to show only flights
                                              having this
                                            </span>
                                          </span>
                                          <div className="labels">
                                            <div
                                              style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                              <b></b>
                                              <span className="tooltip"></span>
                                            </div>
                                            <div
                                              style={{ cursor: 'auto', hidden: '' }}>
                                              <wego-icon
                                                className="small moon"
                                                icon="moon"
                                              ></wego-icon>
                                              <span className="tooltip">
                                                Overnight Flight
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-3">

                                      <a
                                        onClick={(e) => handleClick(e.target.id)}
                                        key={flights.PaxJourney_Id}
                                        id={flights.PaxJourney_Id}
                                        cla={flights.resBookDesigCode}
                                        className="btn btn-orange"
                                      >
                                        {DepButtonText}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer text-left">
                                  <Collapse defaultActiveKey={["1"]} ghost>
                                    <Panel header="Flight Details" key="0" onClick={() => saveFareBasisCode(flights.FareBasisCode,flights.Dep_IATA_LocationCode,flights.Arr_IATA_LocationCode)}>
                                      <Tabs
                                        defaultActiveKey="1"
                                        items={[
                                          {
                                            label: "Itinerary",
                                            key: "1",
                                            children: (
                                              <>
                                                <div className="row">
                                                  <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                      <img
                                                        src={
                                                          "images/airlines/PK.png"
                                                        }
                                                        style={{
                                                          width: "60px",
                                                          height: "60px",
                                                        }}
                                                        alt="flight-img"
                                                      />
                                                    </li>
                                                    <div className="verticalline">
                                                      <li>
                                                        {" "}
                                                        <p className="block-title">

                                                          {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode}) `} to{" "}
                                                          {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Dep_AircraftScheduledDateTime
                                                            ).format("HH:mm")}{" "}
                                                          </span>
                                                          -{" "}
                                                          <span>
                                                            {removeFirstTwoCharactersSlice(flights.Duration)}
                                                          </span>{" "}

                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane fa-flip-vertical"></i>
                                                          </span>
                                                          <span
                                                            style={{ color: "black", paddingLeft: "5px" }}
                                                          >
                                                            {moment(
                                                              flights.Arr_AircraftScheduledDateTime
                                                            ).format("HH:mm")}
                                                          </span>
                                                        </p>
                                                      </li>
                                                      <li>
                                                        {flights.CarrierDesigCode} -{" "}
                                                        {flights.OperatingCarrierFlightNumberText}{" "}
                                                      </li>
                                                      <li>
                                                        {flights.CabintypeName} (
                                                        {flights.CabinTypeCode})
                                                      </li>
                                                      {/* <li>{flights.stopQuantity}</li> */}
                                                    </div>
                                                  </ul>
                                                </div>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Baggage",
                                            key: "2",
                                            children: (
                                              <>
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    Allowed Weight:{" "}
                                                    {flights.MaxWeightValue}{" "}
                                                    {flights.MaxWeightUnit}
                                                  </li>
                                                </ul>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Cancellation",
                                            key: "3",
                                            children: (
                                              <>
                                                <table>
                                                 
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                       {penality}
                                                      </p>
                                                    </td>
                                                   
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                          ,
                                          {
                                            label: "Date Change",
                                            key: "4",
                                            children: (
                                              <>
                                                <table>
                                                  
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                        {penality}
                                                      </p>
                                                    </td>
                                                   
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                        ]}
                                      />
                                      {!DepConnectingFLights ? <></> : DepConnectingFLights.filter(
                                        (cflights) =>
                                          cflights.PaxJourney_Id ===
                                          flights.PaxJourney_Id &&
                                          cflights.FlightType === "DC"
                                        // && cflights.resBookDesigCode ===
                                        // flights.resBookDesigCode
                                      ).map((cflights) => (
                                        <Tabs style={{ paddingTop: "20px" }}
                                          defaultActiveKey="1"
                                          items={[
                                            {
                                              label: "Itinerary",
                                              key: "1",
                                              children: (
                                                <>
                                                  <div className="row">
                                                    <ul
                                                      style={{ listStyle: "none" }}
                                                    >
                                                      <li>
                                                        <img
                                                          src={
                                                            "images/airlines/PK.png"
                                                          }
                                                          style={{
                                                            width: "60px",
                                                            height: "60px",
                                                          }}
                                                          alt="flight-img"
                                                        />
                                                      </li>
                                                      <div className="verticalline">
                                                        <li>
                                                          {" "}
                                                          <p className="block-title">
                                                            {`${cflights.Dep_StationName} (${cflights.Dep_IATA_LocationCode}) `} to{" "}
                                                            {`${cflights.Arr_StationName} (${cflights.Arr_IATA_LocationCode})`}

                                                          </p>
                                                        </li>
                                                        <li>
                                                          <p>
                                                            <span style={{ fontSize: "large" }}>
                                                              <i className="fa fa-plane"></i>
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: "black", paddingLeft: "5px",
                                                              }}
                                                            >
                                                              {moment(
                                                                cflights.Dep_AircraftScheduledDateTime
                                                              ).format("HH:mm")}{" "}
                                                            </span>
                                                            -{" "}
                                                            <span>
                                                              {removeFirstTwoCharactersSlice(flights.Duration)}
                                                            </span>{" "}
                                                            -
                                                            <span style={{ fontSize: "large" }}>
                                                              <i className="fa fa-plane fa-flip-vertical"></i>
                                                            </span>
                                                            <span
                                                              style={{
                                                                color: "black", paddingLeft: "5px"
                                                              }}
                                                            >
                                                              {moment(
                                                                cflights.Arr_AircraftScheduledDateTime
                                                              ).format("HH:mm")}
                                                            </span>
                                                          </p>
                                                        </li>
                                                        <li>
                                                          {cflights.CarrierDesigCode} -{" "}
                                                          {cflights.OperatingCarrierFlightNumberText}{" "}
                                                        </li>
                                                        <li>
                                                          {cflights.CabintypeName} (
                                                          {cflights.CabinTypeCode})
                                                        </li>
                                                      </div>
                                                    </ul>
                                                  </div>
                                                </>
                                              ),
                                            },
                                            {
                                              label: "Baggage",
                                              key: "2",
                                              children: (
                                                <>
                                                  <ul style={{ listStyle: "none" }}>
                                                    <li>
                                                      Allowed Weight:{" "}
                                                      {cflights.MaxWeightValue}{" "}
                                                      {cflights.MaxWeightUnit}
                                                    </li>
                                                  </ul>
                                                </>
                                              ),
                                            },
                                            {
                                              label: "Cancellation",
                                              key: "3",
                                              children: (
                                                <>
                                                  <table>
                                                    <tr>
                                                      <th style={{ width: "70%" }}>
                                                        Time
                                                      </th>
                                                      <th
                                                        style={{
                                                          width: "30%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Fee
                                                      </th>
                                                    </tr>
                                                    <tr>
                                                      <td style={{ width: "70%" }}>
                                                        <p>
                                                          Penalty for adults before
                                                          departure (Not applicable
                                                          within 24 hours of
                                                          departure).{" "}
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "30%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <p>
                                                          {cflights.CancelPenalty}
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </>
                                              ),
                                            },
                                            ,
                                            {
                                              label: "Date Change",
                                              key: "4",
                                              children: (
                                                <>
                                                  <table>
                                                    <tr>
                                                      <th style={{ width: "70%" }}>
                                                        Time
                                                      </th>
                                                      <th
                                                        style={{
                                                          width: "30%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Fee
                                                      </th>
                                                    </tr>
                                                    <tr>
                                                      <td style={{ width: "70%" }}>
                                                        <p>
                                                          Penalty for adults before
                                                          departure (Not applicable
                                                          within 24 hours of
                                                          departure).{" "}
                                                        </p>
                                                      </td>
                                                      <td
                                                        style={{
                                                          width: "30%",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        <p>
                                                          {cflights.ChangePenalty}
                                                        </p>
                                                      </td>
                                                    </tr>
                                                  </table>
                                                </>
                                              ),
                                            },
                                          ]}
                                        />
                                      ))}
                                    </Panel>
                                  </Collapse>
                                </div>
                              </div>
                            </div>
                          </form>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}



                  {HideReturnFlights ? (
                    <div id="ReturnFlights">
                      <div>
                        {!RetDirectFlights ? <></> : RetDirectFlights.map((flights) => (
                          <div
                            id={flights.Option_Id}
                            className="list-block main-block f-list-block"
                          >
                            <div style={{ display: "none" }}>
                              {" "}
                              {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                              {(img = flights.Carrier + ".png")}
                            </div>
                            <div className="card">
                              <div className="card-header p-1  d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span className="text-left" style={{}}><span>{flights.CarrierName}</span></span>
                                <span>Return</span>
                                <span> </span>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <img
                                      src={"images/airlines/PK.png"}
                                      style={{ width: "60px", height: "60px" }}
                                      alt="flight-img"
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <div className="time">
                                      <div className="line line--tip">
                                        <strong className="mb-3"> {moment(flights.Dep_AircraftScheduledDateTime).format(
                                          "HH:mm"
                                        )}</strong>
                                        <span
                                          data-airport-role="departure"
                                          className="code"
                                          data-leg-index="0"
                                        >
                                          {" "}
                                          {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`}
                                          <span className="tooltip">
                                            Click to show only flights
                                            having this
                                          </span>
                                        </span>
                                      </div>
                                      <div className="line line--middle">
                                        <span>
                                          <span className="icon-text">
                                            <b>Total</b>
                                            <br />
                                            {removeFirstTwoCharactersSlice(flights.Duration)}
                                          </span>
                                        </span>
                                        <dom-if style={{ display: 'none' }}>
                                          <template is="dom-if"></template>
                                        </dom-if>
                                        <div className="direct-label">
                                          {renderStops(flights.Stops)}
                                        </div>
                                      </div>
                                      <div className="line line--tip">
                                        <strong className="mb-3">  {moment(flights.Arr_AircraftScheduledDateTime).format(
                                          "HH:mm"
                                        )} </strong>
                                        <span
                                          data-airport-role="arrival"
                                          className="code"
                                          data-leg-index="0"
                                        >
                                          {" "}
                                          {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}

                                          <span className="tooltip">
                                            Click to show only flights
                                            having this
                                          </span>
                                        </span>
                                        <div className="labels">
                                          <div
                                            style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                            <b></b>
                                            <span className="tooltip"></span>
                                          </div>
                                          <div
                                            style={{ cursor: 'auto', hidden: '' }}>
                                            <wego-icon
                                              className="small moon"
                                              icon="moon"
                                            ></wego-icon>
                                            <span className="tooltip">
                                              Overnight Flight
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-3">
                                    <a
                                      key={flights.PaxJourney_Id}
                                      id={flights.PaxJourney_Id}
                                      cla={flights.resBookDesigCode}
                                      cabin={flights.cabin}
                                      onClick={(e) => HandleReturnClick(e.target.id)}
                                      className="btn btn-orange"
                                    >
                                      Book Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer text-left">
                                <Collapse defaultActiveKey={["1"]} ghost>
                                  <Panel header="Flight Details" key="0">
                                    <Tabs
                                      defaultActiveKey="1"
                                      items={[
                                        {
                                          label: "Itinerary",
                                          key: "1",
                                          children: (
                                            <>
                                              <div className="row">
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    <img
                                                      src={
                                                        "images/airlines/PK.png"
                                                      }
                                                      style={{
                                                        width: "60px",
                                                        height: "60px",
                                                      }}
                                                      alt="flight-img"
                                                    />
                                                  </li>
                                                  <div className="verticalline">
                                                    <li>
                                                      {" "}
                                                      <p className="block-title">

                                                        {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode}) `}to{" "}
                                                        {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}


                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span style={{ fontSize: "large" }}>
                                                          <i className="fa fa-plane"></i>
                                                        </span>
                                                        <span
                                                          style={{ color: "black", paddingLeft: "5px" }}
                                                        >
                                                          {moment(
                                                            flights.Dep_AircraftScheduledDateTime
                                                          ).format("HH:mm")}{" "}
                                                        </span>
                                                        -{" "}
                                                        <span>
                                                          {removeFirstTwoCharactersSlice(flights.Duration)}
                                                        </span>{" "}
                                                        -
                                                        <span style={{ fontSize: "large" }}>
                                                          <i className="fa fa-plane fa-flip-vertical"></i>
                                                        </span>
                                                        <span
                                                          style={{ color: "black", paddingLeft: "5px" }}
                                                        >
                                                          {moment(
                                                            flights.Arr_AircraftScheduledDateTime
                                                          ).format("HH:mm")}
                                                        </span>
                                                      </p>
                                                    </li>
                                                    <li>
                                                      {flights.CarrierDesigCode} -{" "}
                                                      {flights.OperatingCarrierFlightNumberText}{" "}
                                                    </li>
                                                    <li>
                                                      {flights.CabintypeName} (
                                                      {flights.CabinTypeCode})
                                                    </li>
                                                  </div>
                                                </ul>
                                              </div>
                                            </>
                                          ),
                                        },
                                        {
                                          label: "Baggage",
                                          key: "2",
                                          children: (
                                            <>
                                              <ul style={{ listStyle: "none" }}>
                                                <li>
                                                  Allowed Weight:{" "}
                                                  {flights.MaxWeightValue}{" "}
                                                  {flights.MaxWeightUnit}
                                                </li>
                                              </ul>
                                            </>
                                          ),
                                        },
                                        {
                                          label: "Cancellation",
                                          key: "3",
                                          children: (
                                            <>
                                              <table>
                                                <tr>
                                                  <th style={{ width: "70%" }}>
                                                    Time
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Fee
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <td style={{ width: "70%" }}>
                                                    <p>
                                                      Penalty for adults before
                                                      departure (Not applicable
                                                      within 24 hours of
                                                      departure).{" "}
                                                    </p>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <p>{flights.CancelPenalty}</p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </>
                                          ),
                                        },
                                        ,
                                        {
                                          label: "Date Change",
                                          key: "4",
                                          children: (
                                            <>
                                              <table>
                                                <tr>
                                                  <th style={{ width: "70%" }}>
                                                    Time
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Fee
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <td style={{ width: "70%" }}>
                                                    <p>
                                                      Penalty for adults before
                                                      departure (Not applicable
                                                      within 24 hours of
                                                      departure).{" "}
                                                    </p>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <p>{flights.ChangePenalty}</p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                  </Panel>
                                </Collapse>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div>
                        {!RetConnectingMainFLights ? <></> : RetConnectingMainFLights.map((flights) => (
                          <div
                            id={flights.Option_Id}
                            className="list-block main-block f-list-block"
                          >
                            <div style={{ display: "none" }}>
                              {" "}
                              {(duration = flights.TotalDuration)}
                                            {(hours = parseInt(duration.match(/(\d+)H/)[1], 10))}
                                            {(minutes = parseInt(duration.match(/(\d+)M/)[1], 10))}
                              {(img = flights.CarrierDesigCode + ".png")}
                            </div>
                            <div className="card">
                              <div className="card-header p-1 d-flex justify-content-between" style={{ backgroundColor: 'white' }} >  <span className="text-left" style={{}}><span>{flights.CarrierName}</span></span>
                                <span>Return</span>
                              </div>
                              <div className="card-body">
                                <div className="row">
                                  <div className="col-sm-3">
                                    <img
                                      src={"images/airlines/PK.png"}
                                      style={{ width: "60px", height: "60px" }}
                                      alt="flight-img"
                                    />

                                  </div>

                                  <div className="col-sm-6">
                                    <div className="time">
                                      <div className="line line--tip">
                                        <strong className="mb-3"> {moment(flights.Dep_AircraftScheduledDateTime).format(
                                          "HH:mm"
                                        )}</strong>
                                        <span
                                          data-airport-role="departure"
                                          className="code"
                                          data-leg-index="0"
                                        >
                                          {" "}
                                          {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode})`}
                                          <span className="tooltip">
                                            Click to show only flights
                                            having this
                                          </span>
                                        </span>
                                      </div>
                                      <div className="line line--middle">
                                        <span>
                                          <span className="icon-text">
                                            <b>Total</b>
                                            <br />
                                            {removeFirstTwoCharactersSlice(flights.Duration)}
                                          </span>
                                        </span>
                                        <dom-if style={{ display: 'none' }}>
                                          <template is="dom-if"></template>
                                        </dom-if>
                                        <div className="direct-label">
                                          {renderStops(flights.Stops)}
                                        </div>
                                      </div>
                                      <div className="line line--tip">
                                        <strong className="mb-3">  {moment(flights.Arr_AircraftScheduledDateTime).format(
                                          "HH:mm"
                                        )} </strong>
                                        <span
                                          data-airport-role="arrival"
                                          className="code"
                                          data-leg-index="0"
                                        >
                                          {" "}
                                          {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}

                                          <span className="tooltip">
                                            Click to show only flights
                                            having this
                                          </span>
                                        </span>
                                        <div className="labels">
                                          <div
                                            style={{ cursor: 'auto', marginTop: '-3px', hidden: '' }}>
                                            <b></b>
                                            <span className="tooltip"></span>
                                          </div>
                                          <div
                                            style={{ cursor: 'auto', hidden: '' }}>
                                            <wego-icon
                                              className="small moon"
                                              icon="moon"
                                            ></wego-icon>
                                            <span className="tooltip">
                                              Overnight Flight
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-sm-3">

                                    <a
                                      key={flights.PaxJourney_Id}
                                      id={flights.PaxJourney_Id}
                                      // cla={flights.resBookDesigCode}
                                      cabin={flights.cabin}
                                      onClick={(e) => HandleReturnClick(e.target.id)}
                                      className="btn btn-orange"
                                    >
                                      Book Now
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div className="card-footer text-left">
                                <Collapse defaultActiveKey={["1"]} ghost>
                                  <Panel header="Flight Details" key="0">
                                    <Tabs
                                      defaultActiveKey="1"
                                      items={[
                                        {
                                          label: "Itinerary",
                                          key: "1",
                                          children: (
                                            <>
                                              <div className="row">
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    <img
                                                      src={
                                                        "images/airlines/PK.png"
                                                      }
                                                      style={{
                                                        width: "60px",
                                                        height: "60px",
                                                      }}
                                                      alt="flight-img"
                                                    />
                                                  </li>
                                                  <div className="verticalline">

                                                    <li>

                                                      {" "}
                                                      <p className="block-title">

                                                        {`${flights.Dep_StationName} (${flights.Dep_IATA_LocationCode}) `}to{" "}
                                                        {`${flights.Arr_StationName} (${flights.Arr_IATA_LocationCode})`}

                                                      </p>
                                                    </li>
                                                    <li>
                                                      <p>
                                                        <span style={{ fontSize: "large" }}>
                                                          <i className="fa fa-plane"></i>
                                                        </span>
                                                        <span
                                                          style={{ color: "black", paddingLeft: "5px" }}
                                                        >
                                                          {moment(
                                                            flights.Dep_AircraftScheduledDateTime
                                                          ).format("HH:mm")}{" "}
                                                        </span>
                                                        -{" "}
                                                        <span>
                                                          {removeFirstTwoCharactersSlice(flights.Duration)}
                                                        </span>{" "}

                                                        <span style={{ fontSize: "large" }}>
                                                          <i className="fa fa-plane fa-flip-vertical"></i>
                                                        </span>
                                                        <span
                                                          style={{ color: "black", paddingLeft: "5px" }}
                                                        >
                                                          {moment(
                                                            flights.Arr_AircraftScheduledDateTime
                                                          ).format("HH:mm")}
                                                        </span>
                                                      </p>
                                                    </li>
                                                    <li>
                                                      {flights.CarrierDesigCode} -{" "}
                                                      {flights.OperatingCarrierFlightNumberText}{" "}
                                                    </li>
                                                    <li>
                                                      {flights.CabintypeName} (
                                                      {flights.CabinTypeCode})
                                                    </li>
                                                  </div>
                                                </ul>
                                              </div>
                                            </>
                                          ),
                                        },
                                        {
                                          label: "Baggage",
                                          key: "2",
                                          children: (
                                            <>
                                              <ul style={{ listStyle: "none" }}>
                                                <li>
                                                  Allowed Weight:{" "}
                                                  {flights.MaxWeightValue}{" "}
                                                  {flights.MaxWeightUnit}
                                                </li>
                                              </ul>
                                            </>
                                          ),
                                        },
                                        {
                                          label: "Cancellation",
                                          key: "3",
                                          children: (
                                            <>
                                              <table>
                                                <tr>
                                                  <th style={{ width: "70%" }}>
                                                    Time
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Fee
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <td style={{ width: "70%" }}>
                                                    <p>
                                                      Penalty for adults before
                                                      departure (Not applicable
                                                      within 24 hours of
                                                      departure).{" "}
                                                    </p>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <p>{flights.CancelPenalty}</p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </>
                                          ),
                                        },
                                        ,
                                        {
                                          label: "Date Change",
                                          key: "4",
                                          children: (
                                            <>
                                              <table>
                                                <tr>
                                                  <th style={{ width: "70%" }}>
                                                    Time
                                                  </th>
                                                  <th
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    Fee
                                                  </th>
                                                </tr>
                                                <tr>
                                                  <td style={{ width: "70%" }}>
                                                    <p>
                                                      Penalty for adults before
                                                      departure (Not applicable
                                                      within 24 hours of
                                                      departure).{" "}
                                                    </p>
                                                  </td>
                                                  <td
                                                    style={{
                                                      width: "30%",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    <p>{flights.ChangePenalty}</p>
                                                  </td>
                                                </tr>
                                              </table>
                                            </>
                                          ),
                                        },
                                      ]}
                                    />
                                    {!RetConnectingFLights ? <></> : RetConnectingFLights.filter(
                                      (cflights) =>
                                        cflights.PaxJourney_Id ===
                                        flights.PaxJourney_Id &&
                                        cflights.FlightType === "RC"
                                      // && cflights.resBookDesigCode ===
                                      // flights.resBookDesigCode
                                    ).map((cflights) => (
                                      <Tabs style={{ marginTop: "20px" }}
                                        defaultActiveKey="1"
                                        items={[
                                          {
                                            label: "Itinerary",
                                            key: "1",
                                            children: (
                                              <>
                                                <div className="row">
                                                  <ul
                                                    style={{ listStyle: "none" }}
                                                  >
                                                    <li>
                                                      <img
                                                        src={
                                                          "images/airlines/PK.png"
                                                        }
                                                        style={{
                                                          width: "60px",
                                                          height: "60px",
                                                        }}
                                                        alt="flight-img"
                                                      />
                                                    </li>
                                                    <div className="verticalline">
                                                      <li>
                                                        {" "}
                                                        <p className="block-title">

                                                          {`${cflights.Dep_StationName} (${cflights.Dep_IATA_LocationCode}) `}to{" "}
                                                          {`${cflights.Arr_StationName} (${cflights.Arr_IATA_LocationCode})`}
                                                        </p>
                                                      </li>
                                                      <li>
                                                        <p>
                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane"></i>
                                                          </span>
                                                          <span
                                                            style={{
                                                              color: "black", paddingLeft: "5px"
                                                            }}
                                                          >
                                                            {moment(
                                                              cflights.Dep_AircraftScheduledDateTime
                                                            ).format("HH:mm")}{" "}
                                                          </span>
                                                          -{" "}
                                                          <span>
                                                            {removeFirstTwoCharactersSlice(flights.Duration)}
                                                          </span>{" "}

                                                          <span style={{ fontSize: "large" }}>
                                                            <i className="fa fa-plane fa-flip-vertical"></i>
                                                          </span>
                                                          <span
                                                            style={{
                                                              color: "black", paddingLeft: "5px"
                                                            }}
                                                          >
                                                            {moment(
                                                              cflights.Arr_AircraftScheduledDateTime
                                                            ).format("HH:mm")}
                                                          </span>
                                                        </p>
                                                      </li>
                                                      <li>
                                                        {cflights.CarrierDesigCode} -{" "}
                                                        {cflights.OperatingCarrierFlightNumberText}{" "}
                                                      </li>
                                                      <li>
                                                        {cflights.CabintypeName} (
                                                        {cflights.CabinTypeCode})
                                                      </li>
                                                    </div>
                                                  </ul>
                                                </div>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Baggage",
                                            key: "2",
                                            children: (
                                              <>
                                                <ul style={{ listStyle: "none" }}>
                                                  <li>
                                                    Allowed Weight:{" "}
                                                    {cflights.MaxWeightValue}{" "}
                                                    {cflights.MaxWeightUnit}
                                                  </li>
                                                </ul>
                                              </>
                                            ),
                                          },
                                          {
                                            label: "Cancellation",
                                            key: "3",
                                            children: (
                                              <>
                                                <table>
                                                  <tr>
                                                    <th style={{ width: "70%" }}>
                                                      Time
                                                    </th>
                                                    <th
                                                      style={{
                                                        width: "30%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Fee
                                                    </th>
                                                  </tr>
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                        Penalty for adults before
                                                        departure (Not applicable
                                                        within 24 hours of
                                                        departure).{" "}
                                                      </p>
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "30%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <p>
                                                        {cflights.CancelPenalty}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                          ,
                                          {
                                            label: "Date Change",
                                            key: "4",
                                            children: (
                                              <>
                                                <table>
                                                  <tr>
                                                    <th style={{ width: "70%" }}>
                                                      Time
                                                    </th>
                                                    <th
                                                      style={{
                                                        width: "30%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      Fee
                                                    </th>
                                                  </tr>
                                                  <tr>
                                                    <td style={{ width: "70%" }}>
                                                      <p>
                                                        Penalty for adults before
                                                        departure (Not applicable
                                                        within 24 hours of
                                                        departure).{" "}
                                                      </p>
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "30%",
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      <p>
                                                        {cflights.ChangePenalty}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </table>
                                              </>
                                            ),
                                          },
                                        ]}
                                      />
                                    ))}
                                  </Panel>
                                </Collapse>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              )
            }
            else {
              return (<></>)
            }
          })()}
        </div>
        {isDisplayed && isDisplayed === true &&
          (
            <>
              <div class="needHelp  inlineDiv col-12 col-md-3 col-lg-3 order-1 order-lg-2" style={{ maxHeight: "150px !important" }} >
                <a
                  className="btn btn-orange mb5"
                  id="alerts"
                  onClick={(e) => handleOpenPopup()}
                >
                  Low fare Alert
                </a>
                <h2 class="rotate45">Need Help?</h2>
                <div class="need_help_extend">
                  <div class="d-block row">
                    <div class="inlineDiv"><img src={ImageSupport} width="53" height="53"></img></div>
                    <div class="inlineDiv ml-3">
                      <span class="d-block colorGrey">For booking help</span>
                      <span class="d-block colorBlack">(051) 111 77 88 00</span>
                    </div>
                  </div>

                </div>
              </div>
            </>
          )
        }

      </div>
    )}
  </>;
}

export default Hitit;
