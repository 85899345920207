import React,{ useState } from 'react'
import { Box, Tab, Tabs, Typography } from '@mui/material';

function InnerPage(){
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newTabIndex) => {
      setTabIndex(newTabIndex);
    };
  
    return(
        <>
        
     
<section className="innerpage-wrapper">
            <div id="why-us" className="innerpage-section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div className="page-heading innerpage-heading">
                                <h2>Discover New Destinations with Malik Express Travel & Tours</h2>
                                <hr className="heading-line" />
                            </div>

                            <div className="row">
                                <div className="col-12 col-md-12 col-lg-7 col-xl-7"
                                     id="why-us-tabs">
                                      <Box>
      <Box>
        <Tabs value={tabIndex} onChange={handleTabChange}
         sx={{
            '& .MuiTabs-indicator': { backgroundColor: '#faa61a' },
            '& .MuiTab-root': { color: 'black' },
            '& .Mui-selected': { color: '#faa61a' },
          }}
        >
          <Tab label="Why us?" />
          <Tab label="Satisfaction" />
          <Tab label="Customized" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {tabIndex === 0 && (
          <Box>
            <Typography>
            <p style={{color:'black'}}>
            Are you feeling stressed out about your travel plans? Do you need help finding cheap flights, hotels, and tour packages from Pakistan? If so, consider using the services of a professional travel agent.
                                            </p>
                                            <p style={{color:'black'}}>
                                            Malik Express is a travel agency based in Islamabad that offers a wide range of travel services. Their experienced travel agents will collaborate with you to organize every detail of your vacation, including hotels, transportation, tours, and excursions. So why not let Malik Express assist you in making your next holiday planning much less stressful?
                                            </p>
            </Typography>
          </Box>
        )}
        {tabIndex === 1 && (
          <Box>
            <Typography>
            <p style={{color:'black'}}>
            At Malik Express Travel & Tours, we are committed to providing our valued clients with the best travel services available. Our travel agency has been operating in Pakistan for over 30 years and since then, we have proudly built up a reputation of being one of the leading travel agencies in the country. We take great pride in understanding our client's travel needs and reflect that understanding through quality travel services rendered by our professional travel agents. 
                                            </p>
                                            <p style={{color:'black'}}>
                                            With flexible travel packages, excellent customer service and an efficient procedure to booking and ticketing – it is no wonder why customers keep returning to us when they plan their trips. Put your trust in us and let us ensure you get a fulfilling travel experience!
                                            </p>
            </Typography>
          </Box>
        )}
        {tabIndex === 2 && (
          <Box>
            <Typography>
            <p style={{color:'black'}}>
            When it comes to travel services, Malik Express offers a wide variety of options. Whether you are looking for customized tours to make the most out of your trip or simply need travel assistance, Malik Express is your one stop shop. Every travel experience through our travel agency is designed specifically to meet the needs of its customers—no feeling rushed and overlooked. At Malik Express, all travel dreams become a reality with state-of-the-art travel experiences tailored just for you!
                                            </p>
                                            <p style={{color:'black'}}>
                                            Each destination carries stories waiting to be shared and with our personalized travel services and tour packages, you can easily find experiences that fit perfectly with your travel desires. With so many unique tours, there is much to explore with Malik Express!
                                            </p>
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
                                </div>

                                <div className="col-12 col-md-12 col-lg-5 col-xl-5"
                                     id="progress-bars">
                                    <div className="bar">
                                        <h4>Satisfied Clients</h4>
                                        <div className="progress">
                                            <div className="progress-bar progress_percent"
                                                 role="progressbar"
                                                 aria-valuenow="90"
                                                 aria-valuemin="0"
                                                 aria-valuemax="100"
                                                 style={{width: "90%"}}>
                                                <span>90%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bar">
                                        <h4>Tour Packages</h4>
                                        <div className="progress">
                                            <div className="progress-bar progress_percent"
                                                 role="progressbar"
                                                 aria-valuenow="90"
                                                 aria-valuemin="0"
                                                 aria-valuemax="100"
                                                 style={{width: "90%"}}>
                                                <span>90%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bar">
                                        <h4>Hotel Booking</h4>
                                        <div className="progress">
                                            <div className="progress-bar progress_percent"
                                                 role="progressbar"
                                                 aria-valuenow="75"
                                                 aria-valuemin="0"
                                                 aria-valuemax="100"
                                                 style={{width: "75%"}}>
                                                <span>75%</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="bar">
                                        <h4>Best Price Guaranteed</h4>
                                        <div className="progress">
                                            <div className="progress-bar progress_percent"
                                                 role="progressbar"
                                                 aria-valuenow="100"
                                                 aria-valuemin="0"
                                                 aria-valuemax="100"
                                                 style={{width: "100%"}}>
                                                <span>100%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </>
    )    
}

export default InnerPage;