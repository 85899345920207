import React from "react";
import { TextField } from "@mui/material";
import $ from "jquery";
import { useState, useEffect } from "react";
import { DatePicker } from "react-rainbow-components";
import { Radio, Space, Select } from "antd";
import axios from "axios";
import { format, addDays } from "date-fns";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import AutocompleteNew from "../SearchResult/AutocompleteNew";
import AutocompleteNew2 from "../SearchResult/AutocompleteNew2";

import SearchBar from "./SearchBar";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MosqueIcon from "@mui/icons-material/Mosque";
import FlightIcon from "@mui/icons-material/Flight";
import HotelIcon from "@mui/icons-material/Hotel";
import ArticleIcon from "@mui/icons-material/Article";
import SecurityIcon from "@mui/icons-material/Security";
import LuggageIcon from "@mui/icons-material/Luggage";


var SearchType = 1;
function Slider() {
  // const apiUrl = "https://localhost:7173";
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Save instance for the further update
  }, []);
  //Creating Icons for Flights and Hotel
  /*     const MyIconFlight=( <svg width="24" height="24" viewBox="0 0 24 24"  fill="black" xmlns="http://www.w3.org/2000/svg" className="_1MZrIK28AE8HVj289UBgZw st-svg-icon mr-2 _1VvPNtUpO0rXj3iz2Ie8yD">
        <g>
            <path d="M22.9605 7.71829C20.9272 6.96759 18.7425 6.85387 16.6423 7.38928L13.9132 8.08504L6.1809 3.65203C6.022 3.56095 5.83398 3.53531 5.65637 3.58055L2.40855 4.40855C2.14614 4.47548 1.94533 4.6868 1.89185 4.9523C1.83841 5.21775 1.94182 5.49033 2.15796 5.65354L7.5317 9.71202L5.31892 10.2762L2.27041 8.4688C1.94852 8.27798 1.53349 8.3721 1.32541 8.68326L0.118991 10.4876C-0.0639617 10.7612 -0.0330711 11.125 0.193335 11.3639L3.84086 15.2123C3.97562 15.3545 4.16134 15.4323 4.35226 15.4323C4.41025 15.4323 4.46865 15.4251 4.52631 15.4104L22.8026 10.7511C23.4917 10.5754 23.9608 10.003 23.9978 9.29282C24.0348 8.58262 23.6276 7.96462 22.9605 7.71829Z"
                fill="black"></path>
            <path d="M12.1368 19.0327H0.704577C0.315468 19.0327 0 19.3482 0 19.7373C0 20.1264 0.315468 20.4419 0.704577 20.4419H12.1367C12.5258 20.4419 12.8413 20.1264 12.8413 19.7373C12.8413 19.3482 12.5259 19.0327 12.1368 19.0327Z"
                fill="black"></path>
        </g>
        <defs>
            <clipPath><rect width="24" height="24" fill="white" transform="translate(0 0.000244141)"></rect></clipPath>
        </defs>
    </svg>); 
    
    const MyIconHotel=( <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="_1MZrIK28AE8HVj289UBgZw st-svg-icon mr-2 _1VvPNtUpO0rXj3iz2Ie8yD"><path d="M21.3344 7.56177H8.92116C8.31468 7.56177 7.30225 7.79164 7.30225 9.35186V11.7387H22.9533V9.35186C22.9533 7.79653 21.9409 7.56177 21.3344 7.56177Z" fill="black"></path><path d="M3.92745 12.257H1.56511V3.91296H0V20.0874H1.56511V15.9105H22.4349V20.0825H24V12.257H3.92745Z" fill="black"></path><path d="M4.17198 11.8364C5.37942 11.8364 6.35825 10.8576 6.35825 9.65013C6.35825 8.44269 5.37942 7.46387 4.17198 7.46387C2.96454 7.46387 1.98572 8.44269 1.98572 9.65013C1.98572 10.8576 2.96454 11.8364 4.17198 11.8364Z" fill="black"></path></svg>)
    */

  const MyIconFlight =
    '<img src="../images/icons/airplane.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';
  const MyIconHotel =
    '<img src="../images/icons/hotel.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';
  const MyIconVisa =
    '<img src="../images/icons/visa.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';
  const MyIconTours =
    '<img src="../images/icons/touring.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';
  const MyIconUmrah =
    '<img src="../images/icons/hajj.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';
    const MyIconinsurance =
    '<img src="../images/icons/insurance.png" alt="flight-img"  style={{width:"100%"}} className="img-fluid" />';

  const [selectedUmrahCity, setselectedUmrahCity] = useState("Makkah");

  //here is the Visa Search related Requests

  async function SendVisaRequest() {
    const url = apiUrl + "/api/VisaSearch/CountryGet?term=" + VisaArrivalCity;
    // console.log(url);
    await axios
      .get(url)
      .then((response) => {
        const data = response.data;
        navigate("/visas/" + VisaArrivalCity + "-visa", {
          state: { props: data },
        });
      })
      .catch((error) => console.error("Error: ${error}"));
  }

 

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onVisaKeyUp = (e) => {
    setLoading(true);
    fetchVisaData(e.target.value);
  };

  async function fetchVisaData(e) {
    // setLoading(true);
    const url = apiUrl + "/api/FlightSearch/CountrySearch?term=" + e;
    // console.log(url);
    await axios.get(url);
    setLoading(true)
      .then((response) => {
        const data = response.data;
        setLoading(false);
        SetVisaStates(data);
        //   console(response.data);
        // suggestions  = data;
        //  console(suggestions);
      })
      .catch((error) => console.error("Error: ${error}"));
  }


 
  const mystyle = {
    background:
      "linear-gradient(rgba(0,0,0,0.3),rgba(0,0,0,0.3)),url(images/malikexpress_background.jpg) 50% 0%",
    backgroundsize: "contain",
  };

  const [UmrahStartdate, UmrahsetStartDate] = useState(
    format(new Date(), "yyyy/MM/dd")
  );
  const [UmrahEnddate, UmrahsetEndDate] = useState(
    format(new Date(), "yyyy/MM/dd")
  );
  const UmrahSetStartDate = (date) => {
    UmrahsetStartDate(format(date, "yyyy/MM/dd"));
  };
  const UmrahSetEndDate = (date) => {
    UmrahsetEndDate(format(date, "yyyy/MM/dd"));
  };
  const [VisaStates, SetVisaStates] = useState([]);
  const [States, setStates] = useState([]);

  //Setting values of the feilds
  const [Classvalue, setRadioValue] = useState("Economy");
  const [DepartureCity, setDepartureCity] = useState("");
  const [ArrivalCity, setArrivalCity] = useState("");
  const [VisaArrivalCity, setVisaArrivalCity] = useState("");

  const [MultiFirstDepartureCity, setMultiFirstDepartureCity] = useState();
  const [MultiFirstArrivalCity, setMultiFirstArrivalCity] = useState();
  const [MultiSecondDepartureCity, setMultiSecondDepartureCity] = useState();
  const [MultiSecondArrivalCity, setMultiSecondArrivalCity] = useState();
  const [MultiThirdDepartureCity, setMultiThirdDepartureCity] = useState();
  const [MultiThirdArrivalCity, setMultiThirdArrivalCity] = useState();
  const [MultiFourthDepartureCity, setMultiFourthDepartureCity] = useState();
  const [MultiFourthArrivalCity, setMultiFourthArrivalCity] = useState();
  const [MultiFifthDepartureCity, setMultiFifthDepartureCity] = useState();
  const [MultiFifthArrivalCity, setMultiFifthArrivalCity] = useState();
  const [MultiSixthDepartureCity, setMultiSixthDepartureCity] = useState();
  const [MultiSixthArrivalCity, setMultiSixthArrivalCity] = useState();

  const [Startdate, setStartDate] = useState(
    format(addDays(new Date(), 1), "yyyy/MM/dd")
  );
  const [EndDate, setEndDate] = useState(
    format(addDays(new Date(), 1), "yyyy/MM/dd")
  );
  
  const CheckOpen = (e) => {
    setOpen(true);

    const url = apiUrl + "/api/FlightSearch/CountryAll";
    // console.log(url);
    axios
      .get(url)
      .then((response) => {
        const data = response.data;
        setLoading(false);
        SetVisaStates(data);
      })
      .catch((error) => console.error("Error: ${error}"));
  };
  //Setting value of Home Tabs
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  function ClosePopUp() {
    const el = document.getElementById("MobileDatePickerOverlay");
    el.classList.remove("mlk-drawer-open");
  }

  const [StartMultiFirstDate, setStartMultiFirstDate] = useState(
    format(new Date(), "yyyy/MM/dd")
  );
  const [StartMultiSecondDate, setStartMultiEndDate] = useState(
    format(new Date(StartMultiFirstDate), "yyyy/MM/dd")
  );
  const [StartMultiThirdDate, setStartMultiThirdDate] = useState(
    format(new Date(StartMultiSecondDate), "yyyy/MM/dd")
  );
  const [StartMultiFourthDate, setStartMultiFourthDate] = useState(
    format(new Date(StartMultiThirdDate), "yyyy/MM/dd")
  );
  const [StartMultiFifthDate, setStartMultiFifthDate] = useState(
    format(new Date(StartMultiFourthDate), "yyyy/MM/dd")
  );
  const [StartMultiSixthDate, setStartMultiSixthDate] = useState(
    format(new Date(StartMultiFifthDate), "yyyy/MM/dd")
  );
  const [economytoggle, seteconomyToggle] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 700);
  const [adultscount, setadultsCount] = useState(1);
  const [childrencount, setchildrenCount] = useState(0);
  const [infantscount, setinfantsCount] = useState(0);

  const SetStarttDate = (date) => {
    setStartDate(format(date, "yyyy/MM/dd"));
  };
  const SetEnddDate = (date) => {
    setEndDate(format(date, "yyyy/MM/dd"));
  };

  

  const SetMultiFirstDatee = (date) => {
    setStartMultiFirstDate(format(date, "yyyy/MM/dd"));
  };
  const SetMultiSecondDatee = (date) => {
    setStartMultiEndDate(format(date, "yyyy/MM/dd"));
  };
  const SetMultiThirdDatee = (date) => {
    setStartMultiThirdDate(format(date, "yyyy/MM/dd"));
  };
  const SetMultiFourthDatee = (date) => {
    setStartMultiFourthDate(format(date, "yyyy/MM/dd"));
  };
  const SetMultiFifthDatee = (date) => {
    setStartMultiFifthDate(format(date, "yyyy/MM/dd"));
  };
  const SetMultiSixthDatee = (date) => {
    setStartMultiSixthDate(format(date, "yyyy/MM/dd"));
  };

  const ShowMultiWays = (type) => {
    SearchType = type;
    const el = document.getElementById("SinlgeTwoWayDiv");
    el.style.display = "none";

    const ell = document.getElementById("MultiWaySelectionDiv");
    ell.style.display = "block";

    const double = document.getElementById("TwoWaySelectionTop");
    const single = document.getElementById("OneWaySelectionTop");
    const multi = document.getElementById("MultiWaySelectionTop");
    single.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    double.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    multi.classList.add("_3Flxco_ApdE_UsZi6Cts3l");
  };
  const ShowStartDate = (type) => {
    SearchType = type;
    const ell = document.getElementById("MultiWaySelectionDiv");
    ell.style.display = "none";

    const elb = document.getElementById("SinlgeTwoWayDiv");
    elb.style.display = "block";

    const el = document.getElementById("StartDateFirstDIV");
    el.style.display = "none";
    const double = document.getElementById("TwoWaySelectionTop");
    const single = document.getElementById("OneWaySelectionTop");
    const multi = document.getElementById("MultiWaySelectionTop");
    double.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    multi.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    single.classList.add("_3Flxco_ApdE_UsZi6Cts3l");
  };
  const ShowEndDateDiv = (type) => {
    SearchType = type;
    const ell = document.getElementById("MultiWaySelectionDiv");
    ell.style.display = "none";

    const elb = document.getElementById("SinlgeTwoWayDiv");
    elb.style.display = "block";

    const el = document.getElementById("StartDateFirstDIV");
    const single = document.getElementById("OneWaySelectionTop");
    const double = document.getElementById("TwoWaySelectionTop");
    const multi = document.getElementById("MultiWaySelectionTop");
    single.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    multi.classList.remove("_3Flxco_ApdE_UsZi6Cts3l");
    double.classList.add("_3Flxco_ApdE_UsZi6Cts3l");
    el.style.display = "block";
  };
  var MultiDivsShownumber = 2;
  function AddNewDestination() {
    var multipleshowid = MultiDivsShownumber + 1;
    MultiDivsShownumber = multipleshowid;
    switch (multipleshowid) {
      case 3:
        $("#MultiThirdDiv").show();
        break;
      case 4:
        $("#MultiFourthDiv").show();
        break;
      case 5:
        $("#MultiFifthDiv").show();
        break;
      case 6:
        $("#MultiSixthDiv").show();
        $("#AddDestinationButton").hide();
        break;
    }
  }
  function DeleteNewDestination() {
    var value = MultiDivsShownumber;
    switch (value) {
      case 3:
        $("#MultiThirdDiv").hide();
        $("#MultiFourthDiv").hide();
        $("#MultiFifthDiv").hide();
        $("#MultiThirdDiv").hide();
        MultiDivsShownumber--;
        break;
      case 4:
        $("#MultiSixthDiv").hide();
        $("#MultiFifthDiv").hide();
        $("#MultiFourthDiv").hide();
        MultiDivsShownumber--;
        break;
      case 5:
        $("#MultiSixthDiv").hide();
        $("#MultiFifthDiv").hide();
        MultiDivsShownumber--;
        break;
      case 6:
        $("#MultiSixthDiv").hide();
        $("#AddDestinationButton").show();
        MultiDivsShownumber--;
        break;
    }
  }

  function handleSelectChange(event) {
    setselectedUmrahCity(event);
  }

  async function SendUmrahRequest() {
    const url = apiUrl + "/api/umrah/umrahhotels?term=" + selectedUmrahCity;
    // console.log(url);
    await axios
      .get(url)
      .then((response) => {
        const data = response.data;
        navigate("/UmrahHotels", { state: { props: data } });
      })
      .catch((error) => console.error("Error: ${error}"));
  }

 
  return (
    <section className="flexslider-container" id="flexslider-container-2">
      <div className="flexslider slider" id="slider-2" >
        <ul className="slides"></ul>
      </div>

      <main
        className="mlk-layout-content hLNN-RF2oxmHDQO0AGP_0 mlk-height-search"
        // style={{ marginTop: "-520px" }}
      >
        <div
          className="mlk-row _24_swGyEDgCusBPHo_zJWz pb-16 MobilePadding"
          style={{ rowGap: "24px" }}
        >
          <div className="mlk-col mlk-col-24">
            <div className="ant-tabs-content-holder">
              <div className="_3E4Vc-PW-NkvQMIgdfYlI- p-xs-0">
                <div
                  className="mlk-row _3LiQIwcG_drQvN4i9BAA7"
                  style={{ rowGap: "0px" }}
                >
                  <div className="mlk-col mlk-col-24">
                    <div className="mlk-tabs mlk-tabs-top mlk-tabs-card" style={{boxShadow:'0px 0px 40px rgba(0,0,0,0.2)'}}>
                      <div role="tablist" className="mlk-tabs-nav">
                        <div className="mlk-tabs-nav-wrap CustomOverflow"style={{overflow:"auto"}}>
                          <Box
                            sx={{ width: "100%", typography: "body1" }}
                            style={{ backgroundColor: "transparent"}}
                              type="submit"
                          >
                            <TabContext
                              value={value}
                              style={{ backgroundColor: "transparent"}}
                                type="submit"
                            >
                              <Box
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                                style={{ backgroundColor: "transparent"}}
                                  type="submit"
                              >
                                <TabList
                                  onChange={handleChange}
                                  style={{
                                    backgroundColor: "transparent",
                                    boxShadow: "none",
                                    overflow:'auto'
                                  }}
                                    type="submit"
                                >
                                  <Tab
                                    label="Flights"
                                    value="1"
                                    className="CustomTopClasses"
                                    style={{ borderRadius: "11px 11px 0px 0px"}}
                                    icon={<FlightIcon />}
                                    sx={{
                                      bgcolor:
                                        value === 1
                                          ? "#e5b815"
                                          : "white",
                                      color:
                                        value === 1
                                          ? "white "
                                          : "#e5b815",
                                    }}
                                  >
                                    <span>
                                      <div
                                        className="mlk-row mlk-row-center mlk-row-middle"
                                        style={{ rowGap: "0px" }}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="_1MZrIK28AE8HVj289UBgZw st-svg-icon mr-2 _1VvPNtUpO0rXj3iz2Ie8yD"
                                        >
                                          <g>
                                            <path
                                              d="M22.9605 7.71829C20.9272 6.96759 18.7425 6.85387 16.6423 7.38928L13.9132 8.08504L6.1809 3.65203C6.022 3.56095 5.83398 3.53531 5.65637 3.58055L2.40855 4.40855C2.14614 4.47548 1.94533 4.6868 1.89185 4.9523C1.83841 5.21775 1.94182 5.49033 2.15796 5.65354L7.5317 9.71202L5.31892 10.2762L2.27041 8.4688C1.94852 8.27798 1.53349 8.3721 1.32541 8.68326L0.118991 10.4876C-0.0639617 10.7612 -0.0330711 11.125 0.193335 11.3639L3.84086 15.2123C3.97562 15.3545 4.16134 15.4323 4.35226 15.4323C4.41025 15.4323 4.46865 15.4251 4.52631 15.4104L22.8026 10.7511C23.4917 10.5754 23.9608 10.003 23.9978 9.29282C24.0348 8.58262 23.6276 7.96462 22.9605 7.71829Z"
                                              fill="black"
                                            ></path>
                                            <path
                                              d="M12.1368 19.0327H0.704577C0.315468 19.0327 0 19.3482 0 19.7373C0 20.1264 0.315468 20.4419 0.704577 20.4419H12.1367C12.5258 20.4419 12.8413 20.1264 12.8413 19.7373C12.8413 19.3482 12.5259 19.0327 12.1368 19.0327Z"
                                              fill="black"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath>
                                              <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                                transform="translate(0 0.000244141)"
                                              ></rect>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </div>
                                    </span>
                                  </Tab>
                                  <Tab
                                    label="Insurance"
                                    value="6"
                                      type="submit"
                                    className="CustomTopClasses"
                                    style={{ borderRadius: "11px 11px 0px 0px"}}
                                    href="/online-travel-insurance-pakistan"
                                    icon={<SecurityIcon />}
                                    sx={{
                                      bgcolor:
                                        value === 6
                                          ? "#e5b815"
                                          : "white",
                                      color:
                                        value === 6
                                          ? "white "
                                          : "#e5b815",
                                    }}
                                  >
                                    <span>
                                      <div
                                        className="mlk-row mlk-row-center mlk-row-middle"
                                        style={{ rowGap: "0px" }}
                                      >
                                        <svg
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="_1MZrIK28AE8HVj289UBgZw st-svg-icon mr-2 _1VvPNtUpO0rXj3iz2Ie8yD"
                                        >
                                          <g>
                                            <path
                                              d="M22.9605 7.71829C20.9272 6.96759 18.7425 6.85387 16.6423 7.38928L13.9132 8.08504L6.1809 3.65203C6.022 3.56095 5.83398 3.53531 5.65637 3.58055L2.40855 4.40855C2.14614 4.47548 1.94533 4.6868 1.89185 4.9523C1.83841 5.21775 1.94182 5.49033 2.15796 5.65354L7.5317 9.71202L5.31892 10.2762L2.27041 8.4688C1.94852 8.27798 1.53349 8.3721 1.32541 8.68326L0.118991 10.4876C-0.0639617 10.7612 -0.0330711 11.125 0.193335 11.3639L3.84086 15.2123C3.97562 15.3545 4.16134 15.4323 4.35226 15.4323C4.41025 15.4323 4.46865 15.4251 4.52631 15.4104L22.8026 10.7511C23.4917 10.5754 23.9608 10.003 23.9978 9.29282C24.0348 8.58262 23.6276 7.96462 22.9605 7.71829Z"
                                              fill="black"
                                            ></path>
                                            <path
                                              d="M12.1368 19.0327H0.704577C0.315468 19.0327 0 19.3482 0 19.7373C0 20.1264 0.315468 20.4419 0.704577 20.4419H12.1367C12.5258 20.4419 12.8413 20.1264 12.8413 19.7373C12.8413 19.3482 12.5259 19.0327 12.1368 19.0327Z"
                                              fill="black"
                                            ></path>
                                          </g>
                                          <defs>
                                            <clipPath>
                                              <rect
                                                width="24"
                                                height="24"
                                                fill="white"
                                                transform="translate(0 0.000244141)"
                                              ></rect>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                      </div>
                                    </span>
                                  </Tab>
                                  <Tab
                                    label="Hotels"
                                    value="2"
                                      type="submit"
                                    className="CustomTopClasses"
                                    style={{ borderRadius: "11px 0px 0px 0px" ,padding:'0px !important'}}
                                    icon={<HotelIcon />}
                                    sx={{
                                      bgcolor:
                                        value === 2
                                          ? "#e5b815"
                                          : "white",
                                      color:
                                        value === 2
                                          ? "white"
                                          : "#e5b815",
                                    }}
                                  />
                                  <Tab
                                    label="Visa"
                                    type="submit"
                                    className="CustomTopClasses"
                                    value="3"
                                   // href="/visa-consultancy"
                                    style={{ borderRadius: "11px 0px 0px 0px" }}
                                    icon={<ArticleIcon />}
                                    sx={{
                                      bgcolor:
                                        value === 3
                                          ? "#e5b815"
                                          : "white",
                                      color:
                                        value === 3
                                          ? "white"
                                          : "#e5b815",
                                    }}
                                  >
                                    Visa
                                  </Tab>
                                  <Tab
                                    label="Tours"
                                    className="CustomTopClasses"
                                    value="4"
                                    style={{ borderRadius: "11px 0px 0px 0px" }}
                                    icon={<LuggageIcon />}
                                    sx={{
                                      bgcolor:
                                        value === 4
                                          ? "#e5b815"
                                          : "white",
                                      color:
                                        value === 4
                                          ? "white"
                                          : "#e5b815",
                                    }}
                                  >
                                    Tours
                                  </Tab>
                                  <Tab
                                    label="Umrah"
                                    className="CustomTopClasses"
                                    value="5"
                                    style={{ borderRadius: "11px 0px 0px 0px" }}
                                    icon={<MosqueIcon />}
                                    sx={{
                                      bgcolor:
                                        value === 5
                                          ? "#e5b815"
                                          : "white",
                                      color:
                                        value === 5
                                          ? "white"
                                          : "#e5b815",
                                    }}
                                  >
                                    Umrah
                                  </Tab>
                                </TabList>
                              </Box>
                              <TabPanel value="1"     style={{
                                    
                                    overflow:'auto !important',
                                    margin:'10px'
                                  }} >

                               <SearchBar></SearchBar>

                              </TabPanel>
                              <TabPanel value="2">
                                <div>
                                  <script
                                    src="https://sbhc.portalhc.com/238554/searchbox/497997"
                                    crossorigin="anonymous"
                                    async
                                  ></script>
                                </div>
                              </TabPanel>
                              <TabPanel value="3">
                                <div
                                  role="tabpanel"
                                  tabIndex="0"
                                  aria-hidden="false"
                                  className="CutomTab tab-pane active mlk-tabs-tabpane mlk-tabs-tabpane-active"
                                  id="FlightsTab"
                                  aria-labelledby="rc-tabs-0-tab-Flights"
                                >
                                  <div
                                    className="ml-2 mr-2 mlk-row _3gePwZ9SAAGVfaKFiLvuNP"
                                    style={{
                                      marginLeft: "-14px",
                                      marginRight: "-14px",
                                      rowGap: "22px",
                                    }}
                                  >
                                    <div className="mlk-col mlk-col-24 pl-xs-0 pr-xs-0 pr-md-4 pl-md-4">
                                      <div
                                        className="mlk-row mlk-row-space-between mlk-row-middle"
                                        style={{ rowGap: "0px" }}
                                      >
                                        <div>
                                          <div
                                            className="mlk-row"
                                            style={{ rowGap: "0px" }}
                                          ></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="mlk-col mlk-col-24"
                                      id="SinlgeTwoWayDiv"
                                    >
                                      <div
                                        className="mlk-col mlk-col-24"
                                        style={{
                                          paddingLeft: "14px",
                                          paddingRight: "14px",
                                        }}
                                      >
                                        <div
                                          className="mlk-row"
                                          style={{
                                            marginLeft: "-17.5px",
                                            marginRight: "-17.5px",
                                            rowGap: "0px",
                                          }}
                                        >
                                          <div
                                            className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-12 mb-3"
                                            style={{
                                              paddingLeft: "17.5px",
                                              paddingRight: "17.5px",
                                            }}
                                          >
                                            <div>
                                              <div className="st-airport-selector">
                                                <svg
                                                  width="15"
                                                  height="21"
                                                  viewBox="0 0 15 21"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                                >
                                                  <path
                                                    d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                                                    fill="black"
                                                  ></path>
                                                </svg>
                                                <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                                                  <div className="mlk-select-selector">
                                                    <span className="mlk-select-selection-search">
                                                      <Autocomplete
                                                        id="FirstCity"
                                                        onKeyUp={onVisaKeyUp}
                                                        options={VisaStates.map(
                                                          (option) => option
                                                        )}
                                                        value={DepartureCity}
                                                        disabled
                                                        onChange={(
                                                          event,
                                                          newValue
                                                        ) => {
                                                          setDepartureCity(
                                                            newValue
                                                          );
                                                        }}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Pakistan"
                                                          />
                                                        )}
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-12 mb-3"
                                            style={{
                                              paddingLeft: "17.5px",
                                              paddingRight: "17.5px",
                                            }}
                                          >
                                            <div id="FlyingTo">
                                              <div className="st-airport-selector">
                                                <svg
                                                  width="15"
                                                  height="21"
                                                  viewBox="0 0 15 21"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                                >
                                                  <path
                                                    d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                                                    fill="black"
                                                  ></path>
                                                </svg>
                                                <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                                                  <div className="mlk-select-selector">
                                                    <span className="mlk-select-selection-search">
                                                      <Autocomplete
                                                        open={open}
                                                        onOpen={CheckOpen}
                                                        onKeyUp={onVisaKeyUp}
                                                        onClose={() =>
                                                          setOpen(false)
                                                        }
                                                        options={VisaStates.map(
                                                          (option) => option
                                                        )}
                                                        value={VisaArrivalCity}
                                                        onChange={(
                                                          event,
                                                          newValue
                                                        ) => {
                                                          setVisaArrivalCity(
                                                            newValue
                                                          );
                                                        }}
                                                        loading={loading}
                                                        renderInput={(
                                                          params
                                                        ) => (
                                                          <TextField
                                                            {...params}
                                                            variant="standard"
                                                            placeholder="Traveling To"
                                                            InputProps={{
                                                              ...params.InputProps,
                                                              endAdornment: (
                                                                <>
                                                                  {loading ? (
                                                                    <CircularProgress
                                                                      color="inherit"
                                                                      size={20}
                                                                    />
                                                                  ) : null}
                                                                  {
                                                                    params
                                                                      .InputProps
                                                                      .endAdornment
                                                                  }
                                                                </>
                                                              ),
                                                            }}
                                                          />
                                                        )}
                                                      />
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="mlk-col mlk-col-24 text-center md:pt-2 lg:pt-2 xl:pt-2 xxl:pt-2"
                                        style={{
                                          paddingLeft: "14px",
                                          paddingRight: "14px",
                                        }}
                                      >
                                        <div
                                          className="mlk-row mlk-row-center mlk-row-middle"
                                          style={{
                                            rowGap: "10px",
                                            paddingTop: "10px",
                                          }}
                                        >
                                          <button
                                            type="submit"
                                            onClick={SendVisaRequest}
                                            className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base"
                                          >
                                            <span
                                              role="img"
                                              aria-label="search"
                                              className="anticon anticon-search"
                                            >
                                              <svg
                                                viewBox="64 64 896 896"
                                                focusable="false"
                                                data-icon="search"
                                                width="1em"
                                                height="1em"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                              </svg>
                                            </span>
                                            <span>Go</span>
                                          </button>

                                          <button
                                            type="button"
                                            className="mlk-btn mlk-btn-link _2lOe1Wj4kELEfYdDMvvSNJ"
                                          >
                                            <svg
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                            >
                                              <path
                                                d="M20.5355 12.8121H18.6794C18.8686 13.3301 18.972 13.8891 18.972 14.4717V21.4866C18.972 21.7295 18.9297 21.9626 18.8528 22.1795H21.9213C23.0675 22.1795 24 21.2469 24 20.1007V16.2766C24 14.3663 22.4459 12.8121 20.5355 12.8121Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M5.02806 14.4717C5.02806 13.889 5.13142 13.3301 5.3206 12.8121H3.46454C1.55419 12.8121 0 14.3663 0 16.2767V20.1008C0 21.247 0.932486 22.1795 2.07872 22.1795H5.14726C5.07034 21.9626 5.02806 21.7295 5.02806 21.4866V14.4717Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M14.1216 11.0073H9.87841C7.96806 11.0073 6.41387 12.5615 6.41387 14.4719V21.4867C6.41387 21.8694 6.72409 22.1796 7.10678 22.1796H16.8933C17.276 22.1796 17.5862 21.8694 17.5862 21.4867V14.4719C17.5862 12.5615 16.032 11.0073 14.1216 11.0073Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M12 1.82092C9.70258 1.82092 7.83348 3.69002 7.83348 5.98751C7.83348 7.54587 8.69359 8.90689 9.96381 9.62145C10.5663 9.96036 11.2609 10.154 12 10.154C12.7391 10.154 13.4337 9.96036 14.0362 9.62145C15.3065 8.90689 16.1666 7.54582 16.1666 5.98751C16.1666 3.69007 14.2975 1.82092 12 1.82092Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M4.68366 5.70447C2.96546 5.70447 1.56764 7.10228 1.56764 8.82049C1.56764 10.5387 2.96546 11.9365 4.68366 11.9365C5.1195 11.9365 5.53454 11.8462 5.91155 11.6839C6.5634 11.4033 7.10087 10.9065 7.43344 10.2843C7.66688 9.84752 7.79968 9.34929 7.79968 8.82049C7.79968 7.10233 6.40186 5.70447 4.68366 5.70447Z"
                                                fill="black"
                                              ></path>
                                              <path
                                                d="M19.3164 5.70447C17.5982 5.70447 16.2004 7.10228 16.2004 8.82049C16.2004 9.34933 16.3332 9.84757 16.5666 10.2843C16.8992 10.9066 17.4366 11.4034 18.0885 11.6839C18.4655 11.8462 18.8805 11.9365 19.3164 11.9365C21.0346 11.9365 22.4324 10.5387 22.4324 8.82049C22.4324 7.10228 21.0346 5.70447 19.3164 5.70447Z"
                                                fill="black"
                                              ></path>
                                            </svg>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                              <TabPanel value="4">
                                <div className="">
                                  Here is the content of Tours
                                </div>
                              </TabPanel>
                              <TabPanel value="5">
                                <div className="">
                                  <div
                                    role="tabpanel"
                                    tabIndex="0"
                                    aria-hidden="false"
                                    className="CutomTab tab-pane active mlk-tabs-tabpane mlk-tabs-tabpane-active"
                                    id="FlightsTab"
                                    aria-labelledby="rc-tabs-0-tab-Flights"
                                  >
                                    <div
                                      className="ml-2 mr-2 mlk-row _3gePwZ9SAAGVfaKFiLvuNP"
                                      style={{
                                        marginLeft: "-14px",
                                        marginRight: "-14px",
                                        rowGap: "22px",
                                      }}
                                    >
                                      <div className="mlk-col mlk-col-24 pl-xs-0 pr-xs-0 pr-md-4 pl-md-4">
                                        <div
                                          className="mlk-row mlk-row-space-between mlk-row-middle"
                                          style={{ rowGap: "0px" }}
                                        >
                                          <div>
                                            <div
                                              className="mlk-row"
                                              style={{ rowGap: "0px" }}
                                            ></div>
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="mlk-col mlk-col-24"
                                        id="SinlgeTwoWayDiv"
                                      >
                                        <div
                                          className="mlk-col mlk-col-24"
                                          style={{
                                            paddingLeft: "14px",
                                            paddingRight: "14px",
                                          }}
                                        >
                                          <div
                                            className="mlk-row"
                                            style={{
                                              marginLeft: "-17.5px",
                                              marginRight: "-17.5px",
                                              rowGap: "0px",
                                            }}
                                          >
                                            <div
                                              className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-8 mb-3"
                                              style={{
                                                paddingLeft: "17.5px",
                                                paddingRight: "17.5px",
                                              }}
                                            >
                                              <div
                                                className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search"
                                                style={{ width: "100%" }}
                                              >
                                                <div className="mlk-select-selector">
                                                  <Space wrap>
                                                    <Select
                                                      id="UmrahCity"
                                                      name="UmrahCity"
                                                      value={selectedUmrahCity}
                                                      onChange={
                                                        handleSelectChange
                                                      }
                                                      defaultValue="Makkah"
                                                      style={{
                                                        width: "100%",
                                                      }}
                                                      allowClear
                                                      options={[
                                                        {
                                                          value: "Makkah",
                                                          label: "Makkah",
                                                        },
                                                        {
                                                          value: "Madinah",
                                                          label: "Madinah",
                                                        },
                                                      ]}
                                                    />
                                                  </Space>
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-8 mb-3"
                                              style={{
                                                paddingLeft: "17.5px",
                                                paddingRight: "17.5px",
                                              }}
                                            >
                                              <div className="mlk-picker mlk-picker-large _1epwiHF8YSic2nULq7H-mU">
                                                <div className="mlk-picker-input">
                                                  <DatePicker
                                                    className="Datepickerwidth"
                                                    placeholder="Start Date"
                                                    minDate={new Date()}
                                                    name="StartDatePicker"
                                                    value={UmrahStartdate}
                                                    onChange={UmrahSetStartDate}
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                            <div
                                              className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-8 mb-3"
                                              style={{
                                                paddingLeft: "17.5px",
                                                paddingRight: "17.5px",
                                              }}
                                            >
                                              <div className="mlk-picker mlk-picker-large _1epwiHF8YSic2nULq7H-mU">
                                                <div className="mlk-picker-input">
                                                  <DatePicker
                                                    className="Datepickerwidth"
                                                    placeholder="Start Date"
                                                    minDate={new Date()}
                                                    name="StartDatePicker"
                                                    value={UmrahEnddate}
                                                    onChange={UmrahSetEndDate}
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="mlk-col mlk-col-24 text-center md:pt-2 lg:pt-2 xl:pt-2 xxl:pt-2"
                                          style={{
                                            paddingLeft: "14px",
                                            paddingRight: "14px",
                                          }}
                                        >
                                          <div
                                            className="mlk-row mlk-row-center mlk-row-middle"
                                            style={{
                                              rowGap: "10px",
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <button
                                              type="submit"
                                              onClick={SendUmrahRequest}
                                              className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base"
                                            >
                                              <span
                                                role="img"
                                                aria-label="search"
                                                className="anticon anticon-search"
                                              >
                                                <svg
                                                  viewBox="64 64 896 896"
                                                  focusable="false"
                                                  data-icon="search"
                                                  width="1em"
                                                  height="1em"
                                                  fill="currentColor"
                                                  aria-hidden="true"
                                                >
                                                  <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                                </svg>
                                              </span>
                                              <span>Go</span>
                                            </button>

                                            <button
                                              type="submit"
                                              className="mlk-btn mlk-btn-link _2lOe1Wj4kELEfYdDMvvSNJ"
                                            >
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="_1MZrIK28AE8HVj289UBgZw st-svg-icon"
                                              >
                                                <path
                                                  d="M20.5355 12.8121H18.6794C18.8686 13.3301 18.972 13.8891 18.972 14.4717V21.4866C18.972 21.7295 18.9297 21.9626 18.8528 22.1795H21.9213C23.0675 22.1795 24 21.2469 24 20.1007V16.2766C24 14.3663 22.4459 12.8121 20.5355 12.8121Z"
                                                  fill="black"
                                                ></path>
                                                <path
                                                  d="M5.02806 14.4717C5.02806 13.889 5.13142 13.3301 5.3206 12.8121H3.46454C1.55419 12.8121 0 14.3663 0 16.2767V20.1008C0 21.247 0.932486 22.1795 2.07872 22.1795H5.14726C5.07034 21.9626 5.02806 21.7295 5.02806 21.4866V14.4717Z"
                                                  fill="black"
                                                ></path>
                                                <path
                                                  d="M14.1216 11.0073H9.87841C7.96806 11.0073 6.41387 12.5615 6.41387 14.4719V21.4867C6.41387 21.8694 6.72409 22.1796 7.10678 22.1796H16.8933C17.276 22.1796 17.5862 21.8694 17.5862 21.4867V14.4719C17.5862 12.5615 16.032 11.0073 14.1216 11.0073Z"
                                                  fill="black"
                                                ></path>
                                                <path
                                                  d="M12 1.82092C9.70258 1.82092 7.83348 3.69002 7.83348 5.98751C7.83348 7.54587 8.69359 8.90689 9.96381 9.62145C10.5663 9.96036 11.2609 10.154 12 10.154C12.7391 10.154 13.4337 9.96036 14.0362 9.62145C15.3065 8.90689 16.1666 7.54582 16.1666 5.98751C16.1666 3.69007 14.2975 1.82092 12 1.82092Z"
                                                  fill="black"
                                                ></path>
                                                <path
                                                  d="M4.68366 5.70447C2.96546 5.70447 1.56764 7.10228 1.56764 8.82049C1.56764 10.5387 2.96546 11.9365 4.68366 11.9365C5.1195 11.9365 5.53454 11.8462 5.91155 11.6839C6.5634 11.4033 7.10087 10.9065 7.43344 10.2843C7.66688 9.84752 7.79968 9.34929 7.79968 8.82049C7.79968 7.10233 6.40186 5.70447 4.68366 5.70447Z"
                                                  fill="black"
                                                ></path>
                                                <path
                                                  d="M19.3164 5.70447C17.5982 5.70447 16.2004 7.10228 16.2004 8.82049C16.2004 9.34933 16.3332 9.84757 16.5666 10.2843C16.8992 10.9066 17.4366 11.4034 18.0885 11.6839C18.4655 11.8462 18.8805 11.9365 19.3164 11.9365C21.0346 11.9365 22.4324 10.5387 22.4324 8.82049C22.4324 7.10228 21.0346 5.70447 19.3164 5.70447Z"
                                                  fill="black"
                                                ></path>
                                              </svg>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </TabPanel>
                            </TabContext>
                          </Box>
                        </div>
                        <div className="mlk-tabs-nav-operations mlk-tabs-nav-operations-hidden">
                          <button
                            type="submit"
                            className="mlk-tabs-nav-more"
                            tabIndex="-1"
                            aria-hidden="true"
                            aria-haspopup="listbox"
                            aria-controls="rc-tabs-0-more-popup"
                            id="rc-tabs-0-more"
                            aria-expanded="false"
                            style={{ visibility: "hidden", order: "1" }}
                          >
                            <span
                              role="img"
                              aria-label="ellipsis"
                              className="anticon anticon-ellipsis"
                            >
                              <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="ellipsis"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true"
                              >
                                <path d="M176 511a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0zm280 0a56 56 0 10112 0 56 56 0 10-112 0z"></path>
                              </svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

     
    </section>
  );
}

export default Slider;
