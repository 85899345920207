import React, { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Assessment, ConfirmationNumber } from "@mui/icons-material";

function Testing() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const apiUrl = process.env.REACT_APP_API_URL;
    const pp_ResponseCode = searchParams.get("pp_ResponseCode")
    const pp_ResponseMessage = searchParams.get("pp_ResponseMessage")
    const bookingId = searchParams.get("pp_BillReference")
    const [accessToken, setAccessToken] = useState(null);
    console.log('Access data:', accessToken);
    const [paymentDetail, setPaymentDetail] = useState({});
    console.log('payment form ',paymentDetail)
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    
    const currentDates = formatDate(new Date());
    console.log('Current Date and Time:', currentDates);
    const FastpayToken = async () => {
        try {
            const response = await fetch('https://api.malikexpress.com/api/payfast/GetAccessToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    merchant_id: '21277',
                    secured_key: '-VFew2B7oKZBitKsXjXnAJ5-FG'
                })
            });
    
            if (response.status === 200) {
                const data = await response.json()
                setAccessToken(data.accesS_TOKEN)
                console.log('Response data:', response.accesS_TOKEN);
                
            } else {
                throw new Error('Failed to get access token. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching access token:',error);
        }
    };
    const Fastpay = async () => {
        
           
                const payload = {
                    TOKEN: accessToken,
                    CURRENCY_CODE: 'PKR',
                    MERCHANT_ID: '21277',
                    SUCCESS_URL:  'https://malikexpress.com/ConfirmedPayment/',
                    FAILURE_URL:  'https://malikexpress.com/ConfirmedPayment/',
                    CHECKOUT_URL: 'https://malikexpress.com/ConfirmedPayment/',
                    CUSTOMER_EMAIL_ADDRESS: 'ali@gmail.com',
                    CUSTOMER_MOBILE_NO: '03115912474',
                    TXNAMT: '2000',
                    BASKET_ID: '1480',
                    ORDER_DATE: currentDates,
                    SIGNATURE: 'testing',
                    VERSION: 'MERCHANT-CART-0.1',
                    TXNDESC: 'Item Purchased from Cart',
                    PROCCODE: '00',
                    TRAN_TYPE:'ECOMM_PURCHASE',
                    STORE_ID:'malik',
                    RECURRING_TXN:'true',


                };
                

                setPaymentDetail(payload);

               
              
      
    };
    useEffect(() => {
        FastpayToken().then(() => {
            Fastpay();
        });
    }, []);
    


    const handleJazzcash =  async () => {
       
        document.getElementById("FormPGS").submit();
  
    };
 
    return (
        <>
            <Helmet>
                <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>

                <meta
                    name="description"
                    content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
                    data-react-helmet="true"
                />
                <meta
                    name="keywords"
                    content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
                    data-react-helmet="true"
                />
                <link rel="canonical" href="https://malikexpress.com/thankyou" />
            </Helmet>
            <MainMenu></MainMenu>

            <section className="innerpage-wrapper">
                <div id="thank-you" className="innerpage-section-padding">
                    <div className="container">
                        

                        <PaymentForms paymentDetails={paymentDetail} />
                      
                        <button className="btn btn-orange" size="lg" onClick={handleJazzcash}>
                                                Pay amount
                                            </button>  

                    </div>
                </div>
            </section>

            <Footer></Footer>
        </>
    )
}

const PaymentForms = ({ paymentDetail }) => {
    if (!paymentDetail || Object.keys(paymentDetail).length === 0) {
        return null; // ya phir kuch placeholder HTML return karein
    }

    return (
        <form id="FormPGS" action="https://ipguat.apps.net.pk/Ecommerce/api/Transaction/PostTransaction" method="POST">
            {Object.keys(paymentDetail).map((key) => {
                const fieldName = key;
                const fieldValue = paymentDetail[key];
                return (
                    <input
                        key={fieldName}
                       // type="hidden"
                        id={fieldName}
                        name={fieldName}
                        value={fieldValue}
                    />
                );
            })}
        </form>
    );
};


export default Testing;

