import React, { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import {Link, useLocation , useNavigate} from "react-router-dom";
import moment from "moment";
import axios from "axios";

function VisaThankYou(){

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      

    return(
        <>
        <MainMenu></MainMenu>
          <section className="page-cover" id="cover-thank-you">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="page-title">Thank You</h1>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item"><Link href="#">Home</Link></li>
                            <li className="breadcrumb-item">Thank You</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section className="innerpage-wrapper">
            <div id="thank-you" className="innerpage-section-padding">
                <div className="container">
                    <div className="row">
        
                        <div className="col-12 col-md-12 col-lg-9 col-xl-9 content-side">
                            <div className="space-right">
                                <div className="thank-you-note">
                                   <h3>Your Information is Successfully Uploaded!</h3>
                                   <p>Our Team will contact you for More details the Visa Process.Thankyou for Your Co Operation</p>
                               </div>
                                                           
                               <div className="traveler-info">
                                    
                                    <div className="contact">
                                    <h2>For Contact :</h2>
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-envelope"></i></span></div>
                                            <div className="text"><p>info@malikexpress.com</p></div>
                                        </div>
                                        
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-phone"></i></span></div>
                                            <div className="text"><p>051-111-778-800</p></div>
                                        </div>
                                        
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-map-marker"></i></span></div>
                                            <div className="text"><p> Office 1-2, Plot, Javed Plaza, 31 West AKM Fazl-ul-Haq Rd, A Block G 6/3 Blue Area, Islamabad, Islamabad Capital Territory 44000</p></div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                                
                        <div className="col-12 col-md-12 col-lg-3 col-xl-3 side-bar blog-sidebar right-side-bar">
                      
                            <div className="row">    
        
                                
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="side-bar-block follow-us">
                                        <h2 className="side-bar-heading">Follow Us</h2>
                                        <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-facebook"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-twitter"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-linkedin"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-google-plus"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-pinterest-p"></i></span></Link></li>
                                        </ul>
                                    </div>
                                        </div>
                                
                                    </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
        </>
    )
}

export default VisaThankYou;