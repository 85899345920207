import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';

function TravelPlan() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

   
    return (

        <section>
             

                    <div id="visa-banners" class="innerpage-section-padding">
            <div class="container">
                <div class="row">
                    
                    
                    <div class="col-12 col-md-12">
                        <div class="service-intro-text" style={{textAlign:'center'}}>
                           
                            <h1 style={{ fontWeight: 'bold' }}>Online Travel Insurance Pakistan - Your Gateway to Worry-Free Adventures</h1>
                            
                            <blockquote>
                                            Welcome to <a style={{color:'#fff'}} href="https://malikexpress.com/">Malik Express Travel & Tours</a>, your trusted partner in exploring the world with confidence. When it comes to planning a journey, be it for leisure, education, or business, ensuring your peace of mind is our top priority. Our comprehensive travel insurance solutions cater to every traveler's needs, making us the go-to choice for online travel insurance in Pakistan. We understand the evolving dynamics of the travel landscape, and our range of offerings, from Covid covered travel insurance to specialized student travel insurance and coverage for Schengen visa applicants, is designed to meet your specific requirements.
                                        </blockquote>
                            <div style={{margin:'40px'}}>
                            <a href="/online-travel-insurance-pakistan" class="btn btn-black btn-lg">Book Now</a>

                            </div>
                        </div>
                    </div>
                    
                 </div>
            </div>
        </div>
            
            <div className="plan-80 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
                        <div class="page-heading">
                            <p style={{fontWeight:'bold',color:'#000'}}>Find the Perfect Coverage </p>
                            <h1 style={{fontWeight:'bold'}}>Discover Our Range of Travel Insurance Plans</h1>
                            <blockquote>
                            At Malik Express Travel & Tours, we understand that every traveler is unique, and that's why we offer a diverse range of travel insurance plans to cater to your individual needs and preferences. Whether you're embarking on an international adventure, welcoming guests from abroad, or traveling for business or leisure, we have the perfect insurance solution for you. Our comprehensive insurance plans provide coverage for medical emergencies, trip cancellations, lost baggage, and more, ensuring that you can travel with confidence and peace of mind. With flexible options, competitive premiums, and seamless online purchase, finding the right travel insurance plan has never been easier. Explore our range of insurance offerings and embark on your next journey with the assurance that you're protected every step of the way.
                            </blockquote>
                            <br />
                            <hr class="heading-line" />
                        </div>
    <div className="justify-content-center">


<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
    <div className="col-md-5 " >
            <div>
            <img
                                            src="../images/international insurance.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
        </div>
          
          
        <div className="col-md-7 " >
            <div>
            <p style={{fontWeight:'bold',color:'#000'}}>Explore the World with Confidence</p>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>International Travel Insurance:</h2>
                <blockquote>Embark on your global adventures with peace of mind, knowing that you're covered by Malik Express International Travel Insurance.
                     Our comprehensive travel insurance plans offer protection against a wide range of travel risks, 
                     including medical emergencies, trip cancellations, lost baggage, and more. Whether you're exploring 
                     bustling cities, relaxing on exotic beaches, or immersing yourself in diverse cultures, our international
                      travel insurance ensures that you can travel with confidence and security.</blockquote>
            </div>
            <div style={{margin:'20px'}}>
                            <a href="/international-travel-insurance-online" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>
        </div>


        <div className="row justify-content-center" style={{paddingBottom:'50px'}}>
    <div className="col-md-7 order-2 order-md-1" >
        <div>
            <p style={{fontWeight:'bold',color:'#000'}}>Welcome Your Guests with Assurance </p>
            <h2 className="text-left" style={{ fontWeight: 'bold' }}>Visitor Travel Insurance </h2>
            <blockquote>
                Ensure a worry-free visit for your loved ones with Malik Express Visitor Travel Insurance.
                Whether they're coming to explore the sights, celebrate special occasions, or simply spend 
                quality time with family and friends, our visitor travel insurance plans provide essential 
                coverage for medical emergencies, unexpected illnesses, and accidents. With easy online purchase 
                and flexible coverage options, you can extend a warm welcome to your guests while ensuring their 
                safety and well-being throughout their stay.
            </blockquote>
        </div>
        <div style={{margin:'5px'}}>
                            <a href="/visitor-travel-insurance-online" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
    </div>
    <div className="col-md-5 order-1 order-md-2" >
        <div>
            <img
                src="../images/visitor insurance.jpg"
                alt="travel insurance"
                style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
            />
        </div>
    </div>
</div>

        
<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
         <div className="col-md-5 " >
            <div>
            <img
                                            src="../images/business travel insurance.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
            </div>
            <div className="col-md-7 " >
            <div>
            <p style={{fontWeight:'bold',color:'#000'}}>Navigate Business Travel with Ease </p>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Business Travel Insurance</h2>
                <blockquote>
                Stay focused on your business objectives while Malik Express Business Travel Insurance takes care of your travel needs. Our specialized travel insurance plans are designed to provide comprehensive coverage for corporate travelers, offering protection against flight delays, trip interruptions, and other unforeseen events. With features such as emergency medical assistance and coverage for business equipment, our business travel insurance ensures that you can stay productive and efficient, even while on the move.
                </blockquote>
            </div>
            <div style={{margin:'20px'}}>
                            <a href="/business-travel-insurance-online" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>

</div>

<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
        <div className="col-md-7 order-2 order-md-1" >
            <div>
            <p style={{fontWeight:'bold',color:'#000'}}>Empowering Student Adventures </p>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Student Travel Insurance</h2>
                <blockquote>
                Embark on your educational journey with confidence, backed by Malik Express Student Travel Insurance. Whether you're studying abroad, participating in an exchange program, or exploring new horizons during your gap year, our student insurance plans offer essential coverage for medical emergencies, academic interruptions, and more. With affordable premiums and customizable options, our student travel insurance ensures that you can focus on your studies and experiences without worrying about unexpected setbacks.                </blockquote>
            </div>
            <div style={{margin:'5px'}}>
                            <a href="/student-travel-insurance-online" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>

        <div className="col-md-5  order-1 order-md-2" >
            <div>
            <img
                                            src="../images/student insurance.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
            </div>
         </div> 

         {/* <div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
         <div className="col-md-5 " >
            <div>
            <img
                                            src="../images/covid insurance .jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available' , borderRadius:'1rem'}}
                                        />
            </div>
            </div>
        
        <div className="col-md-7 " >
            <div>
            <p style={{fontWeight:'bold',color:'#000'}}>Travel Safely in a Post-Pandemic World </p>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Covid-covered Travel Insurance</h2>
                <blockquote>
                Travel with confidence amidst the ongoing pandemic with Malik Express Covid-covered Travel Insurance. Our specialized travel insurance plans provide comprehensive coverage for Covid-related risks, including medical expenses, trip cancellations, and quarantine expenses. Whether you're planning a vacation, family reunion, or essential business trip, our Covid-covered travel insurance offers peace of mind and protection against the uncertainties of travel during these challenging times.   
                                 </blockquote>
            </div>
            <div style={{margin:'20px'}}>
                            <a href="/covid-covered-travel-insurance-online" class="btn btn-black btn-lg">Read More 
                            <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                            </a>

                            </div>
        </div>

       
         </div>  */}

<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
      
      <div className="col-md-5 ">
                 <div>
                 <img
                                                 src="../images/schengen visa travel insurance.jpg"
                                                 alt="travel insurance"
                                                 style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                             />
                 </div>
                 </div>
             <div className="col-md-7" >
                 <div>
                 <p style={{fontWeight:'bold',color:'#000'}}>Explore Europe with Peace of Mind  </p>
                     <h2 className="text-left" style={{ fontWeight: 'bold' }}>Travel Insurance for Schengen Visa</h2>
                     <blockquote>
                     Embark on your European adventure with confidence, knowing that you're covered by Malik Express Travel Insurance for Schengen Visa. Our Schengen-compliant insurance plans offer seamless coverage for medical emergencies, hospitalization, and repatriation, ensuring that you meet the mandatory visa requirements while exploring the diverse cultures and landscapes of the Schengen Area. With easy online purchase and instant policy issuance, our travel insurance for Schengen Visa from Pakistan simplifies your travel preparations and enhances your European experience.                                 </blockquote>
                 </div>
                 <div style={{margin:'5px'}}>
                                 <a href="/travel-insurance-for-schengen-visa-online" class="btn btn-black btn-lg">Read More 
                                 <i className="fa fa-chevron-right" style={{marginLeft:'10px'}}></i>
                                 </a>
     
                                 </div>
             </div>
            
            
              </div> 
        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>
    );

}



export default TravelPlan;