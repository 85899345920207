import React, { forwardRef } from 'react'

const TwentyDaysTitle = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>21 Days Umrah Package from {props.cityTwentyOne}</h2>
                    <p>{props.paragraphTwentyOne}</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default TwentyDaysTitle