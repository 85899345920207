import React, { useState } from 'react';

const Offers = () => {
    const [activeRow, setActiveRow] = useState(0);

    const prevRow = () => {
        setActiveRow(prev => (prev === 0 ? 1 : prev - 1));
    };

    const nextRow = () => {
        setActiveRow(prev => (prev === 1 ? 0 : prev + 1));
    };
  return (
    <section id="hot-tour" className="section-padding">
     <div className="container">
              
               
     <div className={`row hot-offer-row ${activeRow === 0 ? 'active' : 'inactive'}`} style={{ display: activeRow === 0 ? 'flex' : 'none' }}>

                        <div className="col-md-12 col-lg-12 col-xl-7 hot-tour-carousel">
                            <div id="hot-tour-carousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="../images/free travel insurnace.jpg" alt="Los Angeles" />
                                    </div>
                                </div>
                                <a className="left arrow-icons carousel-control-prev" href="#hot-tour-carousel" data-slide="prev" onClick={prevRow}
                   style={window.innerWidth > 1024 ? 
                    {fontWeight:'100',fontSize:'20px',height:'60px',width:'60px',textAlign:'center',padding:'19px',marginTop:'170px',background:'rgba(0, 0, 0, 0.7)',borderRadius:'100%'} 
                    : { display: 'none' }}>
                    <span className="  fa fa-arrow-left" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-5 hot-tour-text">
                        <h3 style={{color:'red' ,marginBottom:'30px'}}>Exclusive HOT OFFER</h3>
                            <h2>Purchase Any Tour Package</h2>
                            <span>&</span>
                            <h2 style={{marginBottom:'30px'}}>Get FREE Travel Insurance.</h2>
                            <a href="tour-detail-right-sidebar.html" className="btn btn-orange">Book Now</a>
                            <a href="tour-detail-right-sidebar.html" className="btn btn-details">See Details</a>
                            
                            
              
                            <a className="left arrow-icons carousel-control-prev" href="#hot-tour-carousel" data-slide="prev" onClick={prevRow}
                   style={window.innerWidth <= 768 ? { display: 'block', fontWeight: '100', fontSize: '20px', height: '60px', width: '60px', textAlign: 'center', padding: '19px', background: 'rgba(0, 0, 0, 0.7)', borderRadius: '100%' } : { display: 'none' }}>
                    <span className="glyphicon  fa fa-arrow-left" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="right arrow-icons carousel-control-next" href="#hot-tour-carousel" data-slide="next"  onClick={nextRow}
                   style={window.innerWidth <= 768 ? { display: 'block', fontWeight: '100', fontSize: '20px', height: '60px', width: '60px', textAlign: 'center', padding: '19px', background: 'rgba(0, 0, 0, 0.7)', borderRadius: '100%' } : { display: 'none' }}>
                    
                    <span className="glyphicon  fa fa-arrow-right" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>



                    <a className="right arrow-icons carousel-control-next" href="#hot-tour-carousel" data-slide="next"  onClick={nextRow}
                   style={window.innerWidth > 1024 ? 
                          {fontWeight:'100',fontSize:'20px',height:'60px',width:'60px',textAlign:'center',padding:'19px',marginTop:'170px',background:'rgba(0, 0, 0, 0.7)',borderRadius:'100%'} 
                          : { display: 'none' }}>
                    <span className="glyphicon  fa fa-arrow-right" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
                        </div>
                    </div>
                

                    <div className={`row hot-offer-row ${activeRow === 1 ? 'active' : 'inactive'}`} style={{ display: activeRow === 1 ? 'flex' : 'none' }}>

                    <div className="col-md-12 col-lg-12 col-xl-7 hot-tour-carousel">
                            <div id="hot-tour-carousel" className="carousel slide" data-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="../images/free visa assistance.jpg" alt="Los Angeles" />
                                    </div>
                                </div>
                                <a className="left arrow-icons carousel-control-prev" href="#hot-tour-carousel" data-slide="prev" onClick={prevRow}
                   style={window.innerWidth > 1024 ? 
                    {fontWeight:'100',fontSize:'20px',height:'60px',width:'60px',textAlign:'center',padding:'19px',marginTop:'170px',background:'rgba(0, 0, 0, 0.7)',borderRadius:'100%'} 
                    : { display: 'none' }}>
                    <span className="  fa fa-arrow-left" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-xl-5 hot-tour-text">
                            <h3 style={{color:'red',marginBottom:'30px'}}>Exclusive HOT OFFER</h3>
                            <h2>Buy Travel Insurance</h2>
                            <span>&</span>
                            <h2 style={{marginBottom:'30px'}}>Get FREE Visa Assistance</h2>
                            <a href="tour-detail-right-sidebar.html" className="btn btn-orange">Book Now</a>
                            <a href="tour-detail-right-sidebar.html" className="btn btn-details">See Details</a>
                           
                            <a className="left arrow-icons carousel-control-prev" href="#hot-tour-carousel" data-slide="prev" onClick={prevRow}
                   style={window.innerWidth <= 768 ? { display: 'block', fontWeight: '100', fontSize: '20px', height: '60px', width: '60px', textAlign: 'center', padding: '19px', background: 'rgba(0, 0, 0, 0.7)', borderRadius: '100%' } : { display: 'none' }}>
                    <span className="glyphicon  fa fa-arrow-left" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="right arrow-icons carousel-control-next" href="#hot-tour-carousel" data-slide="next"  onClick={nextRow}
                   style={window.innerWidth <= 768 ? { display: 'block', fontWeight: '100', fontSize: '20px', height: '60px', width: '60px', textAlign: 'center', padding: '19px', background: 'rgba(0, 0, 0, 0.7)', borderRadius: '100%' } : { display: 'none' }}>
                    <span className="glyphicon  fa fa-arrow-right" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>



                    <a className="right arrow-icons carousel-control-next" href="#hot-tour-carousel" data-slide="next"  onClick={nextRow}
                   style={window.innerWidth > 1024 ? 
                          {fontWeight:'100',fontSize:'20px',height:'60px',width:'60px',textAlign:'center',padding:'19px',marginTop:'170px',background:'rgba(0, 0, 0, 0.7)',borderRadius:'100%'} 
                          : { display: 'none' }}>
                    <span className="glyphicon  fa fa-arrow-right" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
                        </div>
                    </div>
                
            </div>
</section>
  )
}

export default Offers

