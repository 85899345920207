import React from 'react'
import { useState } from 'react';

import { FaAngleDown } from 'react-icons/fa';
const FaqsFile = () => {

    const [openSection, setOpenSection] = useState(null);
    const handleSectionClick = (sectionId, event) => {
        // Prevent the default behavior of anchor tag
        event.preventDefault();
        // Toggle the openSection state
        setOpenSection(openSection === sectionId ? null : sectionId);
    };
  return (
    <div id="services-section">
    <div class="page-heading mar_main" >
        <h2>FAQs for Umrah Packages from Lahore</h2>
        {/* <blockquote>
            Travel insurance is a crucial aspect of planning any trip, offering peace of mind and financial protection against unforeseen events. At <a style={{ color: '#000' }} href="https://malikexpress.com/travel-agency-contact-number">Malik Express Travel Agency </a>, we understand the importance of comprehensive travel insurance for travelers from Pakistan. Here's everything you need to know about travel insurance, especially when traveling to Schengen countries and beyond.                            </blockquote> */}
        <br />
        <hr class="heading-line" />
    </div>
    <div className="service-block-1 mar_main2" >
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse1"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse1", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    What is included in the Umrah packages from Lahore?
                        <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>

            <div id="collapse1" className={`panel-collapse collapse ${openSection === "collapse1" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Our Umrah packages from Lahore include Umrah visa processing, round-trip air tickets, hotel accommodation in Makkah and/or Madinah, ground transportation, guidance, spiritual lectures, and optional Ziyarat to visit sacred sites.
                  </p>
                </div>
            </div>
        </div>


        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse2"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse2", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    How can I book an Umrah package from Lahore?
                    <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse2" className={`panel-collapse collapse ${openSection === "collapse2" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    You can book an Umrah package from Lahore by visiting our website, contacting our customer service team, or visiting our office. Our team will guide you through the booking process and help you choose the package that best suits your needs.
                    </p>                                    </div>
            </div>
        </div>

        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse3"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse3", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    What are the available durations for the Umrah packages?  <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse3" className={`panel-collapse collapse ${openSection === "collapse3" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    We offer Umrah packages with various durations, including 7 days, 10 days, 15 days, 21 days, and 28 days, to accommodate different preferences and schedules.

                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse4"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse4", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    	What types of accommodation are provided in the Umrah packages?     <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse4" className={`panel-collapse collapse ${openSection === "collapse4" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Our Umrah packages include a range of accommodation options, from standard to luxury hotels, in both Makkah and Madinah. You can choose the type of accommodation based on your budget and preferences.
                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse5"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse5", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Are there any additional costs not covered by the Umrah package?
                                                              <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse5" className={`panel-collapse collapse ${openSection === "collapse5" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    While our Umrah packages are comprehensive, there may be additional costs such as personal expenses, extra meals, and any optional tours not included in the package. Our team will provide a detailed list of inclusions and exclusions.
                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse6"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse6", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Is transportation included in the Umrah packages from Lahore?
        <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse6" className={`panel-collapse collapse ${openSection === "collapse6" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Yes, our Umrah packages include ground transportation between Makkah, Madinah, and Jeddah airports, as well as for the Ziyarat and other scheduled activities.

                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse7"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse7", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    	Do you provide guided tours and spiritual lectures?
                    <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse7" className={`panel-collapse collapse ${openSection === "collapse7" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Yes, our Umrah packages include guided tours of significant religious sites and spiritual lectures to enhance your Umrah experience. Our knowledgeable guides will provide insightful information and support throughout your journey.
                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse8"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse8", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    Can I customize my Umrah package from Lahore?
                    <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse8" className={`panel-collapse collapse ${openSection === "collapse8" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    Absolutely! We offer customized Umrah packages to meet your specific needs and preferences. Whether you want to extend your stay, upgrade your accommodation, or include additional activities, our team will tailor a package just for you.
                    </p>   </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse9"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse9", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    	What documents are required for the Umrah visa?      <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse9" className={`panel-collapse collapse ${openSection === "collapse9" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    To process your Umrah visa, you will need a valid passport, passport-sized photographs, a completed visa application form, and proof of vaccination. Our team will provide detailed guidance on the required documentation.


                    </p>                                    </div>
            </div>
        </div>
        <div className="faqcard">
            <div className="card-header" style={{ backgroundColor: 'rgb(210 197 197 / 44%)' }}>
                <a
                    data-toggle="collapse"
                    href="#collapse10"
                    style={{ textAlign: 'left', color: '#000' }}
                    onClick={(event) => handleSectionClick("collapse10", event)}
                >
                    <h3 className="black semibold" style={{ fontSize: 20 }}>
                    	When is the best time to book an Umrah package?
                              <FaAngleDown style={{ float: 'right', marginTop: 5, fontSize: 25, }} />
                    </h3>
                </a>
            </div>
            <div id="collapse10" className={`panel-collapse collapse ${openSection === "collapse10" ? "show" : ""}`}>
                <div className="card-body">
                    <p style={{ textAlign: 'left' }}>
                    It is advisable to book your Umrah package as early as possible, especially during peak seasons like Ramadan and Hajj. Early booking ensures better availability of flights and accommodations and allows us to offer you the best possible rates.
                    </p>                                    </div>
            </div>
        </div>
       
    

    </div>
</div>
  )
}

export default FaqsFile
