import React, { useEffect, useRef  } from 'react'
import { useLocation } from "react-router-dom";
import { enc, HmacSHA256 } from 'crypto-js';
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Helmet } from "react-helmet";

function Payment ()
{
    
  const location = useLocation();
  const jsonData = JSON.parse(location.state.props); // + "00";
let grandPrice = location.state.grandPrice; //"000";
let bookingId = location.state.bookingId; //"000";
  //console.log(bookingId.pp_BillReference);
 

  const dt = new Date();
let pp_TxnDateTime = dt.getFullYear() +
pad2(dt.getMonth() + 1) + 
pad2(dt.getDate()) +
pad2(dt.getHours()) +
pad2(dt.getMinutes()) +
pad2(dt.getSeconds());

let pp_TxnExpiryDateTime =  dt.getFullYear() +
pad2(dt.getMonth() + 1) + 
pad2(dt.getDate() + 1) +
pad2(dt.getHours()) +
pad2(dt.getMinutes()) +
pad2(dt.getSeconds());

let pp_BillReference =  "T" + pp_TxnDateTime;
let pp_TXnRefNo = "T" + pp_TxnDateTime;

//const navigate = useNavigate();


/* console.log(pp_TXnRefNo);
console.log(pp_TxnDateTime);
console.log(pp_TxnExpiryDateTime); */

function pad2(n) {  // always returns a string
    return (n < 10 ? '0' : '') + n;
}

const paymentGatewayURL = 'https://uat-merchants.niftepay.pk/CustomerPortal/transactionmanagement/merchantform';
var x;
    function Xero(x)
        {
            return x > 9 ? "" + x : "0" + x;
        }

        function getDateTime(incrementDays)
        {
            var d = new Date();
            d.setDate(d.getDate() + incrementDays);
            var M = d.getMonth() + 1;
            var D = d.getDate();
            var H = d.getHours();
            var m = d.getMinutes() + 1;
            var s = d.getSeconds() + 1;
            var dateTime = "".concat(d.getFullYear().toString(), Xero(M), Xero(D), Xero(H), Xero(m), Xero(s));
            return dateTime;
        }
        function initialize()
        {
            let dateTime = getDateTime(0);
            document.getElementById("pp_TXnRefNo").value = "T" + dateTime;
            document.getElementById("pp_BillReference").value = bookingId ; //"T" + dateTime;
            document.getElementById("pp_TxnDateTime").value = dateTime;
            document.getElementById("pp_TxnExpiryDateTime").value = getDateTime(2);
            document.getElementById("pp_Amount").value = grandPrice;
            return true;
        }
        function calculateHash()
        {
            var inputs = {
            "pp_Amount": document.getElementById("pp_Amount").value,
            "pp_BillReference": document.getElementById("pp_BillReference").value,
            "pp_Description": document.getElementById("pp_Description").value,
            "pp_Language": document.getElementById("pp_Language").value,
            "pp_MerchantID": document.getElementById("pp_MerchantID").value,
            "pp_Password": document.getElementById("pp_Password").value,
            "pp_ReturnURL": document.getElementById("pp_ReturnURL").value,
            "pp_SubMerchantID": document.getElementById("pp_Sub_MerchantID").value,
            "pp_TxnCurrency": document.getElementById("pp_TxnCurrency").value,
            "pp_TxnDateTime": document.getElementById("pp_TxnDateTime").value,
            "pp_TxnExpiryDateTime": document.getElementById("pp_TxnExpiryDateTime").value,
            "pp_TxnRefNo": document.getElementById("pp_TXnRefNo").value,
            "pp_Version": document.getElementById("pp_Version").value,
            // "ppmpf_1": document.getElementById("ppmpf_l").value,
            // "ppmpf_2": document.getElementById("ppmpf_2").value,
            // "ppmpf_3": document.getElementById("ppmpf_3").value,
            // "ppmpf_4": document.getElementById("ppmpf_4").value,
            // "ppmpf_5": document.getElementById("ppmpf_5").value
        }
        // var inputs = {
        //     "pp_Amount": '100',
        //     "pp_BillReference":'abc',
        //     "pp_Description": 'test',
        //     "pp_Language": 'EN',
        //     "pp_MerchantID": 'Udesire',
        //     "pp_Password": 'JijMkOBn10A=',
        //     "pp_ReturnURL": 'https://172.16.227.31:444/MerchantSimulatorPGW/HttpRequestDemoServer/Index',
        //     "pp_SubMerchantID": '',
        //     "pp_TxnCurrency": 'PKR',
        //     "pp_TxnDateTime": '20220219172009',
        //     "pp_TxnExpiry DateTime": '20220219172009',
        //     "pp_TXnRefNo": 'T20220219172009',
        //     "pp_Version": '1.1',
        //     "ppmpf_1": '',
        //     "ppmpf_2": '',
        //     "ppmpf_3": '',
        //     "ppmpf_4": '',
        //     "ppmpf_5": ''
        // }

        try {
            let orderedlnputs = { };
        let concatenatedString = document.getElementById("Salt").value;
        Object.keys(inputs).sort().forEach(key => {
            concatenatedString = !(inputs[key] === "" || inputs[key] == undefined) ? concatenatedString + "&" + inputs[key] : concatenatedString;
        });
           // console.log(concatenatedString);
           // debugger;
            var hash = HmacSHA256(concatenatedString, document.getElementById("Salt").value);
         //   console.log(hash);
                    var hashinBase64 = enc.Base64.stringify(hash);
        document.getElementById("pp_SecureHash").value = hash.toString();

         //   console.log(inputs)
          //  console.log(hash.toString());

          //  window.location.href = ("https://uat-merchants.niftepay.pk/CustomerPortal/transactionmanagement/merchantform");
            return true;
        }
        catch (e) {

            console.log(e)
            return false;
        }


    }

    const formRef = useRef(null);
    
    useEffect(() => {
        window.scrollTo(0, 0);
         const timer = setTimeout(() => {
            if (formRef.current) {
                calculateHash();
                formRef.current.submit();
              }
          },  5000); // Change the duration (in milliseconds) as per your requirement
       
    }, []);

    return(
        <>
          <Helmet>
        <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>

        <meta
          name="description"
          content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://malikexpress.com/payment" />
      </Helmet>
         <MainMenu></MainMenu>
      <br></br><br></br>
      <div className="container">
      <div className="row">
           {/*  <iframe src={"https://pakistan.paymob.com/api/acceptance/iframes/34166?payment_token=" + token } width="600" height="700"></iframe>        
            https://pakistan.paymob.com/api/acceptance/iframes/34166?payment_token={payment_key_obtained_previously} */}
<form id="s2bpay-ctgryl-form" ref={formRef}  onSubmit={calculateHash} name="niftForm" method="POST" action="https://uat-merchants.niftepay.pk/CustomerPortal/transactionmanagement/merchantform">
  
  <h3>Please wait, redirecting to payment gateway. Thanks</h3>
<div style={{display: 'none'}}>
    
Salt / Secrert Key:<input className="textboxStyle" id="Salt" name="Salt" value="3eVUrOxl2hM="/>
pp_Version: <input className="textboxStyle" id="pp_Version" name="pp_Version" value="1.1" />
pp_Language: <input className="textboxStyle" id="pp_Language" name="pp_Language" value="EN" />
pp_Merchantid: <input className="textboxStyle" id="pp_MerchantID" name="pp_MerchantID" value="MEX001" />
pp_SubMerchantID: <input className="textboxStyle" id="pp_Sub_MerchantID" name="pp_SubMerchantID" value="" />
pp_Password: <input className="textboxStyle" id="pp_Password" name="pp_Password" value="7MrklmrgycM="  />
pp_Amount: <input className="textboxStyle" id="pp_Amount" name="pp_Amount" value={grandPrice} />
pp_TXnRefNo: <input className="textboxStyle" id="pp_TXnRefNo" name="pp_TXnRefNo" value={pp_TXnRefNo} />
pp_TxnDateTime: <input className="textboxStyle" id="pp_TxnDateTime" name="pp_TxnDateTime" value={pp_TxnDateTime} />
pp_TxnExpiry DateTime: <input className="textboxStyle" id="pp_TxnExpiryDateTime" name="pp_TxnExpiryDateTime" value={pp_TxnExpiryDateTime} />
pp_Bill Reference: <input className="textboxStyle" id="pp_BillReference" name="pp_BillReference" value={pp_BillReference} />
pp_Description: <input className="textboxStyle" id="pp_Description" name="pp_Description" value="SAMPLE_DES" />
pp_ReturnURL: <input className="textboxStyle biggerTextBox" id="pp_ReturnURL" name="pp_ReturnURL" value="https://malikexpress.com/thankyou"  />
pp_TxnCurrency: <input className="textboxStyle" id="pp_TxnCurrency" name="pp_TxnCurrency" value="PKR" />
ppmpf_1: <input className="textboxStyle" id="ppmpf_1" name="ppmpf_l" value=""  />
ppmpf_2: <input className="textboxStyle" id="ppmpf_2" name="ppmpf_2" value=""  />
ppmpf_3: <input className="textboxStyle" id="ppmpf_3" name="ppmpf_3" value=""  />
ppmpf_4: <input className="textboxStyle" id="ppmpf_4" name="ppmpf_4" value=""  />
ppmpf_5: <input className="textboxStyle" id="ppmpf_5" name="ppmpf_5" value=""  />
pp_SecureHash: <input className="textboxStyle" id="pp_SecureHash" name="pp_SecureHash" value="" />
<input type = "submit" onClick={calculateHash} value="Submit" />
</div>

   
        </form>
</div>
</div>
        <Footer></Footer>
        </>



    );
}

export default Payment;