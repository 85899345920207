
import React from 'react'

import { useState, useRef } from 'react';

import { FaAngleDown } from 'react-icons/fa';



import DaysFoot from './DaysFoot';
import EnquiryForm from './EnquiryForm';
import SevenData from './SevenData';
import Journey from './Journey';
import UmrahPackages from './UmrahPackages';
import SevenTitle from './SevenTitle';
import FaqsFile from './FaqsFile';
import LahoreContent from './LahoreContent';
import PagesContent from './PagesContent';
import ItineraryContent from './ItineraryContent';
import Inclusion from './Inclusion';
import TenDaysTitle from './TenDaysTitle';
import FifteenDaysTitle from './FifteenDaysTitle';
import TwentyDaysTitle from './TwentyDaysTitle';
import TwentyFiveTitle from './TwentyFiveTitle';
import TenData from './TenData';
import FifteenData from './FifteenData';
import TwentyOneData from './TwentyOneData';
import TwentyEightData from './TwentyEightData';
import CityChooseUs from './CityChooseUs';
import { Helmet } from 'react-helmet';
import FaqsFaisalabad from './FaqsFaisalabad';
import MainMenu from '../Layout/MainMenu';

import Testimonials from '../Home/Testimonials';
import LatestBlogs from '../Home/LatestBlogs';
import Footer from '../Home/Footer';
import Newsletters from '../Home/Newsletters';
const Faisalbad = () => {
  const sevenTitleRef = useRef(null);
  const enquiryFormRef = useRef(null);
  const tenTitleRef = useRef(null);
  const fifteenTitleRef = useRef(null);
  const twentyOneTitleRef = useRef(null);
   const twentyEightTitleRef = useRef(null);


  const scrollToEnquiryForm = () => {
    
    if (enquiryFormRef.current) {
      enquiryFormRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
 
  return (
    <>
     <Helmet>
                <title >{"Customized Umrah Packages from Faisalabad| Ramadan Packages"}</title>
                
                <meta name="description" content={"Malik Express offers range of customized Umrah Packages from Faisalabad Pakistan. Umrah Visa, Family Umrah Packages & 7 days, 10 days, 20 days, 30 days packages."} data-react-helmet="true" />
                <meta name="keywords" content={"umrah packages, customized umrah packages, umrah visa price, 28 days umrah package from Faisalabad, 7 days umrah package from Faisalabad, umrah packages for 7 days, umrah packages for family, umrah packages 7 days, 30 days umrah package from Faisalabad, umrah economy packages, VIP umrah packages"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/umrah-packages-from-faisalabad" />
            </Helmet>
    <MainMenu />
    <section className="page-cover" id="cover-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <ul className="breadcrumb">
                        <li className="breadcrumb-item"><a href="https://malikexpress.com/">Home</a></li>   <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item">Umrah Packages</li>
                                <li className="breadcrumb-item">Faisalbad</li>
                            </ul>
                            
                            <h1 className="page-title" style={{marginTop: "30px"}}>2024 Umrah Packages from Faisalbad</h1>
                           
                        </div>
                    </div>
                </div>
            </section>
            <PagesContent
             city = "Faisalbad" 
            text1 = "At Malik Express Travel & Tours, we are proud to offer a diverse range of Umrah packages meticulously tailored to meet the unique needs of our Muslim community in Pakistan. Our packages are crafted to provide a seamless and stress-free journey, allowing you to focus entirely on your spiritual devotion without any distractions." 
            text2 = "We are excited to announce that our 2024 Umrah packages are now available! If you're planning a sacred journey to Makkah and Madinah, Malik Express Travel & Tours is your ideal travel partner. We offer a variety of packages to suit your preferences and requirements, ensuring a memorable and fulfilling pilgrimage. Our standard Umrah package includes:"
            />
    <div className="banner-sec with-side-img" style={{marginTop: "40px"}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-6">
          <div className="detail-holder">
            <div className="text-holder">
              <h2>Customized Umrah Packages from Faisalbad</h2>
              <p>
              With years of experience serving countless pilgrims, our dedicated team at Malik Express Travel & Tours understands the profound significance of this spiritual journey. We are committed to crafting the perfect Umrah package that aligns with your specific needs and timelines, ensuring an exceptional experience for you and your family. Let us handle the logistics while you embark on a transformative spiritual journey.
              </p>
              <div className="city-button">
        <button className='city-btn1' type='submit' style={{background: "#fff", color: "black"}} onClick={scrollToEnquiryForm}>Enquire Now</button>
 
       </div>
            </div>
           
            <div className="img-holder">
              <figure>
                <img src="images/PckgIcon/Faisalabad.jpg" alt="Umrah Packages from Karachi" />
              </figure>
            </div>
            
          </div>
        </div>
      </div>
     
    </div>

  </div>
 

{/* <ItineraryContent/> */}
<Inclusion/>
      <EnquiryForm ref={enquiryFormRef} />
    
      <UmrahPackages sevenTitleRef={sevenTitleRef} tenTitleRef={tenTitleRef} fifteenTitleRef={fifteenTitleRef} twentyOneTitleRef={twentyOneTitleRef} twentyEightTitleRef= {twentyEightTitleRef} enquiryFormRef= {enquiryFormRef} />
      
      <SevenTitle citySeven= "Faisalbad" paragraphSeven = "Our 7-day Umrah package is ideal for those seeking a quick yet fulfilling spiritual experience. This package includes visa processing, round-trip airfare, comfortable accommodation, and local transportation. You will have the opportunity to perform Umrah and visit key religious sites in Makkah and Madinah, all within a week."   ref={sevenTitleRef} />
  <SevenData/>
<TenDaysTitle cityTen = "Faisalbad" paragraphTen = "The 10-day Umrah package offers a balanced itinerary that allows for spiritual reflection and exploration. This package includes visa processing, air travel, hotel stays in both Makkah and Madinah, and guided visits to historical and sacred sites. Enjoy a blend of devotion and discovery on this enriching journey." ref={tenTitleRef}/>
<TenData/>
<FifteenDaysTitle cityFifteen ="Faisalbad" paragraphFifteen = "For those who wish to delve deeper into their spiritual journey, the 15-day Umrah package provides ample time to engage in prayers and exploration. This package includes all standard amenities along with extended stays in both holy cities, allowing you to participate in additional Ziyarat and immerse yourself in the spiritual ambiance." ref={fifteenTitleRef}/>
<FifteenData />
<TwentyDaysTitle cityTwentyOne = "Faisalbad" paragraphTwentyOne ="Our 21-day Umrah package is designed for pilgrims seeking an extended period of spiritual immersion. It includes comprehensive services such as visa processing, round-trip airfare, premium accommodation, and guided tours. Experience the serenity and spirituality of Makkah and Madinah at a relaxed pace." ref={twentyOneTitleRef}/>
<TwentyOneData/>
<TwentyFiveTitle cityTwentyEight = "Faisalbad" paragraphTwentyEight = "The 28-day Umrah package is ideal for those who want to dedicate a significant period to their spiritual journey. This package offers full-service arrangements including accommodation, transportation, and a detailed itinerary covering all major religious sites. Take advantage of this extensive stay to connect deeply with your faith." ref={twentyEightTitleRef}/>
<TwentyEightData/>
<CityChooseUs from = 'Faisalbad' />

<FaqsFaisalabad/>
    <DaysFoot/>
    <Testimonials />
    <LatestBlogs />
    <Newsletters />
    <Footer />
  </>
  )
}

export default Faisalbad;