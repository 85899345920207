import React from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Box, Typography, Tab, Tabs,useMediaQuery } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// Features from "../Home/Features";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MainMenu from "../Layout/MainMenu";
import CircularProgress from '@mui/material/CircularProgress';
import Footer from "../Home/Footer";
import axios from "axios";
import FlightMainImage from "../../Icons/I have something to tell you.png";
import SaudiImage from "../../Icons/Cities/3.png";
import UAEImage from "../../Icons/Cities/1.png";
import ItalyImage from "../../Icons/Cities/5.png";
import TurkeyImage from "../../Icons/Cities/4.png";
import ChinaImage from "../../Icons/Cities/2.png";
import MalaysiaImage from "../../Icons/Cities/Malaysia.png";
import SingapureImage from "../../Icons/Cities/Singapur.png";


import IATAImage from "../../Icons/Utility/IATA.jpeg"


import ItalyFlagImage from "../../Icons/CitiesFlags/4.png";
import SaudiFlagImage from "../../Icons/CitiesFlags/5.png";
import UAEFlagImage from "../../Icons/CitiesFlags/1.png";
import TurkeyFlagImage from "../../Icons/CitiesFlags/3.png";
import ChinaFlagImage from "../../Icons/CitiesFlags/6.png";


import UK from "../../Icons/CitiesFlags/AllFlags/1.png";
import ThailandFlag from "../../Icons/CitiesFlags/AllFlags/2.png";
import SwitzerlandFlag from "../../Icons/CitiesFlags/AllFlags/3.png";
import SwedenFlag from "../../Icons/CitiesFlags/AllFlags/4.png";
import SpainFlag from "../../Icons/CitiesFlags/AllFlags/5.png";
import SlovakiaFlag from "../../Icons/CitiesFlags/AllFlags/6.png";
import Singapure from "../../Icons/CitiesFlags/AllFlags/7.png";
import Schegen from "../../Icons/CitiesFlags/AllFlags/8.png";
import Portugal from "../../Icons/CitiesFlags/AllFlags/9.png";
import Poland from "../../Icons/CitiesFlags/AllFlags/10.png";
import Norway from "../../Icons/CitiesFlags/AllFlags/11.png";
import Malaysia from "../../Icons/CitiesFlags/AllFlags/12.png";
import Netherlands from "../../Icons/CitiesFlags/AllFlags/13.png";
import Luxembourg from "../../Icons/CitiesFlags/AllFlags/14.png";
import Lithuania from "../../Icons/CitiesFlags/AllFlags/15.png";
import Indonesia from "../../Icons/CitiesFlags/AllFlags/16.png";
import Itlay from "../../Icons/CitiesFlags/AllFlags/17.png";
import Hunagary from "../../Icons/CitiesFlags/AllFlags/18.png";
import Iceland from "../../Icons/CitiesFlags/AllFlags/19.png";
import Germany  from "../../Icons/CitiesFlags/AllFlags/20.png";
import Greece from "../../Icons/CitiesFlags/AllFlags/21.png";
import France from "../../Icons/CitiesFlags/AllFlags/22.png";
import Liechtensten from "../../Icons/CitiesFlags/AllFlags/23.png";
import Finland from "../../Icons/CitiesFlags/AllFlags/24.png";
import Estonia from "../../Icons/CitiesFlags/AllFlags/25.png";
import Denmark from "../../Icons/CitiesFlags/AllFlags/26.png";
import Belgium from "../../Icons/CitiesFlags/AllFlags/27.png";
import CzechRepublic from "../../Icons/CitiesFlags/AllFlags/28.png";
import Azerbaijan from "../../Icons/CitiesFlags/AllFlags/29.png";
import Austria from "../../Icons/CitiesFlags/AllFlags/30.png";

import TabIconone from "../../Icons/TabIcons/1.png";
import TabIcontwo from "../../Icons/TabIcons/2.png";
import TabIconthree from "../../Icons/TabIcons/3.png";
import TabIconfour from "../../Icons/TabIcons/4.png";
import TabIconfive from "../../Icons/TabIcons/5.png";



function VisaSearchBar() {
   
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const [tabIndex, setTabIndex] = useState(0);
    const [isMobile, setDesktop] = useState(window.innerWidth < 700);

    const handleTabChange = (event, newTabIndex) => {
        setTabIndex(newTabIndex);
    };

    // const apiUrl = "https://localhost:7173"
    const apiUrl = process.env.REACT_APP_API_URL;

    const [States, setStates] = useState([]);
    const [DepartureCity, setDepartureCity] = useState('Pakistan');
    const [ArrivalCity, setArrivalCity] = useState('');

  

    async function fetchData(e) {
        // setLoading(true);
        const url = apiUrl + '/api/FlightSearch/CountrySearch?term=' + e;
        // console.log(url);
        await axios
            .get(url)
            .then((response) => {
                const data = response.data;
                setOptions(data);
                setLoading(false);

                setStates(data);
                //   console(response.data);
                // suggestions  = data;
                //  console(suggestions);          
            })
            .catch((error) => console.error("Error: ${error}"));
    }
    const onKeyUp = e => {
        setLoading(true);

        if (e.keyCode !== 8) {
            if (e.target.value.length >= 1) {
                fetchData(e.target.value);
            }
        }

    };
    const CheckOpen = e => {
        setOpen(true);

        const url = apiUrl + "/api/FlightSearch/CountryAll";
        // console.log(url);
        axios
            .get(url)
            .then((response) => {
                const data = response.data;
                setOptions(data);
                setLoading(false);
                setStates(data);
            })
            .catch((error) => console.error("Error: ${error}"));
    };

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDivVisible, setDivVisible] = useState(false);

    const handleClick = (event) => {
        event.preventDefault();
        setDivVisible(!isDivVisible);
      };
    const navigate = useNavigate();
    const mystyle = {
        background: "rgba(243,245,247)",

    }
    async function SendRequest() {
        const url = apiUrl + "/api/VisaSearch/CountryGet?term=" + ArrivalCity;
        // console.log(url);
        await axios
            .get(url)
            .then((response) => {
                console.log('visa responce',response)
                const data = response.data;
                navigate('/visas/' + ArrivalCity + '-visa', { state: { props: data } });
            })
            .catch((error) => console.error("Error: `${visa}`"));
    }

  function SendCustomRequest(CityName) {
    debugger
        const url = apiUrl + "/api/VisaSearch/CountryGet?term=" + CityName;
        // console.log(url);
         axios
            .get(url)
            .then((response) => {
                const data = response.data;
                navigate('/visas/' + CityName + '-visa', { state: { props: data } });
            })
            .catch((error) => console.error("Error: ${error}"));
    }



    return (
        <>
            <Helmet>
                <title >{"Visa Consultancy | e visa services | Visa Agents Pakistan"}</title>

                <meta name="description" content={"Get the best visa consultancy and e visa services at Malik Express Travel & Tours. Best visa consultants in Islamabad, Pakistan. Contact the best visa agents. "} data-react-helmet="true" />
                <meta name="keywords" content={"visa services, visa assistance, e visa from Pakistan, visa services in Pakistan, umrah e visa from Pakistan, visa services Pakistan, visa agents near me, visa services for Dubai, visa agents, e visa for Pakistani passport, agents for visa, visa agents in Islamabad, visa agents in Pakistan, consultant visa, visa consultancy services, visa consultants in Pakistan, visa consultants Islamabad, visa consultants near me, visa consultancy near me, visa consultancy, consultancy for visa, best visa consultants in Pakistan, visa consultant Pakistan, visa consultation, visa for umrah from Pakistan"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/visa-consultancy" />
            </Helmet>

            <MainMenu></MainMenu>


            <section className="flexslider-container" id="flexslider-container-1">

                <div className="flexslider slider tour-slider" id="slider-1" style={mystyle}>

                </div>
                <main className="mlk-layout-content hLNN-RF2oxmHDQO0AGP_0 MarginCustomClass" style={{ marginTop: "-767px" }}>
                    <div className="mlk-row _24_swGyEDgCusBPHo_zJWz pb-16 MobilePadding" style={{ rowGap: "24px" }} >
                        <div className="mlk-col mlk-col-24" >
                            <div className="_3E4Vc-PW-NkvQMIgdfYlI- p-xs-0" style={{ paddingLeft: "20px", paddingRight: "20px", paddingTop: "0px" }}>
                                <div className="mlk-row _3LiQIwcG_drQvN4i9BAA7" style={{ rowGap: "0px" }}>
                                    <div className=" container row w-100">
                                        <div className="mlk-tabs mlk-tabs-top mlk-tabs-card col-12 col-sm-12 col-md-12 col-lg-8">
                                            <div className="mlk-tabs-content-holder" style={{ backgroundColor: "transparent" }}>
                                                <div className="mlk-tabs-content mlk-tabs-content-top tab-content">
                                                    <div role="tabpanel" tabIndex="0" aria-hidden="false" className="CutomTab tab-pane active mlk-tabs-tabpane mlk-tabs-tabpane-active" id="FlightsTab" aria-labelledby="rc-tabs-0-tab-Flights">

                                                        <div className="ml-2 mr-2 mlk-row _3gePwZ9SAAGVfaKFiLvuNP" style={{ marginLeft: "-14px", marginRight: "-14px", rowGap: "22px" }}>
                                                            <div className="mlk-col mlk-col-24 pl-xs-0 pr-xs-0 pr-md-4 pl-md-4" >
                                                                <div className="mlk-row mlk-row-space-between mlk-row-middle" style={{ rowGap: "0px" }}>

                                                                    <div>
                                                                        <div className="mlk-row text-left TextCenterMobile" style={{ rowGap: "0px", fontFamily: "sans-serif"}} >
                                                                            <h1 className="TextCenterMobile">Your One-Stop Destination for Visa Consultancy Services</h1>
                                                                        </div>
                                                                        <div className="mlk-row text-left TextCenterMobile" style={{ rowGap: "0px", fontFamily: "sans-serif" }} >
                                                                            <h3 className="TextCenterMobile">Malik Express Travel & Tours</h3>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="mlk-col mlk-col-24" id="SinlgeTwoWayDiv">
                                                                <div className="mlk-col mlk-col-24" style={{ paddingLeft: "14px", paddingRight: "14px" }} >
                                                                    <div className="mlk-row" style={{ marginLeft: "-17.5px", marginRight: "-17.5px", rowGap: "0px" }}  >

                                                                        <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-12 mb-3" style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}>
                                                                            <div>
                                                                                <div className="col-12 text-left" style={{ fontWeight: "300", fontSize: "16px", fontWeight: "400" }}>Where Am i From? </div>
                                                                                <div className="st-airport-selector">
                                                                                    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="_1MZrIK28AE8HVj289UBgZw st-svg-icon">
                                                                                        <path d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                                                                                            fill="black"></path>
                                                                                    </svg>
                                                                                    <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                                                                                        <div className="mlk-select-selector">
                                                                                            <span className="mlk-select-selection-search">
                                                                                                <Autocomplete
                                                                                                    id="FirstCity"
                                                                                                    onKeyUp={onKeyUp}
                                                                                                    options={States.map((option) => option)}
                                                                                                    value={DepartureCity}
                                                                                                    disabled
                                                                                                    onChange={(event, newValue) => {
                                                                                                        setDepartureCity(newValue);
                                                                                                    }}
                                                                                                    renderInput={(params) => <TextField {...params} variant='standard' />}
                                                                                                />
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="mlk-col mlk-col-xs-24 mlk-col-sm-24 mlk-col-md-12 mb-3" style={{ paddingLeft: "17.5px", paddingRight: "17.5px" }}>

                                                                            <div id="FlyingTo">
                                                                                <div className="col-12 text-left" style={{ fontWeight: "300", fontSize: "16px", fontWeight: "400" }}>Where Am i Going?</div>
                                                                                <div className="st-airport-selector">
                                                                                    <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="_1MZrIK28AE8HVj289UBgZw st-svg-icon">
                                                                                        <path d="M7.5 0.000244141C3.36449 0.000244141 0 3.21308 0 7.16219C0 12.0813 6.7005 20.4072 6.98574 20.7593C7.10888 20.9112 7.29877 21.0002 7.5 21.0002C7.70123 21.0002 7.89112 20.9113 8.01426 20.7593C8.2995 20.4073 15 12.0814 15 7.16219C15 3.21308 11.6356 0.000244141 7.5 0.000244141ZM7.5 11.6262C4.92234 11.6262 2.82532 9.62366 2.82532 7.16223C2.82532 4.70076 4.92239 2.69821 7.5 2.69821C10.0777 2.69821 12.1747 4.70076 12.1747 7.16219C12.1747 9.62362 10.0777 11.6262 7.5 11.6262Z"
                                                                                            fill="black"></path>
                                                                                    </svg>
                                                                                    <div className="mlk-select mlk-select-lg mlk-select-single mlk-select-allow-clear mlk-select-show-search">
                                                                                        <div className="mlk-select-selector">
                                                                                            <span className="mlk-select-selection-search">
                                                                                                <Autocomplete
                                                                                                    open={open}
                                                                                                    onOpen={CheckOpen}
                                                                                                    onClose={() => setOpen(false)}
                                                                                                    onKeyUp={onKeyUp}
                                                                                                    options={States.map((option) => option)}
                                                                                                    value={ArrivalCity}
                                                                                                    onChange={(event, newValue) => {
                                                                                                        setArrivalCity(newValue);
                                                                                                    }}
                                                                                                    loading={loading}
                                                                                                    renderInput={(params) => (
                                                                                                        <TextField
                                                                                                            {...params}
                                                                                                            variant='standard'
                                                                                                            InputProps={{
                                                                                                                ...params.InputProps,
                                                                                                                endAdornment: (
                                                                                                                    <>
                                                                                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                                                                        {params.InputProps.endAdornment}
                                                                                                                    </>
                                                                                                                ),
                                                                                                            }}
                                                                                                        />
                                                                                                    )}
                                                                                                />



                                                                                                {/* <Autocomplete
                                                                                                    id="SecondCity"
                                                                                                    onKeyUp={onKeyUp}
                                                                                                    options={States.map((option) => option)}
                                                                                                    value={ArrivalCity}          
                                                                                                    onChange={(event, newValue) => {
                                                                                                        setArrivalCity(newValue);
                                                                                                    }}
                                                                                                    renderInput={(params) => <TextField {...params} variant='standard'/>}
                                                                                                /> */}
                                                                                            </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="mlk-col mlk-col-24 text-center md:pt-2 lg:pt-2 xl:pt-2 xxl:pt-2" style={{ paddingLeft: "14px", paddingRight: "14px" }}  >
                                                                    <div className="mlk-row float-right mlk-row-middle ButtonWidthCustom" style={{ rowGap: "10px", paddingTop: "10px" }} >

                                                                        <button type="submit" onClick={SendRequest} style={{ minWidth: "120px" }} className="mlk-btn mlk-btn-primary mlk-btn-lg _26eU9UZhmzdU7C6nyy3iN- xs:text-base sm:text-base">

                                                                            <span role="img" aria-label="search" className="anticon anticon-search">
                                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="search" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                                                    <path d="M909.6 854.5L649.9 594.8C690.2 542.7 712 479 712 412c0-80.2-31.3-155.4-87.9-212.1-56.6-56.7-132-87.9-212.1-87.9s-155.5 31.3-212.1 87.9C143.2 256.5 112 331.8 112 412c0 80.1 31.3 155.5 87.9 212.1C256.5 680.8 331.8 712 412 712c67 0 130.6-21.8 182.7-62l259.7 259.6a8.2 8.2 0 0011.6 0l43.6-43.5a8.2 8.2 0 000-11.6zM570.4 570.4C528 612.7 471.8 636 412 636s-116-23.3-158.4-65.6C211.3 528 188 471.8 188 412s23.3-116.1 65.6-158.4C296 211.3 352.2 188 412 188s116.1 23.2 158.4 65.6S636 352.2 636 412s-23.3 116.1-65.6 158.4z"></path>
                                                                                </svg>
                                                                            </span>
                                                                            <span>Go</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-4 d-none d-lg-block">
                                            <img src={FlightMainImage}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </section>
            <div className="container shadow-lg p-0" style={{ position: "relative", marginTop: "-92px",marginLeft:"auto",marginRight:"auto",}}>
                <div
                    className="d-flex justify-content-between RowClass bg-white py-4"style={{marginRight:"0px",overflow:"hidden"}}>
                    <span className=" MarginCustom flex w-full  col-12 col-lg-3  text-black text-lg text-center justify-center items-center">
                        <strong>1.2M</strong>&nbsp;Happy Customers </span>
                    <span className=" MarginCustom  flex w-full  col-12 col-lg-3 text-black text-lg text-center justify-center items-center">
                        <strong>99%</strong>&nbsp;On Time Delivery </span>
                    <span className="  MarginCustom flex w-full  col-12 col-lg-3 text-black text-lg text-center justify-center items-center">
                        <strong>35</strong>&nbsp;Years of Experience </span>
                    <span className="w-full MarginCustom m-auto">
                        <a className="flex flex-col justify-center col-12 col-lg-3 flex-wrap items-center" aria-label="View our Trustpilot page">
                           <img style={{height:"65px",width:"165px"}} src={IATAImage}></img>
                          
                            {/* <div className="stars flex justify-between items-center">
                                <img className="h-6 xl:h-8 mr-1" alt="" src="https://d16zz69zs6o3lx.cloudfront.net/img/trustpilot/star-filled-black.svg" width="32" height="32"></img>
                                <img className="h-6 xl:h-8 mr-1" alt="" src="https://d16zz69zs6o3lx.cloudfront.net/img/trustpilot/star-filled-black.svg" width="32" height="32"></img>
                                <img className="h-6 xl:h-8 mr-1" alt="" src="https://d16zz69zs6o3lx.cloudfront.net/img/trustpilot/star-filled-black.svg" width="32" height="32"></img>
                                <img className="h-6 xl:h-8 mr-1" alt="" src="https://d16zz69zs6o3lx.cloudfront.net/img/trustpilot/star-filled-black.svg" width="32" height="32"></img>
                                <img className="h-6 xl:h-8 mr-2 xl:mr-4" alt="" src="https://d16zz69zs6o3lx.cloudfront.net/img/trustpilot/star-half-black.svg" width="32" height="32"></img>
                                <img className="h-6 mx-auto" alt="" src="https://d16zz69zs6o3lx.cloudfront.net/img/trustpilot/trustpilot-icon-black.svg" width="98" height="24"></img>
                            </div> */}
                        </a>
                    </span>

                </div>
            </div>

            <section id="tour-offers" className="banner-padding" style={{ paddingBottom:"0px"}}>

                <div className="col">
                    <div className="page-heading">
                        <h2>Popular Visa Destinations</h2>
                        <hr className="heading-line" />
                    </div>

                    <div className="row justify-content-between col-12 w-100 m-0" style={{ borderRadius: "10px" }}>
                        <div onClick={() => SendCustomRequest("Malaysia")}  className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-2 shadow-lg p-1" style={{ borderRadius: "10px" }}>
                            <a href="#">
                                <img src={MalaysiaImage} className="img-fluid" alt="tour-img" />
                            </a>
                            <img height="45px" width="45px" className="m-auto shadow-sm p-1" src={Malaysia} style={{ borderRadius: "50%", position: "relative", top: "-34px", backgroundColor: "white", overflow: "hidden" }}></img>
                            <div className="main-info tour-info">
                                <div className="main-title tour-title">
                                    <a href="#">Malaysia Visa</a>

                                </div>
                            </div>
                            <div className="text-center" style={{ top: "23px", position: "relative" }}>
                                <a href="#" className="btn btn-orangecustom">Learn More</a>
                            </div>
                        </div>

                        <div onClick={() => SendCustomRequest("Turkey")} className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-2 shadow-lg p-1" style={{ borderRadius: "10px" }}>
                            <a >
                                <img src={TurkeyImage} className="img-fluid" alt="tour-img" />
                            </a>
                            <img height="45px" src={TurkeyFlagImage} width="45px" className="m-auto shadow-sm p-1" style={{ borderRadius: "50%", position: "relative", top: "-34px", backgroundColor: "white", overflow: "hidden" }}></img>
                            <div className="main-info tour-info">
                                <div className="main-title tour-title">
                                    <a onClick={() => SendCustomRequest("Turkey")}>Turkey Visa</a>

                                </div>
                            </div>
                            <div className="text-center" style={{ top: "23px", position: "relative" }}>
                                <a href="#" className="btn btn-orangecustom">Learn More</a>
                            </div>
                        </div>

                        <div onClick={() => SendCustomRequest("China")} className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-2 shadow-lg p-1" style={{ borderRadius: "10px" }}>
                            <a href="#">
                                <img src={ChinaImage} className="img-fluid" alt="tour-img" />
                            </a>
                            <img height="45px" src={ChinaFlagImage} width="45px" className="m-auto shadow-sm p-1" style={{ borderRadius: "50%", position: "relative", top: "-34px", backgroundColor: "white", overflow: "hidden" }}></img>
                            <div className="main-info tour-info">
                                <div className="main-title tour-title">
                                    <a href="#">China Visa</a>

                                </div>
                            </div>
                            <div className="text-center" style={{ top: "23px", position: "relative" }}>
                                <a href="#" className="btn btn-orangecustom">Learn More</a>
                            </div>
                        </div>

                        <div onClick={() => SendCustomRequest("Dubai")} className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-2 shadow-lg p-1" style={{ borderRadius: "10px" }}>
                            <a href="#">
                                <img src={UAEImage} className="img-fluid" alt="tour-img" />
                            </a>
                            <img height="45px" src={UAEFlagImage} width="45px" className="m-auto shadow-sm p-1" style={{ borderRadius: "50%", position: "relative", top: "-34px", backgroundColor: "white", overflow: "hidden" }}></img>
                            <div className="main-info tour-info">
                                <div className="main-title tour-title">
                                    <a href="#">Dubai Visa</a>

                                </div>
                            </div>
                            <div className="text-center" style={{ top: "23px", position: "relative" }}>
                                <a href="#" className="btn btn-orangecustom">Learn More</a>
                            </div>
                        </div>
                        <div onClick={() => SendCustomRequest("Singapore")} className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-2 shadow-lg p-1" style={{ borderRadius: "10px" }}>
                            <a href="#">
                                <img src={SingapureImage} className="img-fluid" alt="tour-img" />
                            </a>
                            <img height="45px" src={Singapure} width="45px" className="m-auto shadow-sm p-1" style={{ borderRadius: "50%", position: "relative", top: "-34px", backgroundColor: "white", overflow: "hidden" }}></img>
                            <div className="main-info tour-info">
                                <div className="main-title tour-title">
                                    <a href="#">Singapore Visa</a>

                                </div>
                            </div>
                            <div className="text-center" style={{ top: "23px", position: "relative" }}>
                                <a href="#" className="btn btn-orangecustom">Learn More</a>
                            </div>
                        </div>

                    </div>
                    <div className="text-center d-none d-lg-block"style={{marginTop:"80px"}} onClick={handleClick}>
                        <a href="#" className="btn btn-orange"style={{borderRadius:"50%",height:"80px",width:"80px",padding:"15px"}}>  {isDivVisible ? 'View Less' : 'View All'} </a>
                    </div>
                </div>
            </section>

            {isDivVisible && <div> <section id="AllCountries" className=" container" >
            <div className="row">
              
                   <div className="col-4 text-left">
                   <a className=""style={{cursor:"pointer"}}onClick={() => SendCustomRequest("Turkey")}>
                    <img height="35px" width="35px" className=" shadow-sm p-1" src={TurkeyFlagImage} style={{ borderRadius: "50%",  backgroundColor: "white", overflow: "hidden" ,top:"11px",position:"relative"}}></img>
                        <span className=""style={{fontWeight:"bolder",marginLeft:"5px"}}>Turkey  </span>
                    </a>
                </div>
                   <div className="col-4 text-left">
                   <a className=""style={{cursor:"pointer"}}onClick={() => SendCustomRequest("China")}>
                    <img height="35px" width="35px" className=" shadow-sm p-1" src={ChinaFlagImage} style={{ borderRadius: "50%",  backgroundColor: "white", overflow: "hidden" ,top:"11px",position:"relative"}}></img>
                        <span className=""style={{fontWeight:"bolder",marginLeft:"5px"}}>China  </span>
                    </a>
                </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Dubai")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={UAEFlagImage} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Dubai  </span>
                        </a>
                    </div>

                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("UK")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={UK} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>UK  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Thailand")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={ThailandFlag} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Thailand  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Switzerland")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={SwitzerlandFlag} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Switzerland  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Sweden")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={SwedenFlag} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Sweden  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Spain")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={SpainFlag} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Spain  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Slovakia")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={SlovakiaFlag} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Slovakia  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Indonesia")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Singapure} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Indonesia  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Schengen")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Schegen} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Schengen  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Portugal")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Portugal} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Portugal  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Poland")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Poland} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Poland  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Norway")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Norway} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Norway  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Malaysia")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Malaysia} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Malaysia  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Netherlands")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Netherlands} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Netherlands  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Luxembourg")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Luxembourg} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Luxembourg  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Lithuania")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Lithuania} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Lithuania  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Switzerland")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Indonesia} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Switzerland  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Itlay")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={ItalyFlagImage} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Itlay  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Hunagary")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Hunagary} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Hunagary  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Iceland")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Iceland} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Iceland  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Germany")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Germany} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Germany  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Greece")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Greece} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Greece  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("France")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={France} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>France  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Liechtensten")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Liechtensten} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Liechtensten  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Finland")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Finland} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Finland  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Estonia")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Estonia} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Estonia  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Denmark")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Denmark} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Denmark  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("Belgium")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Belgium} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Belgium  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }}onClick={() => SendCustomRequest("CzechRepublic")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={CzechRepublic} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>CzechRepublic  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Azerbaijan")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Azerbaijan} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Azerbaijan  </span>
                        </a>
                    </div>
                    <div className="col-4 text-left">
                        <a className="" style={{ cursor: "pointer" }} onClick={() => SendCustomRequest("Austria")}>
                            <img height="35px" width="35px" className=" shadow-sm p-1" src={Austria} style={{ borderRadius: "50%", backgroundColor: "white", overflow: "hidden", top: "11px", position: "relative" }}></img>
                            <span className="" style={{ fontWeight: "bolder", marginLeft: "5px" }}>Austria  </span>
                        </a>
                    </div>





            </div>
            </section></div>}

           
            <section className="innerpage-wrapper" style={{backgroundColor: "rgba(248,250,252"}}>
                <div id="why-us" className="innerpage-section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="page-heading innerpage-heading">
                                    <h2>Discover New Destinations with Malik Express Travel & Tours</h2>
                                    <hr className="heading-line" />
                                </div>

                                <div className="">
                                    <div className="col-12 col-md-12 col-lg-12 col-xl-12"
                                        id="why-us-tabs">
                                        <Box>
                                            <Box>
                                                <Tabs value={tabIndex} onChange={handleTabChange}
                                                    sx={{
                                                        '& .MuiTabs-indicator': { backgroundColor: '#faa61a' },
                                                        '& .MuiTab-root': { color: 'black' },
                                                        '& .Mui-selected': { color: '#faa61a' },
                                                    }}
                                                    orientation={isMobile ? 'vertical' : 'horizontal'} // Set orientation based on device
                                                >
                                                    <Tab className="col mt-3"style={{borderRadius:"20px"}} label="e-Visa" />
                                                    <Tab className="col mt-3"style={{borderRadius:"20px"}} label="e-Visa (On Arrival)" />
                                                    <Tab className="col mt-3"style={{borderRadius:"20px"}} label="Guided Visa" />
                                                </Tabs>
                                            </Box>
                                            <Box sx={{ padding: 2 }}>
                                                {tabIndex === 0 && (
                                                    <>
                                                    <div className="row justify-content-center">
                                                        <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                           <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Apply Online </h3>
                                                            <a href="/visa-consultancy">
                                                                <img src={TabIconone} className="img-fluid" alt="tour-img" />
                                                            </a>
                                                            <div className="main-info tour-info">
                                                                <div className="main-title tour-title">

                                                                </div>
                                                            </div>
                                                            <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                              <p>
                                                              To start, easily apply for your visa online with our application portal. You can have the option to pay with a credit or debit card.
                                                              </p>
                                                            </div>
                                                        </div>

                                                        <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                           <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Get the Documents via e-mail</h3>
                                                            <a href="/visa-consultancy">
                                                                <img src={TabIcontwo} className="img-fluid" alt="tour-img" />
                                                            </a>
                                                            <div className="main-info tour-info">
                                                                <div className="main-title tour-title">

                                                                </div>
                                                            </div>
                                                            <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                              <p>
                                                              Save time as we communicate with the embassy on your behalf. Soon, you'll receive the necessary documents via email. 
                                                              </p>
                                                            </div>
                                                        </div>

                                                        <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                           <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Reach your Destination</h3>
                                                            <a href="/visa-consultancy">
                                                                <img src={TabIconthree} className="img-fluid" alt="tour-img" />
                                                            </a>
                                                            <div className="main-info tour-info">
                                                                <div className="main-title tour-title">

                                                                </div>
                                                            </div>
                                                            <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                              <p>
                                                              All you have to do is present your passport and the documents we provided upon arrival. It's that simple!
                                                              </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                                )}
                                                {tabIndex === 1 && (
                                                    <>
                                                        <div className="row justify-content-center">
                                                            <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                               <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Apply Online </h3>
                                                                <a href="/visa-consultancy">
                                                                    <img src={TabIconfour} className="img-fluid" alt="tour-img" />
                                                                </a>
                                                                <div className="main-info tour-info">
                                                                    <div className="main-title tour-title">

                                                                    </div>
                                                                </div>
                                                                <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                                  <p>
                                                                  Start by completing our online application process and conveniently pay with a credit or debit card. 
                                                                  </p>
                                                                </div>
                                                            </div>

                                                            <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                               <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Get Documents via e-mail</h3>
                                                                <a href="/visa-consultancy">
                                                                    <img src={TabIconfive} className="img-fluid" alt="tour-img" />
                                                                </a>
                                                                <div className="main-info tour-info">
                                                                    <div className="main-title tour-title">

                                                                    </div>
                                                                </div>
                                                                <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                                  <p>
                                                                  Once your application is submitted, you'll receive all the necessary documents via email, eliminating the need to deal with the embassy.
                                                                  </p>
                                                                </div>
                                                            </div>

                                                            <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                               <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Reach Your Destination</h3>
                                                                <a href="/visa-consultancy">
                                                                    <img src={TabIconone} className="img-fluid" alt="tour-img" />
                                                                </a>
                                                                <div className="main-info tour-info">
                                                                    <div className="main-title tour-title">

                                                                    </div>
                                                                </div>
                                                                <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                                  <p>
                                                                  Finally, reach your destination and seamlessly enter the country by presenting your passport and the documents we provided. 
                                                                  </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                                {tabIndex === 2 && (
                                                   <>
                                                   <div className="row justify-content-center">
                                                       <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                          <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Apply Online</h3>
                                                           <a href="/visa-consultancy">
                                                               <img src={TabIcontwo} className="img-fluid" alt="tour-img" />
                                                           </a>
                                                           <div className="main-info tour-info">
                                                               <div className="main-title tour-title">

                                                               </div>
                                                           </div>
                                                           <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                             <p>
                                                             Fill out the simplified online application with our Guide Manual's guidance.
                                                             </p>
                                                           </div>
                                                       </div>

                                                       <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                          <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Personal Assistance</h3>
                                                           <a href="/visa-consultancy">
                                                               <img src={TabIconthree} className="img-fluid" alt="tour-img" />
                                                           </a>
                                                           <div className="main-info tour-info">
                                                               <div className="main-title tour-title">

                                                               </div>
                                                           </div>
                                                           <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                             <p>
                                                             Get unlimited support during the entire process from our team of e-Visa Experts that will help you apply correctly.
                                                             </p>
                                                           </div>
                                                       </div>

                                                       <div className="MarginBottomCustom col-12 col-sm-12 col-md-4 col-lg-3 shadow-lg m-5 h-100" style={{ borderRadius: "10px",border:"2px solid #faa61a",minHeight:"390px" }}>
                                                          <h3 style={{marginTop:"10px",marginBottom:"20px"}}>Complete Documentation</h3>
                                                           <a href="/visa-consultancy">
                                                               <img src={TabIconfour} className="img-fluid" alt="tour-img" />
                                                           </a>
                                                           <div className="main-info tour-info">
                                                               <div className="main-title tour-title">

                                                               </div>
                                                           </div>
                                                           <div className="text-center" style={{ marginTop:"20px",marginBottom:"30px" }}>
                                                             <p>
                                                             Send all the required documents to the Embassy or Consulate, and attend the Visa Interview if needed.
                                                             </p>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </>
                                                )}
                                            </Box>
                                        </Box>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div id="visa-banner" class="innerpage-section-padding">
            <div class="container">
                <div class="row">
                    
                    
                    <div class="col-12 col-md-12 col-lg-8">
                        <div class="service-intro-text">
                            <h4>Welcome to Malik Express - Travel & Tours</h4>
                            <h2 style={{color: "white"}}>Unlocking Seamless Visa Solutions</h2>
                            <p style={{color: "white"}}>When your travel plans require visas, navigating the intricate world of visa applications and requirements can be a daunting task. At Malik Express Travel & Tours, we're your trusted partner in simplifying the visa application process. As experts in visa consultancy, we offer a comprehensive range of services to cater to your specific travel needs.</p>
                            <a href="/visa-consultancy" class="btn btn-black btn-lg">Book Now</a>
                        </div>
                    </div>
                    
                 </div>
            </div>
        </div>
       
        <section class="innerpage-wrapper">
            <div id="services-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="page-heading">
                                <h2>Our Visa Consultancy Services</h2>
                                <hr class="heading-line" />
                            </div>
                            
                            <div class="row">
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="service-block-1">
                                        <div class="service-icon-1">
                                            <span><i class="fa fa-globe"></i></span>
                                        </div>
                                        
                                        <div class="service-text-1">
                                            <h3>Schengen Visa Assistance</h3>
                                            <p>Dreaming of exploring the enchanting Schengen countries? We specialize in Schengen visa assistance, guiding you through the intricacies of the application process to ensure you have the right documents for your European adventure. Our Schengen visa experts ensure that your application is in full compliance with the Schengen countries' requirements. We help you prepare the necessary documentation, provide guidance on the visa interview, and ensure that your travel plans go off without a hitch.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="service-block-1">
                                        <div class="service-icon-1">
                                            <span><i class="fa fa-users"></i></span>
                                        </div>
                                        
                                        <div class="service-text-1">
                                            <h3>Tourist Visa Assistance</h3>
                                            <p>Whether you're planning a getaway to a tourist hotspot or an exotic locale, our tourist visa consultancy services make your journey hassle-free. We provide expert guidance and assistance, ensuring your tourist visa application is a breeze. Our team is well-versed in the specific requirements of each destination, and we'll assist you in preparing a complete application, increasing your chances of visa approval. Your dream vacation is just a consultation away.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-12 col-md-6 col-lg-4">
                                    <div class="service-block-1">
                                        <div class="service-icon-1">
                                            <span><i class="fa fa-dollar"></i></span>
                                        </div>
                                        
                                        <div class="service-text-1">
                                            <h3>Visit Visa Assistance</h3>
                                            <p>Reconnecting with family, attending special events, or simply exploring a new country? Our visit visa consultancy team is at your service. We assist you in preparing and submitting your visit visa application, making your visit memorable. We understand the importance of family reunions and personal travel, and we're dedicated to facilitating your journey. Our visit visa experts are here to ensure that you can create lasting memories with your loved ones.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="service-block-1">
                                        <div class="service-icon-1">
                                            <span><i class="fa fa-book"></i></span>
                                        </div>
                                        
                                        <div class="service-text-1">
                                            <h3>Student Visa Consultants</h3>
                                            <p>Pursuing higher education in a foreign country is a life-changing experience. Our student visa consultants offer valuable insights and assistance to students seeking to study abroad. We help you navigate the complexities of student visa applications, ensuring you meet all the requirements. From helping you choose the right course and educational institution to preparing your visa application, we're committed to your academic success. Let us be your guide on the path to a brighter future.</p>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="col-12 col-md-6 col-lg-6">
                                    <div class="service-block-1">
                                        <div class="service-icon-1">
                                            <span><i class="fa fa-map-marker"></i></span>
                                        </div>
                                        
                                        <div class="service-text-1">
                                            <h3>Transit Visa Services</h3>
                                            <p>Planning a layover or stopover? Our transit visa services ensure you meet all requirements for a smooth and stress-free transit experience. Whether you're traveling for business or leisure, our transit visa consultants can help you obtain the necessary documentation for your short stay. We'll guide you through the process, ensuring that your transit is convenient and hassle-free.</p>
                                        </div>
                                    </div>
                                </div>
                                
                               
                            </div>
                        </div>
                    </div>
                </div>   
            </div>
            
            <div id="search-banner" class="innerpage-103-pd-tb back-size"> 
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-10 col-xl-8">
                            <h4>Malik Express Travel & Tours - Your One-Stop Destination for Visa Consultancy Services</h4>
                            <h2>Best Visa Consultancy & E-Visa Services in Pakistan</h2>
                            <p style={{fontSize: "16px"}}>Embarking on an international journey can be thrilling, but the visa process can often be daunting, so you need visa consultancy. Fear not; Malik Express Travel & Tours has got you covered. Our expert visa agents specialize in visa consultancy and e-visa services to make your travel dreams a reality. We comprehend the importance of a smooth and hassle-free visa application process, so we provide personalized assistance and guidance to ensure you get your visa approved in no time.
                                <br /><br />With years of experience and deep insights into visa application intricacies, our agents are well-equipped to cater to all your visa-related needs. We also offer convenient e-visa services, allowing you to apply for your visa from the comfort of your home. Our Malik Express Travel & Tours team will ensure a hassle-free visa experience for business, study, or leisure.
                                </p>
                            <a href="/contactus" class="btn btn-black btn-lg">Book Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <section id="luxury-car" class="section-padding"> 
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6 no-pd-r luxury-img">
                            <img src="images/mex/luxury-car1.png" class="img-fluid" alt="luxury-car" />
                        </div>
                        
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6 no-pd-l luxury-text">
                            <div class="luxury-car-text">
                                <h2>Popular Visas</h2>
                                <p>Malik Express Travel & Tours offers visa services for many countries that are popular among travelers. Some of the most frequently requested visa countries include the United States, United Kingdom, Canada, Australia, Schengen countries, Turkey, Malaysia, Thailand, and China. These countries are famous for different reasons, such as tourism, education, business, and family visits. The visa requirements and application processes for each of these countries can be complex and time-consuming, but with Malik Express Travel & Tours' expertise, clients can easily navigate them.</p>
                                <a href="car-detail-right-sidebar.html" class="btn btn-black" style={{backgroundColor: "white", color: "black"}}>From PKR 13,000</a>
                                <a href="car-detail-right-sidebar.html" class="btn btn-o-border">Get A Quote</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="luxury-car"> 
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-6 col-xl-6 no-pd-r luxury-text">
                            <div class="luxury-car-text">
                                <h2>Your Trusted Travel Partner – Expert Visa Consultants</h2>
                                <p>Malik Express Travel & Tours is known for its exceptional visa services and expert visa consultants. Their team of professionals is well-versed in the visa application process and can assist clients in obtaining the necessary documentation for their travel needs. Whether it is a tourist visa, business visa, or student visa, Malik Express Travel & Tours' agents know the requirements and procedures for each type of visa. They are also up-to-date with the latest visa policies and regulations, ensuring clients receive accurate and timely information. With their expertise, clients can trust that their visa applications will be handled efficiently and carefully.</p>
                                <a href="car-detail-right-sidebar.html" class="btn btn-black" style={{backgroundColor: "white", color: "black"}}>Contact</a>
                                <a href="car-detail-right-sidebar.html" class="btn btn-o-border">Get A Quote</a>
                            </div>
                        </div>

                        <div class="col-12 col-md-12 col-lg-6 col-xl-6 no-pd-l luxury-img ">
                            <img src="images/mex/luxury-car.png" class="img-fluid" alt="luxury-car" />
                        </div>
                        
                        
                    </div>
                </div>
            </section>
            
            <section id="best-features" class="banner-padding black-features" style={{marginTop: "20px"}}>
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 col-lg-6">
                            <div class="b-feature-block">
                                <span><i class="fa fa-globe"></i></span>
                                <h3>Tourist Visa</h3>
                                <div style={{textAlign: "left"}}>
                                    <p><b>Tourism Purpose:</b> A tourist visa is typically issued to individuals who plan to visit a foreign country for the purpose of tourism, leisure, and recreational activities.<br /> It is meant for travelers who are exploring the destination as tourists.</p><br />
                                    <p><b>Limited Activities:</b> Travelers on a tourist visa are usually restricted from engaging in paid work, business activities, or any activities that generate income within the host country. The primary focus is on leisure and sightseeing</p><br />
                                    <p><b>Short-term Stay:</b> Tourist visas often have a limited duration, usually ranging from a few weeks to a few months, depending on the country and the specific visa.</p><br />
                                    <p><b>Tourist Attractions:</b> Travelers on tourist visas typically visit well-known tourist attractions, stay in hotels or guesthouses, and enjoy recreational activities.</p>
                                </div>
                            </div>
                       </div>
                       
                       <div class="col-md-6 col-lg-6">
                            <div class="b-feature-block">
                                <span><i class="fa fa-plane"></i></span>
                                <h3>Visit Visa</h3>
                                <div style={{textAlign: "left"}}>
                            <p><b>General Purpose:</b> A visit visa, also known as a visitor visa or a family visit visa, is issued for various purposes, including visiting family and friends, attending family events, medical treatment, or even business meetings. It is broader in scope than a tourist visa.</p><br />
                                <p><b>Diverse Activities:</b> Visitors on a visit visa may have more flexibility in terms of activities, which can include visiting relatives, attending events, seeking medical treatment, or attending business meetings.</p><br />
                                <p><b>Varied Duration:</b> The duration of a visit visa can vary widely, depending on the specific purpose and the country's policies. It can range from a short visit to an extended stay.</p><br />
                                    <p><b>Accommodation:</b> Visitors on a visit visa may stay with family or friends, in addition to using commercial accommodations, which is often the case with tourist visas.</p>
                                    </div>
                            </div>
                       </div>                     
                    </div>
                </div>
            </section>

            <div id="why-us" class="innerpage-section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
                            <div class="page-heading innerpage-heading">
                                <h2>Why Choose Us?</h2>
                                <hr class="heading-line" />
                            </div>
                            
                    
                            <div class="row">
                            
                            <div class="col-12 col-md-12 col-lg-12 col-xl-12"id="why-us-tabs">

                            <TabContext value={value}  style={{border:"1px", borderStyle: "solid"}} >
  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
    <TabList onChange={handleChange} aria-label="lab API tabs example">
      <Tab label="Local Expertise" value="1" />
      <Tab label="Schengen Visa Specialists" value="2" />
      <Tab label="Extensive Network" value="3" />
      <Tab label="Reliable Support" value="4" />
      <Tab label="Comprehensive Services" value="5" />
    </TabList>
  </Box>
  <TabPanel value="1"> <div id="local-expertise"  role="tabpanel" class="tab-pane active">
                                            <p>We're not just your local visa consultants; we're your trusted partners in the world of visas. Our deep understanding of visa regulations and procedures, combined with our personalized approach, means you receive support tailored to your unique travel needs. We're committed to ensuring your journey starts on the right foot.</p>
                                            <a href="/visa-consultancy">Discover More<span><i class="fa fa-angle-double-right"></i></span></a>                                            
                                        </div>
                                        
                                       </TabPanel>
  <TabPanel value="2"> <div id="schengen"  role="tabpanel" class="tab-pane">
                                            <p>Dreaming of exploring the captivating Schengen countries? Look no further. Our visa specialists are well-versed in the intricacies of Schengen visa applications. We don't just guide you; we pave the way for your European dreams to come to life. With us, your Schengen visa journey is seamless and stress-free.</p>
                                            
                                            <a href="/visa-consultancy">Discover More<span><i class="fa fa-angle-double-right"></i></span></a>                                            
                                        </div></TabPanel>
  <TabPanel value="3"><div id="network" role="tabpanel" class="tab-pane">
                                            <p> Our influence knows no bounds. Thanks to our expansive global network of visa agents, we deliver efficient visa services. From the moment you entrust us with your application, you can rest assured that we'll work diligently to process it promptly. Your visa is our priority, and our network ensures your travel plans stay on track.</p>
                                           
                                            <a href="/visa-consultancy">Discover More<span><i class="fa fa-angle-double-right"></i></span></a>                                            
                                        </div></TabPanel>
  <TabPanel value="4">  <div id="support" role="tabpanel" class="tab-pane">
                                            <p>We're with you every step of the way. Visa applications can be overwhelming, but with our reliable support, you won't have to navigate them alone. Our experts are here to offer guidance and assistance, making the entire process as smooth as possible. Your peace of mind is our mission.</p>
                                           
                                            <a href="/visa-consultancy#">Discover More<span><i class="fa fa-angle-double-right"></i></span></a>                                            
                                        </div></TabPanel>
  <TabPanel value="5">   <div id="services" role="tabpanel" class="tab-pane">
                                            <p>Your travel needs are diverse, and so are our services. Whether you're planning a leisurely getaway with a tourist visa, a family visit with a visit visa, pursuing educational dreams with a student visa, or embarking on a business journey with a transit visa, we have you covered. Our comprehensive services cater to all your visa requirements, ensuring your travel plans are met with success.</p>
                                          
                                            <a href="/visa-consultancy">Discover More<span><i class="fa fa-angle-double-right"></i></span></a>                                            
                                        </div></TabPanel>

</TabContext>
                            </div>
                            
                           
                            
                            
                        </div>
                        </div>
                   </div>                  
                   <hr />
                   <br />

                   <p style={{marginTop: "15px;"}}>Your travel aspirations deserve expert visa consultancy. Contact Malik Express Travel & Tours for reliable visa assistance and unlock the world of seamless travel. With us, your journey begins with a hassle-free visa application process.</p>
                </div>
            </div>
        
        </section>

        {/*     <section id="best-features" className="banner-padding orange-features">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-heading">
                                <h2>
                                    Best Visa Consultancy & E-Visa Services in Pakistan
                                </h2>
                                <hr className="heading-line" />
                            </div>

                            <p style={{ color: 'white' }}>
                                Embarking on an international journey can be thrilling, but the visa process can often be daunting, so you need visa consultancy. Fear not; Malik Express Travel & Tours has got you covered. Our expert visa agents specialize in visa consultancy and e-visa services to make your travel dreams a reality. We comprehend the importance of a smooth and hassle-free visa application process, so we provide personalized assistance and guidance to ensure you get your visa approved in no time.
                            </p>
                            <p style={{ color: 'white' }}>
                                With years of experience and deep insights into visa application intricacies, our agents are well-equipped to cater to all your visa-related needs. We also offer convenient e-visa services, allowing you to apply for your visa from the comfort of your home. Our Malik Express Travel & Tours team will ensure a hassle-free visa experience for business, study, or leisure.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="best-features" className="banner-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-heading">
                                <h2>
                                    Your Trusted Travel Partner – Expert Visa Consultants
                                </h2>
                                <hr className="heading-line" />
                            </div>

                            <p style={{ color: 'black' }}>
                                Malik Express Travel & Tours is known for its exceptional visa services and expert visa consultants. Their team of professionals is well-versed in the visa application process and can assist clients in obtaining the necessary documentation for their travel needs. Whether it is a tourist visa, business visa, or student visa, Malik Express Travel & Tours' agents know the requirements and procedures for each type of visa. They are also up-to-date with the latest visa policies and regulations, ensuring clients receive accurate and timely information. With their expertise, clients can trust that their visa applications will be handled efficiently and carefully.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section id="best-features" className="banner-padding orange-features">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-heading">
                                <h2>
                                    Popular Visas                </h2>
                                <hr className="heading-line" />
                            </div>

                            <p style={{ color: 'white' }}>
                                Malik Express Travel & Tours offers visa services for many countries that are popular among travelers. Some of the most frequently requested visa countries include the United States, United Kingdom, Canada, Australia, Schengen countries, Turkey, Malaysia, Thailand, and China. These countries are famous for different reasons, such as tourism, education, business, and family visits. The visa requirements and application processes for each of these countries can be complex and time-consuming, but with Malik Express Travel & Tours' expertise, clients can easily navigate them.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
             */}
            <Footer></Footer>
        </>
    )
}
export default VisaSearchBar;

