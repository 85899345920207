import React, { useState, useEffect, useRef } from "react";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import {Link, useLocation , useNavigate} from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { Helmet } from "react-helmet";

function ThankYou(props){

   const location = useLocation();
   console.log(location);
   console.log(props);

 /* const bookingId = ''; //location.state.pp_BillReference;
  const pp_ResponseCode = location.state.pp_ResponseCode;
  const pp_ResponseMessage = location.state.props; */

  const queryParameters = new URLSearchParams(window.location.search)
  const pp_ResponseCode = queryParameters.get("pp_ResponseCode")
  const pp_ResponseMessage = queryParameters.get("pp_ResponseMessage")
  const bookingId = queryParameters.get("pp_BillReference")

  const [data, setData] = useState(null);
  const [grandTotal, setGrandTotal] = useState(0);

/*   const queryParameters = new URLSearchParams(window.location.search)
  const pp_ResponseCode = queryParameters.get("pp_ResponseCode")
  const pp_ResponseMessage = queryParameters.get("pp_ResponseMessage")
 */
  //console.log(pp_ResponseMessage);
  //console.log(pp_ResponseCode);

  const searchParams = new URLSearchParams(window.location.search);

  // Get a specific query parameter value
 // const paramValue = searchParams.get('pp_ResponseCode');
 // console.log(paramValue);


  const apiUrl = process.env.REACT_APP_API_URL;

  const url = apiUrl + "/api/FlightBooking";

   async function fetchData1() {
    await axios
    .get(url + "/thankyou?BookingID=" + bookingId)
    .then((response) => {
   const returnData = response.data;
    //retData= response.data;
//   console.log(response.data);
    setTimeout(() => {
        setData(response.data);
        
      }, 2000);
   // console.log(data);    
    }) 
      .catch((error) => {
        console.log(error);
      });
  } 

  useEffect(() => {  
    window.scrollTo(0, 0);
    fetch("https://malikexpress.com/thankyou").then(response=>{
        console.log(response);
        console.log(response.payload)
  });
       //  fetchData();
    //  CalculateTotal();
  }, []);

  function CalculateTotal(){
    let GrandTotal=0;
   
    GrandTotal = parseInt(data?.reservation[0]?.adultBasicFare) + parseInt(data?.reservation[0]?.adultTax) + 
    parseInt(data?.reservation[0]?.childBasicFare) + parseInt(data?.reservation[0]?.childTax) + 
    parseInt(data?.reservation[0]?.infantBasicFare) + parseInt(data?.reservation[0]?.infantTax) + 1000;
    let PKLogo="images/airlines/PK.png"
//console.log(GrandTotal)
    setGrandTotal(GrandTotal);  
  }


 // console.log(data); 
    return(
        <>
               <Helmet>
        <title>{"Cheap Flights | Domestic International Ticket Booking Online"}</title>

        <meta
          name="description"
          content={"Malik Express Travel & Tours offers best deals on cheap flights, ticket booking online, domestic flights Pakistan & international flight booking at best prices. "}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={"Online ticket booking, ticket booking online, flight booking, air ticket, airline tickets, ticket booking, ticket prices, cheap tickets, tickets for cheap flights, book tickets online Pakistan, cheap flights for Pakistan, cheap flights in Pakistan, online flights booking, online ticket booking in Pakistan, online tickets booking Pakistan, world's best airlines, cheap air ticket, cheap flights from Pakistan, domestic flights in Pakistan, domestic flights Pakistan, domestic air ticket prices in Pakistan, domestic flights, international flights, online flight booking in Pakistan"}
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://malikexpress.com/thankyou" />
      </Helmet>
        <MainMenu></MainMenu>
      
        <section className="innerpage-wrapper">
            <div id="thank-you" className="innerpage-section-padding">
                <div className="container">
                    <div className="row">
        
                        <div className="col-12 col-md-12 col-lg-9 col-xl-9 content-side">
                            <div className="space-right">
                                <div className="thank-you-note">
                                   <h3>Your Booking is confirmed now. Thank You!</h3>
                                   <p>{pp_ResponseMessage}</p>
                                   <Link href="#" className="btn btn-orange">Print Details</Link>
                               </div>
                                                           
                               <div className="traveler-info">
                                       <h3 className="t-info-heading"><span><i className="fa fa-info-circle"></i></span>Traveler Information</h3>
                                       <div className="table-responsive">
                                           <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>{data ? data.paxList[0].title: 'Loading...'}</td>
                                                </tr>
                                                <tr>
                                                    <td>First Name:</td>
                                                    <td>{data ? data.paxList[0].lastName : ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>Last Name:</td>
                                                    <td>{data ? data.paxList[0].firstName : '...'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email Address:</td>
                                                    <td>{data ? data.reservation[0].email : '...'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Document ID</td>
                                                    <td>{data ? data.paxList[0].document : '...'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Document Type</td>
                                                    <td>{data ? data.paxList[0].documentType : '...'}</td>
                                                </tr>
                                                <tr>
                                                    <td>Document Expiry</td>
                                                    <td>{data ? data.paxList[0].die : '...'}</td>
                                                </tr>                                               
                                            </tbody>
                                        </table>
                                       </div>
                                    
                                    <div className="contact">
                                    <h2>Contact Us</h2>
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-envelope"></i></span></div>
                                            <div className="text"><p>support@star-hotel.com</p></div>
                                        </div>
                                        
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-phone"></i></span></div>
                                            <div className="text"><p>+222 – 5548 656</p></div>
                                        </div>
                                        
                                        <div className="c-list">
                                            <div className="icon"><span><i className="fa fa-map-marker"></i></span></div>
                                            <div className="text"><p>Street No: 1234/A, Blu Vard Area, Main Double Road, UK</p></div>
                                        </div>
                                    </div>
                               </div>
                            </div>
                        </div>
                                
                        <div className="col-12 col-md-12 col-lg-3 col-xl-3 side-bar blog-sidebar right-side-bar">
                      
                            <div className="row">    
        
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="side-bar-block recent-post">
                                        <h2 className="side-bar-heading">Flight Details</h2>
        
                                        <div className="table-responsive">
                                           <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>PNR</td>
                                                    <td>{data ? data.pnr[0].pnr: 'Loading...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Origin</td>
                                                    <td>{data ? data.flightSegments[0].originID: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Departure</td>
                                                    <td>{data ? data.flightSegments[0].destinationID: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Departure</td>
                                                    <td>{data ? data.flightSegments[0].departureDate: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Arrival</td>
                                                    <td>{data ? data.flightSegments[0].arrivalDate: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Flight</td>
                                                    <td>{data ?  data.flightSegments[0].operatingAirlineName + '-' + data.flightSegments[0].flightNumber: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Type</td>
                                                    <td>{data ? data.flightSegments[0].way: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Class</td>
                                                    <td>{data ? data.flightSegments[0].serviceClassID: '...'}</td>
                                                </tr>
                                                </tbody>
                                        </table>
                                       </div>
                                   </div>
                                </div>
                                
                                <div className="col-12 col-md-6 col-lg-12">
                                    <div className="side-bar-block recent-post">
                                        <h2 className="side-bar-heading">Payment Details</h2>
        
                                        <div className="table-responsive">
                                           <table className="table">
                                            <tbody>
                                                <tr>
                                                    <td>Adults</td>
                                                    <td>{data ? data.reservation[0].adults: 'Loading...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Children</td>
                                                    <td>{data ? data.reservation[0].children: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Infancts</td>
                                                    <td>{data ? data.reservation[0].infants: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>adult Basic Fare</td>
                                                    <td>{data ? data.reservation[0].adultBasicFare: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>adultTax</td>
                                                    <td>{data ? data.reservation[0].adultTax: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Child Basic Fare</td>
                                                    <td>{data ?  data.reservation[0].childBasicFare: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Child Tax</td>
                                                    <td>{data ? data.flightSegments[0].childTax: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Infant Basic Fare</td>
                                                    <td>{data ? data.flightSegments[0].infantBasicFare: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Infant Tax</td>
                                                    <td>{data ? data.flightSegments[0].infantTax: '...'}</td>
                                                </tr>
                                                <tr>
                                                <td>Grand Total</td>
                                                    <td>{grandTotal ? grandTotal : ''}</td>
                                                </tr>
                                                </tbody>
                                        </table>
                                       </div>
                                   </div>
                                </div>
                                
                                <div className="col-12 col-md-12 col-lg-12">
                                    <div className="side-bar-block follow-us">
                                        <h2 className="side-bar-heading">Follow Us</h2>
                                        <ul className="list-unstyled list-inline">
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-facebook"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-twitter"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-linkedin"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-google-plus"></i></span></Link></li>
                                            <li className="list-inline-item"><Link href="#"><span><i className="fa fa-pinterest-p"></i></span></Link></li>
                                        </ul>
                                    </div>
                                        </div>
                                
                                    </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </section>
      
        <Footer></Footer>
        </>
    )
}

export default ThankYou;