import React from 'react'

const Features = () => {
  return (
    <section id="best-features" className="banner-padding orange-features">
    <div className="container">
        <div className="row">
            <div className="col-md-6 col-lg-3">
                <div className="b-feature-block">
                    <span><i className="fa fa-dollar"></i></span>
                    <h3>Best Price Guarantee</h3>
                    <p>
                    Explore our tour packages with confidence, knowing that you're getting the best deals in the market. We offer a price match guarantee to ensure you enjoy unmatched value for your money.                        
                        </p>
                </div>
            </div>
           
            <div className="col-md-6 col-lg-3">
                <div className="b-feature-block">
                    <span><i className="fa fa-lock"></i></span>
                    <h3>Safe and Secure</h3>
                    <p>
                    Your safety is our top priority. With Malik Express, you can travel worry-free, knowing that we prioritize your security at every step of your journey. From booking to boarding, we've got you covered.
                        </p>
                </div>
            </div>
           
            <div className="col-md-6 col-lg-3">
                <div className="b-feature-block">
                    <span><i className="fa fa-thumbs-up"></i></span>
                    <h3>Best Travel Agents</h3>
                    <p>
                    Our team of experienced travel agents is dedicated to providing you with personalized services. Let our knowledgeable agents’ help you plan the perfect itinerary tailored to your preferences & budget.
                         </p>
                </div>
            </div>
           
            <div className="col-md-6 col-lg-3">
                <div className="b-feature-block">
                    <span><i className="fa fa-bars"></i></span>
                    <h3>Travel Guidelines</h3>
                    <p>
                    Stay informed for your upcoming adventure with our comprehensive travel guidelines. From visa requirements to packing tips, we provide all the information you need to make your trip hassle-free.
                        </p>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default Features

