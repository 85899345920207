import React from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect,useRef } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import { easing, event } from "jquery";
//import moment from "moment";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { subYears, addYears, format, parse } from 'date-fns';
import { FaAngleDown } from 'react-icons/fa';


function CovidTravel() {
    const [isDivVisible, setDivVisible] = useState(false);
    const [ArrivalCity, setArrivalCity] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const playerRef = useRef(null);

    useEffect(() => {
      // Load the YouTube Player API script
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  
      // Initialize YouTube player when API is ready
      window.onYouTubeIframeAPIReady = initPlayer;
  
      // Clean up function
      return () => {
        window.onYouTubeIframeAPIReady = null;
      };
    }, []);
  
    // Function to initialize YouTube player
    const initPlayer = () => {
      if (!playerRef.current) {
        playerRef.current = new window.YT.Player('youtube-player', {
          height: '400',
          width: '315',
          videoId: 'Qdbl4PnMYDQ',
          playerVars: {
            autoplay: 1,
            mute: 1
          },
          events: {
            onStateChange: onPlayerStateChange
          }
        });
      }
    };
    const onPlayerStateChange = (event) => {
      if (event.data === window.YT.PlayerState.ENDED) {
        // Load a new video after current video ends
        playerRef.current.loadVideoById({
          videoId: 'Qdbl4PnMYDQ',
          startSeconds: 0,
          suggestedQuality: 'hd720'
        });
      }
    };
    return (
<>
<section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
              <p style={{fontWeight:'bold',color:'#fff'}}>Stay Protected Against the Unexpected</p>
                <h1 style={{fontWeight:'bold',color:'#fff'}}>
                Covid Covered Travel Insurance 
                </h1>
              <hr  style={{borderColor:'#fff'}} className="heading-line" />
              <br />
              <blockquote style={{color:'white'}}>
              Covid Covered Travel Insurance provides essential coverage for travelers amidst the uncertainties brought about by the ongoing pandemic. It offers protection against Covid-related expenses, including medical treatment, trip cancellations, and quarantine accommodations, ensuring that you can travel with confidence and peace of mind. At Malik Express Travel & Tours, our Covid Covered Travel Insurance plans are designed to safeguard your health and finances during your journeys, allowing you to explore the world safely.
             
              </blockquote>
            </div>
            </div>
          </div>
        </div>
      </section>


      <section>
            
            
            <div className="plan-800 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center">
        <div className="col-md-7  order-2 order-md-1">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Who Benefits from Covid Covered Travel Insurance Plans?</h2>
                <blockquote>

                Our Covid Covered Travel Insurance plans are ideal for travelers planning domestic or international trips during the pandemic. Whether you're embarking on a much-needed vacation, traveling for business, or visiting family and friends, our insurance coverage offers essential protection against Covid-related risks. With the uncertainties surrounding travel restrictions, border closures, and health protocols, having Covid Covered Travel Insurance ensures that you're prepared for any eventuality and can adapt to changing circumstances with ease.                    
                     </blockquote>
            </div>
            
        </div>
        
       <div className="col-md-4 order-1 order-md-2" >
            <div>
          
    <div className="planimg" id="youtube-player" />

            </div>
        </div>
        
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>

<section className="plan-800 pt-xs-20 ">
        <div className="container">
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                        <div className="page-heading innerpage-heading">
                            <h2>Key Features of International Travel Insurance Plans</h2>
                            <hr className="heading-line" />
                        </div>
                        <div className="container">
    <div className="row justify-content-center">
        <div className="grid grid-cols-12 gap-4 md:gap-6 py-[1.875rem] md:pt-10 md:pb-0">
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/covid coverage .png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Covid Medical Coverage</h3>
      <p className="text-14 leading-1-4 md:text-16">Receive coverage for Covid-related medical expenses, including testing, treatment, and quarantine accommodations, in the event of illness or exposure during your travels.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/trip cancellation.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Trip Cancellation Protection</h3>
      <p className="text-14 leading-1-4 md:text-16">Protect your travel investment against unexpected trip cancellations due to Covid-related reasons, such as travel advisories, flight cancellations, or personal health </p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/Emergency Assistance.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Emergency Assistance</h3>
      <p className="text-14 leading-1-4 md:text-16">Access 24/7 emergency assistance services, including medical consultations, evacuation support, and travel advice, to navigate Covid-related challenges while abroad.</p>
    </div>
    <div className="col-span-12 md:col-span-6 lg:col-span-4 shadow-secondary rounded-[40px] px-4 py-6 md:p-8 text-center">
      <img
        alt="Visa Application Support"
        title="Visa Application Support"
        loading="lazy"
        width="96"
        height="96"
        decoding="async"
        data-nimg="1"
        className="mx-auto mb-4 w-[60px] h-[60px] md:w-[96px] md:h-[96px]"
        sizes="(max-width: 768px) 100vw, 100vw"
        src="../images/Flexible Options.png"
        style={{ color: 'transparent', objectFit: 'cover' }}
      />
      <h3 className="font-bold mb-1 text-18 leading-1-4 md:text-20">Flexible Options</h3>
      <p className="text-14 leading-1-4 md:text-16">Choose from a range of coverage options and policy durations to suit your travel needs and budget, with the convenience of easy online purchase from Pakistan.</p>
    </div>
    </div>
    </div>
</div>


                    </div>

                </div>
            </div>
</section>


        <section>
            
            
            <div className="plan-80 pt-xs-20 " >
                <section className="coverage">
                
                <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                   

                        <div className="container">
    <div className="justify-content-center">


<div  className="row justify-content-center" style={{paddingBottom:'50px'}}>
<div className="col-md-5" >
            <div>
            <img
                                            src="../images/why choose us.jpg"
                                            alt="travel insurance"
                                            style={{ display: 'block', margin: 'auto' ,width: '-webkit-fill-available', borderRadius:'1rem'}}
                                        />
            </div>
        </div> 
        <div className="col-md-7">
            <div>
                <h2 className="text-left" style={{ fontWeight: 'bold' }}>Why Choose Malik Express Travel & Tours?</h2>
                <blockquote>

                At Malik Express Travel & Tours, we prioritize the safety and well-being of our travelers, especially during these unprecedented times. Our Covid Covered Travel Insurance plans offer comprehensive coverage and peace of mind, allowing you to embark on your journeys with confidence and flexibility. Trust Malik Express Travel & Tours to provide reliable insurance protection for your travels, ensuring that you can explore the world safely and responsibly.
                      </blockquote>
            </div>
            
        </div>
      
        </div>


     

        </div>
    </div>



                    </div>

                
            </div>
                </section>
            </div>

            
  

        </section>
        </>

    );

}



export default CovidTravel;