import React, { forwardRef } from 'react'

const FifteenDaysPackage = forwardRef((props,ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>15 Days Umrah Packages</h2>
                    <p>For pilgrims seeking a more profound spiritual journey, our 15 Days Umrah Packages offer an extended stay that allows for greater reflection and engagement in religious activities. This package includes all standard amenities, such as Umrah visa processing, flights, premium accommodations, and local transportation. Enjoy guided visits to historical and sacred sites in Makkah and Madinah, and take part in enriching spiritual lectures. With additional time for Ziyarat and personal worship, the 15-day package provides a comprehensive experience that allows you to absorb the spiritual ambiance of the holy cities fully. Whether traveling with family or alone, this package ensures a rewarding and spiritually uplifting journey.
                    ifteen</p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default FifteenDaysPackage;