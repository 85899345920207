

import React from 'react';
import './TourSlider.css';


const TourItem = ({ tour }) => {
    return ( 
        <div className="item-tour">
            <div className="content-item-inner">
                <div className="post_images">
                    <a className="woocommerce-LoopProduct-link woocommerce-loop-product__link">
                        <img src={tour.imageUrl} alt={tour.title} />
                    </a>
                </div>
                <div className="wrapper_content">
                    <div className="post_title">
                        <h4>
                            <a  rel="bookmark">{tour.title}</a>
                        </h4>
                    </div>
                    <div className="description">
                        <div className="col-lg-6 makkah">
                            <span className="icon">
                                <img src={tour.imageUrl2} alt="Makkah Icon"/>
                            </span>
                            <span className="no-of-days">{tour.makkahDays}</span>
                            <span className="hotel">{tour.makkahHotel}
                                <br /> {tour.rate} 
                            </span>
                            <span className="stars">
                                <img loading="lazy"  src="https://www.perfectumrah.pk/images/3-star.png" alt="Stars" />
                            </span>
                        </div>
                        <div className="col-lg-6 madina">
                            <span className="icon">
                                <img src={tour.imageUrl3} alt="Madinah Icon"/>
                            </span>
                            <span className="no-of-days">{tour.madinahDays}</span>
                            <span className="hotel">{tour.madinahHotel}
                                <br /> {tour.rate}
                            </span>
                            <span className="stars">
                                <img loading="lazy" className="alignnone size-full wp-image-2170" src="https://www.perfectumrah.pk/images/3-star.png" alt="Stars" />
                            </span>
                        </div>
                        <div className="enquire">
                            <a >View Details</a>
                        </div>
                    </div>
                    <div className="wrapper-price">
                        <span className="price">
                            <span className="woocommerce-Price-amount amount">
                                <bdi>
                          <span className="woocommerce-Price-currencySymbol">{tour.currency} </span>{tour.price}</bdi>
                            </span>
                        </span>
                        <span className="post_date"> | 21 Nights</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TourItem;

