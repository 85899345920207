import React, { forwardRef } from 'react'

const TenDaysPackage = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>10 Days Umrah Packages</h2>
                    <p>Discover a balanced blend of spirituality and exploration with our 10 Days Umrah Packages. This package provides ample time for worship and reflection while allowing you to immerse yourself in the history and culture of Islam's holiest cities. Our comprehensive package includes Umrah visa processing, flights, hotel accommodations in both Makkah and Madinah, and organized transportation. You will have the opportunity to visit important religious sites, participate in spiritual lectures, and engage in Ziyarat, ensuring a deeply meaningful experience. The 10-day itinerary is perfect for pilgrims who wish to deepen their connection with their faith while enjoying a comfortable and memorable stay.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default TenDaysPackage;