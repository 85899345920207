import React, { forwardRef } from 'react'

const TwentyOnePackage = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="umh-heading">
                    <h2>21 Days Umrah Packages</h2>
                    <p>Experience a deeper level of spiritual immersion with our 21 Days Umrah Packages, designed for those who wish to spend an extended period in the holy cities of Makkah and Madinah. This package includes comprehensive services such as Umrah visa processing, round-trip airfare, luxury accommodations, and seamless ground transportation. Engage in extensive Ziyarat and explore significant religious sites with our expert guidance. The 21-day itinerary provides the perfect balance between worship, exploration, and relaxation, allowing you to experience the serenity and spirituality of your pilgrimage at a leisurely pace. Join us for a transformative journey that strengthens your faith and leaves a lasting impact.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
  )
});

export default TwentyOnePackage;