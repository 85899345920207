import React from "react";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";
import Features from "../Home/Features";
import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import MainMenu from "../Layout/MainMenu";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../Home/Footer";
import axios from "axios";
import Slider from '../Layout/Slider';

function Hotels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{"Online Hotel Booking | Compare Prices Best Rates Website "}</title>

        <meta
          name="description"
          content={"Malik Express offer online hotel booking website with the best hotel deals worldwide. Compare hotel prices, check availability & book online at best rates. "}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={"Online hotel booking, hotel booking sites, hotel booking website, hotel booking websites, hotel rates, hotel prices, hotel deals, hotel room booking"}
          data-react-helmet="true"
        />
        <link rel="canonical" href="https://malikexpress.com/hotel-booking" />
      </Helmet>

      <MainMenu></MainMenu>

      <Slider></Slider>
      
      <section id="best-features" className="banner-padding orange-features">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-heading">
                <h1>
                Online Hotel Booking with Malik Express Travel & Tours                </h1>
                <hr className="heading-line" />
              </div>

              <p style={{color:'white'}}>
              Whether you're seeking a luxurious escape or budget-friendly hotel booking, finding the right hotel at the best price is essential for an enjoyable trip. With online hotel booking options becoming more popular and reliable, it's no wonder that Malik Express Travel & Tours has become a top choice for travel enthusiasts. With access to a vast selection of hotels worldwide and competitive rates, Malik Express has become alike with convenience and affordability. With just a few clicks, travellers can explore a variety of hotel deals and compare hotel rates to find the perfect fit for their travel needs. Whether planning a weekend retreat or a month-long adventure, booking with Malik Express ensures you'll save money without sacrificing quality. Trust us, and your next vacation will thank you for booking with Malik Express Travel & Tours.
              </p>
            </div>
          </div>
        </div>
      </section>

         

      <section id="best-features" className="banner-padding "  style={{borderBottom: 'solid',color:'rgb(255, 193, 7)'}}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-plane"></i>
                </span>
                <h3>Compare Hotel Prices and Book Worldwide</h3>
                <p>
                At Malik Express Travel & Tours, we make online hotel booking easy to find the perfect hotel worldwide. Our website allows you to effortlessly compare hotel prices from different parts of the globe. You can always count on us for unbeatable hotel prices, and trust that you're getting the most value for your hard-earned cash.
                </p>
              </div>
            </div>          
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-suitcase"></i>
                </span>
                <h3>Book Your Hotel Online in Seconds</h3>
                <p>
                Booking a hotel online has always been challenging! With our easy-to-use online hotel booking system, you can find the perfect hotel and book it in just a few clicks. We offer online payments with secure encryption, so you can ensure your payment information is safe and secure. So save time and book online today!
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-thumbs-up"></i>
                </span>
                <h3>Explore the World with Malik Express Travel & Tours</h3>
                <p>
                Explore the world with Malik Express Travel & Tours and find the perfect hotel for your next adventure. Whether you're looking for a luxury resort, a budget hotel, or something in between, we have something for everyone. Our website makes it easy to compare hotels and prices to find the perfect hotel for your needs. So start exploring today!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
}

export default Hotels;
