import React from 'react'

function Testimonials(){
    return(
<section id="testimonials" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading white-heading">
                            <h2>Testimonials</h2>
                            <hr className="heading-line" />
                        </div>

                        <div className="carousel slide"
                             data-ride="carousel"
                             id="quote-carousel">
                            <div className="carousel-inner text-center">
                                <div className="carousel-item active">
                                    <blockquote>
                                        I’d highly recommend this, i had the best experience.If
                                        you want to travel comfortably then go for it. You won’t
                                        be disappointed
                                    </blockquote>
                                    <div className="rating">
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                    </div>

                                    <small>Sarina mirza</small>
                                </div>
                                <div className="carousel-item">
                                    <blockquote>
                                        A very good Experience with Malik Express, regarding Umrah
                                        Services. They have good Economy Packages with close
                                        distance from Haram, they also have online hotel portal to
                                        offer Star Packages and separate ticketing Department...
                                        In short Malik Express have one window solution...Highly
                                        Recommended.
                                    </blockquote>
                                    <div className="rating">
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                    </div>

                                    <small>Kashif Saif</small>
                                </div>

                                <div className="carousel-item">
                                    <blockquote>
                                        Great place for travel related queries. Provide Visa
                                        services,travel insurance,destination management,hotels
                                        booking and much more totally recommend it.
                                    </blockquote>
                                    <div className="rating">
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                        <span><i className="fa fa-star orange"></i></span>
                                    </div>

                                    <small>Ahsan Tahir</small>
                                </div>
                            </div>

                            <ol className="carousel-indicators mx-auto">
                                <li data-target="#quote-carousel"
                                    data-slide-to="0"
                                    className="active">
                                    <a href="https://g.page/malik-express-travel-agent?share">
                                        <img src="../images/google review.svg"
                                             className="img-fluid d-block"
                                             alt="client-img" />
                                    </a>
                                </li>
                                <li data-target="#quote-carousel" data-slide-to="1">
                                    <a href="https://g.page/malik-express-travel-agent?share">
                                        <img src="../images/google review.svg"
                                             className="img-fluid d-block"
                                             alt="client-img" />
                                    </a>
                                </li>
                                <li data-target="#quote-carousel" data-slide-to="2">
                                    <a href="https://g.page/malik-express-travel-agent?share">
                                        <img src="../images/google review.svg"
                                             className="img-fluid d-block"
                                             alt="client-img" />
                                    </a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Testimonials;