import React, {useEffect} from 'react'
import Features from "../Home/Features";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Helmet } from 'react-helmet';

function Contact() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
         <Helmet>
                <title >{"Malik Express | Travel Agency Contact Number"}</title>
                <meta name="description" content={"Get in touch with Malik Express by dialing travel agency contact number to plan your dream holiday. Call us now at our UAN number: 051111778800."} data-react-helmet="true"/>
                <meta name="keywords" content={"Travel agency contact number, travel agency, travel agents contact, travel agency Islamabad, travel agency contact number"} data-react-helmet="true"/>
                <link rel="canonical" href="hhttps://malikexpress.com/travel-agency-contact-number" />
            </Helmet>
            <MainMenu></MainMenu>

            <section className="page-cover" id="cover-faq">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                        <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item">Contact Us</li>
                            </ul>
                            <h3 className='page-heading'>Travel Stress Free - Book your Vacation with Ease</h3>
                            <h1 className="page-title">Contact Us Today – Dial Top Travel Agency Contact Number</h1>
                           
                        </div>
                    </div>
                </div>
            </section>
            <section id="best-features" className="banner-padding orange-features"  style={{borderBottom: 'solid',color:'rgb(255, 193, 7)'}}>
        <div className="container">
            <div className='row'>
           <p style={{color:'white'}}>Dial Islamabad’s top travel agency contact number! When planning a dream vacation, there's nothing more valuable than having a trusted travel agency. At Malik Express Travel & Tours, we take the hassle out of your travel planning experience. Our team of expert travel agents is dedicated to crafting unforgettable journeys tailored to your unique expectations. Every traveller has individual preferences and needs, so we ensure every detail is handled. Whether you're looking for an exhilarating adventure or a luxurious escape, our travel agency contact number is always available. Let us help you explore the world quickly and comfortably and create memories that will last a lifetime.</p>
            </div>
         
        </div>
      </section>


            <section className="innerpage-wrapper">
                <div id="contact-us" className="innerpage-section-padding">
                    <div className="container">
                        <div className="row">

                            <div className="col-md-12 col-lg-5 no-pd-r">
                                <div className="custom-form contact-form">
                                    <h3>Contact Us</h3>
                                    <p>Have any questions regarding our services? Feel free to contact us via our contact details listed here and our staff will be available to answer your queries.</p>
                                    <form id="frm_contact" name="frm_contact" method="post">

                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Name" name="txt_name"
                                                id="txt_name" />
                                            <span><i className="fa fa-user"></i></span>
                                        </div>

                                        <div className="form-group">
                                            <input type="email" className="form-control" placeholder="Email" name="txt_email"
                                                id="txt_email" />
                                            <span><i className="fa fa-envelope"></i></span>
                                        </div>

                                        <div className="form-group">
                                            <input type="text" className="form-control" placeholder="Subject" name="txt_phone"
                                                id="txt_phone" />
                                            <span><i className="fa fa-phone"></i></span>
                                        </div>

                                        <div className="form-group">
                                            <textarea className="form-control" rows="4" placeholder="Your Message"
                                                name="txt_message" id="txt_message"></textarea>
                                            <span><i className="fa fa-pencil"></i></span>
                                        </div>
                                        <div className="col-md-12 text-center" id="result_msg"></div>
                                        <button name="submit" id="submit" className="btn btn-orange btn-block">Send</button>
                                    </form>
                                </div>
                            </div>

                            <div className="col-md-12 col-lg-7 no-pd-l">
                                <div className="map">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3318.3840518479983!2d73.08427091462885!3d33.724883842358636!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf806632aeb3%3A0x3477e0f4a87c6fd2!2sMalik+Express!5e0!3m2!1sen!2s!4v1449555179722"
                                        allowfullscreen></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <section id='best-features' classNameName='innerpage-wrapper orange-features'>
                <div classNameName='container'>
                    <div className="row">

                        <h2 className="col-md-12">Branch Offices:<hr /></h2>


                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-md-12">

                                    <div className="row">
                                        <div className="col-12 col-md-4">
                                            <div className="contact-block-2">
                                                <span className="border-shape-top"></span>
                                                <span><i className="fa fa-map-marker"></i></span>
                                                <h4>Swat</h4>
                                                <h4 classNameName='mt-2'>Email:</h4><a href="#">sales-swt@malikexpress.com</a>

                                                <h4 classNameName='mt-2'>Phone Number</h4><a href="#">+92 (946) 712445-7</a>
                                                <h4 classNameName='mt-2'>Address</h4><a>
                                                    Pameer Squire G.T.Road. Mingora, Swat, Pakistan.
                                                </a>
                                                <span className="border-shape-bot"></span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="contact-block-2">
                                                <span className="border-shape-top"></span>
                                                <span><i className="fa fa-map-marker"></i></span>
                                                <h4>Peshawar</h4>
                                                <h4 classNameName='mt-2'>Email:</h4><a href="#">sales-pew@malikexpress.com</a>

                                                <h4 classNameName='mt-2'>Phone Number</h4><a href="#">+92 (91) 5275160</a>
                                                <h4 classNameName='mt-2'>Address</h4><a>
                                                    UG-79, Deans Trade Center, Peshawar Cantt, Pakistan.
                                                </a>
                                                <span className="border-shape-bot"></span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="contact-block-2">
                                                <span className="border-shape-top"></span>
                                                <span><i className="fa fa-map-marker"></i></span>
                                                <h4>Kabul, Afghanistan</h4>
                                                <h4 classNameName='mt-2'>Email:</h4><a href="#">sales-pew@malikexpress.com</a>

                                                <h4 classNameName='mt-2'>Phone Number</h4><a href="#">+93 (20) 2203280</a>
                                                <h4 classNameName='mt-2'>Address</h4><a>
                                                    Sher-E-Naw, Opposite Loya Saranwali, Kabul, Afghanistan.
                                                </a>
                                                <span className="border-shape-bot"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row'style={{marginTop:"75px"}}>
                                        <div className="col-12 col-md-4">
                                            <div className="contact-block-2">
                                                <span className="border-shape-top"></span>
                                                <span><i className="fa fa-map-marker"></i></span>
                                                <h4>Dubai, UAE</h4>
                                                <h4 classNameName='mt-2'>Email:</h4><a href="#">sales@mountaintoptourism.com</a>

                                                <h4 classNameName='mt-2'>Phone Number</h4><a href="#">+971 585744929</a>
                                                <h4 classNameName='mt-2'>Address</h4><a>
                                                    Office No. 102, B8 Building,
                                                    Behind Mall Of Emirates Dubai,UAE.
                                                </a>
                                                <span className="border-shape-bot"></span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="contact-block-2">
                                                <span className="border-shape-top"></span>
                                                <span><i className="fa fa-map-marker"></i></span>
                                                <h4>Lahore</h4>
                                                <h4 classNameName='mt-2'>Phone Number</h4><a href="#">+971 585744929</a>
                                                <span className="border-shape-bot"></span>
                                            </div>
                                        </div>

                                        <div className="col-12 col-md-4">
                                            <div className="contact-block-2">
                                                <span className="border-shape-top"></span>
                                                <span><i className="fa fa-map-marker"></i></span>
                                                <h4>Faisalabad</h4>
                                                <h4>Phone Number</h4><a href="#">0092 345 4777794</a>
                                                <span className="border-shape-bot"></span>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Features></Features>
            <Footer></Footer>

        </>
    )
}

export default Contact;