import React from 'react';
import './PagesContent.css'; // Custom CSS file for your specific styling
import LahoreContent from './LahoreContent';

const PagesContent = (props) => {
  return (
    <div className="container" style={{marginTop: "40px"}}>
    <div className="row align-items-center">
      <div className="col-md-6">
        <div className="unique-image-section">
          <img src="images/PckgIcon/ContentImg.png" alt="Umrah" className="unique-image" />
        </div>
      </div>
      <div className="col-md-6">
        <div className="unique-text-section">
          <h2>2024 Umrah Packages from {props.city}</h2>
          <p style={{marginBottom: "12px"}}>{props.text1}</p>
          <br />
          <p><b>{props.text2}</b></p>
          <LahoreContent />
        </div>
      </div>
    </div>
  </div>
);
}

export default PagesContent;
