import { forwardRef } from 'react';

const SevenPackage = forwardRef((props, ref) => {
    
    return (
        <div ref={ref} className="umh-duration-sec" style={{ paddingBottom: "10px", paddingTop: "10px" }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="umh-heading">
                            <h2>7 Days Umrah Packages</h2>
                            <p>Embark on a spiritual journey with our 7 Days Umrah Packages designed for those seeking a concise yet fulfilling pilgrimage experience. This package allows you to perform Umrah and explore the holy cities of Makkah and Madinah within a week. We provide all-inclusive services, including Umrah visa processing, round-trip airfare, comfortable hotel accommodation close to the Haram, and convenient ground transportation. Our guided itinerary ensures that you make the most of your time with visits to sacred sites and landmarks. Whether you are traveling solo or with family, our 7-day package offers a seamless and enriching pilgrimage experience that lets you focus entirely on your spiritual devotion.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default SevenPackage;