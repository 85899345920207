import React from 'react'

function LatestBlogs(){
    return(
<section id="latest-blog" className="section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="page-heading">
                            <h2>Our Latest Blogs</h2>
                            <hr className="heading-line" />
                        </div>

                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="main-block latest-block">
                                    <div className="main-img latest-img">
                                        <a href="/travel-blog">
                                            <img src="images/Blog-mex/Singapore.jpg"
                                                 className="img-fluid"
                                                 alt="blog-img" />
                                        </a>
                                    </div>
                                    

                                    <div className="latest-info">
                                        <ul className="list-unstyled">
                                            <li>
                                                <span><i className="fa fa-calendar-minus-o"></i></span>29
                                                Jan,2022<span className="author">by: <a href="/travel-blog">Kainat Tariq</a></span>
                                            </li>
                                        </ul>
                                    </div>
                                    

                                    <div className="main-info latest-desc">
                                        <div className="row">
                                            <div className="col-10 col-md-10 main-title">
                                                <a href="/travel-blog">Singapore Travel Guide </a>
                                                <p>
                                                    Singapore is a city humming with decent variety
                                                    based upon an essence of Malay, Chinese, Indian, and
                                                    European impacts. If you plan your trip to
                                                    Singapore, here is an ultimate guide with a sample
                                                    itinerary and budget.
                                                </p>
                                            </div>
                                          
                                        </div>
                                        

                                        <span className="arrow">
                                            <a href="/travel-blog"><i className="fa fa-angle-right"></i></a>
                                        </span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                          

                            <div className="col-md-6 col-lg-4">
                                <div className="main-block latest-block">
                                    <div className="main-img latest-img">
                                        <a href="/travel-blog">
                                            <img src="images/Blog-mex/Turkey.jpg"
                                                 className="img-fluid"
                                                 alt="blog-img" />
                                        </a>
                                    </div>
                                    

                                    <div className="latest-info">
                                        <ul className="list-unstyled">
                                            <li>
                                                <span><i className="fa fa-calendar-minus-o"></i></span>22
                                                March,2022<span className="author">by: <a href="/travel-blog">Hassam Khan</a></span>
                                            </li>
                                        </ul>
                                    </div>
                                    

                                    <div className="main-info latest-desc">
                                        <div className="row">
                                            <div className="col-10 col-md-10 main-title">
                                                <a href="/travel-blog">Turkey Visa from Pakistan</a>
                                                <p>
                                                    Pakistani passport holders can quickly get an entry
                                                    visa for Turkey as the ratio of visit visas is very
                                                    high for citizens of Pakistan. There are a large
                                                    number of companies that provide consultancy
                                                    services to get Turkey visa from Pakistan.
                                                </p>
                                            </div>
                                          
                                        </div>
                                        

                                        <span className="arrow">
                                            <a href="/travel-blog"><i className="fa fa-angle-right"></i></a>
                                        </span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                          

                            <div className="col-md-6 col-lg-4">
                                <div className="main-block latest-block">
                                    <div className="main-img latest-img">
                                        <a href="/travel-blog">
                                            <img src="images/Blog-mex/Schengen.jpg"
                                                 className="img-fluid"
                                                 alt="blog-img" />
                                        </a>
                                    </div>
                                    

                                    <div className="latest-info">
                                        <ul className="list-unstyled">
                                            <li>
                                                <span><i className="fa fa-calendar-minus-o"></i></span>30
                                                June,2022<span className="author">by: <a href="/travel-blog">Kainat Tariq</a></span>
                                            </li>
                                        </ul>
                                    </div>
                                    

                                    <div className="main-info latest-desc">
                                        <div className="row">
                                            <div className="col-10 col-md-10 main-title">
                                                <a href="/travel-blog"> Apply for Schengen Visa</a>
                                                <p>
                                                    Schengen area is one of the most famous destinations
                                                    for Pakistani Citizens. They visit the Schengen zone
                                                    either for a holiday trip or business purposes, so
                                                    they must apply for a Schengen visa to visit the
                                                    area.
                                                </p>
                                            </div>
                                          
                                        </div>
                                        

                                        <span className="arrow">
                                            <a href="/travel-blog"><i className="fa fa-angle-right"></i></a>
                                        </span>
                                    </div>
                                    
                                </div>
                                
                            </div>
                          
                        </div>
                        

                        <div className="view-all text-center">
                            <a href="/travel-blog" className="btn btn-orange">View All</a>
                        </div>
                        
                    </div>
                  
                </div>
                
            </div>
            
        </section>
    )
}

export default LatestBlogs;