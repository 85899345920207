import React from 'react'
import TopBar from '../Layout/Topbar';
import SideNav from '../Layout/Sidenav';
import Slider from '../Layout/Slider';


import MainMenu from '../Layout/MainMenu';

function Layout(){
    return(
        <>
      {/* <TopBar/> */}
      <MainMenu></MainMenu>
      <SideNav></SideNav>
      <Slider></Slider>
      
      
      </>
    )
}

export default Layout;