import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { useState, useEffect } from 'react';
import MainMenu from "../Layout/MainMenu";
import Footer from "../Home/Footer";
import { FaPlus, FaMinus, FaTimes, FaEnvelope } from 'react-icons/fa';
import { CSSTransition } from 'react-transition-group';
import axios from "axios";
import { HmacSHA224 } from "crypto-js";
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBIcon,
    MDBInput,
    MDBRadio,
    MDBRow,
} from "mdb-react-ui-kit";
import { Button } from "antd";
import { FaAngleDown } from 'react-icons/fa';
import { Quickreply } from "@mui/icons-material";
import CryptoJS from 'crypto-js';
import { subYears, addYears, format, parse } from 'date-fns';
import { DatePicker, TimePicker } from "react-rainbow-components"; // Import DatePicker and TimePicker components

function SelectPaymnt() {
    const [isLoading, setIsLoading] = useState(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const url = apiUrl + "/api/Insurance";
    const [timeout, setTimeout] = useState('');
    const [jazzcash, setJazzcash] = useState('')
    const [kuickpay, setkuickpay] = useState('')
    const [openSection, setOpenSection] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { formvalue, data, singleFee, familyFee, BookingID, passengertype, arrivalDate, departureDate, InsuranceType, dob } = location.state || {};
    const [selectedPayment, setSelectedPayment] = useState('payfast');
    const [paymentDetails, setPaymentDetails] = useState({});
    const [paymentDetail, setPaymentDetail] = useState({});
    const [isBankSelected, setIsBankSelected] = useState(true);
    const [isCardSelected, setIsCardSelected] = useState(false);
    const [isewallet, setIsewallet] = useState(false);
    const [activePayment, setActivePayment] = useState('directAccount');
    const [fname, setFname] = useState('')
    const [phoneno, setPhoneno] = useState('')
    const [date, setDate] = useState(new Date())
    // const [time, setTime] = useState()
    console.log('activement', activePayment)
   
    const payment = async () => {
        try {
            if (formvalue.First_name && passengertype && familyFee && singleFee && BookingID) {
                const response = await axios.get(`${apiUrl}/api/PaymentGateway/payment?PaxName=${formvalue.First_name}&GrandTotal=${((passengertype === 'family' ? familyFee : singleFee) * 0.85).toFixed(0)}&BookingID=${BookingID}`);
                const fetchedPaymentDetails = response.data;

                setPaymentDetail(fetchedPaymentDetails);
                console.log('paymnet is', fetchedPaymentDetails)
                return true;
            }
            else {
                setTimeout(payment(), 1000); // Retry after 1 second if conditions are not met
                return false; // Indicate that Kuickpay is not successful yet
            }
        } catch (error) {

        }
    };
    const GrandTotal = ((passengertype === 'family' ? familyFee : singleFee) * 0.85).toFixed(0)

    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const day = String(today.getDate()).padStart(2, '0');

    const currentDate = `${year}-${month}-${day}`;
    function formatDate(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    
    const currentDates = formatDate(new Date());
    console.log('paymet detail:', paymentDetail);
    
    const Kuickpay = async () => {
        try {
            if (BookingID && GrandTotal && formvalue.Mobile_no && formvalue.Email) {
                const response = await axios.post('https://checkout.kuickpay.com/api/KPToken', {
                    institutionID: '09640',
                    kuickpaySecuredKey: 'z/Em87zZ9tOUsx9VJWR5shqpwD3eygxm30+1kEyVbnQ='
                    //live  z/Em87zZ9tOUsx9VJWR5shqpwD3eygxm30+1kEyVbnQ=   09640
                    // test xWX+A8qbYkLgHf3e/pu6PZiycOGc0C/YXOr3XislvxI=
                }, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const authToken = response.data.auth_token;
                const payload = {
                    OrderID: BookingID,
                    MerchantName: 'Malik Air Express',
                    Amount: GrandTotal,
                    TransactionDescription: 'wellcome',
                    CustomerMobileNumber: formvalue.Mobile_no,
                    CustomerEmail: formvalue.Email,
                    SuccessUrl: 'https://malikexpress.com/ConfirmedPayment/',
                    FailureUrl: 'https://malikexpress.com/ConfirmedPayment/',
                    OrderDate: currentDate,
                    CheckoutUrl: 'https://malikexpress.com/ConfirmedPayment/',
                    Token: authToken,
                    GrossAmount: '0',
                    TaxAmount: '0',
                    Discount: '0',
                    InstitutionID: '09640'
                };
                const signature = CryptoJS.MD5('09640' + BookingID + GrandTotal + 'z/Em87zZ9tOUsx9VJWR5shqpwD3eygxm30+1kEyVbnQ=').toString();
                payload.Signature = signature;

                setPaymentDetails(payload);
                return true;
            } else {
                setTimeout(Kuickpay, 1000);
                return false;
            }
        } catch (error) {
            //console.error('Error:', error.message);
        }
    };



    const [accessToken, setAccessToken] = useState(null);
    console.log('Access data:', accessToken);
    const [errors, setError] = useState('');
    const FastpayToken = async () => {
        try {
            const response = await fetch('https://api.malikexpress.com/api/payfast/GetAccessToken', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    merchant_id: '21277',
                    secured_key: '-VFew2B7oKZBitKsXjXnAJ5-FG'
                })
            });
    
            if (response.status === 200) {
                const data = await response.json()
                setAccessToken(data.accesS_TOKEN)
               // console.log('Response data:', response.accesS_TOKEN);
                
            } else {
                throw new Error('Failed to get access token. Status code: ' + response.status);
            }
        } catch (error) {
            console.error('Error fetching access token:',error);
        }
    };
    let isFetchingAccessToken = false; // Flag to track if access token is being fetched

 
    const Fastpay = async () => {
        try {
            if (BookingID && GrandTotal && formvalue.Mobile_no && formvalue.Email) {
                const response = await fetch('https://api.malikexpress.com/api/payfast/GetAccessToken', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        merchant_id: '21277',
                        secured_key: '-VFew2B7oKZBitKsXjXnAJ5-FG'
                    })
                });
                
                const data = await response.json()

                const authToken = data.accesS_TOKEN;

                console.log('fast token ',authToken)
                const payload = {
                    BASKET_ID: BookingID.toString(),
                    ORDER_DATE: currentDates,
                    TOKEN: authToken,
                    CURRENCY_CODE: 'PKR',
                    MERCHANT_ID: '21277',
                    SUCCESS_URL: 'https://malikexpress.com/ConfirmedPayment/',
                    FAILURE_URL: 'https://malikexpress.com/ConfirmedPayment/',
                    CHECKOUT_URL: 'https://typedwebhook.tools/webhook/cfe4e40e-8c5c-4d5b-867a-017bce41070c',
                    CUSTOMER_EMAIL_ADDRESS: formvalue.Email,
                    CUSTOMER_MOBILE_NO: formvalue.Mobile_no,
                    TXNAMT: GrandTotal,
                    SIGNATURE: '',
                    VERSION: 'MERCHANT-CART-0.1',
                    TXNDESC: 'Item Purchased from Cart',
                    PROCCODE: '00',
                    TRAN_TYPE: 'ECOMM_PURCHASE',
                    STORE_ID: '',
                    RECURRING_TXN: 'true'
                };
                setPaymentDetail(payload);
                return true;
            } else {
                setTimeout(Fastpay, 1000);
                return false;
            }
        } catch (error) {
            //console.error('Error:', error.message);
        }
    };
    useEffect(() => {
       // FastpayToken();
      //  Kuickpay();
        Fastpay()
    }, [selectedPayment]);

    useEffect(() => {
        setTimeout(() => {
            // Fastpay()
            setIsLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const onPrevious = () => {
        navigate('/TravelInsurance_Booking', { state: { formvalue, data, singleFee, familyFee, passengertype, arrivalDate, departureDate, InsuranceType, dob } });
    }

    const onPrevious1 = () => {
        navigate('/Beneficiary_Details', { state: { data } });
    }

    const onPrevious2 = () => {
        navigate('/Review_Information', { state: { formvalue, data, singleFee, familyFee, BookingID, passengertype, arrivalDate, departureDate, InsuranceType } });
    }

    const handleJazzcash = async () => {
        const kuickpaySuccess = await payment(); // Wait for Kuickpay to complete
        if (kuickpaySuccess) {
            document.getElementById("FormPGS").submit();
        } else {
            // Handle the case where Kuickpay was not successful
            // Maybe show a message or retry Kuickpay
        }
    };

    const handleKuickpay = async () => {
        setActivePayment('kuickPay');
        setShowBankDetails(!showBankDetails)
        const kuickpaySuccess = await Kuickpay(); // Wait for Kuickpay to complete
        if (kuickpaySuccess) {
            document.getElementById("FormPG").submit();
        } else {
            // Handle the case where Kuickpay was not successful
            // Maybe show a message or retry Kuickpay
        }
    };



    const handleSectionClick = (sectionId, event) => {
        // Prevent the default behavior of anchor tag
        event.preventDefault();
        // Toggle the openSection state
        setOpenSection(openSection === sectionId ? null : sectionId);
    };
    const [showBankDetails, setShowBankDetails] = useState(true);

    const handleGrandTotalClick = () => {
        setShowBankDetails(!showBankDetails);
        setActivePayment('directAccount');
    };
    // Function to handle bank selection
    const handleBankSelect = () => {
        setIsBankSelected(true);
        setIsCardSelected(false);
        setIsewallet(false)
    };

    // Function to handle card selection
    const handleCardSelect = () => {
        setIsBankSelected(false);
        setIsCardSelected(true);
        setIsewallet(false)
    };
    const handleEwalletSelect = () => {
        setIsBankSelected(false);
        setIsCardSelected(false);
        setIsewallet(true)
    };
    const handleInputChangeI = (value) => {
        let formattedValue = value;
        formattedValue = value.toISOString().split('T')[0]; // Extract YYYY-MM-DD from ISO string
        setDate(formattedValue)

    };

    const getCurrentTime = () => {
        const now = new Date();
        const hours = now.getHours();
        return {
            hours: hours % 12 || 12, // Convert 0 to 12-hour format
            minutes: now.getMinutes(),
            isAM: hours < 12, // Determine if it's AM or PM
        };
    };
    const [time, setTime] = useState('');
    const handleTimeChange = (newTime) => {
        const hours = parseInt(newTime.split(':')[0]);
        console.log('hours is', hours);
    
        const isPM = hours >= 12; 
    
        const formattedHours = hours % 12 || 12; 
        const formattedTime = `${formattedHours}:${newTime.split(':')[1]} ${isPM ? 'PM' : 'AM'}`;
    
        setTime(formattedTime);
    };
    function postData() {
       // setLoading(true);
        const instance = axios.create({
            baseURL: url,
            timeout: 10000,
            headers: { "X-Custom-Header": "foobar" },
        });
        if (fname != undefined) {
            if (fname.length < 2) {
                alert("Please provide first name at least 2 characters.");
               // setLoading(false)
                return;
            }
        } else {
            alert("Please provide first name at least 2 characters.");
           // setLoading(false)
            return;
        }
        if (phoneno != undefined) {
            if (phoneno.length < 1) {
                alert("Please provide last name at least 2 characters.");
               // setLoading(false)
                return;
            }
        } else {
            alert("Please provide last name at least 2 characters.");
           // setLoading(false)
            return;
        }
        if (date != undefined) {
            if (date.length < 10) {
                alert("Please provide Cnic number at least 13 characters.");
              //  setLoading(false)
                return;
            }
        } else {
            alert("Please provide Cnic number at least 13 characters.");
            //setLoading(false)
            return;
        }

        if (time != undefined) {
            if (time.length < 1) {
                alert("Please provide Date of birth.");
               // setLoading(false) 
                return;
            }
        } else {
            alert("Please provide  Date of birth");
            //setLoading(false)
            return;
        }
       
        


        const fromdata = {
            'Full_Name': fname,
            'Mobile_no': phoneno,
            'Date' : date,
            'Time' : time
        };
        const jsonData = JSON.stringify(fromdata);

        axios
            .post(url + "/calltoaction", jsonData, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
              

                if (response.status === 200) {
                    const data = response
                    console.log('payfast token',response)
                    //navigate('/Review_Information', { state: { formvalue, data, singleFee, familyFee, passengertype, arrivalDate, departureDate, InsuranceType, BookingID, dob } });
                   

                } else {

                    console.error("Unexpected response status:", response.status);
                }

            })
            .catch((error) => console.error(error));
    }
    useEffect(() => {
       // postData()
    }, []);
  

    console.log('request call', fname, phoneno, date,time)


    return (
        <>
            <Helmet>
                <title>{"Online Travel Insurance Pakistan | Schengen, Student, Covid"}</title>
                <meta name="description" content={"Malik Express - Your One-Stop Destination for Travel Insurance Solutions. Online Travel Insurance Pakistan- Schengen, Student, and Covid Covered. "} data-react-helmet="true" />
                <meta name="keywords" content={"travel insurance, travel insurance for Schengen visa, student travel insurance, Covid covered travel insurance, travel insurance for Schengen visa Pakistan, travel insurance health, travel insurance for turkey, travel insurance online, travel insurance online Pakistan, travel insurance companies in Pakistan, travel insurance medical, travel insurance Schengen, travel insurance Europe, travel insurance for Schengen visa price"} data-react-helmet="true" />
                <link rel="canonical" href="https://malikexpress.com/online-travel-insurance-pakistan" />
            </Helmet>

            <MainMenu></MainMenu>

            {isLoading && <div className="loader"></div>}
            {!isLoading && (
                <section className="innerpage-wrapper">
                    <div className="card">
                        <div className="tab-navigation">
                            <div id="travel-tabs">
                                <ul className="nav2 nav-tabs t-payment-tab" role="tablist">
                                    <li className="p-info-li">
                                        <a role="tab" onClick={onPrevious} className="cartTabBtn">
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Personal Details</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="review-info-li">
                                        <a role="tab" onClick={onPrevious2} className="cartTabBtn" >
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Review Information</h5>
                                            </div>
                                        </a>
                                    </li>
                                    <li className="active">
                                        <a role="tab" className="cartTabBtn">
                                            <div className="s-no"></div>
                                            <div className="tab-name">
                                                <h5>Select Payment Option</h5>
                                            </div>
                                        </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                    <MDBContainer fluid className="py-5" style={{ backgroundColor: "#eee" }}>
                        <MDBRow className="d-flex justify-content-center " style={{ marginTop: '20px' }}>
                            <MDBCol md="8" lg="6" xl="7">
                                <MDBCard className="rounded-3">
                                    <MDBCardBody className="mx-1 my-2">
                                        <h2 style={{ color: '#e5b622', marginLeft: '30px', marginBottom: '20px', borderBottom: '1px solid #ccc', padding: '10px 10px' }}>Payment Method</h2>

                                        <div style={{ display: 'ruby-text' }}>
                                            <div className="d-flex pb-3 col-md-8 ">
                                                <div className="rounded border d-flex justify-content-between align-items-center w-100 p-3">
                                                    <div className="row pl-2">
                                                        <input
                                                            type="radio"
                                                            style={{ borderRadius: '50%', width: '1.25rem', height: '1.25rem', marginTop: '0.125em', marginRight: '4px' }}
                                                            name="radioNoLabel"
                                                            id="payfast"
                                                            checked={selectedPayment === 'payfast'}
                                                            onChange={() => setSelectedPayment('payfast')}
                                                        />
                                                        <p className="mb-0 pl-1">
                                                            PayFast
                                                        </p>
                                                    </div>
                                                    <img src="../images/payfast.png" alt='payment' style={{ width: '95px', marginRight: '10px' }} />
                                                </div>
                                            </div>
                                            {/* <div className="d-flex pb-3 col-md-8 ">
                                                <div className="rounded border d-flex justify-content-between align-items-center w-100 p-3">
                                                    <div className="row pl-2">
                                                        <input
                                                            type="radio"
                                                            style={{ borderRadius: '50%', width: '1.25rem', height: '1.25rem', marginTop: '0.125em', marginRight: '4px' }}
                                                            name="radioNoLabel"
                                                            id="kuickpay"
                                                            checked={selectedPayment === 'kuickpay'}
                                                            onChange={() => setSelectedPayment('kuickpay')}
                                                        />
                                                        <p className="mb-0">
                                                            {" "} KuickPay
                                                        </p>
                                                    </div>
                                                    <img src="../images/KuickPay.svg" alt='payment' style={{ width: '90px', transform: 'rotate(-10deg)' }} />
                                                </div>
                                            </div> */}
                                        </div>


                                        <div className="center2 pt-5 pb-5 ml-2">



                                            <PaymentForm paymentDetails={paymentDetails} />
                                            <PaymentForms paymentDetail={paymentDetail} />


                                            <button className="btn btn-orange" size="lg" onClick={selectedPayment === 'payfast' ? handleJazzcash : selectedPayment === 'kuickpay' ? handleKuickpay : null}>
                                                Pay amount
                                            </button>

                                        </div>

                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="8" lg="6" xl="3">
                                <MDBCard className="rounded-3">
                                    <MDBCardBody className="mx-1 my-2">
                                        <div className="tumb">
                                            <img
                                                className="lozad"
                                                src="../images/CSI-Logo.png"
                                                alt="Habib Insurance Gold - Worldwide"
                                            />
                                        </div>


                                        {data && data.length > 0 ? (
                                            <div className="containerrs">
                                                <div className="left-corner">
                                                    Medical cover:
                                                </div>
                                                <div className="right-corner">
                                                    {`$${data[0].MedicalExpense}`}
                                                </div>

                                                <div className="left-corner">
                                                    Coverage Upto:
                                                </div>
                                                <div className="right-corner">
                                                    {`${data[0].Duration} days`}
                                                </div>

                                                <div className="left-corner">
                                                    Premium:
                                                </div>
                                                <div className="right-corner">
                                                    <p className="pricce">
                                                        <b>{`Rs.${((passengertype === 'family' ? familyFee : singleFee) * 0.85).toFixed(0)}`}</b>                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            <></>
                                        )}

                                    </MDBCardBody>
                                </MDBCard>
                                <MDBCard className="rounded-3">
                                    <MDBCardBody>
                                        <div style={{ paddingBottom: '10px', borderBottom: '1px solid #ccc' }}>
                                            <a
                                                data-toggle="collapse"
                                                href="#collapse1"
                                                style={{ textAlign: 'left', color: '#000' }}
                                                onClick={(event) => handleSectionClick("collapse1", event)}
                                            >

                                                <h2 className="black semibold" style={{ fontSize: 18 }}>
                                                    How will I get the policy?
                                                    <FaAngleDown style={{ float: 'right', marginTop: 0, fontSize: 20, }} />
                                                </h2>
                                            </a>
                                            <div id="collapse1" className={`panel-collapse collapse ${openSection === "collapse1" ? "show" : ""}`}>

                                                <p style={{ textAlign: 'left', color: '#000' }}>
                                                    Once you have successfully purchased travel insurance, your policy will be promptly delivered to you electronically via email. This comprehensive document will encompass all pertinent details of your coverage, including policy limits, terms, and contact information for assistance. Should you prefer a hard copy of the policy or delivery via alternative platforms such as WhatsApp, please feel free to contact our dedicated support team, and we will accommodate your request promptly.                                                </p>

                                            </div>
                                        </div>
                                        <div style={{ paddingTop: '10px', }}>

                                            <a
                                                data-toggle="collapse"
                                                href="#collapse2"
                                                style={{ textAlign: 'left', color: '#000' }}
                                                onClick={(event) => handleSectionClick("collapse2", event)}
                                            >
                                                <h2 className="black semibold" style={{ fontSize: 18 }}>
                                                    How do I know the policy issued is genuine?
                                                    <FaAngleDown style={{ float: 'right', marginTop: 0, fontSize: 20, }} />
                                                </h2>
                                            </a>
                                            <div id="collapse2" className={`panel-collapse collapse ${openSection === "collapse2" ? "show" : ""}`}>

                                                <p style={{ textAlign: 'left', color: '#000' }}>
                                                    Rest assured, all policies issued by Malik Express Travel & Tours are authentic and recognized. You can verify the authenticity of your policy by cross-checking the details provided with our records. Additionally, you can reach out to our dedicated customer support team for any clarification or assistance regarding your policy.                                                </p>

                                            </div>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    </MDBContainer>
                </section>

            )}

            
           
            <Footer></Footer>
        </>
    );
}

const PaymentForm = ({ paymentDetails }) => {
    return (
        <form id="FormPG" action="https://checkout.kuickpay.com/api/Redirection" method="POST">
            {Object.keys(paymentDetails).map((key) => {
                const fieldName = key;
                const fieldValue = paymentDetails[key];
                return (
                    <input
                        key={fieldName}
                        type="hidden"
                        id={fieldName}
                        name={fieldName}
                        value={fieldValue}
                    />
                );
            })}
        </form>
    );
};

const PaymentForms = ({ paymentDetail }) => {
    return (
        <form id="FormPGS" action="https://ipg1.apps.net.pk/Ecommerce/api/Transaction/PostTransaction" method="POST">
            {Object.keys(paymentDetail).map((key) => {
                const fieldName = key;
                const fieldValue = paymentDetail[key];
                return (
                    <input
                        key={fieldName}
                        type="hidden"
                        id={fieldName}
                        name={fieldName}
                        value={fieldValue}
                    />
                );
            })}
        </form>
    );
};




export default SelectPaymnt;
