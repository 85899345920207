import React from 'react'
import {useNavigate} from "react-router-dom";
import Footer from './Home/Footer';
import Layout from './Layout/Layout';
import About from './About/About'
import Home from './Home/Home';
import Newsletters from './Home/Newsletters';

function FrontPage(){
   
    const queryParameters = new URLSearchParams(window.location.search)
    const pp_ResponseCode = queryParameters.get("pp_ResponseCode")
    const pp_ResponseMessage = queryParameters.get("pp_ResponseMessage")
    const pp_BillReference = queryParameters.get("pp_BillReference")

   console.log(pp_ResponseMessage);
   console.log(pp_ResponseCode);

   

    const navigate = useNavigate();

    if (pp_ResponseCode != undefined && pp_ResponseCode !== "")
    {
        navigate('/thankyou',{state:{props: pp_ResponseMessage, pp_ResponseCode: pp_ResponseCode, pp_BillReference: pp_BillReference }});

    }
    
    return(
<>
        <Layout></Layout>
        <Home></Home>
        <Newsletters></Newsletters>
        <Footer></Footer>
        </>
    )
}

export default FrontPage;