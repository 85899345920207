import React, {useEffect} from 'react'
import Features from "../Home/Features";
import Footer from "../Home/Footer";
import MainMenu from "../Layout/MainMenu";
import { Helmet } from 'react-helmet';

function About() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
          <Helmet>
                <title >{"Malik Express | Leading Travel Agency in Islamabad"}</title>
                <meta name="description" content={"Malik Express Travel & Tours is a leading travel agency offering professional travel services. Authorized visa services provider & member of IATA & TAAP. "} data-react-helmet="true"/>
                <meta name="keywords" content={"Travel services, travel agents Pakistan, travel agency in Islamabad Pakistan, travel agents in Islamabad Pakistan, travel agency near me, travel agents, travel agencies in Islamabad, travel agency Islamabad, travel agencies, travel agency in Pakistan"} data-react-helmet="true"/>
                <link rel="canonical" href="https://malikexpress.com/travel-agency-in-islamabad" />
            </Helmet>
         <MainMenu></MainMenu>
            <section className="page-cover" id="cover-faq">
                <div className="container">
                    <div className="row">
                    <ul className="breadcrumb">
                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                <li className="breadcrumb-item">About Us </li>
                            </ul>
                        <div className="col-md-12">
                            <h1 className="page-title">About the Leading Travel Agency in Islamabad – Malik Express</h1>
                          
                        </div>
                    </div>
                </div>
            </section>

            <section className="innerpage-wrapper">
                <div id="about-us">
                    <div id="about-content" className="section-padding">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="flex-content">
                                        <div className="flex-content-img about-img">
                                            <img src="images/mex-about-us.png" className="img-fluid" alt="about-img" />
                                        </div>
                                        <div className="about-text">
                                            <div className="about-detail">
                                                <p>
                                                Malik Express, established in 1992, has emerged as a leading travel agency in Islamabad and a tour operator in Pakistan, spanning all major cities, including Kabul, Afghanistan, and Dubai internationally. Our commitment and professionalism have earned us a stellar reputation, which we uphold by meticulously ensuring the top-quality delivery of our services. From ticketing to visa assistance and travel insurance, we offer extensive travel services to cater to all our clients' travel needs. Let us guide you on your next memorable journey.
                                                </p>
                                              
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="" className="banner-padding ">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <h2>Association with IATA & TAAP</h2>
                                    <p style={{color:"black",fontFamily:"none" }}>
                                    In 2001, shortly after its inception, Malik Express became a proud member of two prestigious associations - the International Air Transport Association (IATA) and the Travel Agents Association of Pakistan (TAAP). As seasoned players in the market, we value innovative technologies and continuously adopt the latest software to provide customized solutions to our clients. Moreover, we partner with world-class hotels across various continents and esteemed international airlines, with direct access to their online reservation and booking system. These offerings enable us to offer impeccable services and make travel a seamless experience for our clients.
                                    </p>                                  
                                </div>
                            </div>
                        </div>
                    </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <h2>Unmatched Travel Insurance with Malik Express</h2>
                                    <p style={{color:"black",fontFamily:"none" }}>
                                    As your trusted travel companion, Malik Express is proud to hold the esteemed position of General Sales Agent (G.S.A) in Pakistan for The Universal Insurance Company Pvt Ltd. Through our collaboration with Euro Cross International, a Dutch company renowned for its top-notch travel insurance and assistance services; we offer the finest travel insurance to our valued customers. 
                                    </p>
                                    <div className="margin-small py-5 mt-5 m-sm-0 "></div>
                                   

                                </div>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <h2>Authorized Visa Drop Box Agent Privilege</h2>
                                    <p style={{color:"black",fontFamily:"none" }}>
                                    But that's not all. We've also earned the privilege of being an authorized Visa Drop Box Agent for the Malaysian High Commission. Our visa services extend to several countries, including Thailand, Singapore, and the United Arab Emirates, where third-party visa processing is permitted. Experience the convenience of effortless travel planning with Malik Express on your side.
                                    </p>
                                    <div className="margin-small py-5 mt-5 m-sm-0 "></div>
                                   

                                </div>
                            </div>
                        </div>
                   
                </div>
            </section>

            <section id="best-features" className="banner-padding orange-features"  style={{borderBottom: 'solid',color:'rgb(255, 193, 7)'}}>
        <div className="container">
            <div className='row'>
            <h2 className='page-heading  m-auto' style={{color:'white'}}>Why Choose Us? </h2>
            </div>
            
          <div className="row">            
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-plane"></i>
                </span>
                <h3 style={{color:'white'}}>Our Inspiration</h3>
                <p>
                At Malik Express, we firmly believe that human capital is integral to the success of any progressive organization. That's why we outdo to employ and retain the exceptional talent in the market. Our inspiring work environment, highly-conducive rewards, and monetary benefits help us attract the cream of the crop. But that's just the beginning. We also continuously nurture our team members so they're always prepared to meet the ever-growing demands of our clients.
                </p>
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-thumbs-up"></i>
                </span>
                <h3 style={{color:'white'}}>Our Commitment </h3>
                <p>
                Our human resources team is trained to identify and anticipate the exact needs of our clients, whether they're individuals or corporate entities. That way, we offer customized solutions that exceed expectations. And it's not just about business success - we genuinely care about our valued clients and their satisfaction.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="b-feature-block">
                <span style={{color:'rgb(255, 193, 7)'}}>
                  <i className="fa fa-suitcase"></i>
                </span>
                <h3 style={{color:'white'}}>Innovative Solutions</h3>
                <p>
                We know technology is vital, but we also believe it's merely a tool. At Malik Express, we use innovative technology solutions to complement person-to-person contact and accommodate our clients in the best possible way. Teamwork, effective communication, and the highest ethical standards are embedded in our company philosophy. 
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

            <Features></Features>
            <Footer></Footer>

        </>
    )
}

export default About;